export class CheckForReuploadRequest {
  ConveyanceName: string;
  InitialValue: string;
  User: string;
  ESFPRID: string;
  EDocsPGNO: string;
  EDocsUCASNo: string;
  EDocsClearingRefNo: string;
  EDocsRegNo: string;
}
