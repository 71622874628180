import * as React from "react";
import { withSize } from "react-sizeme";
import { toTitleCase } from "./Functions";
import { Logging, LoggingLevel } from "../../Logging";
import {
  Callout,
  CommandBar,
  ConstrainMode,
  DefaultButton,
  DetailsHeader,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  DirectionalHint,
  IColumn,
  IconButton,
  IDetailsGroupDividerProps,
  IDetailsHeaderProps,
  IDetailsRowProps,
  IGroup,
  ITooltipHostProps,
  Label,
  MessageBar,
  SelectionMode,
  Separator,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
  Toggle,
  TooltipHost,
} from "@fluentui/react";
import dateFormat from "dateformat";
import { IItem } from "../../models/IItem";
import CookieMonster from "../../assets/icons/Cookiemonster";
import { Button } from "@fluentui/react-components";
import { Link } from "react-router-dom";
import { PreviewImg } from "./PreviewImg";

interface IProps {
  Logger: Logging;
  eid: string;
  size: ISize;
  items: IItem[];
  sort?: string;
  sortdesc?: string;
  group?: string;
  groupdesc?: string;
  groupcond?: string;
  filter?: string;
  columnclass?: string;
  widths?: string;
  format?: string;
  tooltips?: string;
  reorder?: string;
  toprowcount?: string;
  bottomrowcount?: string;
  target?: string;
  funcPassUpFilterSortChange?: any;
  checksum?: any;
  output?: string;
  sproc?: string;
  list?: string;
  gridindex: any;
  start: number;
  limit: number;
  loadInitialOptions?: any;
  showAdvancedSort: boolean;
  showAdvancedGrouping: boolean;
  showAdvancedFiltration: boolean;
  showCookieBouncer: boolean;
  Refresher: string;
  ButtonsAndFunctions?: {
    Name: string;
    ColumnKey: string;
    Function: (id: any, value?: any) => void;
  }[];
  Callouts: boolean;
}

interface IState {
  size: ISize;
  columns: IColumn[];
  groupBy: string[];
  groupByDesc: boolean[];
  groupByCond: Map<string, Map<string, boolean>>;
  rawGroupCounts: Map<string, Map<string, number>>;
  sortBy: string[];
  sortByDesc: boolean[];
  filterBy: string[][];
  defaultColumnOptions: string | undefined;
  currentColumnOptions: string | undefined;
  groups: IGroup[];
  items: IItem[];
  rowCount: number;
  previewItem: IItem | undefined;
  previewData: string | undefined;
  previewLocation: any;
  previewVisible: boolean;
  optionsColumn: IColumn | undefined;
  optionsColumnLocation: any;
  gridindex: any;
  start: number;
  limit: number;
  showAdvancedSort: boolean;
  showAdvancedGrouping: boolean;
  showAdvancedFiltration: boolean;
  showCookieBouncer: boolean;
}

interface ISize {
  width: number;
  height: number;
}

class GridContent extends React.Component<IProps, IState> {
  private filenameColumn = "Filename";
  private fileURLColumn = "Filename_URL";
  private previewURLColumn = "_PreviewURL";
  private viewURLColumn = "_ViewURL";
  private libraryURLColumn = "_LibraryURL";
  private editURLColumn = "_EditURL";

  createPageList = () => {
    let numlist = [];
    var thisLimit = this.state.limit || 200;
    var thisStart = this.state.start || 0;
    var itemcount = this.state.items.length;
    var pagecount = itemcount / thisLimit;
    if (itemcount % thisLimit > 0) {
      pagecount++;
    }
    var currentPage = thisStart / thisLimit + 1; // essentially a zero-indexed page number.

    var dotted: boolean = false;
    for (let i = 1; i <= pagecount; i++) {
      if (
        i < 3 ||
        i > pagecount - 3 ||
        (i > currentPage - 3 && i < currentPage + 3)
      ) {
        dotted = false;
        numlist.push(
          i === currentPage ? (
            <div key={"page_" + i} className="pageNo thisPage">
              {i}
            </div>
          ) : (
            <div
              key={"page_" + i}
              className="pageNo clickable"
              onClick={() => this.goToPage_Function(i)}
            >
              {i.toString()}
            </div>
          ),
        );
      } else if (dotted === false) {
        numlist.push(
          <div key={"dots_" + i} className="betweenPageNos">
            ...
          </div>,
        );
        dotted = true;
      }
    }
    return numlist;
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      gridindex: this.props.gridindex,
      size: this.props.size,
      columns: [],
      groupBy: [],
      groupByDesc: [],
      groupByCond: new Map<string, Map<string, boolean>>(),
      rawGroupCounts: new Map<string, Map<string, number>>(),
      sortBy: [],
      sortByDesc: [],
      filterBy: [],
      defaultColumnOptions: undefined,
      currentColumnOptions: undefined,
      groups: [],
      items: [],
      rowCount: 0,
      previewItem: undefined,
      previewData: undefined,
      previewLocation: undefined,
      previewVisible: false,
      optionsColumn: undefined,
      optionsColumnLocation: undefined,
      start: 0,
      limit: 0,
      showAdvancedSort: this.props.showAdvancedSort,
      showAdvancedGrouping: this.props.showAdvancedGrouping,
      showAdvancedFiltration: this.props.showAdvancedFiltration,
      showCookieBouncer: this.props.showCookieBouncer,
    };
  }

  public componentDidMount() {
    let {
      groupBy,
      groupByDesc,
      groupByCond,
      sortBy,
      sortByDesc,
      filterBy,
      start,
    } = this.state;
    if (this.props.group !== undefined) {
      this.props.group.split(",").forEach((c, i) => {
        groupBy.push(c);
        groupByDesc.push(
          this.props.groupdesc !== undefined &&
            this.props.groupdesc.split(",").length > i &&
            this.props.groupdesc.split(",")[i].toLowerCase() === "true",
        );
      });
    }
    if (this.props.sort !== undefined) {
      this.props.sort.split(",").forEach((c, i) => {
        sortBy.push(c);
        sortByDesc.push(
          this.props.sortdesc !== undefined &&
            this.props.sortdesc.split(",").length > i &&
            this.props.sortdesc.split(",")[i].toLowerCase() === "true",
        );
      });
    }
    if (this.props.filter !== undefined) {
      this.props.filter.split(";").forEach((f) => {
        filterBy.push(f.split(","));
      });
    }

    const defaultColumnOptions = [
      groupBy,
      groupByDesc,
      groupByCond,
      sortBy,
      sortByDesc,
      filterBy,
      start,
    ];
    this.props.Logger.log(
      "Checking for Cookie: co__" +
        this.props.eid.replaceAll(this.props.Refresher, "") +
        "__" +
        (this.props.output ? this.props.output : "") +
        "__" +
        (this.props.list ? this.props.list : "") +
        "__" +
        (this.props.sproc ? this.props.sproc : "") +
        "__" +
        (this.props.gridindex ? this.props.gridindex : ""),
      LoggingLevel.INFO,
    );
    const columnOptions = JSON.parse(
      localStorage.getItem(
        "co__" +
          this.props.eid.replaceAll(this.props.Refresher, "") +
          "__" +
          (this.props.output ? this.props.output : "") +
          "__" +
          (this.props.list ? this.props.list : "") +
          "__" +
          (this.props.sproc ? this.props.sproc : "") +
          "__" +
          (this.props.gridindex ? this.props.gridindex : ""),
      ) || "{}",
    );
    if (columnOptions) {
      this.props.Logger.log(
        "Loaded Column Options from Cookie: " + JSON.stringify(columnOptions),
        LoggingLevel.INFO,
      );
      groupBy = columnOptions[0] || undefined;
      groupByDesc = columnOptions[1] || undefined;
      groupByCond = columnOptions[2] || undefined;
      sortBy = columnOptions[3] || undefined;
      sortByDesc = columnOptions[4] || undefined;
      filterBy = columnOptions[5] || undefined;
      start = columnOptions[6] || 0;
    }

    this.setState({
      groupBy: groupBy || this.state.groupBy,
      groupByDesc: groupByDesc || this.state.groupByDesc,
      groupByCond: groupByCond || this.state.groupByCond,
      sortBy: sortBy || this.state.sortBy,
      sortByDesc: sortByDesc || this.state.sortByDesc,
      filterBy: filterBy || this.state.filterBy,
      start: start || this.props.start,
      limit: this.props.limit,
      defaultColumnOptions: JSON.stringify(defaultColumnOptions),
      currentColumnOptions: JSON.stringify(columnOptions),
    });

    this.manageCookies();

    this.generateState();
  }

  // Urg. This is a nasty hack, but is required to trigger a rerender in the event
  // we change the items being provided. Otherwise the GridContent will only use
  // the initial props provided to calculate the state, and will not update.
  // I'm aware that the method is deprecated, but the entire GridContent is using
  // older methods and this is the only way to make it work without a rewrite.
  public UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<IProps>,
    nextContext: any,
  ): void {
    if (JSON.stringify(nextProps.items) != JSON.stringify(this.props.items)) {
      this.generateState();
    }
  }

  public componentDidUpdate() {
    if (this.props.items.length !== this.state.rowCount) {
      //  this.props.Logger.log('- length test POSITIVE', LoggingLevel.DEBUG);
      //  this.props.Logger.log('New Items Detected (was ' + this.state.rowCount + ', now ' + this.props.items.length + ')', LoggingLevel.DEBUG);
      this.generateState();
    } else if (this.props.size.width !== this.state.size.width) {
      //  this.props.Logger.log('- width test POSITIVE', LoggingLevel.DEBUG);
      //  this.props.Logger.log('Resize Detected (from ' + this.state.size.width + 'px to ' + this.props.size.width + 'px)', LoggingLevel.DEBUG);
      this.generateState();
    }
  }

  public render() {
    const { columns, groups } = this.state;

    return (
      <div className={"UoE-DataGrid"}>
        {this.state.showCookieBouncer === true ? (
          <Button
            tabIndex={0}
            className="CookieMonster"
            onClick={() => this.CookieMonster()}
            title="Clear Cookies (reset to screen defaults)"
          >
            <CookieMonster />
          </Button>
        ) : (
          ""
        )}
        {this.state.filterBy !== null &&
        this.state.filterBy.length > 0 &&
        this.state.showAdvancedFiltration === true ? (
          <div className="FilterList">
            <div className="FilterListTitle">Filters:</div>
            {this.state.filterBy &&
              this.state.filterBy.map((key, k) => (
                <div className="FilterListCapsule" key={k}>
                  <div className="FilterListColName">{key[0]}</div>
                  <div className="FilterListValueChanger">
                    <TextField
                      name={key[0]}
                      value={this.state.filterBy[k][1].trim()}
                      onChange={(ev, nv) =>
                        this.FilterChange_Event_Top(ev, nv, key)
                      }
                      onBlur={(ev) => this.FilterBlur_Event_Top(ev)}
                    />
                  </div>
                  <div className="FilterListRemover">
                    <IconButton
                      iconProps={{ iconName: "Cancel" }}
                      onClick={() => this.FilterClear_Function(key[0])}
                      title="Remove Filter"
                    />
                  </div>
                </div>
              ))}
          </div>
        ) : (
          ""
        )}
        {this.state.groupBy !== null &&
        this.state.groupBy.length > 0 &&
        this.state.groupByDesc !== null &&
        this.state.groupByDesc.length > 0 &&
        this.state.showAdvancedGrouping === true ? (
          <div className="GroupRanker">
            <div className="GroupRankerTitle">Grouping:</div>
            {this.state.groupBy &&
              this.state.groupBy.map((key, k) => (
                <div className="GroupRankerCapsule" key={k}>
                  <div className="GroupRankerShuntUp">
                    <IconButton
                      iconProps={{ iconName: "Up" }}
                      onClick={() => this.GroupShuntUp_Function(key)}
                      disabled={k === 0}
                      title={
                        k === 0
                          ? "Prioritise Group: Already at the top of the chain."
                          : "Prioritise Group: Move this group up the chain."
                      }
                    />
                  </div>
                  <div className="GroupRankerColName">{key}</div>
                  {this.state.groupByDesc[k] === true ? (
                    <div className="GroupRankerAscendingSwitcher">
                      <IconButton
                        iconProps={{ iconName: "CaretSolidUp" }}
                        onClick={() => this.GroupAscendingChange_Function(key)}
                        disabled={this.state.groupByDesc[k] === false}
                        title={
                          this.state.groupByDesc[k] === false
                            ? "Group Ascending: Already ascending."
                            : "Group Ascending: Group by this column and sort the groups in ascending order."
                        }
                      />
                    </div>
                  ) : (
                    <div className="GroupRankerDescendingSwitcher">
                      <IconButton
                        iconProps={{ iconName: "CaretSolidDown" }}
                        onClick={() => this.GroupDescendingChange_Function(key)}
                        disabled={this.state.groupByDesc[k] === true}
                        title={
                          this.state.groupByDesc[k] === true
                            ? "Group Descending: Already descending."
                            : "Group Descending: Group by this column and sort the groups in descending order."
                        }
                        src={key}
                      />
                    </div>
                  )}
                  <div className="GroupRankerRemover">
                    <IconButton
                      iconProps={{ iconName: "Cancel" }}
                      onClick={() => this.GroupClear_Function(key)}
                      title="Remove Group: Remove this group from the chain."
                    />
                  </div>
                  <div className="GroupRankerShuntDown">
                    <IconButton
                      iconProps={{ iconName: "Down" }}
                      onClick={() => this.GroupShuntDown_Function(key)}
                      disabled={k === this.state.groupBy.length - 1}
                      title={
                        k === this.state.groupBy.length - 1
                          ? "Deprioritise Group: Already at the bottom of the chain."
                          : "Deprioritise Group: Move this group down the chain."
                      }
                    />
                  </div>
                </div>
              ))}
          </div>
        ) : (
          ""
        )}
        {this.state.sortBy !== null &&
        this.state.sortBy.length > 0 &&
        this.state.sortByDesc !== null &&
        this.state.sortByDesc.length > 0 &&
        this.state.showAdvancedSort === true ? (
          <div className="SortRanker">
            <div className="SortRankerTitle">Sorting:</div>
            {this.state.sortBy &&
              this.state.sortBy.map((key, k) => (
                <div className="SortRankerCapsule" key={k}>
                  <div className="SortRankerShuntUp">
                    <IconButton
                      iconProps={{ iconName: "Up" }}
                      onClick={() => this.SortShuntUp_Function(key)}
                      disabled={k === 0}
                      title={
                        k === 0
                          ? "Prioritise Sort: Already at the top of the chain."
                          : "Prioritise Sort: Move this sort up the chain."
                      }
                    />
                  </div>
                  <div className="SortRankerColName">{key}</div>
                  {this.state.sortByDesc[k] === false ? (
                    <div className="SortRankerDescendingSwitcher">
                      <IconButton
                        iconProps={{ iconName: "CaretSolidDown" }}
                        onClick={() => this.SortDescendingChange_Function(key)}
                        disabled={this.state.sortByDesc[k] === true}
                        title={
                          this.state.sortByDesc[k] === true
                            ? "Sort Descending: Already descending."
                            : "Sort Descending: Sort this column in descending order."
                        }
                        src={key}
                      />
                    </div>
                  ) : (
                    <div className="SortRankerAscendingSwitcher">
                      <IconButton
                        iconProps={{ iconName: "CaretSolidUp" }}
                        onClick={() => this.SortAscendingChange_Function(key)}
                        disabled={this.state.sortByDesc[k] === false}
                        title={
                          this.state.sortByDesc[k] === false
                            ? "Sort Ascending: Already ascending."
                            : "Sort Ascending: Sort this column in ascending order."
                        }
                      />
                    </div>
                  )}
                  <div className="SortRankerRemover">
                    <IconButton
                      iconProps={{ iconName: "Cancel" }}
                      onClick={() => this.SortClear_Function(key)}
                      title="Remove Sort: Remove this sort from the chain."
                    />
                  </div>
                  <div className="SortRankerShuntDown">
                    <IconButton
                      iconProps={{ iconName: "Down" }}
                      onClick={() => this.SortShuntDown_Function(key)}
                      disabled={k === this.state.sortBy.length - 1}
                      title={
                        k === this.state.sortBy.length - 1
                          ? "Deprioritise Sort: Already at the bottom of the chain."
                          : "Deprioritise Sort: Move this sort down the chain."
                      }
                    />
                  </div>
                </div>
              ))}
          </div>
        ) : (
          ""
        )}

        {this.state.limit !== undefined &&
        this.state.limit < this.state.items.length ? (
          <div className="PaginationArea">
            <div className="PaginationTitle">
              {(this.props.toprowcount || "Total Records: %").replace(
                "%",
                (this.state.items.length !== this.state.rowCount
                  ? this.state.items.length.toString() + " of "
                  : "") + this.state.rowCount.toString(),
              ) +
                " (" +
                this.state.limit.toString() +
                " per page)"}
            </div>
            {this.state.start !== null && this.state.start !== 0 ? (
              <div
                className="PageBackButton clickable"
                onClick={() => this.PageBack_Function()}
              >
                Previous Page
              </div>
            ) : (
              <div className="PageBackButton">Previous Page</div>
            )}
            {this.createPageList()}
            {this.state.start !== null &&
            this.state.items.length < this.state.limit + this.state.start &&
            this.state.items.length > this.state.start ? (
              <div className="PageForwardButton">Next Page</div>
            ) : (
              <div
                className="PageForwardButton clickable"
                onClick={() => this.PageForward_Function()}
              >
                Next Page
              </div>
            )}
          </div>
        ) : this.props.toprowcount !== undefined ? (
          <Label>
            {this.props.toprowcount.replace(
              "%",
              (this.state.items.length !== this.state.rowCount
                ? this.state.items.length.toString() + " of "
                : "") + this.state.rowCount.toString(),
            )}
          </Label>
        ) : (
          ""
        )}
        <DetailsList
          items={
            this.state.limit < this.state.rowCount
              ? this.state.items.slice(
                  this.state.start,
                  this.state.start + this.state.limit,
                )
              : this.state.items
          }
          compact={true}
          columns={columns.length > 0 ? columns : undefined}
          groups={
            groups.length > 0
              ? this.computedgroups(
                  this.state.items,
                  this.state.limit < this.state.rowCount
                    ? this.state.items.slice(
                        this.state.start,
                        this.state.start + this.state.limit,
                      )
                    : this.state.items,
                  this.state.groupBy,
                  this.state.groupByCond,
                  this.state.rawGroupCounts,
                )
              : undefined
          }
          onColumnHeaderClick={this.onColumnClick}
          selectionMode={SelectionMode.none}
          constrainMode={ConstrainMode.horizontalConstrained}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          onRenderRow={this.onRenderRow}
          onRenderDetailsHeader={this.onRenderDetailsHeader}
          groupProps={{
            onRenderHeader: this.onRenderGroupHeader,
            onToggleCollapseAll: this.GroupsToggle_Event,
          }}
          onShouldVirtualize={(props) => false}
        />
        {this.props.bottomrowcount !== undefined ? (
          <Label>
            {this.props.bottomrowcount.replace(
              "%",
              this.state.rowCount.toString(),
            )}
          </Label>
        ) : (
          ""
        )}
        {this.state.limit !== undefined &&
        this.state.limit < this.state.rowCount ? (
          <div className="PaginationArea">
            {this.state.start !== null && this.state.start !== 0 ? (
              <div
                className="PageBackButton clickable"
                onClick={() => this.PageBack_Function()}
              >
                Previous Page
              </div>
            ) : (
              <div className="PageBackButton">Previous Page</div>
            )}
            {this.createPageList()}
            {this.state.start !== null &&
            this.state.items.length > this.state.limit + this.state.start ? (
              <div
                className="PageForwardButton clickable"
                onClick={() => this.PageForward_Function()}
              >
                Next Page
              </div>
            ) : (
              <div className="PageForwardButton">Next Page</div>
            )}
          </div>
        ) : (
          <span />
        )}
        <Callout
          directionalHint={DirectionalHint.bottomCenter}
          target={this.state.optionsColumnLocation}
          hidden={this.state.optionsColumn ? false : true}
          preventDismissOnLostFocus={false}
          preventDismissOnResize={true}
          preventDismissOnScroll={true}
          onDismiss={() =>
            this.setState({
              optionsColumn: undefined,
              optionsColumnLocation: undefined,
            })
          }
        >
          <MessageBar
            styles={{ root: { backgroundColor: "white" } }}
            onDismiss={() =>
              this.setState({
                optionsColumn: undefined,
                optionsColumnLocation: undefined,
              })
            }
            dismissButtonAriaLabel="Close"
          >
            <strong>
              {this.state.optionsColumn ? this.state.optionsColumn.name : ""}
            </strong>
          </MessageBar>
          {this.state.optionsColumn ? (
            <Stack tokens={{ padding: "5px 5px 5px 5px" }}>
              <Separator>
                <strong>Sorting</strong>
              </Separator>
              <Toggle
                label="Sort Ascending"
                inlineLabel
                checked={
                  this.state.sortBy.includes(this.state.optionsColumn!.key) &&
                  this.state.sortByDesc[
                    this.state.sortBy.indexOf(this.state.optionsColumn!.key)
                  ] === false
                }
                onChange={(ev, c) => this.SortAscendingChange_Event(ev, c)}
              />
              <Toggle
                label="Sort Descending"
                inlineLabel
                checked={
                  this.state.sortBy.includes(this.state.optionsColumn!.key) &&
                  this.state.sortByDesc[
                    this.state.sortBy.indexOf(this.state.optionsColumn!.key)
                  ] === true
                }
                onChange={(ev, c) => this.SortDescendingChange_Event(ev, c)}
              />
              <Separator>
                <strong>Grouping</strong>
              </Separator>
              <Toggle
                label="Group"
                inlineLabel
                checked={this.state.groupBy.includes(
                  this.state.optionsColumn!.key,
                )}
                onChange={(ev, c) => this.GroupChange_Event(ev, c)}
              />
              <Separator>
                <strong>Filtering</strong>
              </Separator>
              <Stack horizontal>
                <TextField
                  value={
                    this.state.filterBy.some(
                      (f) => f[0] === this.state.optionsColumn!.key,
                    )
                      ? this.state.filterBy
                          .find(
                            (f) => f[0] === this.state.optionsColumn!.key,
                          )![1]
                          .trim()
                      : ""
                  }
                  onChange={(ev, nv) => this.FilterChange_Event(ev, nv)}
                  onBlur={() => this.FilterBlur_Event()}
                />
                <IconButton
                  iconProps={{ iconName: "Cancel" }}
                  title="Remove Filter"
                  onClick={() => this.FilterClear_Event()}
                />
              </Stack>
              <Separator>
                <strong>Clear All</strong>
              </Separator>
              <Stack horizontal horizontalAlign="center">
                <IconButton
                  iconProps={{ iconName: "Sort" }}
                  title="Clear Sorting"
                  disabled={this.state.sortBy.length <= 0}
                  onClick={() => this.SortsClear_Event()}
                />
                <IconButton
                  iconProps={{ iconName: "GroupedDescending" }}
                  title="Clear Grouping"
                  disabled={this.state.groupBy.length <= 0}
                  onClick={() => this.GroupsClear_Event()}
                />
                <IconButton
                  iconProps={{ iconName: "Filter" }}
                  title="Clear Filtering"
                  disabled={this.state.filterBy.length <= 0}
                  onClick={() => this.FiltersClear_Event()}
                />
                <IconButton
                  iconProps={{ iconName: "ColumnOptions" }}
                  title="Clear All"
                  disabled={
                    this.state.sortBy.length <= 0 &&
                    this.state.groupBy.length <= 0 &&
                    this.state.filterBy.length <= 0
                  }
                  onClick={() => this.AllClear_Event()}
                />
              </Stack>
            </Stack>
          ) : (
            <span />
          )}
        </Callout>
        {this.props.Callouts === false ? (
          ""
        ) : (
          <React.Fragment>
            <Callout
              className="DocumentPreview"
              directionalHint={DirectionalHint.rightBottomEdge}
              directionalHintFixed={false}
              target={this.state.previewLocation}
              hidden={this.state.previewItem ? false : true}
              calloutMaxWidth={400}
              calloutMaxHeight={470}
              onDismiss={() =>
                this.setState({
                  previewItem: undefined,
                  previewLocation: undefined,
                })
              }
            >
              <div onMouseLeave={(ev) => this.onMouseLeave(ev)}>
                <MessageBar
                  styles={{ root: { backgroundColor: "white" } }}
                  onDismiss={() =>
                    this.setState({
                      previewItem: undefined,
                      previewLocation: undefined,
                    })
                  }
                  dismissButtonAriaLabel="Close"
                >
                  {this.state.previewItem
                    ? this.state.previewItem[this.filenameColumn]
                    : ""}
                </MessageBar>
                <Spinner
                  size={SpinnerSize.large}
                  style={{
                    position: "absolute",
                    top: 200,
                    left: 180,
                    visibility: this.state.previewVisible
                      ? "hidden"
                      : "visible",
                  }}
                />
                {this.state.previewItem &&
                this.state.previewItem[this.previewURLColumn] ? (
                  this.state.previewItem[this.previewURLColumn]
                    .toLowerCase()
                    .endsWith(".png") ||
                  this.state.previewItem[this.previewURLColumn]
                    .toLowerCase()
                    .endsWith(".jpg") ||
                  this.state.previewItem[this.previewURLColumn]
                    .toLowerCase()
                    .endsWith(".jpeg") ||
                  this.state.previewItem[this.previewURLColumn]
                    .toLowerCase()
                    .endsWith(".gif") ? (
                    <div
                      style={{
                        height: 390,
                        width: 400,
                        verticalAlign: "middle",
                        textAlign: "center",
                        visibility: this.state.previewVisible
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      {this.state.previewItem["_ident"] !== undefined ? (
                        <PreviewImg
                          ident={this.state.previewItem["_ident"]}
                          docid={this.state.previewItem["_cacheId"]}
                          filename={this.state.previewItem["_previewType"]}
                          visible={this.state.previewVisible}
                        />
                      ) : (
                        <img
                          src={
                            this.state.previewItem
                              ? this.state.previewItem[this.previewURLColumn]
                              : ""
                          }
                          alt={
                            this.state.previewItem
                              ? this.state.previewItem[this.fileURLColumn]
                              : ""
                          }
                          style={{
                            maxHeight: 390,
                            maxWidth: 400,
                            visibility: this.state.previewVisible
                              ? "visible"
                              : "hidden",
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      {this.state.previewItem["_ident"] !== undefined ? (
                        <PreviewImg
                          ident={this.state.previewItem["_ident"]}
                          docid={this.state.previewItem["_cacheId"]}
                          filename={this.state.previewItem["_previewType"]}
                          visible={this.state.previewVisible}
                        />
                      ) : (
                        <iframe
                          src={
                            this.state.previewItem
                              ? this.state.previewItem[this.previewURLColumn]
                              : ""
                          }
                          title={
                            this.state.previewItem
                              ? this.state.previewItem[this.fileURLColumn]
                              : ""
                          }
                          width="100%"
                          height="100%"
                          frameBorder={0}
                          marginWidth={0}
                          marginHeight={0}
                          style={{
                            height: 390,
                            width: 400,
                            visibility: this.state.previewVisible
                              ? "visible"
                              : "hidden",
                          }}
                        />
                      )}
                    </>
                  )
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: 150,
                      height: 240,
                      width: 400,
                      visibility: this.state.previewVisible
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    <em>No preview available for this file.</em>
                  </div>
                )}
                <CommandBar
                  items={[
                    // { key: 'EDIT', name: 'EDIT', href: this.state.previewItem && this.state.previewItem[this.fileURLColumn] ? this.state.previewItem[this.fileURLColumn] : '', target: '_blank', disabled: !(this.state.previewItem && this.state.previewItem[this.fileURLColumn]) },
                    {
                      key: "VIEW",
                      //  name: "VIEW",
                      //  href:
                      //    this.state.previewItem &&
                      //    this.state.previewItem[this.viewURLColumn]
                      //      ? this.state.previewItem[this.viewURLColumn]
                      //      : "",
                      target: "_blank",
                      disabled: !(
                        this.state.previewItem &&
                        this.state.previewItem[this.viewURLColumn]
                      ),
                      commandBarButtonAs: () => (
                        <Link
                          className="ViewCommand"
                          title="VIEW"
                          to={
                            this.state.previewItem &&
                            this.state.previewItem[this.fileURLColumn]
                              ? this.state.previewItem[this.fileURLColumn]
                              : ""
                          }
                          target="_blank"
                        >
                          VIEW
                        </Link>
                      ),
                    },
                    // { key: 'SEND', name: 'SEND', href: this.state.previewItem && this.state.previewItem[this.viewURLColumn] ? ('mailto:?subject=' + encodeURIComponent(this.state.previewItem[this.filenameColumn]) + '&body=' + encodeURIComponent('Open:\n<' + this.state.previewItem[this.fileURLColumn] + '>\nOpen in Web:\n<' + this.state.previewItem[this.viewURLColumn] + '>')) : '', target: '_blank', disabled: !(this.state.previewItem && this.state.previewItem[this.viewURLColumn]) },
                    // { key: 'VIEW LIBRARY', name: 'VIEW LIBRARY', href: this.state.previewItem && this.state.previewItem[this.libraryURLColumn] ? this.state.previewItem[this.libraryURLColumn] : '', target: '_blank', disabled: !(this.state.previewItem && this.state.previewItem[this.libraryURLColumn]) },
                    {
                      key: "REFILE",
                      name: "REFILE",
                      href:
                        this.state.previewItem &&
                        this.state.previewItem[this.editURLColumn]
                          ? this.state.previewItem[this.editURLColumn]
                          : "",
                      //target: "_blank",
                      disabled: !(
                        this.state.previewItem &&
                        this.state.previewItem[this.editURLColumn]
                      ),
                      commandBarButtonAs: () =>
                        this.state.previewItem &&
                        this.state.previewItem[this.editURLColumn] !=
                          undefined &&
                        this.state.previewItem[this.editURLColumn] != "" ? (
                          <Link
                            className="RefileCommand"
                            title="REFILE"
                            to={
                              this.state.previewItem &&
                              this.state.previewItem[this.editURLColumn]
                                ? this.state.previewItem[this.editURLColumn]
                                : ""
                            }
                          >
                            REFILE
                          </Link>
                        ) : (
                          <></>
                        ),
                    },
                  ]}
                />
              </div>
            </Callout>
            <Callout
              directionalHint={DirectionalHint.bottomAutoEdge}
              target={this.state.previewLocation}
              hidden={this.state.previewData ? false : true}
              preventDismissOnLostFocus={false}
              preventDismissOnResize={true}
              preventDismissOnScroll={true}
              onDismiss={() =>
                this.setState({
                  previewData: undefined,
                  previewLocation: undefined,
                })
              }
            >
              <MessageBar
                className="moreTextHeader"
                styles={{ root: { backgroundColor: "white" } }}
                onDismiss={() =>
                  this.setState({
                    previewData: undefined,
                    previewLocation: undefined,
                  })
                }
                dismissButtonAriaLabel="Close"
              />
              <div
                className="moreText"
                // style={{ height: 240, width: 400, margin: 10 }} -moved to css
                dangerouslySetInnerHTML={{ __html: this.state.previewData! }}
              />
            </Callout>
          </React.Fragment>
        )}
      </div>
    );
  }

  private generateState() {
    let newColumns: IColumn[] = [];
    if (this.props.items.length > 0) {
      let rowColumns = Object.keys(this.props.items[0]);
      if (this.props.reorder !== undefined) {
        let ro = this.props.reorder.split(",").filter((o) => {
          return rowColumns.includes(o);
        });
        rowColumns = ro.concat(rowColumns);
      }

      let columnTitleMap: any = {};

      rowColumns.forEach((c) => {
        if (
          c.charAt(0) === "_" ||
          c.endsWith("_URL") ||
          c.endsWith("_LINK") ||
          c.endsWith("_TARGET") ||
          c.endsWith("_CSS") ||
          c.endsWith("_CLASS") ||
          c.endsWith("_ICONFLAG") ||
          c.endsWith("_ICON") ||
          c.endsWith("_FLAG") ||
          c.endsWith("_MORE") ||
          c.endsWith("_FORMAT") ||
          c.endsWith("_TITLE")
        ) {
          return;
        } else {
          columnTitleMap[c] = toTitleCase(
            c
              .replace(/_IMG$/g, "")
              .replace(/_A$/g, "")
              .replace(/_DATE$/g, "")
              .replace(/_HTML$/g, "")
              .replace(/_BUTTON$/g, "")
              .replace(/_FUNC$/g, "")
              .replace(/_CHECKBOX$/g, "")
              .replace(/_TEXTENTRY$/g, "")
              .replace(/_/g, " "),
          );
        }
      });

      rowColumns.forEach((c) => {
        if (c.endsWith("_TITLE")) {
          columnTitleMap[c.replace("_TITLE", "")] = this.props.items[0][c];
        }
      });

      rowColumns.forEach((c) => {
        if (
          c.charAt(0) === "_" ||
          c.endsWith("_URL") ||
          c.endsWith("_LINK") ||
          c.endsWith("_TARGET") ||
          c.endsWith("_CSS") ||
          c.endsWith("_CLASS") ||
          c.endsWith("_ICONFLAG") ||
          c.endsWith("_ICON") ||
          c.endsWith("_FLAG") ||
          c.endsWith("_MORE") ||
          c.endsWith("_FORMAT") ||
          c.endsWith("_TITLE")
        )
          return;

        if (
          this.state.groupBy.includes(c) &&
          !rowColumns.some((s) => s === c + "_LINK" || s === c + "_URL")
        )
          return;
        const col: IColumn = {
          fieldName: c,
          isResizable: false,
          isPadded: false,
          key: c,
          minWidth: 0,
          name: columnTitleMap[c],
        };

        if (this.props.columnclass !== undefined) {
          const colClass = this.props.columnclass
            .split(";")
            .find((cc) => cc.split(",")[0] === col.key);
          if (colClass !== undefined)
            col.className = colClass.split(",")[1].trim();
        }
        if (
          this.props.widths !== undefined &&
          this.props.widths.split(";").some((w) => w.split(",")[0] === col.key)
        ) {
          if (col.className === undefined) col.className = "";
          col.className += " fixedWidth";
          col.className = col.className.trim();
        }

        col.onRender = (item: IItem) => {
          if (!Object.keys(item).includes(col.key)) return "";
          let css = "";
          if (item[col.key + "_CSS"] !== undefined) {
            css += " " + item[col.key + "_CSS"].trim();
          }
          css = css.trim();
          if (item[col.key + "_CLASS"] !== undefined) {
            css += " " + item[col.key + "_CLASS"].trim();
          }
          css = css.trim();
          let link = "";
          if (item[col.key + "_LINK"] !== undefined) {
            link = item[col.key + "_LINK"].trim();
          }
          if (item[col.key + "_URL"] !== undefined) {
            link = item[col.key + "_URL"].trim();
          }
          let target = "";
          if (this.props.target !== undefined) {
            target = this.props.target;
          }
          if (item[col.key + "_TARGET"] !== undefined) {
            target = item[col.key + "_TARGET"].trim();
          }
          let iconflag = "";
          if (item[col.key + "_ICONFLAG"] !== undefined) {
            iconflag = item[col.key + "_ICONFLAG"].trim();
          }
          let icon = "";
          if (item[col.key + "_ICON"] !== undefined) {
            icon = item[col.key + "_ICON"].trim();
          }
          let flag = "";
          if (item[col.key + "_FLAG"] !== undefined) {
            flag = item[col.key + "_FLAG"].trim();
          }
          let more = "";
          if (item[col.key + "_MORE"] !== undefined) {
            more = item[col.key + "_MORE"].trim();
          }
          let format = "";
          if (item[col.key + "_FORMAT"] !== undefined) {
            format = item[col.key + "_FORMAT"].trim();
          }

          let content: any = item[col.key] ? item[col.key].trim() : "";
          if (
            col.key.endsWith("_IMG") ||
            (content.startsWith("HTTP") &&
              (content.endsWith(".JPG") || content.endsWith(".PNG")))
          ) {
            content = <img src={content} alt={content} />;
          } else if (col.key.endsWith("_A") || content.startsWith("HTTP")) {
            content = (
              <Link to={content} target={target}>
                {content}
              </Link>
              // <a href={content} target={target}>
              //   {content}
              // </a>
            );
          } else if (
            (col.key.endsWith("_DATE") && content !== "") ||
            (content.match(/^\d{4}-\d{2}-\d{2}/) &&
              !Number.isNaN(Date.parse(content)))
          ) {
            const d = new Date(content);
            content =
              d.getHours() === 0 && d.getMinutes() === 0 && d.getSeconds() === 0
                ? d.toLocaleDateString()
                : d.toLocaleString();
            if (format !== "" || this.props.format !== undefined) {
              if (format === "" && this.props.format !== undefined) {
                const format2 = this.props.format
                  .split(";")
                  .find((f) => f.split(",")[0] === col.key);
                if (format2 !== undefined) format = format2.split(",")[1];
              }
              if (format !== "") {
                content = dateFormat(d, format);
              }
            }
          } else if (col.key.endsWith("_HTML") || content.match(/<[^>]*>/)) {
            content = <span dangerouslySetInnerHTML={{ __html: content }} />;
          } else if (col.key.endsWith("_FUNC")) {
            if (content === col.key.replace("_FUNC", "")) {
              const thisBAF = this.props.ButtonsAndFunctions.find(
                (th) => th.Name === content,
              );
              content = (
                <div
                  className="InducedFunction"
                  onClick={() => {
                    thisBAF.Function(item[thisBAF.ColumnKey]);
                  }}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              );
            } else {
              content = <div dangerouslySetInnerHTML={{ __html: content }} />;
            }
          } else if (col.key.endsWith("_CHECKBOX")) {
            const thisBAF = this.props.ButtonsAndFunctions.find(
              (th) => th.Name === col.key,
            );
            content = (
              <div className="GridCheckbox">
                <input
                  type="checkbox"
                  onChange={() => {
                    thisBAF.Function(item[thisBAF.ColumnKey]);
                  }}
                  checked={JSON.parse(content.toLowerCase()) === true}
                  id={item[thisBAF.ColumnKey] + col.key.toString()}
                ></input>
                <label
                  htmlFor={item[thisBAF.ColumnKey] + col.key.toString()}
                ></label>
              </div>
            );
          } else if (col.key.endsWith("_TEXTENTRY")) {
            const thisBAF = this.props.ButtonsAndFunctions.find(
              (th) => th.Name === col.key,
            );
            content = (
              <div className="GridTextEntry">
                <input
                  type="text"
                  onChange={(change) => {
                    thisBAF.Function(
                      item[thisBAF.ColumnKey],
                      change.target.value,
                    );
                  }}
                  value={content}
                  id={item[thisBAF.ColumnKey] + col.key.toString()}
                ></input>
                <label
                  htmlFor={item[thisBAF.ColumnKey] + col.key.toString()}
                ></label>
              </div>
            );
          }

          if (col.key.endsWith("_BUTTON") && item[col.key].trim() !== "") {
            const button_key = col.key.replace(/_BUTTON$/g, "");
            var button_link = "";
            if (item[button_key + "_LINK"] !== undefined) {
              button_link = item[button_key + "_LINK"].trim();
            }
            if (item[button_key + "_URL"] !== undefined) {
              button_link = item[button_key + "_URL"].trim();
            }
            var button_css = "";
            if (item[button_key + "_CSS"] !== undefined) {
              button_css = item[button_key + "_CSS"].trim();
            }
            button_css = css.trim();
            if (item[button_key + "_CLASS"] !== undefined) {
              button_css = item[button_key + "_CLASS"].trim();
            }
            button_css = css.trim();
            var button_icon = "";
            if (item[button_key + "_ICON"] !== undefined) {
              button_icon = item[button_key + "_ICON"].trim();
            }
            content = (
              <DefaultButton
                className={button_css}
                iconProps={{ iconName: button_icon }}
                onClick={() => {
                  window.location.href = button_link;
                }}
              >
                {content}
              </DefaultButton>
            );
          }

          if (link !== "") {
            content = (
              <Link to={link} className={css} target={target}>
                {content}
              </Link>
              // <a href={link} className={css} target={target}>
              //   {content}
              // </a>
            );
          } else if (css !== "") {
            content = <span className={css}>{content}</span>;
          }

          if (iconflag !== "") {
            content = (
              <span>
                <span dangerouslySetInnerHTML={{ __html: iconflag }}></span>
                {content}&nbsp;
              </span>
            );
          }
          if (icon !== "") {
            content = (
              <span>
                <img
                  src={icon}
                  alt="Icon"
                  style={{ verticalAlign: "text-bottom" }}
                />
                &nbsp;{content}
              </span>
            );
          }
          if (flag !== "") {
            content = (
              <span>
                {content}&nbsp;
                <span dangerouslySetInnerHTML={{ __html: flag }}></span>
              </span>
            );
          }

          if (more !== "") {
            content = (
              <span
                onMouseDown={(ev) =>
                  this.onMouseDown(item, ev.target, more, true)
                }
                style={{ cursor: "pointer" }}
              >
                {content}
              </span>
            );
          }

          return content;
        };

        if (!newColumns.some((c) => c.key === col.key)) {
          newColumns.push(col);
        }
      });
    }

    // all the resize cruft makes the console very very busy; commenting out for now
    // can be uncommented if we need to debug resize again
    //const logging = new Logging();
    if (newColumns.length > 1) {
      const widthCorrection = -20; //Fix for Fabric
      const widthClipped = 20; //Fix for Clipping
      let availableWidth = Math.min(
        this.props.size.width,
        document.body.clientWidth,
        window.innerWidth,
      );
      let contentBox = document.getElementById("contentBox");
      if (
        contentBox !== undefined &&
        contentBox !== null &&
        availableWidth > contentBox.clientWidth
      )
        availableWidth = contentBox.clientWidth;
      //logging.log('Available Width: ' + availableWidth + 'px (at first calc)', LoggingLevel.INFO);
      if (this.state.groupBy.length > 0) {
        availableWidth -= this.state.groupBy.length * 36;
        //logging.log('Available Width: ' + availableWidth + 'px (with minus ' + (this.state.groupBy.length * 36) + 'px for group spacers)', LoggingLevel.INFO);
      }
      let totalColumnUnits = 0;
      let firstFlexColumn = true;
      let numFixedWidth = 0;
      for (let i = 0; i < newColumns.length; i++) {
        const key = newColumns[i].key;
        if (this.props.widths !== undefined) {
          const fixedWidth = this.props.widths
            .split(";")
            .find((w) => w.split(",")[0] === key);
          if (fixedWidth !== undefined) {
            numFixedWidth++;
            availableWidth -= Number(fixedWidth.split(",")[1]);
            //logging.log('Available Width: ' + availableWidth + 'px (corrected for fixed width column)', LoggingLevel.INFO);
            continue;
          }
        }
        let columnUnits = 0;
        columnUnits = Math.ceil(String(newColumns[i].name).length / 2);
        String(newColumns[i].name)
          .split(" ")
          .forEach((w) => {
            if (w.length > columnUnits) columnUnits = w.length;
          });
        this.props.items.forEach((item) => {
          if (!Object.keys(item).includes(key)) return;
          if (item[key] === null) return;
          if (
            item[key].match(/^\d{4}-\d{2}-\d{2}/) &&
            !Number.isNaN(Date.parse(item[key]))
          ) {
            const d = new Date(item[key]);
            const dateString =
              d.getHours() === 0 && d.getMinutes() === 0 && d.getSeconds() === 0
                ? d.toLocaleDateString()
                : d.toLocaleString();
            if (dateString.length > columnUnits)
              columnUnits = dateString.length;
          } else {
            let lines = item[key]
              .replace(/<br[ ]?[/]?>/g, "\n")
              .split(/\r\n|\r|\n/g);
            lines.forEach((line) => {
              line = line
                .replace(/<[^>]*>/g, " ")
                .replace(/\s{2,}/g, " ")
                .trim();
              if (line.length > columnUnits) columnUnits = line.length;
            });
          }
        });
        if (firstFlexColumn) {
          firstFlexColumn = false;
          columnUnits = Math.floor(columnUnits * 0.75);
        }
        if (columnUnits < 5) columnUnits = 5;
        if (columnUnits > availableWidth * 0.03)
          columnUnits = Math.ceil(availableWidth * 0.03);
        newColumns[i].minWidth = columnUnits;
        totalColumnUnits += columnUnits;
      }
      availableWidth -= (newColumns.length - numFixedWidth) * widthClipped;
      //logging.log('Available Width: ' + availableWidth + 'px (with minus ' + ((newColumns.length-numFixedWidth) * widthClipped) + 'px for column clipping)', LoggingLevel.INFO);
      const unitWidth = Math.floor(availableWidth / totalColumnUnits);
      //logging.log('Available Width: ' + availableWidth + 'px, Total Units: ' + totalColumnUnits + ', Unit Width: ' + unitWidth + 'px', LoggingLevel.INFO);
      //let usedWidth = 0;
      newColumns.forEach((c) => {
        const fixedWidth =
          this.props.widths !== undefined
            ? this.props.widths
                .split(";")
                .find((w) => w.split(",")[0] === c.key)
            : undefined;
        if (fixedWidth !== undefined) {
          c.minWidth = Number(fixedWidth.split(",")[1]) + widthCorrection;
          c.maxWidth = c.minWidth;
          //logging.log(c.key + ': ' + (c.minWidth-widthCorrection) + 'px (Fixed Width)', LoggingLevel.INFO);
        } else {
          //logging.log(c.key + ': ' + c.minWidth + ' units, ' + (Math.floor(unitWidth * c.minWidth) + widthClipped) + 'px', LoggingLevel.INFO);
          c.minWidth =
            Math.floor(unitWidth * c.minWidth) + widthClipped + widthCorrection;
          if (c === newColumns[0]) c.minWidth -= 10;
          if (c === newColumns[newColumns.length - 1]) c.minWidth += 10;
        }
        //usedWidth += c.minWidth;
      });
      //logging.log('Used Width: ' + usedWidth + 'px / ' + availableWidth + 'px', LoggingLevel.INFO);
    }

    this.setState(
      {
        size: this.props.size,
        rowCount: this.props.items.length,
        columns: newColumns,
        previewItem: undefined,
        previewData: undefined,
        previewLocation: undefined,
        previewVisible: false,
        optionsColumn: undefined,
        optionsColumnLocation: undefined,
      },
      () => {
        this.manageCookies();
        this.groupAndSortItems();
      },
    );
  }

  private onRenderRow = (props?: IDetailsRowProps): JSX.Element => {
    var css = "";
    if (props !== undefined) {
      var item = this.state.items[props.itemIndex];
      if (item["_CSS"] !== undefined) css += item["_CSS"];
      css = css.trim();
      if (item["_CLASS"] !== undefined) css += item["CLASS"];
      css = css.trim();

      if (
        this.props.Callouts !== false &&
        item[this.previewURLColumn] !== undefined &&
        item[this.previewURLColumn] !== ""
      ) {
        var timer;
        return (
          <div
            onMouseEnter={(ev) => {
              const target = ev.target;
              timer = setTimeout(() => {
                this.onMouseEnter(item, target);
              }, 500);
            }}
            onMouseLeave={(ev) => clearTimeout(timer)}
          >
            <DetailsRow {...props} className={css} />
          </div>
        );
      } else {
        return <DetailsRow {...props} className={css} />;
      }
    }
    return <span />;
  };

  private onRenderDetailsHeader = (
    props?: IDetailsHeaderProps,
  ): JSX.Element => {
    if (props !== undefined) {
      return (
        <DetailsHeader
          {...props}
          onRenderColumnHeaderTooltip={this.onRenderColumnHeaderTooltip}
        />
      );
    }
    return <span />;
  };

  private onRenderColumnHeaderTooltip = (
    props?: ITooltipHostProps,
  ): JSX.Element => {
    if (props !== undefined) {
      const key = props.id!.match(/^.*?[-](.*)[-].*?$/)![1];
      const tooltip =
        this.props.tooltips !== undefined
          ? this.props.tooltips
              .split(";")
              .find((tt) => tt.split(",")[0] === key)
          : undefined;
      if (tooltip !== undefined) {
        return (
          <TooltipHost
            content={tooltip.split(",")[1]}
            directionalHint={DirectionalHint.bottomAutoEdge}
          >
            {props.children}
          </TooltipHost>
        );
      } else {
        return <TooltipHost>{props.children}</TooltipHost>;
      }
    }
    return <span />;
  };

  private onRenderGroupHeader = (
    props?: IDetailsGroupDividerProps,
  ): JSX.Element => {
    if (props !== undefined) {
      return (
        <div className="ms-GroupHeader">
          <span
            className="ms-GroupSpacer"
            style={{ width: (props.group!.level ?? 0) * 36 + "px" }}
          ></span>
          <IconButton
            className="ms-GroupHeader-expand"
            iconProps={{
              iconName: props.group!.isCollapsed
                ? "ChevronRightMed"
                : "ChevronDownMed",
            }}
            onClick={
              () => {
                this.GroupChange_Function(
                  props.group!.data,
                  props.group!.key,
                  props.group!.isCollapsed,
                );
              }
              // {props.onToggleCollapse!(props.group!);}
            }
          />
          <div className="ms-GroupHeader-title">
            {props.group!.name} (
            {this.state.rawGroupCounts !== undefined &&
            props.group!.count !==
              this.state.rawGroupCounts
                .get(props.group!.data)
                ?.get(props.group!.key)
              ? props.group!.count +
                " of " +
                this.state.rawGroupCounts
                  .get(props.group!.data)
                  ?.get(props.group!.key)
              : props.group!.count}
            )
            <IconButton
              title="Remove Grouping"
              className="ms-GroupHeader-remove"
              iconProps={{ iconName: "Cancel" }}
              onClick={() => this.GroupClear_Function(props.group!.data)}
            />
          </div>
        </div>
      );
    }
    return <span />;
  };

  private onColumnClick = (
    ev?: React.MouseEvent<HTMLElement>,
    column?: IColumn,
  ): void => {
    if (ev !== undefined && column !== undefined) {
      let element = ev.target as Element;
      while (
        element &&
        !element.className.split(" ").includes("ms-DetailsHeader-cellTitle")
      ) {
        element = element.parentElement!;
      }
      this.setState({
        optionsColumn: column,
        optionsColumnLocation: element,
        previewItem: undefined,
        previewData: undefined,
        previewLocation: undefined,
        previewVisible: false,
      });
    }
  };

  private onMouseEnter = (
    item: any | undefined,
    target: EventTarget,
    data: string | undefined = undefined,
    fixedElement: boolean = false,
  ) => {
    if (target !== undefined && (item !== undefined || data !== undefined)) {
      let element = target as Element;
      while (
        !fixedElement &&
        element &&
        !element.className.split(" ").includes("ms-DetailsRow")
      ) {
        element = element.parentElement!;
      }
      if (
        item &&
        item[this.previewURLColumn] !== undefined &&
        this.state.previewItem !== item
      ) {
        if (item[this.previewURLColumn] && item[this.previewURLColumn] !== "") {
          this.setState({
            previewItem: item,
            previewData: undefined,
            previewLocation: element,
            previewVisible: false,
          });
          setTimeout(() => this.setState({ previewVisible: true }), 0);
        } else {
          this.setState({
            previewItem: item,
            previewData: undefined,
            previewLocation: element,
            previewVisible: true,
          });
        }
      }
      if (data) {
        this.setState({
          previewItem: undefined,
          previewData: data,
          previewLocation: element,
          previewVisible: true,
        });
      }
    }
  };

  private onMouseDown = (
    item: any | undefined,
    target: EventTarget,
    data: string | undefined = undefined,
    fixedElement: boolean = false,
  ) => {
    this.onMouseEnter(item, target, data, fixedElement);
  };

  private onMouseLeave = (ev: React.MouseEvent<HTMLElement>) => {
    this.setState({
      previewItem: undefined,
      previewData: undefined,
      previewLocation: undefined,
      previewVisible: false,
    });
  };

  private manageCookies = (): void => {
    let {
      gridindex,
      groupBy,
      groupByDesc,
      groupByCond,
      sortBy,
      sortByDesc,
      filterBy,
      defaultColumnOptions,
      currentColumnOptions,
    } = this.state;
    var filterChanged: boolean = false;
    const columnOptions = [
      groupBy,
      groupByDesc,
      groupByCond,
      sortBy,
      sortByDesc,
      filterBy,
    ];

    if (
      (JSON.stringify(columnOptions) === defaultColumnOptions ||
        JSON.stringify(columnOptions) === "[[],[],[],[],[]]") &&
      localStorage.getItem(
        "co__" +
          this.props.eid.replaceAll(this.props.Refresher, "") +
          "__" +
          (this.props.output ? this.props.output : "") +
          "__" +
          (this.props.list ? this.props.list : "") +
          "__" +
          (this.props.sproc ? this.props.sproc : "") +
          "__" +
          (this.props.gridindex ? this.props.gridindex : ""),
      )
    ) {
      //this.props.Logger.log('Deleting Column Options from Cookie', LoggingLevel.INFO);
      localStorage.removeItem(
        "co__" +
          this.props.eid.replaceAll(this.props.Refresher, "") +
          "__" +
          (this.props.output ? this.props.output : "") +
          "__" +
          (this.props.list ? this.props.list : "") +
          "__" +
          (this.props.sproc ? this.props.sproc : "") +
          "__" +
          (this.props.gridindex ? this.props.gridindex : ""),
      );
      currentColumnOptions = undefined;
    } else if (
      JSON.stringify(columnOptions) !== currentColumnOptions &&
      JSON.stringify(columnOptions) !== defaultColumnOptions &&
      JSON.stringify(columnOptions) !== "[[],[],[],[],[]]"
    ) {
      //this.props.Logger.log('Saving Column Options to Cookie: ' + JSON.stringify(columnOptions), LoggingLevel.INFO);
      localStorage.setItem(
        "co__" +
          this.props.eid.replaceAll(this.props.Refresher, "") +
          "__" +
          (this.props.output ? this.props.output : "") +
          "__" +
          (this.props.list ? this.props.list : "") +
          "__" +
          (this.props.sproc ? this.props.sproc : "") +
          "__" +
          (this.props.gridindex ? this.props.gridindex : ""),
        JSON.stringify(columnOptions),
      );
      currentColumnOptions = JSON.stringify(columnOptions);
      if (currentColumnOptions![4] !== JSON.stringify(columnOptions)[4]) {
        filterChanged = true;
      }
    }

    if (this.props.output === "special-clearing") {
      this.props.funcPassUpFilterSortChange(
        gridindex,
        filterBy,
        sortBy,
        sortByDesc,
        filterChanged,
      );
    } else {
      this.groupAndSortItems();
    }
  };

  private CookieMonster = (): void => {
    let { groupBy, groupByDesc, groupByCond, sortBy, sortByDesc, filterBy } =
      this.state;

    sortBy = [];
    sortByDesc = [];
    groupBy = [];
    groupByDesc = [];
    groupByCond = new Map<string, Map<string, boolean>>();
    filterBy = [];

    if (this.props.group !== undefined) {
      this.props.group.split(",").forEach((c, i) => {
        groupBy.push(c);
        groupByDesc.push(
          this.props.groupdesc !== undefined &&
            this.props.groupdesc.split(",").length > i &&
            this.props.groupdesc.split(",")[i].toLowerCase() === "true",
        );
      });
    }
    if (this.props.sort !== undefined) {
      this.props.sort.split(",").forEach((c, i) => {
        sortBy.push(c);
        sortByDesc.push(
          this.props.sortdesc !== undefined &&
            this.props.sortdesc.split(",").length > i &&
            this.props.sortdesc.split(",")[i].toLowerCase() === "true",
        );
      });
    }
    if (this.props.filter !== undefined) {
      this.props.filter.split(";").forEach((f) => {
        var strings: string[] = [];
        f.split(",").forEach((inf) => {
          strings.push(inf.trim());
        });
        filterBy.push(strings);
      });
    }

    this.setState(
      {
        sortBy: sortBy,
        sortByDesc: sortByDesc,
        groupBy: groupBy,
        groupByDesc: groupByDesc,
        groupByCond: groupByCond,
        filterBy: filterBy,
      },
      function (this: any) {
        this.manageCookies();
        this.groupAndSortItems();
        this.generateState();
      },
    );
  };

  private groupAndSortItems = (): void => {
    let {
      columns,
      groupBy,
      groupByDesc,
      groupByCond,
      rawGroupCounts,
      sortBy,
      sortByDesc,
      filterBy,
      currentColumnOptions,
      limit,
      start,
    } = this.state;
    let items = [...this.props.items];

    const rowColumns = Object.keys(this.props.items[0]);
    groupBy.forEach((g) => {
      if (!rowColumns.includes(g)) {
        const i = groupBy.indexOf(g);
        groupBy = groupBy.filter((ig, ii) => ii !== i);
        groupByDesc = groupByDesc.filter((ig, ii) => ii !== i);
      }
    });
    sortBy.forEach((s) => {
      if (!rowColumns.includes(s)) {
        const i = sortBy.indexOf(s);
        sortBy = sortBy.filter((is, ii) => ii !== i);
        sortByDesc = sortByDesc.filter((is, ii) => ii !== i);
      }
    });
    filterBy.forEach((f) => {
      if (!rowColumns.includes(f[0])) {
        const i = filterBy.indexOf(f);
        filterBy = filterBy.filter((ifb, ii) => ii !== i);
      }
    });

    columns.forEach((c) => {
      c.isSorted = sortBy.includes(c.key);
      c.isSortedDescending = sortByDesc[sortBy.indexOf(c.key)];
      c.isGrouped = groupBy.includes(c.key);
      c.isFiltered = filterBy.some((f) => f[0] === c.key);
    });
    columns = columns.filter(
      (c, i) =>
        !groupBy.includes(c.key) ||
        rowColumns.some((s) => s === c.key + "_LINK" || s === c.key + "_URL"),
    );

    if (filterBy !== undefined && filterBy.length > 0) {
      filterBy.forEach((f) => {
        items = items.filter((i) =>
          i[f[0]].toLowerCase().includes(f[1].toLowerCase().trim()),
        );
      });
    }

    items = this.sortItems(
      items,
      groupBy,
      groupByDesc,
      sortBy,
      sortByDesc,
      start,
      limit,
    );

    if (start > items.length) {
      start = 0;
    }

    rawGroupCounts = new Map<string, Map<string, number>>();

    if (groupBy !== undefined && groupBy.length > 0) {
      groupBy.forEach((group, gi) => {
        rawGroupCounts.set(group, new Map<string, number>()); // ("Department", {})
        items.forEach((item, ii) => {
          if (!rawGroupCounts.get(group)!.has(item[group])) {
            rawGroupCounts.get(group)!.set(item[group], 1); // presence means at least one row;
          } else {
            rawGroupCounts
              .get(group)!
              .set(
                item[group],
                rawGroupCounts.get(group)!.get(item[group])! + 1,
              );
          }
        });
      });
    }

    this.setState(
      {
        columns: columns,
        groups: this.computedgroups(
          items,
          items,
          groupBy,
          groupByCond,
          rawGroupCounts,
        ),
        start: start,
        items: items,
        currentColumnOptions: currentColumnOptions,
        rawGroupCounts: rawGroupCounts,
      },
      () => {
        // console.log(this.state.items);
        this.forceUpdate();
      },
    );
  };

  computedgroups(
    allitems: IItem[],
    constraineditems: IItem[],
    groupBy: string[],
    groupByCond: Map<string, Map<string, boolean>>,
    rawGroupCounts: Map<string, Map<string, number>>,
  ): IGroup[] {
    constraineditems =
      this.state.limit < this.state.rowCount
        ? this.state.items.slice(
            this.state.start,
            this.state.start + this.state.limit,
          )
        : [...this.state.items];

    const groups: IGroup[] = [];
    if (groupBy !== undefined && groupBy.length > 0) {
      constraineditems.forEach((item, ii) => {
        groupBy.forEach((group, gi) => {
          var ref: IGroup[] | undefined;
          ref = groups;
          if (gi >= 1) {
            for (let i = 0; i < gi; i++) {
              if (ref !== undefined) {
                var tmp: IGroup | undefined;
                const gbkey = item[groupBy[i]];
                tmp = ref.find((x) => x.key === gbkey);
                if (tmp !== undefined) {
                  if (tmp.children === undefined) tmp.children = [];
                  ref = tmp.children;
                }
              }
            }
          }
          if (ref !== undefined && item[group] !== undefined) {
            if (!ref.some((x) => x.key === item[group])) {
              try {
                groupByCond.get(group);
              } catch (error) {
                groupByCond = new Map<string, Map<string, boolean>>();
              }
              if (!groupByCond.has(group)) {
                groupByCond.set(group, new Map<string, boolean>());
              }
              if (!groupByCond.get(group)?.has(item[group])) {
                groupByCond.get(group)?.set(item[group], false);
              }
              const ig: IGroup = {
                key: item[group],
                data: group,
                name:
                  toTitleCase(group.replace(/_/g, " ")) +
                  ": " +
                  item[group].trim(),
                count: 1,
                startIndex: ii,
                level: gi,
                isCollapsed: groupByCond.get(group)?.get(item[group]) || false,
              };
              ref.push(ig);
            } else {
              (ref.find((x) => x.key === item[group]) as IGroup).count++;
            }
          }
        });
      });
    }

    return groups;
  }

  private sortItems = (
    items: IItem[],
    groupBy: string[],
    groupDesc: boolean[],
    sortBy: string[],
    sortDesc: boolean[],
    start: number | undefined,
    limit: number | undefined,
  ): IItem[] => {
    var processeditems: IItem[] = items.toSorted((a: IItem, b: IItem) => {
      for (let i = 0; i < groupBy.length; i++) {
        const col = groupBy[i];
        if (isNaN(+a[col]) || isNaN(+b[col])) {
          if (a[col] < b[col]) return groupDesc[groupBy.indexOf(col)] ? 1 : -1;
          if (a[col] > b[col]) return groupDesc[groupBy.indexOf(col)] ? -1 : 1;
        } else {
          if (+a[col] < +b[col])
            return groupDesc[groupBy.indexOf(col)] ? 1 : -1;
          if (+a[col] > +b[col])
            return groupDesc[groupBy.indexOf(col)] ? -1 : 1;
        }
      }
      for (let i = 0; i < sortBy.length; i++) {
        const col = sortBy[i];

        var sizeCheck_a = a[col].match(/([\d.]+) ([KMGT]B)/);
        var sizeCheck_b = null;
        if (sizeCheck_a) sizeCheck_b = b[col].match(/([\d.]+) ([KMGT]B)/);
        if (sizeCheck_a && sizeCheck_b) {
          var a_v =
            Number(sizeCheck_a[1]) *
            (sizeCheck_a[2] === "TB"
              ? 1024 * 1024 * 1024
              : sizeCheck_a[2] === "GB"
                ? 1024 * 1024
                : sizeCheck_a[2] === "MB"
                  ? 1024
                  : 1);
          var b_v =
            Number(sizeCheck_b[1]) *
            (sizeCheck_b[2] === "TB"
              ? 1024 * 1024 * 1024
              : sizeCheck_b[2] === "GB"
                ? 1024 * 1024
                : sizeCheck_b[2] === "MB"
                  ? 1024
                  : 1);
          if (a_v < b_v) return sortDesc[sortBy.indexOf(col)] ? 1 : -1;
          if (a_v > b_v) return sortDesc[sortBy.indexOf(col)] ? -1 : 1;
        }

        if (isNaN(+a[col]) || isNaN(+b[col])) {
          if (a[col] < b[col]) return sortDesc[sortBy.indexOf(col)] ? 1 : -1;
          if (a[col] > b[col]) return sortDesc[sortBy.indexOf(col)] ? -1 : 1;
        } else {
          if (+a[col] < +b[col]) return sortDesc[sortBy.indexOf(col)] ? 1 : -1;
          if (+a[col] > +b[col]) return sortDesc[sortBy.indexOf(col)] ? -1 : 1;
        }
      }
      return 0;
    });

    return processeditems;
  };

  public async PageForward_Function() {
    var newstart = this.state.start || 0;
    newstart = newstart + (this.state.limit || 200);
    this.setState(
      {
        start: newstart,
      },
      () => {
        this.forceUpdate();
      },
    );
  }

  public async PageBack_Function() {
    var newstart = this.state.start || 0;
    newstart = newstart - (this.state.limit || 200);
    this.setState(
      {
        start: newstart,
      },
      () => {
        this.forceUpdate();
      },
    );
  }

  public async goToPage_Function(pageno: number) {
    var newstart = (pageno - 1) * (this.state.limit || 200);
    this.setState(
      {
        start: newstart,
      },
      () => {
        this.forceUpdate();
      },
    );
  }

  GroupChange_Event = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    checked?: boolean | undefined,
  ) => {
    if (checked) {
      this.setState(
        (oldState) => {
          oldState.groupBy.push(this.state.optionsColumn!.key);
          oldState.groupByDesc.push(false);
          return {
            groupBy: oldState.groupBy,
            groupByDesc: oldState.groupByDesc,
            optionsColumn: undefined,
            optionsColumnLocation: undefined,
          };
        },
        () => {
          this.manageCookies();
        },
      );
    } else {
      this.setState(
        (oldState) => {
          const i = oldState.groupBy.indexOf(this.state.optionsColumn!.key);
          try {
            oldState.groupByCond.delete(this.state.optionsColumn!.key);
          } catch (error) {}
          return {
            groupBy: oldState.groupBy.filter((iv, ii) => ii !== i),
            groupByDesc: oldState.groupByDesc.filter((iv, ii) => ii !== i),
            groupByCond: oldState.groupByCond,
          };
        },
        () => {
          this.generateState();
          this.manageCookies();
        },
      );
    }
  };

  SortAscendingChange_Event = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    checked?: boolean | undefined,
  ) => {
    if (checked) {
      this.setState(
        (oldState) => {
          if (oldState.sortBy.includes(this.state.optionsColumn!.key)) {
            oldState.sortByDesc[
              oldState.sortBy.indexOf(this.state.optionsColumn!.key)
            ] = false;
          } else {
            oldState.sortBy.push(this.state.optionsColumn!.key);
            oldState.sortByDesc.push(false);
          }
          return { sortBy: oldState.sortBy, sortByDesc: oldState.sortByDesc };
        },
        () => {
          this.manageCookies();
        },
      );
    } else {
      this.setState(
        (oldState) => {
          const i = oldState.sortBy.indexOf(this.state.optionsColumn!.key);
          return {
            sortBy: oldState.sortBy.filter((iv, ii) => ii !== i),
            sortByDesc: oldState.sortByDesc.filter((iv, ii) => ii !== i),
          };
        },
        () => {
          this.manageCookies();
        },
      );
    }
  };

  SortDescendingChange_Event = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    checked?: boolean | undefined,
  ) => {
    if (checked) {
      this.setState(
        (oldState) => {
          if (oldState.sortBy.includes(this.state.optionsColumn!.key)) {
            oldState.sortByDesc[
              oldState.sortBy.indexOf(this.state.optionsColumn!.key)
            ] = true;
          } else {
            oldState.sortBy.push(this.state.optionsColumn!.key);
            oldState.sortByDesc.push(true);
          }
          return { sortBy: oldState.sortBy, sortByDesc: oldState.sortByDesc };
        },
        () => {
          this.manageCookies();
        },
      );
    } else {
      this.setState(
        (oldState) => {
          const i = oldState.sortBy.indexOf(this.state.optionsColumn!.key);
          return {
            sortBy: oldState.sortBy.filter((iv, ii) => ii !== i),
            sortByDesc: oldState.sortByDesc.filter((iv, ii) => ii !== i),
          };
        },
        () => {
          this.manageCookies();
        },
      );
    }
  };

  private async SortAscendingChange_Function(colname: string) {
    this.setState(
      (oldState) => {
        if (oldState.sortBy.includes(colname)) {
          oldState.sortByDesc[oldState.sortBy.indexOf(colname)] = false;
        } else {
          oldState.sortBy.push(colname);
          oldState.sortByDesc.push(false);
        }
        return { sortBy: oldState.sortBy, sortByDesc: oldState.sortByDesc };
      },
      () => {
        this.manageCookies();
      },
    );
  }

  private async GroupAscendingChange_Function(colname: string) {
    this.setState(
      (oldState) => {
        if (oldState.groupBy.includes(colname)) {
          oldState.groupByDesc[oldState.groupBy.indexOf(colname)] = false;
        } else {
          oldState.groupBy.push(colname);
          oldState.groupByDesc.push(false);
        }
        return {
          groupBy: oldState.groupBy,
          groupByDesc: oldState.groupByDesc,
        };
      },
      () => {
        this.manageCookies();
      },
    );
  }

  private async SortDescendingChange_Function(colname: string) {
    this.setState(
      (oldState) => {
        if (oldState.sortBy.includes(colname)) {
          oldState.sortByDesc[oldState.sortBy.indexOf(colname)] = true;
        } else {
          oldState.sortBy.push(colname);
          oldState.sortByDesc.push(true);
        }
        return { sortBy: oldState.sortBy, sortByDesc: oldState.sortByDesc };
      },
      () => {
        this.manageCookies();
      },
    );
  }

  private async GroupDescendingChange_Function(colname: string) {
    this.setState(
      (oldState) => {
        if (oldState.groupBy.includes(colname)) {
          oldState.groupByDesc[oldState.groupBy.indexOf(colname)] = true;
        } else {
          oldState.groupBy.push(colname);
          oldState.groupByDesc.push(true);
        }
        return {
          groupBy: oldState.groupBy,
          groupByDesc: oldState.groupByDesc,
        };
      },
      () => {
        this.manageCookies();
      },
    );
  }

  private async SortShuntDown_Function(colname: string) {
    // we reorder the SortBy and SortByDesc arrays to push the current column 'down' one in the sort order ranking.

    this.setState(
      (oldState) => {
        const i = oldState.sortBy.indexOf(colname);
        // get the sortBy index. we've already shecked that this can't be at the end of the array, so...
        var newSortBy = oldState.sortBy.slice(0, i);
        var newSortByDesc = oldState.sortByDesc.slice(0, i);
        newSortBy = [].concat(newSortBy, oldState.sortBy[i + 1]);
        newSortByDesc = [].concat(newSortByDesc, oldState.sortByDesc[i + 1]);
        newSortBy = [].concat(newSortBy, oldState.sortBy[i]);
        newSortByDesc = [].concat(newSortByDesc, oldState.sortByDesc[i]);
        newSortBy = [].concat(newSortBy, oldState.sortBy.slice(i + 2));
        newSortByDesc = [].concat(
          newSortByDesc,
          oldState.sortByDesc.slice(i + 2),
        );
        return {
          sortBy: newSortBy,
          sortByDesc: newSortByDesc,
        };
      },
      () => {
        this.manageCookies();
      },
    );
  }

  private async GroupShuntDown_Function(colname: string) {
    // we reorder the SortBy and SortByDesc arrays to push the current column 'down' one in the sort order ranking.

    this.setState(
      (oldState) => {
        const i = oldState.groupBy.indexOf(colname);
        // get the sortBy index. we've already shecked that this can't be at the end of the array, so...
        // arr.slice(0,2) + arr[3] + arr[2] + arr.slice(4,10)
        var newGroupBy = oldState.groupBy.slice(0, i);
        var newGroupByDesc = oldState.groupByDesc.slice(0, i);
        newGroupBy = [].concat(newGroupBy, oldState.groupBy[i + 1]);
        newGroupByDesc = [].concat(newGroupByDesc, oldState.groupByDesc[i + 1]);
        newGroupBy = [].concat(newGroupBy, oldState.groupBy[i]);
        newGroupByDesc = [].concat(newGroupByDesc, oldState.groupByDesc[i]);
        newGroupBy = [].concat(newGroupBy, oldState.groupBy.slice(i + 2));
        newGroupByDesc = [].concat(
          newGroupByDesc,
          oldState.groupByDesc.slice(i + 2),
        );
        return {
          groupBy: newGroupBy,
          groupByDesc: newGroupByDesc,
        };
      },
      () => {
        this.manageCookies();
      },
    );
  }
  //  }

  private async SortShuntUp_Function(colname: string) {
    // we reorder the SortBy and SortByDesc arrays to push the current column 'down' one in the sort order ranking.
    this.setState(
      (oldState) => {
        const i = oldState.sortBy.indexOf(colname);
        var newSortBy = oldState.sortBy.slice(0, i - 1);
        var newSortByDesc = oldState.sortByDesc.slice(0, i - 1);
        newSortBy = [].concat(newSortBy, oldState.sortBy[i]);
        newSortByDesc = [].concat(newSortByDesc, oldState.sortByDesc[i]);
        newSortBy = [].concat(newSortBy, oldState.sortBy[i - 1]);
        newSortByDesc = [].concat(newSortByDesc, oldState.sortByDesc[i - 1]);
        if (oldState.sortBy.length > i + 1) {
          newSortBy = [].concat(newSortBy, oldState.sortBy.slice(i + 1));
          newSortByDesc = [].concat(
            newSortByDesc,
            oldState.sortByDesc.slice(i + 1),
          );
        }
        return {
          sortBy: newSortBy,
          sortByDesc: newSortByDesc,
        };
      },
      () => {
        this.manageCookies();
      },
    );
  }

  private async GroupShuntUp_Function(colname: string) {
    // we reorder the SortBy and SortByDesc arrays to push the current column 'down' one in the sort order ranking.
    this.setState(
      (oldState) => {
        const i = oldState.groupBy.indexOf(colname);
        // get the sortBy index. we've already shecked that this can't be at the end of the array, so...
        // arr.slice(0,2) + arr[3] + arr[2] + arr.slice(4,10)
        var newGroupBy = oldState.groupBy.slice(0, i - 1);
        var newGroupByDesc = oldState.groupByDesc.slice(0, i - 1);
        newGroupBy = [].concat(newGroupBy, oldState.groupBy[i]);
        newGroupByDesc = [].concat(newGroupByDesc, oldState.groupByDesc[i]);
        newGroupBy = [].concat(newGroupBy, oldState.groupBy[i - 1]);
        newGroupByDesc = [].concat(newGroupByDesc, oldState.groupByDesc[i - 1]);
        if (oldState.groupBy.length > i + 1) {
          newGroupBy = [].concat(newGroupBy, oldState.groupBy.slice(i + 1));
          newGroupByDesc = [].concat(
            newGroupByDesc,
            oldState.groupByDesc.slice(i + 1),
          );
        }
        return {
          groupBy: newGroupBy,
          groupByDesc: newGroupByDesc,
        };
      },
      () => {
        this.groupAndSortItems();
        this.manageCookies();
      },
    );
  }

  FilterChange_Event_Top = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newval?: string | undefined,
    key?: string[] | undefined,
  ) => {
    this.setState(
      (oldState) => {
        let fby = oldState.filterBy.find(
          (f) => f[0] === ((key || [])[0] || ""),
        );
        if (fby !== undefined) {
          if (newval === undefined || newval === "") {
            return {
              filterBy: oldState.filterBy.filter(
                (f) => f[0] !== ((key || [])[0] || ""),
              ),
            };
          } else {
            fby[1] = newval;
          }
        } else if (newval !== undefined && newval !== "") {
          oldState.filterBy.push([(key || [])[0] || "", newval]);
        }
        return { filterBy: oldState.filterBy };
      },
      function (this: GridContent) {
        this.groupAndSortItems();
        this.manageCookies();
      },
    );
  };

  FilterChange_Event = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newval?: string | undefined,
  ) => {
    this.setState(
      (oldState) => {
        let fby = oldState.filterBy.find(
          (f) => f[0] === this.state.optionsColumn!.key,
        );
        if (fby !== undefined) {
          if (newval === undefined || newval === "") {
            return {
              filterBy: oldState.filterBy.filter(
                (f) => f[0] !== this.state.optionsColumn!.key,
              ),
            };
          } else {
            fby[1] = newval.trim();
          }
        } else if (newval !== undefined && newval !== "") {
          oldState.filterBy.push([
            this.state.optionsColumn!.key,
            newval.trim(),
          ]);
        }
        return { filterBy: oldState.filterBy };
      },
      function (this: GridContent) {
        this.groupAndSortItems();
        this.manageCookies();
      },
    );
  };

  FilterBlur_Event = () => {
    this.manageCookies();
  };

  FilterBlur_Event_Top = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const colname = event.currentTarget.name;
    const newval = event.currentTarget.value;

    this.setState(
      (oldState) => {
        let fby = oldState.filterBy.find((f) => f[0] === colname); // this.state.optionsColumn!.key);
        if (fby !== undefined) {
          if (newval === undefined || newval === "") {
            return {
              filterBy: oldState.filterBy.filter((f) => f[0] !== colname),
            }; // this.state.optionsColumn!.key)};
          } else {
            fby[1] = newval.trim();
          }
        } else if (newval !== undefined && newval !== "") {
          oldState.filterBy.push([colname, newval.trim()]);
        }

        return { filterBy: oldState.filterBy };
      },
      function (this: GridContent) {
        this.manageCookies();
      },
    );
  };

  FilterClear_Event = () => {
    this.setState(
      (oldState) => {
        return {
          filterBy: oldState.filterBy.filter(
            (f) => f[0] !== this.state.optionsColumn!.key,
          ),
        };
      },
      () => {
        this.manageCookies();
      },
    );
  };

  private async FilterClear_Function(colname: string) {
    this.setState(
      (oldState) => {
        return {
          filterBy: oldState.filterBy.filter((f) => f[0] !== colname),
        };
      },
      () => {
        this.manageCookies();
      },
    );
  }

  private async SortClear_Function(colname: string) {
    this.setState(
      (oldState) => {
        const i = oldState.sortBy.indexOf(colname);
        return {
          sortBy: oldState.sortBy.filter((iv, ii) => ii !== i),
          sortByDesc: oldState.sortByDesc.filter((iv, ii) => ii !== i),
          // this automatically removes entries from the SortBy/SortBySesc arrays, which automatically updates sortorder.
        };
      },
      () => {
        this.manageCookies();
      },
    );
  }

  GroupsToggle_Event = (isAllCollapsed: boolean) => {
    this.setState(
      (oldState) => {
        if (oldState.groupByCond.forEach !== undefined) {
          oldState.groupByCond.forEach(function (val, key) {
            val.forEach(function (v2, k2) {
              oldState.groupByCond.get(key)?.set(k2, isAllCollapsed);
            });
          });
          return {
            groupByCond: oldState.groupByCond,
          };
        }
      },
      () => {
        this.manageCookies();
        this.groupAndSortItems();
        this.generateState();
      },
    );
  };

  private async GroupChange_Function(
    colname: string,
    groupingvalue: string,
    condensed: boolean | undefined,
  ) {
    this.setState(
      (oldState) => {
        var newGroupByCond: Map<string, Map<string, boolean>> = new Map<
          string,
          Map<string, boolean>
        >();

        try {
          if (oldState.groupByCond !== undefined) {
            oldState.groupByCond.forEach(function (val, key) {
              newGroupByCond.set(key, new Map<string, boolean>());
              val.forEach(function (v2, k2) {
                newGroupByCond.get(key)?.set(k2, v2);
              });
            });
          }
        } catch (error) {
          newGroupByCond.set(colname, new Map<string, boolean>());
        }

        newGroupByCond.get(colname)?.set(groupingvalue, !condensed || false);

        return {
          groupByCond: newGroupByCond,
        };
      },
      () => {
        this.manageCookies();
        this.groupAndSortItems();
        this.generateState();
      },
    );
  }

  private async GroupClear_Function(colname: string) {
    this.setState(
      (oldState) => {
        const i = oldState.groupBy.indexOf(colname);
        const newGroupBy: string[] = oldState.groupBy.filter(
          (iv, ii) => ii !== i,
        );
        const newgroupByDesc: boolean[] = oldState.groupByDesc.filter(
          (iv, ii) => ii !== i,
        );
        try {
          oldState.groupByCond.delete(colname);
        } catch (error) {}
        return {
          groupBy: newGroupBy,
          groupByDesc: newgroupByDesc,
          groupByCond: oldState.groupByCond,
          // this automatically removes entries from the SortBy/SortBySesc arrays, which automatically updates sortorder.
        };
      },
      () => {
        this.manageCookies();
        this.groupAndSortItems();
        this.generateState();
      },
    );
  }

  FiltersClear_Event = () => {
    this.setState(
      {
        filterBy: [],
      },
      () => {
        this.manageCookies();
      },
    );
  };

  GroupsClear_Event = () => {
    this.setState(
      {
        groupBy: [],
        groupByDesc: [],
        groupByCond: new Map<string, Map<string, boolean>>(),
      },
      () => {
        this.generateState();
      },
    );
  };

  SortsClear_Event = () => {
    this.setState(
      {
        sortBy: [],
        sortByDesc: [],
      },
      () => {
        this.manageCookies();
      },
    );
  };

  AllClear_Event = () => {
    this.setState(
      {
        sortBy: [],
        sortByDesc: [],
        groupBy: [],
        groupByDesc: [],
        groupByCond: new Map<string, Map<string, boolean>>(),
        filterBy: [],
      },
      () => {
        this.manageCookies();
      },
    );
  };
}

export default withSize()(GridContent);
