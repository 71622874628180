import uni from "../assets/icons/uni.svg";
import { ErrorResponse, Link, useRouteError } from "react-router-dom";
import AccessibilityIcon from "../assets/icons/AccessibilityIcon";
import "../assets/css/ERROR/ERROR.css";

export default function ErrorPage() {
  const error = useRouteError();

  var whatHaveYouDone = <></>;
  var bear: boolean = false;

  console.log("ErrorPage");
  console.log(error);
  try {
    const treatedError = error as ErrorResponse;
    //  console.log(treatedError);

    if (treatedError.status === 404) {
      bear = true;
      whatHaveYouDone = (
        <>
          <h2>404</h2>
          <h3>Not Found</h3>
          <em>Eustace Bear is very lost.</em>
          <div>
            You've navigated to a URL that doesn't exist - click the University
            of Essex logo to go back to the homepage and try again.
          </div>
        </>
      );
    } else if (treatedError.status === 500) {
      whatHaveYouDone = (
        <>
          <h2>500</h2>
          <h3>Internal Server Error</h3>
          <div>
            Apologies - something's gone wrong at our end. The system has sent
            us an automatic report - we'll work to diagnose and fix it as soon
            as we can.
          </div>
        </>
      );
    } else if (treatedError.status === 503) {
      whatHaveYouDone = (
        <>
          <h2>503</h2>
          <h3>Service Unavailable</h3>
          <div>
            Apologies - this site, or one of its subservices, is unavailable.
            The system has sent us an automatic report - we'll work to diagnose
            and fix it as soon as we can.
          </div>
        </>
      );
    } else if (treatedError.status === 401) {
      whatHaveYouDone = (
        <>
          <h2>401</h2>
          <h3>Unauthorised Access Request</h3>
          <div>
            You do not have access to this page or the contents. Access to this
            resource is strictly limited. If you believe you should have access,
            but are seeing this page, please contact{" "}
            <a href="mailto:sphelp@essex.ac.uk">sphelp@essex.ac.uk</a> giving
            details of your job role and what access you require (Read / File
            Upload) and to which systems (ESF/UGEAF/PGEAF/ECF/AOS).
            <br />
            Thank you.
          </div>
        </>
      );
    } else {
    }
  } catch {
    whatHaveYouDone = (
      <>We're not sure what happened here. Not a 404, 500 or 503.</>
    );
  }

  return (
    <div id="SPOWebUIOnPrem">
      <div className="FullPageLoader">
        <div className="Header AlwaysShort">
          <div className="Operations">
            <Link className="Logo" to="./">
              <div className="LogoSVG">
                <img src={uni} alt="University of Essex Icon" height={60} />
              </div>
            </Link>
          </div>
          <div className="Breadcrumbs">
            <div className="Crumbs">
              {
                //  <div className='Twixt'>&gt;</div>
                //  <Link className='Logo' to='./'>
                //      <div className='Crumb'>SharePoint Online</div>
                //  </Link>
              }
            </div>
          </div>
        </div>
        <div className={bear ? "Body ERROR Bear" : "Body ERROR"}>
          {whatHaveYouDone}
        </div>
        <div className="Footer">
          <div className="AccessibilityMenu">
            <div className="AccessibilityCharm">
              <AccessibilityIcon></AccessibilityIcon>
            </div>
          </div>
          <div className="HelpMenu">
            <div className="HelpCharm">?</div>
          </div>
        </div>
      </div>
    </div>
  );
}
