import ModeStruct from "../models/ModeStruct";
import TaskListGroup from "../models/TaskListGroup";
import { GridWrapper } from "../integrations/UoE-Grid/GridWrapper";
import SPOUploadLocation from "../models/SPOUploadLocation";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess } from "../data/DataAccess";
import { Logging } from "../Logging";
import { useState } from "react";

import "../assets/css/TaskLists.css";
import "../assets/css/ReviewTaskAssignees.css";
import React from "react";

interface IProps {
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  URLRoot: string;
  URLParams: string;
  Mode: string[];
  TaskListGroups: TaskListGroup[] | undefined;
  RenderedTaskList: string | undefined;
  TaskList: string;
  Location: string;
  Refresher: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  GetSPUL: (shortname: string) => SPOUploadLocation;
  SetSearch: (SearchString: string) => void;
  SetTaskList: (TLRef: string) => void;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

interface RTA_KVP {
  Key: string;
  Value: string;
}

interface RTA_FacsWithDeps {
  FacultyCode: string;
  FacultyName: string;
  Depts: RTA_KVP[];
}

interface RTA_FacsWithTeams {
  FacultyCode: string;
  FacultyName: string;
  Teams: RTA_KVP[];
}

export const ReviewTaskAssignees = (props: IProps) => {
  const DeptsByFac: RTA_FacsWithDeps[] = [
    {
      FacultyCode: "HUM",
      FacultyName: "Faculty of Humanities",
      Depts: [
        {
          Key: "SA",
          Value:
            "Philosophical, Historical, and Interdisciplinary Studies (School of)",
        },
        { Key: "EG", Value: "Edge Hotel School" },
        { Key: "IA", Value: "Essex Pathways" },
        { Key: "HU", Value: "Human Rights Centre (Essex Law School)" },
        { Key: "LW", Value: "Essex Law School" },
        { Key: "LT", Value: "Literature, Film, and Theatre Studies" },
        { Key: "EA", Value: "East 15 Acting School" },
      ],
    },
    ,
    {
      FacultyCode: "SCH",
      FacultyName: "Faculty of Science and Health",
      Depts: [
        { Key: "PS", Value: "Psychology" },
        { Key: "BS", Value: "Life Sciences (School of)" },
        {
          Key: "CE",
          Value: "Computer Science and Electronic Engineering (School of)",
        },
        {
          Key: "CF",
          Value: "Centre for Computational Finance and Economic Agents",
        },
        { Key: "HS", Value: "Health and Social Care (School of)" },
        {
          Key: "MA",
          Value: "Mathematics, Statistics and Actuarial Science (School of)",
        },
        {
          Key: "SE",
          Value: "Sport, Rehabilitation and Exercise Sciences (School of)",
        },
      ],
    },
    ,
    {
      FacultyCode: "SOS",
      FacultyName: "Faculty of Social Sciences",
      Depts: [
        { Key: "EC", Value: "Economics" },
        { Key: "BE", Value: "Essex Business School" },
        { Key: "GV", Value: "Government" },
        { Key: "RC", Value: "Institute for Social and Economic Research" },
        { Key: "LL", Value: "Language and Linguistics" },
        { Key: "PA", Value: "Psychosocial and Psychoanalytic Studies" },
        { Key: "SC", Value: "Sociology" },
      ],
    },
  ];
  const TeamsByFac: RTA_FacsWithTeams[] = [
    {
      FacultyCode: "HUM",
      FacultyName: "Faculty of Humanities",
      Teams: [
        // { Key: "CRT", Value: "Course Records Team" },
        { Key: "DEAN", Value: "Dean" },
        // { Key: "INCOME", Value: "Income" },
        // { Key: "IST", Value: "International Services Team" },
        // { Key: "PGRE", Value: "PG Research Education Team" },
        // { Key: "SPT", Value: "Student Progress Team" },
      ],
    },
    ,
    {
      FacultyCode: "SCH",
      FacultyName: "Faculty of Science and Health",
      Teams: [
        // { Key: "CRT", Value: "Course Records Team" },
        { Key: "DEAN", Value: "Dean" },
        // { Key: "INCOME", Value: "Income" },
        // { Key: "IST", Value: "International Services Team" },
        // { Key: "PGRE", Value: "PG Research Education Team" },
        // { Key: "SPT", Value: "Student Progress Team" },
      ],
    },
    ,
    {
      FacultyCode: "SOS",
      FacultyName: "Faculty of Social Sciences",
      Teams: [
        // { Key: "CRT", Value: "Course Records Team" },
        { Key: "DEAN", Value: "Dean" },
        // { Key: "INCOME", Value: "Income" },
        // { Key: "IST", Value: "International Services Team" },
        // { Key: "PGRE", Value: "PG Research Education Team" },
        // { Key: "SPT", Value: "Student Progress Team" },
      ],
    },
  ];

  //  const depts: IDropdownOption[] = [
  //    // { key: "", text: "No Department" },
  //    { key: "LW", text: "Law" },
  //    { key: "BE", text: "Essex Business School" },
  //    { key: "BS", text: "Life Sciences (School of)" },
  //    {
  //      key: "CE",
  //      text: "Computer Science and Electronic Engineering (School of)",
  //    },
  //    { key: "EC", text: "Economics" },
  //    { key: "EG", text: "Edge Hotel School" },
  //    { key: "GV", text: "Government" },
  //    { key: "HS", text: "Health and Social Care (School of)" },
  //    { key: "HU", text: "Human Rights Centre (Essex Law School)" },
  //    { key: "IA", text: "Essex Pathways" },
  //    { key: "LL", text: "Language and Linguistics" },
  //    { key: "LT", text: "Literature, Film, and Theatre Studies" },
  //    { key: "LW", text: "Essex Law School" },
  //    { key: "LX", text: "Languages for All" },
  //    {
  //      key: "MA",
  //      text: "Mathematics, Statistics and Actuarial Science (School of)",
  //    },
  //    { key: "PA", text: "Psychosocial and Psychoanalytic Studies" },
  //    { key: "PS", text: "Psychology" },
  //    {
  //      key: "SA",
  //      text: "Philosophical, Historical and Interdisciplinary Studies (School of)",
  //    },
  //    { key: "SC", text: "Sociology" },
  //    {
  //      key: "SE",
  //      text: "Sport, Rehabilitation and Exercise Sciences (School of)",
  //    },
  //    { key: "SK", text: "Student Development" },
  //  ];

  const [mechanism, setMechanism] = useState("");

  const [dept, setDept] = useState("");
  const [level, setLevel] = useState("");
  const [team, setTeam] = useState("");

  var PassToAllGrids = {
    Callouts: false,
    Logger: props.Logger,
    da: props.da,
    PCA: props.PCA,
    GetLink: props.GetLink,
    URLRoot: props.URLRoot,
    URLParams: props.URLParams,
    class: "UoE-Grid",
    Refresher: props.Refresher,
    Alias: props.Alias,
    AliasChecked: props.AliasChecked,
    ViewAsAll: props.ViewAsAll,
    RegisterError: props.RegisterError,
    DeregisterError: props.DeregisterError,
    RegisterStatus: props.RegisterStatus,
    DeregisterStatus: props.DeregisterStatus,
  };

  return (
    <div className="ReviewTaskAssignees">
      <div className="ReviewTaskAssigneesPanel">
        <h2>View Workflow Task Assignees</h2>
        <p>
          Use this page to check who is currently assigned to which task, and
          for which workflow.
        </p>
        <p>
          Use the controls below to view different catagories of task assignee.
        </p>
        <div className="MechanismSelectors">
          <div
            className={
              "MechanismSelector" + (mechanism === "DeptCen" ? " Active" : "")
            }
            onClick={() => {
              setMechanism("DeptCen");
            }}
          >
            By Department / Centre
          </div>
          <div
            className={
              "MechanismSelector" + (mechanism === "CenServ" ? " Active" : "")
            }
            onClick={() => {
              setMechanism("CenServ");
            }}
          >
            By Central Services
          </div>
          <div
            className={
              "MechanismSelector" +
              (mechanism === "AdmisTeams" ? " Active" : "")
            }
            onClick={() => {
              setMechanism("AdmisTeams");
            }}
          >
            By Admissions Team
          </div>
          <button
            className="ResetButton"
            onClick={() => {
              setDept("");
              setLevel("");
              setTeam("");
              setMechanism("");
            }}
          >
            Reset Search
          </button>
        </div>
        <div className="MechanismInputRacks">
          <div
            className={
              "MechanismInputRack" + (mechanism === "DeptCen" ? " Active" : "")
            }
          >
            <label htmlFor="deptselector">Department</label>
            <select
              id="deptselector"
              onChange={(th) => {
                setDept(th.target.value);
                setLevel("DEPT");
                setTeam("");
              }}
              value={dept}
            >
              <option value="">-</option>
              {DeptsByFac.sort().map((th) => {
                return (
                  <React.Fragment key={"inoptgroup" + th.FacultyCode}>
                    <option disabled={true}>{th.FacultyName}</option>
                    {th.Depts.sort().map((ith) => {
                      return (
                        <option
                          key={"inopt" + th.FacultyCode + "_" + ith.Key}
                          value={ith.Key}
                        >
                          {ith.Value}
                        </option>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </select>
          </div>
          <div
            className={
              "MechanismInputRack" + (mechanism === "CenServ" ? " Active" : "")
            }
          >
            <label htmlFor="CSselector">Central Service</label>
            <select
              id="CSselector"
              onChange={(th) => {
                setDept(th.target.value.split(";")[0]);
                setLevel(th.target.value.split(";")[1]);
                setTeam(th.target.value.split(";")[2]);
              }}
              value={dept + ";" + level}
            >
              <option value=";;">-</option>
              <option value=";CRT;">Course Records Team</option>
              <option value=";IST;">International Services Team</option>
              <option value=";PGRE;">PG Research Education Team</option>
              {/* <option value=";">-</option> */}
              <option value=";;" disabled={true}>
                Student Progress
              </option>
              <option value=";SPT;one">Student Progress Team 1</option>
              <option value=";SPT;two">Student Progress Team 2</option>
              <option value=";SPT;three">Student Progress Team 3</option>
              <option value=";SPT;four">Student Progress Team 4</option>
              <option value=";SPT;five">Student Progress Team 5</option>
              <option value=";SPT;six">Student Progress Team 6</option>
              <option value=";SPT;all">All Student Progress Teams</option>
              {TeamsByFac.sort().map((th) => {
                return (
                  <React.Fragment key={"teamfrag" + th.FacultyCode}>
                    <option disabled={true}>{th.FacultyName}</option>
                    {th.Teams.sort().map((ith) => {
                      return (
                        <option
                          key={"teamfrag" + th.FacultyCode + "_" + ith.Key}
                          value={th.FacultyCode + ";" + ith.Key}
                        >
                          {ith.Value}
                        </option>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </select>
          </div>
          <div
            className={
              "MechanismInputRack" +
              (mechanism === "AdmisTeams" ? " Active" : "")
            }
          >
            <label htmlFor="ADTselector">Admissions Team</label>
            <select
              id="ADTselector"
              onChange={(th) => {
                setDept("");
                setTeam("");
                setLevel(th.target.value);
              }}
              value={level}
            >
              <option value="">-</option>
              <option value="PGAD">Postgraduate Admissions</option>
              <option value="UGAD">Undergraduate Admissions</option>
            </select>
          </div>
        </div>
        {
          //       <h3>Faculty of Humanities</h3>
          //       <ul>
          //         <li>
          //           <Link to="/HUM/SPT">Student Progress Staff Tasks</Link>
          //         </li>
          //         <li>
          //           <Link to="/HUM/DEAN">Dean Tasks</Link>
          //         </li>
          //         <li>
          //           <Link to="/HUM/CRT">Course Records Team Tasks</Link>
          //         </li>
          //         <li>
          //           <Link to="/HUM/OTHER">
          //             Income / Student Wellbeing and Inclusion Service Tasks
          //           </Link>
          //         </li>
          //       </ul>
          //       <h3>Faculty of Science and Health</h3>
          //       <ul>
          //         <li>
          //           <Link to="/SCH/SPT">Student Progress Staff Tasks</Link>
          //         </li>
          //         <li>
          //           <Link to="/SCH/DEAN">Dean Tasks</Link>
          //         </li>
          //         <li>
          //           <Link to="/SCH/CRT">Course Records Team Tasks</Link>
          //         </li>
          //         <li>
          //           <Link to="/SCH/OTHER">
          //             Income / Student Wellbeing and Inclusion Service Tasks
          //           </Link>
          //         </li>
          //       </ul>
          //       <h3>Faculty of Social Sciences</h3>
          //       <ul>
          //         <li>
          //           <Link to="/SOS/SPT">Student Progress Staff Tasks</Link>
          //         </li>
          //         <li>
          //           <Link to="/SOS/DEAN">Dean Tasks</Link>
          //         </li>
          //         <li>
          //           <Link to="/SOS/CRT">Course Records Team Tasks</Link>
          //         </li>
          //         <li>
          //           <Link to="/SOS/OTHER">
          //             Income / Student Wellbeing and Inclusion Service Tasks
          //           </Link>
          //         </li>
          //       </ul>
        }

        {/* for Level
        <Dropdown
          className="TADropdownLevel"
          placeholder="Select Level"
          options={level}
          selectedKey={this.state.level}
          onChange={(e, o) =>
            this.setState({ level: o!.key.toString(), dept: "" })
          }
        /> */}
        {/* for departmant
        {this.state.level === "DEPT" ? (
          <Dropdown
            className="TADropdownDept"
            placeholder="Select Department"
            // disabled={
            //   this.state.level === "" ||
            //   this.state.level === "SPT" ||
            //   this.state.level === "DEAN" ||
            //   this.state.level === "CRT" ||
            //   this.state.level === "OTHER" ||
            //   this.state.level === "SPT" ||
            //   this.state.level === "INCOME" ||
            //   this.state.level === "PGRE" ||
            //   this.state.level === "All"
            // }
            options={depts}
            selectedKey={this.state.dept}
            onChange={(e, o) => this.setState({ dept: o!.key.toString() })}
          />
        ) : (
          ""
        )} */}

        {/* <Dropdown placeholder="Select an option"
        options={team} 
        selectedKey={this.state.team} 
        onChange={(e,o) => this.setState({team: o!.key.toString()})} /> */}
        {
          <GridWrapper
            {...PassToAllGrids}
            key={"TLP_" + props.Refresher}
            eid={"TLPan_" + props.Refresher}
            output="sproc"
            options="showAdvancedSort=true|showAdvancedGrouping=true|showAdvancedFiltration=true|showCookieBouncer=true|toprowcount=Team Count: %"
            group="Campus"
            sort="Study Mode,Study Level"
            class="UoE-Grid ESF"
            sproc="sputilities.webui.UTILITY_WF_ReviewTaskAssignees"
            columnclass="Assigned To,pre;Send Email Notfication,pre"
            NoneMessage="No tasks found."
            limit={100}
            params={["@DEPT", dept, "@TEAM", team, "@LEVEL", level]}
          />
        }
      </div>
    </div>
  );
};

export default ReviewTaskAssignees;

/*

    //
    const depts: IDropdownOption[] = [
      // { key: "", text: "No Department" },
      { key: "LW", text: "Law" },
      { key: "BE", text: "Essex Business School" },
      { key: "BS", text: "Life Sciences (School of)" },
      {
        key: "CE",
        text: "Computer Science and Electronic Engineering (School of)",
      },
      { key: "EC", text: "Economics" },
      { key: "EG", text: "Edge Hotel School" },
      { key: "GV", text: "Government" },
      { key: "HS", text: "Health and Social Care (School of)" },
      { key: "HU", text: "Human Rights Centre (Essex Law School)" },
      { key: "IA", text: "Essex Pathways" },
      { key: "LL", text: "Language and Linguistics" },
      { key: "LT", text: "Literature, Film, and Theatre Studies" },
      { key: "LW", text: "Essex Law School" },
      { key: "LX", text: "Languages for All" },
      {
        key: "MA",
        text: "Mathematics, Statistics and Actuarial Science (School of)",
      },
      { key: "PA", text: "Psychosocial and Psychoanalytic Studies" },
      { key: "PS", text: "Psychology" },
      {
        key: "SA",
        text: "Philosophical, Historical and Interdisciplinary Studies (School of)",
      },
      { key: "SC", text: "Sociology" },
      {
        key: "SE",
        text: "Sport, Rehabilitation and Exercise Sciences (School of)",
      },
      { key: "SK", text: "Student Development" },
    ];
    // const team: IDropdownOption[] = [
    //   {key: '', text: ''}
    // ];
    const level: IDropdownOption[] = [
      // { key: "All", text: "All" },
      { key: "CRT", text: "Course Records Team" },
      { key: "DEAN", text: "Dean" },
      { key: "DEPT", text: "Department" },
      { key: "INCOME", text: "Income" },
      { key: "IST", text: "International Services Team" },
      { key: "PGRE", text: "PGRE" },
      { key: "PGAD", text: "PG Admissions" },
      { key: "SPT", text: "Student Progress" },
      { key: "UGAD", text: "UG Admissions" },
    ];
    */
