import "../../../assets/css/Clearing_LF/Clearing.css";
import { useLoaderData } from "react-router-dom";
import { GridWrapper } from "../../../integrations/UoE-Grid/GridWrapper";

import "../../../assets/css/Clearing_LF/Clearing.css";
import { MessageBar, ProgressIndicator } from "@fluentui/react";
import { useApi } from "../../../Providers/ApiProvider";
import { useEffect, useState } from "react";
import { IItem } from "../../../models/IItem";
import GridContent from "../../../integrations/UoE-Grid/GridContent";
import { Logging } from "../../../Logging";
import { useUser } from "../../../Providers/UserProvider";

interface ClearingApplicantReviewProps {}

enum MessageType {
  NORMAL,
  SUCCESS,
  WARNING,
  ERROR,
}

type Message = {
  type: MessageType;
  message: String;
  show: Boolean;
};

export function ClearingApplicantReview(props: ClearingApplicantReviewProps) {
  let loaderData: any = useLoaderData();
  let api = useApi();
  let user = useUser();
  let [data, setData] = useState<IItem[]>([]);
  let [checkedUcasNos, setCheckedUcasNos] = useState<Map<string, any>>(
    new Map(),
  );
  let [loading, setLoading] = useState<boolean>(true);
  let [checking, setChecking] = useState<number>(0);
  let [hasMutations, setMutations] = useState<boolean>(false);
  let [canSave, setCanSave] = useState<boolean>(false);
  let [validationMessages, setValidationMessages] = useState<string[]>([]);
  let [message, setMessage] = useState<Message>({
    type: MessageType.NORMAL,
    message: "",
    show: false,
  });

  useEffect(() => {
    api.General.getClearingDocsForFlagging(
      loaderData.clearingno.toUpperCase(),
      user.ViewAsUser?.email,
    )
      .then((respData) => {
        setData(respData);
        setCanSave(false);
        setMutations(false);
        setLoading(false);
      })
      .catch((err) => {
        setMessage({
          type: MessageType.ERROR,
          message: "Failed to load documents - " + err,
          show: true,
        });
      });
  }, [user.ViewAsUser]);

  let funcs = [
    {
      Name: "UCAS Verified_CHECKBOX",
      ColumnKey: "_id",
      Function: (id: any) => {
        // if (
        //   message.type == MessageType.NORMAL ||
        //   message.type == MessageType.SUCCESS
        // ) {
        setMessage({
          type: MessageType.NORMAL,
          message: "",
          show: false,
        });
        // }
        setData((old) => {
          return validate(
            old.map((d) => {
              if (d._id == id && d["UCAS Known"] != "❌") {
                setMutations(true);
                // I know. I know. It's nasty - but this is a reliable way of
                // parsing the required strings to bools, flipping them and converting back
                return {
                  ...d,
                  "UCAS Verified_CHECKBOX": JSON.stringify(
                    d["UCAS Verified_CHECKBOX"].toLowerCase() == "false",
                  ),
                  _mutated: "true",
                };
              } else {
                return d;
              }
            }),
          );
        });
      },
    },
    {
      Name: "UCAS No._TEXTENTRY",
      ColumnKey: "_id",
      Function: (id: any, value: string) => {
        let updates: IItem[] = [];
        data.forEach((val) => updates.push({ ...val }));
        updates = data.map((d) => {
          if (d._id == id) {
            return {
              ...d,
              "UCAS No._TEXTENTRY": value.toUpperCase().trim(),
              _mutated: "true",
            };
          } else {
            return d;
          }
        });

        setMessage({
          type: MessageType.NORMAL,
          message: "",
          show: false,
        });
        setData(validate(updates));
        setMutations(true);
      },
    },
  ];

  function validate(toBeValidated: IItem[]): IItem[] {
    setValidationMessages([]);

    if (
      toBeValidated.find((d) => d["UCAS No._TEXTENTRY"] != undefined) ==
      undefined
    ) {
      setCanSave(false);
      return toBeValidated;
    }
    let saveFatal = false;
    let toBeReturned = toBeValidated.map((d) => {
      // It's the wrong shape for a DA/UCAS No. error and no save.
      // ESIS Nasty of 0000000000 is the wrong shape
      // Regex breaks down as...
      // (?!^0{10}$) -> Look ahead, must not match 10 0s
      //            (^[0-9]{10}$ -> Start of group, exactly 10 digits (ucas no format)
      //                          |^DA[0-9]{8}$) -> OR DA and 8 digits (DA format), End of group
      // The ending i on the regex definition makes it case insensitive.
      if (
        d["UCAS No._TEXTENTRY"].match(
          /(?!^0{10}$)(^[0-9]{10}$|^DA[0-9]{8}$)/i,
        ) == null
      ) {
        d["UCAS Known"] = "❌";
        d["UCAS Verified_CHECKBOX"] = "false";
        if (
          d["UCAS No._TEXTENTRY"] != undefined &&
          d["UCAS No._TEXTENTRY"] != "" &&
          d["UCAS No._TEXTENTRY"] != "0000000000"
        ) {
          setValidationMessages((old) => {
            return old.concat(
              d["UCAS No._TEXTENTRY"] + " - is not a valid UCAS No. format",
            );
          });
          setMessage({
            type: MessageType.ERROR,
            message:
              "You have provided a UCAS number that does not match the required format or is the default provided by ESIS (0000000000). Please provide a valid UCAS No.",
            show: true,
          });
          saveFatal = true;
        }
      } else {
        // It matches the right format, do we have it cached?
        if (checkedUcasNos.has(d["UCAS No._TEXTENTRY"])) {
          let resp = checkedUcasNos.get(d["UCAS No._TEXTENTRY"]);

          if (resp[0][0].Valid == 1) {
            d["UCAS Known"] = "✔";
            if (resp[0][0].Difference < 4) {
              d["UCAS Known"] = "❔";
              setValidationMessages((old) => {
                return old.concat(
                  d["UCAS No._TEXTENTRY"] +
                    " - is known to admissions as " +
                    resp[0][0].Surname +
                    ", " +
                    resp[0][0].OtherNames,
                );
              });
            }
          } else {
            d["UCAS Known"] = "❌";
            d["UCAS Verified_CHECKBOX"] = "false";
            setValidationMessages((old) => {
              return old.concat(
                d["UCAS No._TEXTENTRY"] + " - is not known to admissions",
              );
            });
          }
        } else {
          //No cache? Hold everything until we have a DB check performed!
          setChecking((x) => x + 1);
          setMessage({
            type: MessageType.NORMAL,
            message: "Validating UCAS Number...",
            show: true,
          });
          api.General.get(
            "/sproc",
            JSON.stringify({
              sproc: "sputilities.webui.CheckClearingRefNo",
              params: [
                "@ident",
                d["UCAS No._TEXTENTRY"],
                "@clearingRef",
                loaderData.clearingno,
              ],
            }),
          ).then((resp) => {
            setCheckedUcasNos((old) => {
              old.set(d["UCAS No._TEXTENTRY"], resp);
              return new Map(old);
            });

            if (resp[0][0].Valid == 1) {
              d["UCAS Known"] = "✔";
              if (resp[0][0].Difference < 4) {
                d["UCAS Known"] = "❔";
                setValidationMessages((old) => {
                  return old.concat(
                    d["UCAS No._TEXTENTRY"] +
                      " - is known to admissions as " +
                      resp[0][0].Surname +
                      ", " +
                      resp[0][0].OtherNames,
                  );
                });
              }
              setMessage((old) => {
                if (old.show && old.type != MessageType.NORMAL) {
                  return { ...old };
                } else {
                  return {
                    type: MessageType.NORMAL,
                    message: "",
                    show: false,
                  };
                }
              });
            } else {
              d["UCAS Known"] = "❌";
              d["UCAS Verified_CHECKBOX"] = "false";
              setValidationMessages((old) => {
                return old.concat(
                  d["UCAS No._TEXTENTRY"] + " - is not known to admissions",
                );
              });
            }
            setChecking((x) => x - 1);
          });
        }
      }
      return d;
    });

    if (toBeReturned.filter((d) => d["UCAS Known"] == "❌").length == 0) {
      setMessage({
        type: MessageType.SUCCESS,
        message: "Found in Database.",
        show: true,
      });
    }

    setCanSave(!saveFatal);

    return toBeReturned;
  }

  function saveData(): Promise<any> {
    let edited = data.filter((d) => d._mutated == "true");
    return api.General.saveClearingDocsMetadata(edited);
  }

  function reset() {
    setLoading(true);
    api.General.getClearingDocsForFlagging(loaderData.clearingno.toUpperCase())
      .then((respData) => {
        setData(respData);
        setMutations(false);
        setLoading(false);
      })
      .catch((err) => {
        setMessage({
          type: MessageType.ERROR,
          message: "Failed to load documents - " + err,
          show: true,
        });
      });
  }

  if (loaderData !== undefined) {
    return (
      <div className="CLEAR_Container">
        <div className="CLEAR_Row">
          <div className="Blurb_container">
            <h1>Clearing Documents Review</h1>
            <h2>Instructions</h2>
            <p>
              This page can be used to set the <b>UCAS No.</b> and{" "}
              <b>Applicant No. Verified</b> fields for multiple documents
              simultaneously.
              <p>
                Enter the Applicant No. in the Applicant No. text box and click
                on the Applicant No. Verified checkbox to confirm the Applicant
                No is verified.
              </p>
              <p>
                Hovering your mouse cursor over any document will bring up a
                preview window to the right-hand side of the page so you do not
                have to open every document to see what it contains.
              </p>
            </p>
            <br />
            <p>
              Once you have made your changes,{" "}
              <em>
                remember to click the <b>Save Changes</b> button or your changes
                will not be saved.
              </em>
            </p>
          </div>
        </div>
        <div className="CLEAR_Row CLEAR_Main">
          <div className="CLEAR_Col">
            <h2>Applicant Details</h2>
            <GridWrapper
              eid={"CAS_Studata_Summary"}
              sproc="sputilities.webui.CLEARING_student_details"
              params={["@clearingno", loaderData.clearingno.toUpperCase()]}
              Callouts={false}
              output="data"
              URLParams={""}
              NoneMessage="No applicant records found for this person. Check identifier and try again"
            ></GridWrapper>
          </div>
          <div className="CLEAR_Spacer"></div>
          <div className="CLEAR_Col">
            {validationMessages.length > 0 ? (
              <div className="CLEAR_Message CLEAR_Warn_Message">
                <p>
                  The data you have provided has the following (potential)
                  issues:
                </p>
                <ul>
                  {validationMessages.map((val) => {
                    return <li>{val}</li>;
                  })}
                </ul>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="CLEAR_Row">
          {/* <div className="CLEAR_Col"> */}
          {loading == true ? (
            <>
              <div className="CLEAR_Main">
                <div className="CLEAR_Controls">
                  <button
                    className="CLEAR_Primary CLEAR_Button disabled"
                    disabled
                  >
                    Save Changes
                  </button>
                  <button
                    className="CLEAR_Primary CLEAR_Button disabled"
                    disabled
                  >
                    Clear Changes
                  </button>
                  {message.show ? (
                    <div
                      className={
                        "CLEAR_Message" +
                        (message.type === MessageType.SUCCESS
                          ? " CLEAR_Success"
                          : "") +
                        (message.type === MessageType.ERROR
                          ? " CLEAR_Fail"
                          : "")
                      }
                    >
                      {message.message}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <ProgressIndicator className="loading" barHeight={10} />
              </div>
            </>
          ) : (
            <>
              {data == undefined || data.length == 0 ? (
                <MessageBar className="no-results">
                  No Documents Returned For This Applicant
                </MessageBar>
              ) : (
                <>
                  <div className="CLEAR_Main">
                    <div className="CLEAR_Controls">
                      <button
                        className={
                          "CLEAR_Primary CLEAR_Button " +
                          (hasMutations ? "" : "disabled")
                        }
                        disabled={!hasMutations || !canSave || checking > 0}
                        onClick={() => {
                          setMutations(false);
                          setMessage({
                            type: MessageType.NORMAL,
                            message: "Saving...",
                            show: true,
                          });
                          saveData()
                            .then((_) => {
                              setLoading(false);
                              setMessage({
                                type: MessageType.SUCCESS,
                                message: "Changes Saved",
                                show: true,
                              });
                            })
                            .catch((err) => {
                              setMessage({
                                type: MessageType.ERROR,
                                message: "Failed to save metadata - " + err,
                                show: true,
                              });
                              setMutations(true);
                              console.error(JSON.stringify(err));
                            });
                        }}
                      >
                        Save Changes
                      </button>
                      <button
                        className={
                          "CLEAR_Warning CLEAR_Button " +
                          (hasMutations ? "" : "disabled")
                        }
                        disabled={!hasMutations}
                        onClick={() => {
                          reset();
                          setMessage({
                            type: MessageType.NORMAL,
                            message: "Changes Reset",
                            show: true,
                          });
                        }}
                      >
                        Reset Changes
                      </button>
                      {message.show ? (
                        <div
                          className={
                            "CLEAR_Message" +
                            (message.type === MessageType.SUCCESS
                              ? " CLEAR_Success"
                              : "") +
                            (message.type === MessageType.WARNING
                              ? " CLEAR_Warn_Message"
                              : "") +
                            (message.type === MessageType.ERROR
                              ? " CLEAR_Fail"
                              : "")
                          }
                        >
                          {message.message}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <GridContent
                      eid="CLEAR"
                      items={data}
                      Logger={new Logging("LIVE")}
                      gridindex={undefined}
                      start={0}
                      limit={200}
                      showAdvancedSort={true}
                      showAdvancedGrouping={true}
                      showAdvancedFiltration={true}
                      showCookieBouncer={false}
                      Refresher={Date.now().toString()}
                      Callouts={true}
                      ButtonsAndFunctions={funcs}
                      sort="Created Date"
                      widths="UCAS No._TEXTENTRY,150"
                    ></GridContent>
                    <div className="CLEAR_Controls">
                      <button
                        className={
                          "CLEAR_Primary CLEAR_Button " +
                          (hasMutations ? "" : "disabled")
                        }
                        disabled={!hasMutations || !canSave || checking > 0}
                        onClick={() => {
                          setMutations(false);
                          setMessage({
                            type: MessageType.NORMAL,
                            message: "Saving...",
                            show: true,
                          });
                          saveData()
                            .then((_) => {
                              setLoading(false);
                              setMessage({
                                type: MessageType.SUCCESS,
                                message: "Changes Saved",
                                show: true,
                              });
                            })
                            .catch((err) => {
                              setMessage({
                                type: MessageType.ERROR,
                                message: "Failed to save metadata - " + err,
                                show: true,
                              });
                              setMutations(true);
                              console.error(JSON.stringify(err));
                            });
                        }}
                      >
                        Save Changes
                      </button>
                      <button
                        className={
                          "CLEAR_Warning CLEAR_Button " +
                          (hasMutations ? "" : "disabled")
                        }
                        disabled={!hasMutations}
                        onClick={() => {
                          reset();
                          setMessage({
                            type: MessageType.NORMAL,
                            message: "Changes Reset",
                            show: true,
                          });
                        }}
                      >
                        Reset Changes
                      </button>
                      {message.show ? (
                        <div
                          className={
                            "CLEAR_Message" +
                            (message.type === MessageType.SUCCESS
                              ? " CLEAR_Success"
                              : "") +
                            (message.type === MessageType.WARNING
                              ? " CLEAR_Warn_Message"
                              : "") +
                            (message.type === MessageType.ERROR
                              ? " CLEAR_Fail"
                              : "")
                          }
                        >
                          {message.message}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {/* </div> */}
        </div>
      </div>
    );
  } else return <div>loading</div>;
}
