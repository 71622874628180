import { useState } from "react";
import { InvarDataAccess } from "../../../data/InvarDataAccess";
import { InvarAdminConveyanceSegmentConfig } from "./InvarAdminConveyanceSegmentConfig";
import { ActionResponse } from "../../../data/InvarDataModels/ActionResponse";
import { ConveyanceSegmentResponse } from "../../../data/InvarDataModels/ConveyanceSegmentResponse";
import { ConveyanceSegmentConfigResponse } from "../../../data/InvarDataModels/ConveyanceSegmentConfigResponse";

interface IProps {
  IDA: InvarDataAccess;
  ConveyanceSegment: ConveyanceSegmentResponse;
  ConveyanceSegmentConfigs: Array<ConveyanceSegmentConfigResponse>;
  Action: ActionResponse;
  PromoteConveyanceSegment: (CSID: number) => void;
  CanDemote: boolean;
  DemoteConveyanceSegment: (CSID: number) => void;
  CreateConveyanceSegmentConfig: (
    CSID: number,
    name: string,
    value: string,
  ) => void;
  EditConveyanceSegmentConfig: (
    CSCID: number,
    CSID: number,
    newName: string,
    newValue: string,
  ) => void;
}

export const InvarAdminConveyanceSegment = (props: IProps) => {
  const [_ncscname, setNCSCName] = useState("");
  const [_ncscvalue, setNCSCValue] = useState("");

  return props.ConveyanceSegment !== ConveyanceSegmentResponse.prototype ? (
    <div className="ConvSeg">
      <div className="SegID">{props.ConveyanceSegment.Ordinal.toString()}</div>
      <div className="SegID">
        {props.ConveyanceSegment.ConveyanceSegmentID.toString()}
      </div>
      <div
        className={
          "SegPromoter" +
          (props.ConveyanceSegment.Ordinal === 1 ? " Disabled" : "")
        }
        onClick={async () => {
          props.PromoteConveyanceSegment(
            props.ConveyanceSegment.ConveyanceSegmentID,
          );
        }}
      >
        &#9650;
      </div>
      <div
        className={"SegDemoter" + (props.CanDemote ? " Disabled" : "")}
        onClick={async () => {
          props.DemoteConveyanceSegment(
            props.ConveyanceSegment.ConveyanceSegmentID,
          );
        }}
      >
        &#9660;
      </div>
      <div className="SegAction">
        {props.Action !== ActionResponse.prototype && props.Action !== undefined
          ? props.Action.Name
          : ""}
      </div>
      <div className="SegName">{props.ConveyanceSegment.SegmentName}</div>
      <div className="ConvSegConfs">
        {props.ConveyanceSegmentConfigs.filter(
          (ith) =>
            ith.ConveyanceSegmentID ===
            props.ConveyanceSegment.ConveyanceSegmentID,
        ).map((ith, j) => {
          return (
            <InvarAdminConveyanceSegmentConfig
              key={j}
              ConveyanceSegmentConfig={ith}
              EditConveyanceSegmentConfig={props.EditConveyanceSegmentConfig}
              IDA={props.IDA}
            ></InvarAdminConveyanceSegmentConfig>
          );
        })}
      </div>
      <div className="ConvSegConfsAdd">
        <label
          htmlFor={
            "CSC_" +
            props.ConveyanceSegment.ConveyanceSegmentID.toString() +
            "_Name_New"
          }
        >
          Name:
        </label>
        <input
          id={
            "CSC_" +
            props.ConveyanceSegment.ConveyanceSegmentID.toString() +
            "_Name_New"
          }
          required={true}
          type="text"
          value={_ncscname}
          onChange={(ev) => {
            setNCSCName(ev.target.value);
          }}
        ></input>
        <label
          htmlFor={
            "CSC_" +
            props.ConveyanceSegment.ConveyanceSegmentID.toString() +
            "_Val_New"
          }
        >
          Value:
        </label>
        <input
          id={
            "CSC_" +
            props.ConveyanceSegment.ConveyanceSegmentID.toString() +
            "_Val_New"
          }
          onChange={(ev) => {
            setNCSCValue(ev.target.value);
          }}
          value={_ncscvalue}
          type="text"
        ></input>
        <div
          className="ConvSegConfsAddButton"
          onClick={async () => {
            props.CreateConveyanceSegmentConfig(
              props.ConveyanceSegment.ConveyanceSegmentID,
              _ncscname,
              _ncscvalue,
            );
            setNCSCName("");
            setNCSCValue("");
          }}
        >
          &#43;
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
