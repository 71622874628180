import * as React from "react";
import { IItem } from "../../models/IItem";

interface IProps {
  TaskListsTable: IItem[];
}

class TaskListsPanel extends React.Component<IProps> {
  render() {
    if (this.props.TaskListsTable === undefined) {
      return (
        <div className="TaskListsPanel">
          <h2>Task List Visibility</h2>
          <div className="error-message">Task List Presences failed.</div>
        </div>
      );
    } else if (this.props.TaskListsTable.length === 0) {
      return (
        <div className="TaskListsPanel">
          <h2>Task List Visibility</h2>
          <div className="error-message">No Task List Presences found.</div>
        </div>
      );
    } else {
      return (
        <div className="TaskListsPanel">
          <h2>Task List Visibility</h2>
          {this.props.TaskListsTable.map((row: IItem, i: Number) => (
            <React.Fragment key={i.toString()}>
              <div className="TaskList">
                <a href={row.URL}>
                  <div className="AcroIcon">{row.Acronym}</div>
                  <div className="Name">{row.Name}</div>
                </a>
              </div>
            </React.Fragment>
          ))}
        </div>
      );
    }
  }
}

export default TaskListsPanel;
