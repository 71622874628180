import React, { useEffect } from "react";
import "../../assets/css/UserAccessChecker.css";
import { useApi } from "../../Providers/ApiProvider";

export const UserAccessChecker = () => {
  const api = useApi();
  const [_userfilter, _setUserFilter] = React.useState("");
  const [searchuserfilter, setSearchUserFilter] = React.useState("");
  const [_retrievedUser, setRetrievedUser] = React.useState(null);
  const [purge, setPurge] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  console.log(api);
  console.log(_retrievedUser);

  useEffect(() => {
    if (searchuserfilter !== "") {
      setLoading(true);

      api.General.getUser(searchuserfilter).then((res) => {
        setRetrievedUser(res);
      });

      setLoading(false);
    }
  }, [searchuserfilter]);

  useEffect(() => {
    if (purge) {
      setLoading(true);

      setRetrievedUser(null);

      api.General.get(
        "/sproc",
        JSON.stringify({
          sproc: "sputilities.dbo.ClearUserPermissionCache",
          params: ["@clearme", searchuserfilter],
        }),
      ).then((res) => {
        setTimeout(() => {
          api.General.getUser(searchuserfilter).then((res) => {
            setRetrievedUser(res);
          });
        }, 200);
      });

      setPurge(false);
      setLoading(false);
    }
  }, [purge]);

  return (
    <div className="UserAccessChecker">
      <div className="HeaderPanel">
        <h1>User Access Checker</h1>
        <div className="Description">
          <p>
            This tool allows you to check the groups and roles assigned to a
            user in the system.
          </p>
          <p>
            Type a username and click 'scan' to get a list of their Groups and
            their Roles.
          </p>
          <p>
            A user's Groups are what we use to determine their Roles, and their
            Roles are what grant them access to parts of EDRM.
          </p>
          <p>
            If you suspect a user's permissions are incorrect, you can purge
            their cache and reload their permissions by clicking the 'Purge
            cache for this user and reload' button.
          </p>
          <p>
            You may wish to do this if a user's Group memberships have recently
            changed, or if you see mention of the Tombstoned group in their
            Groups.
          </p>
          <p>
            To clear the screen <i>without</i> purging the user's cache, click
            the 'Clear' button.
          </p>
        </div>
      </div>
      <div className="SearchBlock">
        <div id="UserFilter">
          <label htmlFor="UserFilterText">Username:</label>
          <input
            type="text"
            id="UserFilterText"
            value={_userfilter}
            onKeyUp={(ev) => {
              if (ev.key === "Enter") {
                setSearchUserFilter(_userfilter.trim());
              }
            }}
            onChange={(ev) => {
              _setUserFilter(ev.target.value);
            }}
          ></input>
          <button
            disabled={loading}
            onClick={() => {
              setSearchUserFilter(_userfilter);
            }}
          >
            Scan
          </button>
          <button
            disabled={loading || _retrievedUser === null}
            onClick={() => {
              setSearchUserFilter("");
              setRetrievedUser(null);
              _setUserFilter("");
            }}
          >
            Clear
          </button>
          {_retrievedUser === null || _retrievedUser === undefined ? (
            <></>
          ) : (
            <button
              disabled={loading}
              onClick={() => {
                setPurge(true);
              }}
            >
              Purge cache for this user and reload.
            </button>
          )}
        </div>
      </div>
      {_retrievedUser === null || _retrievedUser === undefined ? (
        <div className="UserAccessSearchResults">
          <h4 className="NoResults">No results</h4>
        </div>
      ) : (
        <div className="UserAccessSearchResults">
          <h2 className="Results">&gt; {_retrievedUser?.userPrincipalName}</h2>
          <div className="UserGroups">
            <h3>User Groups</h3>
            <div className="UserGroupList">
              <ul>
                {_retrievedUser?.groups
                  ?.sort((a, b) => (a.mailNickname > b.mailNickname ? 1 : -1))
                  .map((group, i) => {
                    return (
                      <li key={"group_" + i.toString()}>{group.displayName}</li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="UserPermissions">
            <h3>User Roles</h3>
            <div className="UserGroupList">
              <ul>
                {_retrievedUser?.permissions
                  ?.sort((a, b) => (a > b ? 1 : -1))
                  .map((permission, i) => {
                    return (
                      <li key={"permission_" + i.toString()}>{permission}</li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
