import * as React from "react";
import { DataAccess, IParams } from "../../data/DataAccess";
import { Logging, LoggingLevel } from "../../Logging";
import { IItem } from "../../models/IItem";
import DetailPanel from "./DetailPanel";
import DiagnosticPanel from "./DiagnosticPanel";
import TaskListsPanel from "./TaskListsPanel";
import Timeline from "./Timeline";
import { Toggles } from "./Toggles";

interface IProps {
  MODE_LOCAL: boolean;
  MODE_DEVELOPMENT: boolean;
  MODE_STAGING: boolean;
  MODE_LIVE: boolean;
  Logger: Logging;
  da: DataAccess;
  figuid: string;
}

interface IState {
  message?: string;
  user: string;
  fiid: Number;
  urlparameters?: any;
  refreshInterval?: any;
  LastChecked: Date;
  OLD_DatumHistoryCount: Number;
  OLD_AssignmentHistoryCount: Number;
  OLD_EventCount: Number;
  OLD_DesignationCount: Number;
  OLD_AccessCount: Number;
  OLD_MailRequestCount: Number;
  OLD_LatestEventChecksum: Number;
  OLD_LatestDesignationChecksum: Number;
  OLD_LatestAccessChecksum: Number;
  OLD_LatestOtherChecksum: Number;
  OLD_LatestDetailChecksum: Number;
  OLD_LatestTaskListsChecksum: Number;
  OLD_LatestMailRequestChecksum: Number;
  DatumHistoryCount: Number;
  AssignmentHistoryCount: Number;
  EventCount: Number;
  DesignationCount: Number;
  AccessCount: Number;
  MailRequestCount: Number;
  LatestEventChecksum: Number;
  LatestDesignationChecksum: Number;
  LatestAccessChecksum: Number;
  LatestOtherChecksum: Number;
  LatestDetailChecksum: Number;
  LatestTaskListsChecksum: Number;
  LatestMailRequestChecksum: Number;
  ValidFormInstance?: boolean | undefined;
  DetailTable: IItem[];
  AnalysisTable: IItem[];
  CommitTable: IItem[];
  CommitDatumsTable: IItem[];
  AssignmentHistoryTable: IItem[];
  EventTable: IItem[];
  DesignationTable: IItem[];
  AccessTable: IItem[];
  OtherTable: IItem[];
  MailTable: IItem[];
  TaskListsTable: IItem[];
  DiagnosisRequestOptions: { Name: string; Value: string }[];
  DiagnoseThis: string;
  RequestedDiagnosis: Number;
  Diagnosis: IItem[];
  SprocDataTable: IItem[];
  InstanceDataTable: IItem[][];
  Toggles: Toggles;
  SquishMap: boolean[];
}

class CollagenWorkflowSummary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    var newToggles: Toggles = {
      TechView: false,
      SortTimeDescending: true,
      ShowMilliseconds: true,
      ShowAccessLocks: true,
      ShowMail: true,
      ShowData: true,
      ShowAssignments: true,
      ShowEvents: true,
      ShowDesignations: false,
      ShowOther: true,
    };

    this.state = {
      user: "",
      fiid: -1,
      refreshInterval: 60000, // 5000
      LastChecked: new Date("1900-01-01"),
      OLD_DatumHistoryCount: -1,
      OLD_AssignmentHistoryCount: -1,
      OLD_EventCount: -1,
      OLD_DesignationCount: -1,
      OLD_AccessCount: -1,
      OLD_MailRequestCount: -1,
      OLD_LatestEventChecksum: -1,
      OLD_LatestDesignationChecksum: -1,
      OLD_LatestAccessChecksum: -1,
      OLD_LatestOtherChecksum: -1,
      OLD_LatestDetailChecksum: -1,
      OLD_LatestTaskListsChecksum: -1,
      OLD_LatestMailRequestChecksum: -1,
      DatumHistoryCount: 0,
      AssignmentHistoryCount: 0,
      EventCount: 0,
      DesignationCount: 0,
      AccessCount: 0,
      MailRequestCount: 0,
      LatestEventChecksum: 0,
      LatestDesignationChecksum: 0,
      LatestAccessChecksum: 0,
      LatestOtherChecksum: 0,
      LatestDetailChecksum: 0,
      LatestTaskListsChecksum: 0,
      LatestMailRequestChecksum: 0,
      ValidFormInstance: undefined,
      DetailTable: [],
      AnalysisTable: [],
      CommitTable: [],
      CommitDatumsTable: [],
      AssignmentHistoryTable: [],
      EventTable: [],
      DesignationTable: [],
      AccessTable: [],
      OtherTable: [],
      MailTable: [],
      TaskListsTable: [],
      DiagnosisRequestOptions: [],
      DiagnoseThis: "",
      RequestedDiagnosis: 0,
      Diagnosis: [],
      SprocDataTable: [],
      InstanceDataTable: [],
      Toggles: newToggles,
      SquishMap: [],
    };

    this.pollSummarySprocs();

    setInterval(() => {
      this.pollSummarySprocs();
    }, this.state.refreshInterval);

    this.props.Logger.log(
      "Starting CollagenWorkflowSummary...",
      LoggingLevel.INFO,
    );
  }

  public async componentDidMount() {
    this.props.Logger.log(
      "Mounting CollagenWorkflowSummary...",
      LoggingLevel.TRACE,
    );

    await this.props.da.getCurrentUser().then(
      (data) => {
        this.setState({ user: data });
      },
      (reason) => {
        this.props.Logger.log(reason, LoggingLevel.DEBUG);
      },
    );

    this.props.Logger.log(
      "Mounted CollagenWorkflowSummary.",
      LoggingLevel.INFO,
    );
  }

  public render() {
    //new Logging().log('render start', LoggingLevel.INFO);

    if (this.state.message) {
      return <div className="error-message">{this.state.message}</div>;
    } else if (this.state.ValidFormInstance === false) {
      return (
        <div className="error-message">
          The specified Form Instance doesn't exist.
        </div>
      );
    } else if (this.state.ValidFormInstance === undefined) {
      return (
        <div id="ECF-None">
          <h1>Workflow Summary</h1>
          <div className="loading" title="Loading..." />
        </div>
      );
    } else if (this.state.ValidFormInstance === true) {
      return (
        <div id="WFSumm">
          <button
            onClick={(o) => {
              this.Toggle("TechView");
            }}
            id="TechView"
            title={
              this.state.Toggles.TechView
                ? "Switch to Standard View"
                : "Switch to Tech View"
            }
            className={this.state.Toggles.TechView ? "Tech" : "Standard"}
          >
            {this.state.Toggles.TechView === false ? (
              <React.Fragment>&#9881;</React.Fragment>
            ) : (
              <React.Fragment>&#128100;</React.Fragment>
            )}
          </button>
          <h1>Workflow Summary</h1>
          <DetailPanel
            DetailTable={this.state.DetailTable}
            AnalysisTable={this.state.AnalysisTable}
            Toggles={this.state.Toggles}
            {...this.props}
          />
          <TaskListsPanel TaskListsTable={this.state.TaskListsTable} />
          {this.state.Toggles.TechView === true ? (
            <div className="SortAndFilters">
              <button
                onClick={(o) => {
                  this.Toggle("SortTimeDescending");
                }}
                id="TimeToggle"
                title={
                  this.state.Toggles.SortTimeDescending
                    ? "Order from New to Old"
                    : "Order from Old to New"
                }
              >
                {this.state.Toggles.SortTimeDescending === false ? (
                  <React.Fragment>&#128338; &#9660;</React.Fragment>
                ) : (
                  <React.Fragment>&#128338; &#9650;</React.Fragment>
                )}
              </button>
              <button
                onClick={(o) => {
                  this.Toggle("ShowAccessLocks");
                }}
                id="ShowAccess"
                title={
                  this.state.Toggles.ShowAccessLocks
                    ? "Hide Access Timeline"
                    : "Show Access Timeline"
                }
                className={
                  this.state.Toggles.ShowAccessLocks ? "Active" : "Inactive"
                }
              >
                {this.state.Toggles.ShowAccessLocks === false ? (
                  <React.Fragment>&#128065;</React.Fragment>
                ) : (
                  <React.Fragment>&#128065;</React.Fragment>
                )}
              </button>
              <button
                onClick={(o) => {
                  this.Toggle("ShowMail");
                }}
                id="ShowEmails"
                title={
                  this.state.Toggles.ShowMail ? "Hide Emails" : "Show Emails"
                }
                className={this.state.Toggles.ShowMail ? "Active" : "Inactive"}
              >
                {this.state.Toggles.ShowMail === false ? (
                  <React.Fragment>&#9993;</React.Fragment>
                ) : (
                  <React.Fragment>&#9993;</React.Fragment>
                )}
              </button>
              <button
                onClick={(o) => {
                  this.Toggle("ShowData");
                }}
                id="ShowData"
                title={this.state.Toggles.ShowData ? "Hide Data" : "Show Data"}
                className={this.state.Toggles.ShowData ? "Active" : "Inactive"}
              >
                {this.state.Toggles.ShowData === false ? (
                  <React.Fragment>&#128218;</React.Fragment>
                ) : (
                  <React.Fragment>&#128218;</React.Fragment>
                )}
              </button>
              <button
                onClick={(o) => {
                  this.Toggle("ShowAssignments");
                }}
                id="ShowAssignments"
                title={
                  this.state.Toggles.ShowAssignments
                    ? "Hide Assignments"
                    : "Show Assignments"
                }
                className={
                  this.state.Toggles.ShowAssignments ? "Active" : "Inactive"
                }
              >
                {this.state.Toggles.ShowAssignments === false ? (
                  <React.Fragment>&#128101;</React.Fragment>
                ) : (
                  <React.Fragment>&#128101;</React.Fragment>
                )}
              </button>
              <button
                onClick={(o) => {
                  this.Toggle("ShowEvents");
                }}
                id="ShowEvents"
                title={
                  this.state.Toggles.ShowEvents ? "Hide Events" : "Show Events"
                }
                className={
                  this.state.Toggles.ShowEvents ? "Active" : "Inactive"
                }
              >
                {this.state.Toggles.ShowEvents === false ? (
                  <React.Fragment>&#127881;</React.Fragment>
                ) : (
                  <React.Fragment>&#127881;</React.Fragment>
                )}
              </button>
              <button
                onClick={(o) => {
                  this.Toggle("ShowDesignations");
                }}
                id="ShowDesignations"
                title={
                  this.state.Toggles.ShowDesignations
                    ? "Hide Event Scans"
                    : "Show Event Scans"
                }
                className={
                  this.state.Toggles.ShowDesignations ? "Active" : "Inactive"
                }
              >
                {this.state.Toggles.ShowDesignations === false ? (
                  <React.Fragment>&#128269;</React.Fragment>
                ) : (
                  <React.Fragment>&#128269;</React.Fragment>
                )}
              </button>
              <button
                onClick={(o) => {
                  this.Toggle("ShowOther");
                }}
                id="ShowOther"
                title={
                  this.state.Toggles.ShowOther
                    ? "Hide Lifecycle Events"
                    : "Show Lifecycle Events"
                }
                className={this.state.Toggles.ShowOther ? "Active" : "Inactive"}
              >
                {this.state.Toggles.ShowOther === false ? (
                  <React.Fragment>&#127874;</React.Fragment>
                ) : (
                  <React.Fragment>&#127874;</React.Fragment>
                )}
              </button>
              <button
                onClick={(o) => {
                  this.Toggle("ShowMilliseconds");
                }}
                id="ShowMillisecondsToggle"
                title={
                  this.state.Toggles.ShowMilliseconds
                    ? "Show Milliseconds"
                    : "Hide Milliseconds"
                }
                className={
                  this.state.Toggles.ShowMilliseconds ? "Active" : "Inactive"
                }
              >
                .000
              </button>
              <button
                className="Reevaluator"
                onClick={(o) => {
                  this.Designate();
                }}
              >
                Reevaluate Events
              </button>
            </div>
          ) : (
            ""
          )}
          <Timeline
            Interleaved={[]}
            FIGUID={this.props.figuid}
            DA={this.props.da}
            EventTable={this.state.EventTable}
            DesignationTable={this.state.DesignationTable}
            AccessTable={this.state.AccessTable}
            CommitTable={this.state.CommitTable}
            CommitDatumsTable={this.state.CommitDatumsTable}
            AssignmentHistoryTable={this.state.AssignmentHistoryTable}
            OtherTable={this.state.OtherTable}
            MailTable={this.state.MailTable}
            Toggles={this.state.Toggles}
            SquishMap={this.state.SquishMap}
            SquishToggle={this.SquishToggle}
            PassthroughRequeue={this.PassthroughRequeue}
          />
          {this.state.Toggles.TechView === true ? (
            <React.Fragment>
              <label htmlFor="RequestedDiagnosisSelector">
                Diagnose Event:{" "}
              </label>
              <select
                id="RequestedDiagnosisSelector"
                onChange={(ev) => {
                  this.setState({ DiagnoseThis: ev.target.value });
                }}
              >
                {this.state.DiagnosisRequestOptions.map((th, i) => {
                  return (
                    <option key={"dro_" + i.toString()} value={th.Value}>
                      {th.Name}
                    </option>
                  );
                })}
                ;
              </select>
              <button
                className="DiagnoseButton"
                onClick={(ev) => {
                  this.setState(
                    {
                      RequestedDiagnosis: Number.parseInt(
                        this.state.DiagnoseThis,
                      ),
                    },
                    () => {
                      var sFIID = this.state.fiid.toString();
                      var sEID = this.state.DiagnoseThis;
                      if (sFIID !== "-1") {
                        this.getDiagnosis(sFIID, sEID);
                      }
                    },
                  );
                }}
              >
                Diagnose
              </button>
              <DiagnosticPanel
                Diagnosis={this.state.Diagnosis}
                SprocDataTable={this.state.SprocDataTable}
                InstanceDataTable={this.state.InstanceDataTable}
              />
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      );
    } else {
      return <div className="error-message">We're in an unknown state.</div>;
    }
  }

  Toggle = (ToggleName: string) => {
    var _toggles: Toggles = { ...this.state.Toggles };
    if (ToggleName === "TechView") {
      _toggles.TechView = !this.state.Toggles.TechView;
      if (_toggles.TechView === false) {
        _toggles.SortTimeDescending = true;
        _toggles.ShowAccessLocks = true;
        _toggles.ShowMail = true;
        _toggles.ShowData = true;
        _toggles.ShowAssignments = true;
        _toggles.ShowEvents = true;
        _toggles.ShowDesignations = false;
        _toggles.ShowOther = true;
        _toggles.ShowMilliseconds = false;
      } else {
        _toggles.SortTimeDescending = true;
        _toggles.ShowAccessLocks = true;
        _toggles.ShowMail = true;
        _toggles.ShowData = true;
        _toggles.ShowAssignments = true;
        _toggles.ShowEvents = true;
        _toggles.ShowDesignations = true;
        _toggles.ShowOther = true;
        _toggles.ShowMilliseconds = true;
      }
    }
    if (ToggleName === "SortTimeDescending") {
      _toggles.SortTimeDescending = !this.state.Toggles.SortTimeDescending;
    }
    if (ToggleName === "ShowAccessLocks") {
      _toggles.ShowAccessLocks = !this.state.Toggles.ShowAccessLocks;
    }
    if (ToggleName === "ShowMail") {
      _toggles.ShowMail = !this.state.Toggles.ShowMail;
    }
    if (ToggleName === "ShowData") {
      _toggles.ShowData = !this.state.Toggles.ShowData;
    }
    if (ToggleName === "ShowAssignments") {
      _toggles.ShowAssignments = !this.state.Toggles.ShowAssignments;
    }
    if (ToggleName === "ShowEvents") {
      _toggles.ShowEvents = !this.state.Toggles.ShowEvents;
    }
    if (ToggleName === "ShowDesignations") {
      _toggles.ShowDesignations = !this.state.Toggles.ShowDesignations;
    }
    if (ToggleName === "ShowOther") {
      _toggles.ShowOther = !this.state.Toggles.ShowOther;
    }
    if (ToggleName === "ShowMilliseconds") {
      _toggles.ShowMilliseconds = !this.state.Toggles.ShowMilliseconds;
    }
    this.setState({
      Toggles: _toggles,
    });
  };

  // in C# blazor, this function would be a one-line implication statement that wouldn't need EIGHT LINES OF LOGGING to prove that it was FLIPPING A SINGLE BOOLEAN
  SquishToggle = (i: number) => {
    //console.log("squishing index " + i);
    var _squishes: boolean[] = { ...this.state.SquishMap };
    //console.log(_squishes);
    var oldSquish = false;
    if (_squishes[i] !== undefined) {
      //console.log("_squishes[i] = ");
      //console.log(_squishes[i]);
      oldSquish = !_squishes[i];
    } else {
      //console.log("_squishes[i] = undefined");
    }
    _squishes[i] = oldSquish;
    //console.log("_squishes[i] => ");
    //console.log(_squishes[i]);
    //console.log(_squishes);
    this.setState({ SquishMap: _squishes });
  };

  Designate = () => {
    //new Logging().log('Designate start', LoggingLevel.INFO);

    const params: IParams = {
      sproc: "sputilities.webui.SUMMARY_queuenewdesignation",
      params: ["@FIGUID", this.props.figuid],
    };

    this.props.da.getCurrentUser().then((data) => {
      this.setState({ user: data }, () => {
        this.props.da.get("/api/sproc", JSON.stringify(params));
      });
    });

    //new Logging().log('Designate end', LoggingLevel.INFO);
  };

  PassthroughRequeue = (EID: Number, FIGUID: string) => {
    this.props.Logger.log("PassthroughRequeue start", LoggingLevel.INFO);

    const params: IParams = {
      sproc: "sputilities.webui.SUMMARY_QueueNewEvent",
      params: ["@FIGUID", FIGUID.toString(), "@EID", EID.toString()],
    };

    this.props.da.getCurrentUser().then((data) => {
      this.setState({ user: data }, () => {
        this.props.da.get("/api/sproc", JSON.stringify(params));
      });
    });

    this.props.Logger.log("PassthroughRequeue end", LoggingLevel.INFO);
  };

  private getDiagnosis(FormInstanceID: string, EventID: string) {
    const params1: IParams = {
      sproc: "sputilities.webui.SUMMARY_getdiagnosis",
      params: ["@FIID", FormInstanceID, "@EventID", EventID],
    };
    const params2: IParams = {
      sproc: "sputilities.webui.SUMMARY_getdiagnosissprocdata",
      params: ["@FIID", FormInstanceID, "@EventID", EventID],
    };
    const params3: IParams = {
      sproc: "sputilities.webui.SUMMARY_getdiagnosisinstancedata",
      params: ["@FIID", FormInstanceID, "@EventID", EventID],
    };

    this.props.da.getCurrentUser().then((data) => {
      this.setState({ user: data }, () => {
        this.props.da
          .get("/api/sproc", JSON.stringify(params1))
          .then((idata) => {
            this.handleData(params1.sproc, idata);
          });
      });
    });

    this.props.da.getCurrentUser().then((data) => {
      this.setState({ user: data }, () => {
        this.props.da
          .get("/api/sproc", JSON.stringify(params2))
          .then((idata) => {
            this.handleData(params2.sproc, idata);
          });
      });
    });

    this.props.da.getCurrentUser().then((data) => {
      this.setState({ user: data }, () => {
        this.props.da
          .get("/api/sproc", JSON.stringify(params3))
          .then((idata) => {
            this.handleData(params3.sproc, idata);
          });
      });
    });
  }

  getData = (params: IParams) => {
    //new Logging().log('getData start', LoggingLevel.INFO);

    this.props.da.getCurrentUser().then((data) => {
      this.setState({ user: data }, () => {
        this.props.da.get("/api/sproc", JSON.stringify(params)).then(
          (data) => {
            this.handleData(params.sproc, data);
          },
          (reason) => {
            this.setState({ message: reason });
          },
        );
      });
    });

    //new Logging().log('getData end', LoggingLevel.INFO);
  };

  getDetailTable = () => {
    //new Logging().log('getDetailTable start', LoggingLevel.INFO);

    const params: IParams = {
      sproc: "sputilities.webui.SUMMARY_getdetailtable",
      params: ["@FIGUID", this.props.figuid],
    };

    this.getData(params);

    //new Logging().log('getDetailTable end', LoggingLevel.INFO);
  };

  getFormInstanceID = () => {
    //new Logging().log('getFormInstanceID start', LoggingLevel.INFO);

    const params: IParams = {
      sproc: "sputilities.webui.SUMMARY_getforminstanceid",
      params: ["@FIGUID", this.props.figuid],
    };

    this.getData(params);

    //new Logging().log('getFormInstanceID end', LoggingLevel.INFO);
  };

  getWorkflowInstanceID = () => {
    //new Logging().log('getWorkflowInstanceID start', LoggingLevel.INFO);

    const params: IParams = {
      sproc: "sputilities.webui.SUMMARY_getworkflowinstanceid",
      params: ["@FIGUID", this.props.figuid],
    };

    this.getData(params);

    //new Logging().log('getWorkflowInstanceID end', LoggingLevel.INFO);
  };

  getAnalysisTable = () => {
    //new Logging().log('getAnalysisTable start', LoggingLevel.INFO);

    const params: IParams = {
      sproc: "sputilities.webui.SUMMARY_getanalysistable",
      params: ["@FIGUID", this.props.figuid],
    };

    this.getData(params);

    //new Logging().log('getAnalysisTable end', LoggingLevel.INFO);
  };

  getDatumHistory = () => {
    //new Logging().log('getDatumHistory start', LoggingLevel.INFO);

    const params: IParams = {
      sproc: "sputilities.webui.SUMMARY_getdatumhistory",
      params: ["@FIGUID", this.props.figuid],
    };

    this.getData(params);

    //new Logging().log('getDatumHistory end', LoggingLevel.INFO);
  };

  getAssignmentHistory = () => {
    //new Logging().log('getAssignmentHistory start', LoggingLevel.INFO);

    const params: IParams = {
      sproc: "sputilities.webui.SUMMARY_getassignmenthistory",
      params: ["@FIGUID", this.props.figuid],
    };
    this.getData(params);

    //new Logging().log('getAssignmentHistory end', LoggingLevel.INFO);
  };

  getEventQueue = () => {
    //new Logging().log('getEventQueue start', LoggingLevel.INFO);

    const params: IParams = {
      sproc: "sputilities.webui.SUMMARY_geteventqueue",
      params: ["@FIGUID", this.props.figuid],
    };
    this.getData(params);

    //new Logging().log('getEventQueue end', LoggingLevel.INFO);
  };

  getDesignationQueue = () => {
    //new Logging().log('getDesignationQueue start', LoggingLevel.INFO);

    const params: IParams = {
      sproc: "sputilities.webui.SUMMARY_getdesignationqueue",
      params: ["@FIGUID", this.props.figuid],
    };
    this.getData(params);

    //new Logging().log('getDesignationQueue end', LoggingLevel.INFO);
  };

  getOtherEntries = () => {
    //new Logging().log('getOtherEntries start', LoggingLevel.INFO);

    const params: IParams = {
      sproc: "sputilities.webui.SUMMARY_getotherentries",
      params: ["@FIGUID", this.props.figuid],
    };
    this.getData(params);

    //new Logging().log('getOtherEntries end', LoggingLevel.INFO);
  };

  getMailRequests = () => {
    //new Logging().log('getMailRequests start', LoggingLevel.INFO);

    const params: IParams = {
      sproc: "sputilities.webui.SUMMARY_getmailrequests",
      params: ["@FIGUID", this.props.figuid],
    };
    this.getData(params);

    //new Logging().log('getMailRequests end', LoggingLevel.INFO);
  };

  getAccessEntries = () => {
    //new Logging().log('getAccessEntries start', LoggingLevel.INFO);

    const params: IParams = {
      sproc: "sputilities.webui.SUMMARY_getaccessentries",
      params: ["@FIGUID", this.props.figuid],
    };
    this.getData(params);

    //new Logging().log('getAccessEntries end', LoggingLevel.INFO);
  };

  getRelatedEvents = () => {
    //new Logging().log('getRelatedEvents start', LoggingLevel.INFO);

    const params: IParams = {
      sproc: "sputilities.webui.SUMMARY_getrelatedevents",
      params: ["@FIGUID", this.props.figuid],
    };
    this.getData(params);

    //new Logging().log('getRelatedEvents end', LoggingLevel.INFO);
  };

  getTaskListPresences = () => {
    //new Logging().log('getTaskListPresences start', LoggingLevel.INFO);

    const params: IParams = {
      sproc: "sputilities.webui.SUMMARY_gettasklistpresences",
      params: ["@FIGUID", this.props.figuid],
    };
    this.getData(params);

    //new Logging().log('getTaskListPresences end', LoggingLevel.INFO);
  };

  public pollSummarySprocs(): void {
    //new Logging().log('pollSummarySprocs start', LoggingLevel.INFO);

    this.props.da
      .get(
        "/api/sproc",
        JSON.stringify({
          sproc: "sputilities.webui.SUMMARY_getupdatemeta",
          params: ["@FIGUID", this.props.figuid],
        }),
      )
      .then(
        (data) => {
          if (data !== undefined && data[0] !== undefined) {
            this.props.Logger.log("pollSummarySprocs has run.");
            //  console.log(data);

            const o_DatumHistoryCount: Number = this.state.DatumHistoryCount;
            const o_AssignmentHistoryCount: Number =
              this.state.AssignmentHistoryCount;
            const o_EventCount: Number = this.state.EventCount;
            const o_DesignationCount: Number = this.state.DesignationCount;
            const o_AccessCount: Number = this.state.AccessCount;
            const o_MailRequestCount: Number = this.state.MailRequestCount;
            const o_EventChecksum: Number = this.state.LatestEventChecksum;
            const o_DesignationChecksum: Number =
              this.state.LatestDesignationChecksum;
            const o_OtherChecksum: Number = this.state.LatestOtherChecksum;
            const o_DetailChecksum: Number = this.state.LatestDetailChecksum;
            const o_AccessChecksum: Number = this.state.LatestAccessChecksum;
            const o_TaskListsChecksum: Number =
              this.state.LatestTaskListsChecksum;
            const o_MailRequestChecksum: Number =
              this.state.LatestMailRequestChecksum;
            const c_LastChecked: Date = data[0][0].LastChecked as Date;
            const c_DatumHistoryCount: Number = Number.parseInt(
              data[0][0].DatumHistoryCount,
            );
            const c_AssignmentHistoryCount: Number = Number.parseInt(
              data[0][0].AssignmentHistoryCount,
            );
            const c_EventCount: Number = Number.parseInt(data[0][0].EventCount);
            const c_DesignationCount: Number = Number.parseInt(
              data[0][0].DesignationCount,
            );
            const c_AccessCount: Number = Number.parseInt(
              data[0][0].AccessCount,
            );
            const c_MailRequestCount: Number = Number.parseInt(
              data[0][0].MailRequestCount,
            );
            const c_EventChecksum: Number = Number.parseInt(
              data[0][0].LatestEventChecksum,
            );
            const c_DesignationChecksum: Number = Number.parseInt(
              data[0][0].LatestDesignationChecksum,
            );
            const c_OtherChecksum: Number = Number.parseInt(
              data[0][0].LatestOtherChecksum,
            );
            const c_MailRequestChecksum: Number = Number.parseInt(
              data[0][0].LatestMailRequestChecksum,
            );
            const c_DetailChecksum: Number = Number.parseInt(
              data[0][0].LatestDetailChecksum,
            );
            const c_AccessChecksum: Number = Number.parseInt(
              data[0][0].LatestAccessChecksum,
            );
            const c_TaskListsChecksum: Number = Number.parseInt(
              data[0][0].TaskListsChecksum,
            );
            const c_ValidFormInstance: boolean =
              data[0][0].ValidFormInstance === "1";

            this.setState({
              LastChecked: c_LastChecked,
              OLD_DatumHistoryCount: o_DatumHistoryCount,
              OLD_AssignmentHistoryCount: o_AssignmentHistoryCount,
              OLD_EventCount: o_EventCount,
              OLD_DesignationCount: o_DesignationCount,
              OLD_AccessCount: o_AccessCount,
              OLD_MailRequestCount: o_MailRequestCount,
              OLD_LatestEventChecksum: o_EventChecksum,
              OLD_LatestDesignationChecksum: o_DesignationChecksum,
              OLD_LatestOtherChecksum: o_OtherChecksum,
              OLD_LatestDetailChecksum: o_DetailChecksum,
              OLD_LatestAccessChecksum: o_AccessChecksum,
              OLD_LatestTaskListsChecksum: o_TaskListsChecksum,
              OLD_LatestMailRequestChecksum: o_MailRequestChecksum,
              DatumHistoryCount: c_DatumHistoryCount,
              AssignmentHistoryCount: c_AssignmentHistoryCount,
              EventCount: c_EventCount,
              DesignationCount: c_DesignationCount,
              AccessCount: c_AccessCount,
              MailRequestCount: c_MailRequestCount,
              LatestEventChecksum: c_EventChecksum,
              LatestDesignationChecksum: c_DesignationChecksum,
              LatestOtherChecksum: c_OtherChecksum,
              LatestDetailChecksum: c_DetailChecksum,
              LatestAccessChecksum: c_AccessChecksum,
              LatestTaskListsChecksum: c_TaskListsChecksum,
              LatestMailRequestChecksum: c_MailRequestChecksum,
              ValidFormInstance: c_ValidFormInstance,
            });

            var grabDatumHistory: boolean = false;
            var grabAssignmentHistory: boolean = false;
            var grabEventQueue: boolean = false;
            var grabDesignationQueue: boolean = false;
            var grabAccessEntries: boolean = false;
            var grabOtherEntries: boolean = false;
            var grabDetailTable: boolean = false;
            var grabTaskListPresences: boolean = false;
            var grabMailRequests: boolean = false;

            if (c_ValidFormInstance) {
              if (o_DatumHistoryCount !== c_DatumHistoryCount) {
                grabDatumHistory = true;
              }

              if (o_AssignmentHistoryCount !== c_AssignmentHistoryCount) {
                grabAssignmentHistory = true;
              }

              if (
                o_EventCount !== c_EventCount ||
                o_EventChecksum !== c_EventChecksum
              ) {
                grabEventQueue = true;
              }

              if (
                o_DesignationCount !== c_DesignationCount ||
                o_DesignationChecksum !== c_DesignationChecksum
              ) {
                grabDesignationQueue = true;
              }

              if (
                o_AccessCount !== c_AccessCount ||
                o_AccessChecksum !== c_AccessChecksum
              ) {
                grabAccessEntries = true;
              }

              if (o_OtherChecksum !== c_OtherChecksum) {
                grabOtherEntries = true;
              }

              if (o_DetailChecksum !== c_DetailChecksum) {
                grabDetailTable = true;
              }

              if (o_TaskListsChecksum !== c_TaskListsChecksum) {
                grabTaskListPresences = true;
              }

              if (
                o_MailRequestCount !== c_MailRequestCount ||
                o_MailRequestChecksum !== c_MailRequestChecksum
              ) {
                grabMailRequests = true;
              }
            }
            if (grabDatumHistory) {
              this.getDatumHistory();
            }
            if (grabAssignmentHistory) {
              this.getAssignmentHistory();
            }
            if (grabEventQueue) {
              this.getEventQueue();
            }
            if (grabDesignationQueue) {
              this.getDesignationQueue();
            }
            if (grabAccessEntries) {
              this.getAccessEntries();
            }
            if (grabOtherEntries) {
              this.getOtherEntries();
            }
            if (grabMailRequests) {
              this.getMailRequests();
            }
            if (grabTaskListPresences) {
              this.getTaskListPresences();
            }
            if (grabDetailTable) {
              this.getDetailTable();
              this.getAnalysisTable();
              this.getRelatedEvents();
              this.getFormInstanceID();
              this.getWorkflowInstanceID();
            }
          }
        },
        (reason) => {
          this.setState({ message: reason });
        },
      );

    //new Logging().log('pollSummarySprocs end', LoggingLevel.INFO);
  }

  private handleData(sproc: string, data: IItem[][]) {
    //new Logging().log('handleData start', LoggingLevel.INFO);

    if (data !== undefined) {
      this.props.Logger.log(
        sproc + " - tables received: " + data.length,
        LoggingLevel.DEBUG,
      );
      if (data.length === 1 && data[0].length === 1 && data[0][0]["ERROR"]) {
        this.setState({ message: sproc + " :: " + data[0][0]["ERROR"] });
      } else {
        if (sproc === "sputilities.webui.SUMMARY_getdatumhistory") {
          //  console.log("RECV: getdatumhistory");
          //  console.log(data[0]);
          this.setState({ CommitTable: data[0] });
          this.setState({ CommitDatumsTable: data[1] });
        } else if (sproc === "sputilities.webui.SUMMARY_getassignmenthistory") {
          //  console.log("RECV: getassignmenthistory");
          //  console.log(data[0]);
          this.setState({ AssignmentHistoryTable: data[0] });
        } else if (sproc === "sputilities.webui.SUMMARY_geteventqueue") {
          //  console.log("RECV: geteventqueue");
          //  console.log(data[0]);
          this.setState({ EventTable: data[0] });
        } else if (sproc === "sputilities.webui.SUMMARY_getdesignationqueue") {
          //  console.log("RECV: getdesignationqueue");
          //  console.log(data[0]);
          this.setState({ DesignationTable: data[0] });
        } else if (sproc === "sputilities.webui.SUMMARY_getotherentries") {
          //  console.log("RECV: getotherentries");
          //  console.log(data[0]);
          this.setState({ OtherTable: data[0] });
        } else if (sproc === "sputilities.webui.SUMMARY_getmailrequests") {
          //  console.log("RECV: getotherentries");
          //  console.log(data[0]);
          this.setState({ MailTable: data[0] });
        } else if (sproc === "sputilities.webui.SUMMARY_gettasklistpresences") {
          //  console.log("RECV: gettasklistpresences");
          //  console.log(data[0]);
          this.setState({ TaskListsTable: data[0] });
        } else if (sproc === "sputilities.webui.SUMMARY_getdetailtable") {
          //  console.log("RECV: getdetailtable");
          //  console.log(data[0]);
          this.setState({ DetailTable: data[0] });
        } else if (sproc === "sputilities.webui.SUMMARY_getanalysistable") {
          //  console.log("RECV: getanalysistable");
          //  console.log(data[0]);
          this.setState({ AnalysisTable: data[0] });
        } else if (sproc === "sputilities.webui.SUMMARY_getaccessentries") {
          //  console.log("RECV: getaccesstable");
          //  console.log(data[0]);
          this.setState({ AccessTable: data[0] });
        } else if (sproc === "sputilities.webui.SUMMARY_getdiagnosis") {
          console.log("RECV: getdiagnosis");
          console.log(data[0]);
          this.setState({
            Diagnosis: data[0],
          });
        } else if (
          sproc === "sputilities.webui.SUMMARY_getdiagnosissprocdata"
        ) {
          console.log("RECV: getdiagnosissprocdata");
          console.log(data[0]);
          this.setState({
            SprocDataTable: data[0],
          });
        } else if (
          sproc === "sputilities.webui.SUMMARY_getdiagnosisinstancedata"
        ) {
          console.log("RECV: getdiagnosisinstancedata");
          console.log(data[0]);
          this.setState({
            InstanceDataTable: data,
          });
        } else if (sproc === "sputilities.webui.SUMMARY_getforminstanceid") {
          this.setState({ fiid: Number.parseInt(data[0][0].forminstanceid) });
        } else if (sproc === "sputilities.webui.SUMMARY_getrelatedevents") {
          //  console.log("RECV: getrelatedevents");
          //  console.log(data[0]);
          var _diagnosisRequestOptions: { Name: string; Value: string }[] = [];
          _diagnosisRequestOptions.push({
            Value: "-",
            Name: "-",
          });
          data[0].forEach((th) => {
            _diagnosisRequestOptions.push({
              Value: th.EventID,
              Name: th.EventName,
            });
          });
          this.setState({ DiagnosisRequestOptions: _diagnosisRequestOptions });
        }
      }
    }

    //new Logging().log('handleData end', LoggingLevel.INFO);
  }
}

export default CollagenWorkflowSummary;
