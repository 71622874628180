import { useLoaderData } from "react-router-dom";
import "../assets/css/DocumentViewer.css";
import "../assets/css/FullPageLoader.css";
import { useEffect, useState } from "react";
import { useApi } from "../Providers/ApiProvider";

interface DocumentViewerProps {}

export function DocumentViewer(props: DocumentViewerProps) {
  let api = useApi();
  const loader: any = useLoaderData();
  let [document, setDocument] = useState<any>();

  useEffect(() => {
    (async () => {
      console.debug(loader);
      if (loader.docid != undefined) {
        let doc = await api.General.getDocument(
          loader.ident,
          loader.docid,
          loader.filename,
        );
        let reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            // Chromium has a 2MB limit on dataURIs, so we have to convert to blobs.
            // BLOBBY BLOBBY BLOBBY!
            let blobby = dataURLtoBlob(reader.result);
            let blobbyUrl = window.URL.createObjectURL(blobby);

            // convert image file to base64 string
            setDocument(blobbyUrl);
          },
          false,
        );

        reader.readAsDataURL(doc);
      }

      if (loader.wfid != undefined) {
        let doc = await api.General.getWorkflow(loader.ident, loader.wfid);
        let reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            // convert image file to base64 string
            setDocument(reader.result);
          },
          false,
        );

        reader.readAsDataURL(doc);
      }
    })();
  }, []);

  if (document == undefined) {
    return (
      <div className="FullPageLoader">
        <div className="LoadingLabel">Loading...</div>
        <div className="mosaic-loader">
          <div className="cell cell1"></div>
          <div className="cell cell2"></div>
          <div className="cell cell3"></div>
          <div className="cell cell4"></div>
          <div className="cell cell5"></div>
          <div className="cell cell6"></div>
          <div className="cell cell7"></div>
          <div className="cell cell8"></div>
          <div className="cell cell9"></div>
          <div className="cell cell10"></div>
          <div className="cell cell11"></div>
          <div className="cell cell12"></div>
          <div className="cell cell13"></div>
          <div className="cell cell14"></div>
          <div className="cell cell15"></div>
          <div className="cell cell16"></div>
        </div>
      </div>
    );
  } else if (loader.filename == "jpg") {
    return (
      <div className="DocView_Main">
        <img src={document}></img>
      </div>
    );
  } else {
    return (
      <div className="DocView_Main">
        <object
          title="PDF of Document"
          className="DocView_Item"
          type="application/pdf"
          data={document}
        ></object>
      </div>
    );
  }
}

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
