import {
  Handshake32Filled,
  Mail24Regular,
  GlobePerson24Regular,
  DocumentAdd24Regular,
  PersonAccounts24Regular,
  Password24Regular,
  Call32Regular,
  ChatBubblesQuestion32Regular,
} from "@fluentui/react-icons";
import { ReactElement } from "react";
import { useRouteLoaderData } from "react-router-dom";

interface UgApplicantSidebarActionsProps {
  UcasNo: string;
  ApplicantPSEURL: string;

  ShowUploadModal: () => void;
}

export function UgApplicantSidebarActions(
  props: UgApplicantSidebarActionsProps,
): ReactElement {
  let agentDetails: any = useRouteLoaderData("AgentRoot");

  return (
    <>
      <div className="PanelBar Action UGSide">Actions</div>
      <div className="PanelBody UGSide">
        <div>
          <a
            className="PanelButton"
            href={
              "mailto:ugpas@essex.ac.uk?subject=Agent%20Enquiry%20-%20UG%20Applicant%20" +
              props.UcasNo
            }
          >
            <div title="Email a query about this applicant">
              <span className="LeftIcon24">
                <Mail24Regular></Mail24Regular>
              </span>
              <span className="RightText">
                Email a query about this applicant.
              </span>
            </div>
          </a>
          {props.ApplicantPSEURL !== undefined &&
          props.ApplicantPSEURL !== "" ? (
            <a
              className="PanelButton"
              href={props.ApplicantPSEURL}
              target="_blank"
            >
              <div title="Apply for a Pre-Sessional English Language programme">
                <span className="LeftIcon24">
                  <GlobePerson24Regular></GlobePerson24Regular>
                </span>
                <span className="RightText">
                  Apply for a Pre-Sessional English Language programme.{" "}
                  <small>
                    (Applicant must hold a current offer for their intended main
                    programme of study)
                  </small>
                </span>
              </div>
            </a>
          ) : (
            <div className="PanelButton Inert">
              <div title="Apply for a Pre-Sessional English Language programme">
                <span className="LeftIcon24">
                  <GlobePerson24Regular></GlobePerson24Regular>
                </span>
                <span className="RightText">
                  Apply for a Pre-Sessional English Language programme.{" "}
                  <small>
                    (Applicant must hold a current offer for their intended main
                    programme of study)
                  </small>
                </span>
              </div>
            </div>
          )}
          <div
            className="PanelButton"
            id="UploadPanel"
            onClick={() => {
              props.ShowUploadModal();
            }}
          >
            <div title="Upload additional documents">
              <span className="LeftIcon24">
                <DocumentAdd24Regular></DocumentAdd24Regular>
              </span>
              <span className="RightText">Upload additional documents.</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
