export enum LoggingLevel {
  NONE = -1,
  EXCEPTION = 0,
  INFO = 1,
  DEBUG = 2,
  TRACE = 3,
}

export class Logging {
  private logLevel: LoggingLevel = LoggingLevel.TRACE;

  constructor(surface: "LIVE" | "STAGING" | "DEVELOPMENT" | "LOCAL") {
    const querystring = window && window.location && window.location.search;

    if (surface === "LIVE") {
      this.logLevel = LoggingLevel.NONE; // when in production, always use qs to get logs
    } else if (surface === "STAGING") {
      this.logLevel = LoggingLevel.NONE;
    } else if ((surface = "DEVELOPMENT")) {
      this.logLevel = LoggingLevel.DEBUG;
    } else if ((surface = "LOCAL")) {
      this.logLevel = LoggingLevel.TRACE;
    }

    if (querystring.includes("log=none")) {
      this.logLevel = LoggingLevel.NONE;
    } else if (
      querystring.includes("log=exception") ||
      querystring.includes("log=exceptions")
    ) {
      this.logLevel = LoggingLevel.EXCEPTION;
    } else if (querystring.includes("log=info")) {
      this.logLevel = LoggingLevel.INFO;
    } else if (querystring.includes("log=debug")) {
      this.logLevel = LoggingLevel.DEBUG;
    } else if (
      querystring.includes("log=trace") ||
      querystring.includes("log=all")
    ) {
      this.logLevel = LoggingLevel.TRACE;
    }
  }

  public log(
    message: any,
    level: LoggingLevel = LoggingLevel.DEBUG,
    location: string = "",
  ) {
    if (level <= this.logLevel) {
      if (message.toString() != message) {
        console.log(
          "# Logging (" +
            LoggingLevel[level] +
            "): " +
            (location === "" ? "" : " in " + location),
        );
        console.log(message);
        console.log("###");
      } else {
        console.log(
          "Logging (" +
            LoggingLevel[level] +
            "): " +
            message +
            (location === "" ? "" : " in " + location),
        );
      }
    }
  }
}
