import {
  Handshake32Filled,
  Mail24Regular,
  GlobePerson24Regular,
  DocumentAdd24Regular,
  PersonAccounts24Regular,
  Password24Regular,
  Call32Regular,
  ChatBubblesQuestion32Regular,
} from "@fluentui/react-icons";
import { ReactElement, PropsWithChildren } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { DataAccess } from "../../../data/DataAccess";

import "../../../assets/css/AOS2/AOS2_Sidebar.css";

export async function GetAgentData(
  da: DataAccess,
  alias: string,
): Promise<any> {
  return await da
    .get(
      "/sproc",
      JSON.stringify({
        sproc: "sputilities.webui.AOSv2_GetPanelDetails",
        params: ["@username", "{%iu}"],
        imp: alias,
      }),
    )
    .then((data) => {
      if (data == undefined) {
        return {
          AgentName: "",
          AgentEmail: "",
          AgentReference: "",
          AgentContactName: "",
          AgentAddress: "",
          AgentCountry: "",
          AgentIsPAS: false,
          AgentActive: false,
        };
      }
      return {
        AgentName: data[0][0].AgentName,
        AgentEmail: data[0][0].AgentEmail,
        AgentReference: data[0][0].AgentReference,
        AgentContactName: data[0][0].AgentContactName,
        AgentAddress: data[0][0].AgentAddress,
        AgentCountry: data[0][0].AgentCountry,
        AgentIsPAS: data[0][0].AgentIsPAS === "True",
        AgentActive: data[0][0].AgentActive === "True",
      };
    })
    .catch((error) => {
      console.error(error);
      this.RegisterError("AOS_2392", "Failed to get agent details");
      return {
        AgentName: "",
        AgentEmail: "",
        AgentReference: "",
        AgentContactName: "",
        AgentAddress: "",
        AgentCountry: "",
        AgentIsPAS: false,
        AgentActive: false,
      };
    });
}

export function AgentSidebar(props: PropsWithChildren<{}>): ReactElement {
  let agentDetails: any = useRouteLoaderData("AgentRoot");

  return (
    <div className="AOSSidebar Agent">
      <div className="PanelBarSimple">
        <div className="Left">{agentDetails.AgentName}</div>
      </div>
      <div className="PanelBarSimple">
        <div className="Left">{agentDetails.AgentReference}</div>
      </div>
      <div className="PanelBar Title InverseUtil">
        <div className="Left"> </div>
        <div className="Breaker"></div>
        <div className="Right">Agent Information</div>
      </div>
      <div className="PanelBody Agent">
        <div className="PanelField">
          <strong>Contact Name:</strong>
          <span>{agentDetails.AgentContactName}</span>
        </div>
        <div className="PanelField">
          <strong>Email:</strong>
          <span>{agentDetails.AgentEmail}</span>
        </div>
        <div className="PanelField">
          <strong>Agent Address:</strong>
          <span>{agentDetails.AgentAddress}</span>
        </div>
        <div className="PanelField">
          <strong>Country:</strong>
          <span>{agentDetails.AgentCountry}</span>
        </div>
      </div>

      {agentDetails.AgentIsPAS ? (
        <>
          <div className="PanelBar Action PAS">
            Priority Service Level<Handshake32Filled></Handshake32Filled>
          </div>
          <div className="PanelBody PAS">
            <p>
              You are currently registered as one of our Priority Agents. This
              means that you enjoy the following benefits:
            </p>
            <p className="PASPerkPanel">
              <span className="LeftIcon32">
                <Handshake32Filled></Handshake32Filled>
              </span>
              <span className="RightText">
                A defined SLA for processing any applications you make with us.
              </span>
            </p>
            <p className="PASPerkPanel">
              <span className="LeftIcon32">
                <Handshake32Filled></Handshake32Filled>
              </span>
              <span className="RightText">
                Direct email access to the team handling the applications for
                any queries.
              </span>
            </p>
          </div>
        </>
      ) : (
        ""
      )}

      {props.children}

      {/* {this.props.Context === "Applicant" ? (
        <>
          <div
            className={
              "PanelBar Action " +
              (this.props.Mode[0] === "ug" ? "UGSide" : "PGSide")
            }
          >
            Actions
          </div>
          <div
            className={
              "PanelBody " + (this.props.Mode[0] === "ug" ? "UGSide" : "PGSide")
            }
          >
            <div>
              <a
                className="PanelButton"
                href={
                  this.props.Mode[0] === "ug"
                    ? "mailto:ugpas@essex.ac.uk?subject=Agent%20Enquiry%20-%20UG%20Applicant%20" +
                      this.props.Mode[2]
                    : this.props.Mode[0] === "pg"
                      ? "mailto:pgpas@essex.ac.uk?subject=Agent%20Enquiry%20-%20PG%20Applicant%20" +
                        this.props.Mode[2]
                      : ""
                }
              >
                <div title="Email a query about this applicant">
                  <span className="LeftIcon24">
                    <Mail24Regular></Mail24Regular>
                  </span>
                  <span className="RightText">
                    Email a query about this applicant.
                  </span>
                </div>
              </a>
              {this.props.ApplicantPSEURL !== undefined &&
              this.props.ApplicantPSEURL !== "" ? (
                <a
                  className="PanelButton"
                  href={this.props.ApplicantPSEURL}
                  target="_blank"
                >
                  <div title="Apply for a Pre-Sessional English Language programme">
                    <span className="LeftIcon24">
                      <GlobePerson24Regular></GlobePerson24Regular>
                    </span>
                    <span className="RightText">
                      Apply for a Pre-Sessional English Language programme.{" "}
                      <small>
                        (Applicant must hold a current offer for their intended
                        main programme of study)
                      </small>
                    </span>
                  </div>
                </a>
              ) : (
                <div className="PanelButton Inert">
                  <div title="Apply for a Pre-Sessional English Language programme">
                    <span className="LeftIcon24">
                      <GlobePerson24Regular></GlobePerson24Regular>
                    </span>
                    <span className="RightText">
                      Apply for a Pre-Sessional English Language programme.{" "}
                      <small>
                        (Applicant must hold a current offer for their intended
                        main programme of study)
                      </small>
                    </span>
                  </div>
                </div>
              )}
              <div
                className="PanelButton"
                id="UploadPanel"
                onClick={() => {
                  this.props.ShowUploadModal();
                }}
              >
                <div title="Upload additional documents">
                  <span className="LeftIcon24">
                    <DocumentAdd24Regular></DocumentAdd24Regular>
                  </span>
                  <span className="RightText">
                    Upload additional documents.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )} */}

      <div className="PanelBarSimple">Support</div>
      <div className="PanelBody Agent">
        <div>
          <a
            href="mailto:agentenquiries@essex.ac.uk?subject=Agent Enquiry"
            rel="noreferrer"
            target="_blank"
            className="PanelButton"
          >
            <div title="General enquiries (link will attempt to open in your default email client)">
              <span className="LeftIcon24">
                <Mail24Regular></Mail24Regular>
              </span>
              <span className="RightText">General enquiries</span>
            </div>
          </a>
          <a
            href="mailto:agentenquiries@essex.ac.uk?subject=Agent Details Update"
            rel="noreferrer"
            target="_blank"
            className="PanelButton"
          >
            <div title="Update your details">
              <span className="LeftIcon24">
                <PersonAccounts24Regular></PersonAccounts24Regular>
              </span>
              <span className="RightText">Update your details</span>
            </div>
          </a>
          <a
            href="https://www.essex.ac.uk/password"
            rel="noreferrer"
            target="_blank"
            className="PanelButton"
          >
            <div title="Manage your password (link opens in external window)">
              <span className="LeftIcon24">
                <Password24Regular></Password24Regular>
              </span>
              <span className="RightText">Manage your password</span>
            </div>
          </a>
          <a
            href="tel:+441206872897"
            rel="noreferrer"
            target="_blank"
            className="PanelButton"
          >
            <div title="Call us on +44 1206 87 2897">
              <span className="LeftIcon32">
                <Call32Regular></Call32Regular>
              </span>
              <span className="RightText">Call on +44 1206 87 2897</span>
            </div>
          </a>
          <a
            className="PanelButton"
            href="https://v4in1-ti.click4assistance.co.uk/DefaultChat.aspx?AccGUID=d778a52e-092e-442e-b0e0-00f5dc07caed&ScriptID=8&ToolType=1"
            rel="noreferrer"
            target="_blank"
          >
            <div title="Talk to us on live chat (link opens in external window)">
              <span className="LeftIcon32">
                <ChatBubblesQuestion32Regular></ChatBubblesQuestion32Regular>
              </span>
              <span className="RightText">Talk to us on live chat</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
