import * as React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { toTitleCase, sortItems } from "./Functions";
import ListContent from "./ListContent";
import { FontIcon } from "@fluentui/react";
import { IItem } from "../../models/IItem";

interface IProps {
  eid: string;
  items: IItem[];
  heading: string;
  sort?: string;
  sortdesc?: string;
  target?: string;
  ButtonsAndFunctions?: {
    Name: string;
    ColumnKey: string;
    Function: (id: any) => void;
  }[];
}

interface IState {
  items: IItem[];
}

class AccordionContent extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      items: sortItems(this.props.items, this.props.sort, this.props.sortdesc),
    };
  }

  public render() {
    return (
      <Accordion className={"UoE-DataAccordion"} allowZeroExpanded={true}>
        {this.state.items.map((item, ii) => (
          <AccordionItem
            key={ii}
            className={"DataAccordion".concat(
              item[this.props.heading + "_CSS"] !== undefined &&
                item[this.props.heading + "_CSS"].trim() !== ""
                ? " " + item[this.props.heading + "_CSS"]
                : "",
              item[this.props.heading + "_CLASS"] !== undefined &&
                item[this.props.heading + "_CLASS"].trim() !== ""
                ? " " + item[this.props.heading + "_CLASS"]
                : "",
            )}
          >
            <AccordionItemHeading>
              <AccordionItemButton>
                <FontIcon iconName="ScrollUpDown" />
                <em
                  dangerouslySetInnerHTML={{
                    __html:
                      toTitleCase(
                        this.props.heading.replace(/_/g, " ").trim(),
                      ) + ":",
                  }}
                />
                <span
                  className="column-value"
                  dangerouslySetInnerHTML={{ __html: item[this.props.heading] }}
                />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <ListContent
                eid={this.props.eid}
                items={[item]}
                target={this.props.target}
                ButtonsAndFunctions={this.props.ButtonsAndFunctions}
              />
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    );
  }
}

export default AccordionContent;
