import "../../../assets/css/Compliance_LF/ComplianceAuditorScreen.css";
import { useLoaderData } from "react-router-dom";
import { GridWrapper } from "../../../integrations/UoE-Grid/GridWrapper";

import { getIconClassName } from "@fluentui/react";
import { useApi } from "../../../Providers/ApiProvider";
import { useEffect, useState } from "react";

interface ComplianceAttendance {}

export function ComplianceAttendance(props: ComplianceAttendance) {
  let loaderData: any = useLoaderData();
  let api = useApi();
  let [StuName, setStuName] = useState<string>();
  useEffect(() => {
    api.General.get(
      "/sproc",
      JSON.stringify({
        sproc: "sputilities.webui.Comply_studata_TitleName",
        params: ["@PRID", loaderData.ident],
      }),
    ).then((resp) => {
      setStuName(resp[0][0].Name);
    });
  }, []);

  return (
    <div className="CAS_Container">
      <div className="CAS_Row">
        <h1 className="CAS_PageTitle">
          Attendance Report for <strong>{StuName}</strong>
        </h1>
      </div>
      <div className="CAS_Row">
        <h1 className="CAS_PageSubTitle">PR ID: {loaderData.ident}</h1>
      </div>

      <div className="CAS_Row">
        <div className="CAS_Column">
          <GridWrapper
            eid={"CAS_Student_Attendance"}
            sproc="sputilities.webui.COMPLY_Attendance"
            params={["@Ident", loaderData.ident.toUpperCase()]}
            group="Acad Year"
            sort="Event Start"
            sortdesc="true"
            Callouts={false}
            URLParams={""}
            NoneMessage="No Attendance records found for this person. 
                        Check identifier and try again"
          ></GridWrapper>
        </div>
      </div>
    </div>
  );
}
