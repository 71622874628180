import * as React from "react";
import { IItem } from "../../models/IItem";
import { Toggles } from "./Toggles";

interface IProps {
  DetailTable: IItem[];
  AnalysisTable: IItem[];
  Toggles: Toggles;
  MODE_LOCAL: boolean;
  MODE_DEVELOPMENT: boolean;
  MODE_STAGING: boolean;
  MODE_LIVE: boolean;
}

class DetailPanel extends React.Component<IProps> {
  public render() {
    const hostname = window && window.location && window.location.hostname;
    const querystring = window && window.location && window.location.search;
    var rootUrl: string = "";
    if (
      querystring.includes("mode=development") ||
      hostname.includes("localhost")
    ) {
      rootUrl = "http://localhost:62206/";
    } else if (
      querystring.includes("mode=staging") ||
      hostname.includes("formstest")
    ) {
      rootUrl = "https://formstest.essex.ac.uk/";
    } else if (
      querystring.includes("mode=live") ||
      hostname.includes("forms.")
    ) {
      rootUrl = "https://forms.essex.ac.uk/";
    } else {
      rootUrl = "https://forms.essex.ac.uk/";
    }

    var Detail: any = {};
    var Analysis: any = {};
    var TechView = false;
    if (this.props.DetailTable !== undefined) {
      if (this.props.DetailTable.length === 1) {
        Detail = this.props.DetailTable[0];
      }
    }
    if (this.props.AnalysisTable !== undefined) {
      if (this.props.AnalysisTable.length === 1) {
        Analysis = this.props.AnalysisTable[0];
      }
    }
    if (this.props.Toggles !== undefined) {
      if (this.props.DetailTable.length === 1) {
        TechView = this.props.Toggles.TechView;
      }
    }
    return (
      <React.Fragment>
        {Detail !== undefined ? (
          <div className="DetailPanel">
            {Detail.WorkflowName !== undefined && Detail.WorkflowName !== "" ? (
              <h2>{Detail.WorkflowName}</h2>
            ) : (
              ""
            )}
            {Detail.Status !== undefined && Detail.Status !== "" ? (
              <div className={"StatusBanner " + Detail.Status}>
                {Detail.Status}
              </div>
            ) : (
              ""
            )}
            {Detail.URL !== undefined && Detail.URL !== "" ? (
              Detail.Status === "In-Flight" ? (
                <a
                  className="OpenForm"
                  href={rootUrl + Detail.URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Open Form
                </a>
              ) : (
                <a
                  className="OpenForm"
                  href={
                    Detail.RenderedPDF !== undefined &&
                    Detail.RenderedPDF !== ""
                      ? Detail.RenderedPDF
                      : ""
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {Detail.RenderedPDF !== undefined && Detail.RenderedPDF !== ""
                    ? "Open PDF"
                    : "PDF Pending"}
                </a>
              )
            ) : (
              ""
            )}
            {Detail.EK1 !== undefined ? (
              <div className={"EK " + (Detail.EK1 === "" ? "Empty" : "EK1")}>
                <div className="Title">{Detail.EK1Name}</div>
                <div className="Value">{Detail.EK1}</div>
              </div>
            ) : (
              ""
            )}
            {Detail.EK2 !== undefined ? (
              <div className={"EK " + (Detail.EK2 === "" ? "Empty" : "EK2")}>
                <div className="Title">{Detail.EK2Name}</div>
                <div className="Value">{Detail.EK2}</div>
              </div>
            ) : (
              ""
            )}
            {Detail.EK3 !== undefined ? (
              <div className={"EK " + (Detail.EK3 === "" ? "Empty" : "EK3")}>
                <div className="Title">{Detail.EK3Name}</div>
                <div className="Value">{Detail.EK3}</div>
              </div>
            ) : (
              ""
            )}
            {Detail.EK4 !== undefined ? (
              <div className={"EK " + (Detail.EK4 === "" ? "Empty" : "EK4")}>
                <div className="Title">{Detail.EK4Name}</div>
                <div className="Value">{Detail.EK4}</div>
              </div>
            ) : (
              ""
            )}
            {Detail.EK5 !== undefined ? (
              <div className={"EK " + (Detail.EK5 === "" ? "Empty" : "EK5")}>
                <div className="Title">{Detail.EK5Name}</div>
                <div className="Value">{Detail.EK5}</div>
              </div>
            ) : (
              ""
            )}
            {TechView && Detail.FormInstanceGUID !== undefined ? (
              <div
                className={
                  "ID " +
                  (Detail.FormInstanceGUID === ""
                    ? "Empty"
                    : "FormInstanceGUID")
                }
              >
                <div className="Title">FormInstanceGUID</div>
                <div className="Value">{Detail.FormInstanceGUID}</div>
              </div>
            ) : (
              ""
            )}
            {TechView && Detail.FormInstanceID !== undefined ? (
              <div
                className={
                  "ID " +
                  (Detail.FormInstanceID === "" ? "Empty" : "FormInstanceID")
                }
              >
                <div className="Title">FormInstanceID</div>
                <div className="Value">{Detail.FormInstanceID}</div>
              </div>
            ) : (
              ""
            )}
            {TechView && Detail.WorkflowInstanceGUID !== undefined ? (
              <div
                className={
                  "ID " +
                  (Detail.WorkflowInstanceGUID === ""
                    ? "Empty"
                    : "WorkflowInstanceGUID")
                }
              >
                <div className="Title">WorkflowInstanceGUID</div>
                <div className="Value">{Detail.WorkflowInstanceGUID}</div>
              </div>
            ) : (
              ""
            )}
            {TechView && Detail.WorkflowInstanceID !== undefined ? (
              <div
                className={
                  "ID " +
                  (Detail.WorkflowInstanceID === ""
                    ? "Empty"
                    : "WorkflowInstanceID")
                }
              >
                <div className="Title">WorkflowInstanceID</div>
                <div className="Value">{Detail.WorkflowInstanceID}</div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {Analysis !== undefined && Analysis !== "" ? (
          <div className="AnalysisPanel">
            <div className="Analysis RequestorName">
              <b>
                {Analysis.RequestorTitle || "-"}.{" "}
                {Analysis.RequestorSurname || "-"}
              </b>
              , {Analysis.RequestorOtherNames || "-"}
            </div>
            {Analysis.RequestorType !== undefined &&
            Analysis.RequestorType !== "" ? (
              <div className="Analysis RequestorType">
                {Analysis.RequestorType}
              </div>
            ) : (
              ""
            )}
            {Analysis.LinkTarget !== undefined &&
            Analysis.LinkType !== undefined &&
            Analysis.LinkTarget !== "" &&
            Analysis.LinkType !== "" ? (
              <a
                className="Analysis Link"
                href={
                  Analysis.LinkType === "ESF"
                    ? this.props.MODE_STAGING
                      ? "https://esftest.essex.ac.uk/" + Analysis.LinkTarget
                      : this.props.MODE_LIVE
                        ? "https://esf.essex.ac.uk/" + Analysis.LinkTarget
                        : Analysis.LinkTarget
                    : Analysis.LinkType === "UGEAF"
                      ? this.props.MODE_STAGING
                        ? "https://eaftest.essex.ac.uk/" + Analysis.LinkTarget
                        : this.props.MODE_LIVE
                          ? "https://eaf.essex.ac.uk/" + Analysis.LinkTarget
                          : Analysis.LinkTarget
                      : Analysis.LinkType === "PGEAF"
                        ? this.props.MODE_STAGING
                          ? "https://eaftest.essex.ac.uk/" + Analysis.LinkTarget
                          : this.props.MODE_LIVE
                            ? "https://eaf.essex.ac.uk/" + Analysis.LinkTarget
                            : Analysis.LinkTarget
                        : Analysis.LinkTarget
                }
                target="_blank"
                rel="noreferrer"
              >
                {Analysis.LinkType}
              </a>
            ) : (
              ""
            )}
            {Analysis.RequestorDepartment !== undefined &&
            Analysis.RequestorDepartment !== "" ? (
              <div className="Analysis RequestorDepartment">
                {Analysis.RequestorDepartment}
              </div>
            ) : (
              ""
            )}
            {Analysis.RequestorCourse !== undefined &&
            Analysis.RequestorCourse !== "" ? (
              <div className="Analysis RequestorCourse">
                {Analysis.RequestorCourse}
              </div>
            ) : (
              ""
            )}
            {Analysis.RequestorLoS !== undefined &&
            Analysis.RequestorLoS !== "" ? (
              <div className="Analysis RequestorLoS">
                {Analysis.RequestorLoS}
              </div>
            ) : (
              ""
            )}
            {Analysis.RequestorNoS !== undefined &&
            Analysis.RequestorNoS !== "" ? (
              <div className="Analysis RequestorNoS">
                {Analysis.RequestorNoS}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {this.props.DetailTable.length === 0 ? (
          <div>No Detail Rows Returned</div>
        ) : (
          ""
        )}
        {this.props.DetailTable.length > 1 ? (
          <div>Too Many Detail Rows Returned</div>
        ) : (
          ""
        )}
        {this.props.AnalysisTable.length === 0 ? (
          <div>No Detail Rows Returned</div>
        ) : (
          ""
        )}
        {this.props.AnalysisTable.length > 1 ? (
          <div>Too Many Detail Rows Returned</div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default DetailPanel;
