//  import { SPOMetadataField } from "./SharePointOnline";

import { SPOMetadata } from "./SPOMetadata";
import { SPOMetadataField } from "./SPOMetadataField";

export type SPOUploadFile = {
  File: File;
  File_Name: string;
  File_Ext: string;
  Suggested_Name: string;
  Suggested_Ext: string;
  Overridden_Name: string;
  Overridden_Ext: string;
  UploadConveyance: string;
  Destination: string;
  MetaDataFields?: Array<SPOMetadataField>;
  MetaData: Array<SPOMetadata>;
  Errors: Array<{ ErrorName: string; ErrorValue: string }>;
  State: SPOFileState;
  InvarWorkOrderID: Number;
  ReuploadCheckedIdent: string;
  AlreadyUploadedChecked: boolean;
  AlreadyUploadedStruct: CFRStruct;
};

export type CFRStruct = {
  PreviousUploads: CFRPrevious[];
};

export type CFRPrevious = {
  UploadedAt: Date;
  MetaData: any; // string[keyof string];
};

export enum SPOFileState {
  InPreparation,
  Processing,
  Errored,
  Completed,
}
