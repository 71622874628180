import { getIconClassName } from "@fluentui/react";
import * as React from "react";

import "../../assets/css/ESF.css";
import { DataAccess, User } from "../../data/DataAccess";
import ESFNotes from "../../integrations/ESFNotes/ESFNotes";
import { GridWrapper } from "../../integrations/UoE-Grid/GridWrapper";
import ModeStruct from "../../models/ModeStruct";
import SPOUploadLocation from "../../models/SPOUploadLocation";
import { PublicClientApplication } from "@azure/msal-browser";
import { Logging } from "../../Logging";
import { UploadManager } from "../../integrations/SPUploadManager/UploadManager";
import IfPermitted from "../IfPermitted";
import { EmailButton } from "../EmailButton";
import { IMsalContext } from "@azure/msal-react";
import { InvarDataAccess } from "../../data/InvarDataAccess";
import { ECFLink } from "../EcfLink";
import { RegistrationPhoto } from "../../integrations/UoE-Grid/RegistrationPhoto";

interface IProps {
  MSAL: IMsalContext;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  Logger: Logging;
  da: DataAccess;
  IDA: InvarDataAccess;
  PCA: PublicClientApplication;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  GraphAccessToken: string;
  URLRoot: string;
  URLParams: string;
  Refresher: string;
  PRID: string;
  AuthedUser: User;
  ViewAsUser: User;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  GetSPUL: (shortname: string) => SPOUploadLocation;
  SetSearch: (SearchString: string) => void;
  SetTaskList: (TLRef: string) => void;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

interface IState {
  Tab: string;
  ShowUploader: boolean;
}

class ESF extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      Tab: "Completed_Workflows",
      ShowUploader: false,
    };
  }

  render() {
    var PassToAllGrids = {
      Callouts: true,
      Logger: this.props.Logger,
      da: this.props.da,
      prid: this.props.PRID.toUpperCase(),
      PCA: this.props.PCA,
      GetLink: this.props.GetLink,
      URLRoot: this.props.URLRoot,
      URLParams: this.props.URLParams,
      class: "UoE-Grid ESF",
      Refresher: this.props.Refresher,
      Alias: this.props.Alias,
      AliasChecked: this.props.AliasChecked,
      ViewAsAll: this.props.ViewAsAll,
      RegisterError: this.props.RegisterError,
      DeregisterError: this.props.DeregisterError,
      RegisterStatus: this.props.RegisterStatus,
      DeregisterStatus: this.props.DeregisterStatus,
    };

    return (
      <div className="ESFScreen">
        <div className="SectionRow">
          <div className="LeftBlock">
            <span id="RegPhoto">
              <RegistrationPhoto ident={this.props.PRID} />
            </span>
            <span id="EmailLink">
              <EmailButton prid={this.props.PRID} da={this.props.da} />
            </span>
          </div>
          <div className="RightBlock">
            <span id="BasicInfoTop">
              <GridWrapper
                {...PassToAllGrids}
                eid={"BasicInfoTop_" + this.props.Refresher}
                sproc="sputilities.webui.ESF_SUMMARY_basic_info_top"
                params={["@prid", this.props.PRID.toUpperCase()]}
                output="data"
              ></GridWrapper>
            </span>
            <hr />
            <div className="Basic">
              <div className="BasicLeft">
                <span id="BasicInfoLeft">
                  <GridWrapper
                    {...PassToAllGrids}
                    eid={"BasicInfoLeft_" + this.props.Refresher}
                    sproc="sputilities.webui.ESF_SUMMARY_basic_info_left"
                    params={["@prid", this.props.PRID.toUpperCase()]}
                    output="data"
                  ></GridWrapper>
                </span>
              </div>
              <div className="BasicRight">
                <span id="BasicInfoRight">
                  <GridWrapper
                    {...PassToAllGrids}
                    eid={"BasicInfoRight_" + this.props.Refresher}
                    sproc="sputilities.webui.ESF_SUMMARY_basic_info_right"
                    params={["@prid", this.props.PRID.toUpperCase()]}
                    output="data"
                  ></GridWrapper>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="SectionRow">
          <h2>
            <i className={getIconClassName("Education")} aria-hidden="true"></i>{" "}
            Courses
          </h2>
          <span id="Courses">
            <GridWrapper
              {...PassToAllGrids}
              eid={"Courses_" + this.props.Refresher}
              sproc="sputilities.webui.ESF_SUMMARY_courses_view"
              params={["@pr_id", this.props.PRID.toUpperCase()]}
              sort="_mainreg,_asrkey"
              sortdesc="true,true"
              rename="scheme_code_and_title,Scheme Code and Title;registration_number,Reg No;level_of_study,Study Level;attendance_type,Attendance Type;stage_information,Stage Information;main_reg,_mainreg;asr_key,_asrkey;row_colour,_CSS"
            ></GridWrapper>
          </span>
        </div>
        <div className="SectionRow">
          <h2>
            <i
              className={getIconClassName("EngineeringGroup")}
              aria-hidden="true"
            ></i>{" "}
            Current Workflows
          </h2>
          <span id="CurrentWFs">
            <GridWrapper
              {...PassToAllGrids}
              eid={"CurrentWFs_" + this.props.Refresher}
              sproc="sputilities.webui.ESF_SUMMARY_Pane_In_Progress"
              params={["@prid", this.props.PRID.toUpperCase()]}
            ></GridWrapper>
          </span>
        </div>
        <div className="SectionRow">
          <div className="HorizontalTabZone">
            <div
              className={
                "Tab " +
                (this.state.Tab === "Completed_Workflows" ? "Active" : "")
              }
              onClick={() => {
                this.setState({ Tab: "Completed_Workflows" });
              }}
            >
              <i
                className={getIconClassName("Completed")}
                aria-hidden="true"
              ></i>{" "}
              Completed Workflows
            </div>
            <div
              className={
                "Tab " + (this.state.Tab === "Modules" ? "Active" : "")
              }
              onClick={() => {
                this.setState({ Tab: "Modules" });
              }}
            >
              <i
                className={getIconClassName("Education")}
                aria-hidden="true"
              ></i>{" "}
              Modules
            </div>
            <div
              className={
                "Tab " + (this.state.Tab === "Assessment" ? "Active" : "")
              }
              onClick={() => {
                this.setState({ Tab: "Assessment" });
              }}
            >
              <i
                className={getIconClassName("ClipboardList")}
                aria-hidden="true"
              ></i>{" "}
              Assessment
            </div>

            <IfPermitted Whitelist={["ESF.Teams"]}>
              <div
                className={
                  "Tab " + (this.state.Tab === "Notes" ? "Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "Notes" });
                }}
              >
                <i
                  className={getIconClassName("EditNote")}
                  aria-hidden="true"
                ></i>{" "}
                Notes
              </div>
            </IfPermitted>
            <span className="Tab" id="ECFLink_Tab">
              <ECFLink
                prid={this.props.PRID.toUpperCase()}
                Environment={this.props.Environment}
                da={this.props.da}
              />
            </span>
          </div>

          <div
            id="Completed_Workflows"
            hidden={this.state.Tab !== "Completed_Workflows"}
            className="tabcontent"
          >
            <GridWrapper
              {...PassToAllGrids}
              eid={"CompletedWFs_" + this.props.Refresher}
              sproc="sputilities.webui.ESF_SUMMARY_Pane_Completed"
              params={[
                "@prid",
                this.props.PRID.toUpperCase(),
                "@user",
                "{%iu}",
              ]}
            ></GridWrapper>
          </div>

          <div
            id="Modules"
            hidden={this.state.Tab !== "Modules"}
            className="tabcontent"
          >
            <GridWrapper
              {...PassToAllGrids}
              eid={"Modules_" + this.props.Refresher}
              sproc="sputilities.webui.ESF_SUMMARY_get_stu_modules_api"
              params={["@prid", this.props.PRID.toUpperCase()]}
              group="Reg No,Year"
              sort="Title"
              groupdesc="false,true"
              groupcond="false,true"
            ></GridWrapper>
          </div>

          <div
            id="Assessment"
            hidden={this.state.Tab !== "Assessment"}
            className="tabcontent"
          >
            <GridWrapper
              {...PassToAllGrids}
              eid={"Assessment_" + this.props.Refresher}
              sproc="sputilities.webui.ESF_SUMMARY_get_assessment"
              params={["@prid", this.props.PRID.toUpperCase()]}
            ></GridWrapper>
          </div>

          <div
            id="Notes"
            hidden={this.state.Tab !== "Notes"}
            className="tabcontent"
          >
            <ESFNotes
              {...PassToAllGrids}
              da={this.props.da}
              prid={this.props.PRID.toUpperCase()}
              Refresh={this.props.Refresh}
            ></ESFNotes>
          </div>
        </div>
        <div className="ColumnLeft">
          <div className="SectionRow">
            <div>
              <h2>
                <i
                  className={getIconClassName("Documentation")}
                  aria-hidden="true"
                ></i>{" "}
                eDocs
              </h2>
              <IfPermitted Whitelist={["ESF.Uploader"]}>
                <div
                  className="UploadButton"
                  onClick={() => {
                    this.setState({ ShowUploader: !this.state.ShowUploader });
                  }}
                >
                  <i className={getIconClassName("Add")} aria-hidden="true"></i>{" "}
                  Upload New Document(s)
                </div>
              </IfPermitted>
              <div className="JustASpacer"></div>
              {this.state.ShowUploader === false ? (
                <GridWrapper
                  {...PassToAllGrids}
                  eid={"EDocs_" + this.props.Refresher}
                  ForceProxy={false}
                  output="edocs"
                  group="Document Type"
                  sort="Created Date"
                  sortdesc="true"
                ></GridWrapper>
              ) : (
                <UploadManager
                  UploadConveyance={"ESF Direct Upload --> SPO"}
                  Autofill={{
                    Type: "PRID",
                    Value: this.props.PRID.toUpperCase(),
                  }}
                  SPUL={this.props.GetSPUL("ESF")}
                  IncludeRUG={false}
                  SwitchBack={() => {
                    this.setState({ ShowUploader: false });
                  }}
                  {...PassToAllGrids}
                  IDA={this.props.IDA}
                  Environment={this.props.Environment}
                  GraphAccessToken={this.props.GraphAccessToken}
                  Refresh={this.props.Refresh}
                />
              )}
            </div>
          </div>
        </div>
        <div className="ColumnRight">
          <div className="SectionRow">
            <h2>
              <i className={getIconClassName("Mail")} aria-hidden="true"></i>{" "}
              Message History
            </h2>
            <span id="CurrentWFs">
              <GridWrapper
                {...PassToAllGrids}
                eid={"MessageHistory_" + this.props.Refresher}
                sproc="sputilities.webui.ESF_SUMMARY_get_student_emails"
                params={["@prid", this.props.PRID.toUpperCase()]}
                sort="Email_Sent"
                sortdesc="true"
                rename="subject,Subject;status,Status;detail_link,Subject_URL"
                reorder="Subject"
                limit={100}
              ></GridWrapper>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ESF;
