import {
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import FullPageLoader from "./components/FullPageLoader";
import SPOWebUIOnPrem from "./SPOWebUIOnPrem";
import { useEffect, useState } from "react";
import { Logging } from "./Logging";
import { useSurface } from "./Providers/SurfaceProvider";

interface IProps {
  MODE_LOCAL: boolean;
  MODE_DEVELOPMENT: boolean;
  MODE_STAGING: boolean;
  MODE_LIVE: boolean;
  Logger: Logging;
  PCA: PublicClientApplication;
  APIAccessToken: string;
  GraphAccessToken: string;
  ActiveAccount: any;
  URL: string;
  Imp: string;
}

export const SPOWebUIOnPremMSALWrapper = (props: IProps) => {
  const msalContext = useMsal();
  const Surface = useSurface();

  const [hivis, setHiVis] = useState<boolean>(() => load("hivis"));

  const [themeName, setThemeName] = useState(localStorage.getItem("Theme"));

  console.log(themeName);
  useEffect(() => {
    let HVcolour = localStorage.getItem("HiVisColour") ?? "#ff0";
    document.documentElement.style.setProperty("--hivis-accent", HVcolour);
  }, []);

  //  const blerms: ErrorMessage[] = [];
  //  const blstms: StatusMessage[] = [];
  //  const [errms, _setErrorMessages] = useState(blerms);
  //  const [stams, _setStatusMessages] = useState(blstms);
  // consider contextualising these when the time comes
  // but also weigh against however Router works

  useEffect(() => {
    if (
      window.location.pathname.length > 2 &&
      window.location.pathname.endsWith("/")
    ) {
      //  console.debug(window.location.pathname);
      //  console.debug("Nav to " + window.location.pathname.slice(0, -1));
      window.location.replace(window.location.pathname.slice(0, -1));
    }
  }, [window.location.pathname]);

  if (msalContext.inProgress === InteractionStatus.None) {
    return (
      <SPOWebUIOnPrem
        HiVis={hivis}
        SetHiVis={setHiVis}
        MSAL={msalContext}
        Surface={Surface}
        ThemeName={themeName}
        SetThemeName={setThemeName}
        {...props}
      />
    );
  } else {
    return (
      <FullPageLoader
        HiVis={hivis}
        SetHiVis={setHiVis}
        StatusMessages={[]}
        ErrorMessages={[]}
        Surface={Surface}
        ThemeName={themeName}
        SetThemeName={setThemeName}
      />
    );
  }
};

function load(key) {
  const retitem: string = window.sessionStorage.getItem(key) || "false";
  return retitem === "true";
}
