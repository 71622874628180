import Embargo from "../../../models/Embargo";

interface IProps {
  Embargo: Embargo;
  DeleteEmbargo: (EID: string) => void;
  EditEmbargo: (EID: string) => void;
}

export const EmbargoBlock = (props: IProps) => {
  return (
    <div className="InfoRow">
      <div className="TypeFlags">
        {props.Embargo.EmbargoID + " - "}
        {props.Embargo._scopes.map((th, i) => {
          return (
            <span key={"tf_" + i.toString()} className={"TypeFlag " + th}>
              {th}
            </span>
          );
        })}
      </div>
      <div className="From">From</div>
      <div className="FromDate">
        {props.Embargo.EnforcedFrom.local().format("YYYY-MM-DD HH:mm:ss")}
      </div>
      <div className="Until">Until</div>
      <div className="UntilDate">
        {props.Embargo.EnforcedUntil.local().format("YYYY-MM-DD HH:mm:ss")}
      </div>
      {props.EditEmbargo !== undefined ? (
        <div
          className="EmbargoEdit"
          onClick={() => {
            props.EditEmbargo(props.Embargo.EmbargoID);
          }}
        >
          Edit
        </div>
      ) : (
        ""
      )}
      {props.DeleteEmbargo !== undefined ? (
        <div
          className="EmbargoDelete"
          onClick={() => {
            props.DeleteEmbargo(props.Embargo.EmbargoID);
          }}
        >
          Delete
        </div>
      ) : (
        ""
      )}
      <div className="Author">{props.Embargo.Author}</div>
    </div>
  );
};
