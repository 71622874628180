import { MondrianDisplayType } from "../Structs/Enums/MondrianDisplayType";
import { FilterColumnOptions } from "../Structs/Interfaces/FilterColumnOptions";
import { HideabilityColumnOptions } from "../Structs/Interfaces/HideabilityColumnOptions";
import { KeyItem } from "../Structs/Interfaces/KeyItem";
import { SoupColumnOptions } from "../Structs/Interfaces/SoupColumnOptions";
import { MondrianStructuredData } from "../Structs/Types/MondrianStructuredData";
import "./List.css";

interface IProps {
  Template: any;
  Display: MondrianDisplayType;
  FilterOptions: FilterColumnOptions[];
  SoupOptions: SoupColumnOptions[];
  HideabilityOptions: HideabilityColumnOptions[];
  KeyOptions: KeyItem[];
  Data: MondrianStructuredData;
}

const MondrianRenderListHeader = (Template: any) => {
  return <></>;
};

const MondrianRenderListItem = (Template: any, Cells: any[]) => {
  return <></>;
};

export const MondrianList = (props: IProps) => {
  return props.Data.Loading ? (
    <div className="Mondrian__NoResults">Loading...</div>
  ) : props.Data.Rows === undefined || props.Data.Rows.length === 0 ? (
    <div className="Mondrian__NoResults">No results were returned.</div>
  ) : (
    <div className="Mondrian__List">
      <div className="Mondrian__List_Header">
        {MondrianRenderListHeader(props.Template)}
      </div>
      <div className="Mondrian__List_Body">
        {props.Data.Rows.map((th) => {
          return MondrianRenderListItem(props.Template, th.Cells);
        })}
      </div>
      <div className="Mondrian__List_Header">
        {props.Data.Columns.map((th, i) => {
          if (
            props.HideabilityOptions?.some(
              (ith) =>
                th.MachineNames.some((iith) => iith === ith.Column) &&
                ith.Hidden === true, // why, prettier?
            )
          ) {
            return (
              <div
                key={"MGHC_" + i.toString()}
                className="Mondrian__List_Footer_Cell"
              >
                {th}
              </div>
            );
          } else {
            return "";
          }
        })}
      </div>
    </div>
  );
};
