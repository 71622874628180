import Grid from "./Grid";
import { useLocation } from "react-router-dom";
import { useUser } from "../../Providers/UserProvider";
import { useApi } from "../../Providers/ApiProvider";
import { useFunction } from "../../Providers/FunctionProvider";
//  import { useEffect } from "react";

interface IProps {
  ForceProxy?: boolean;
  legacyURLs?: boolean;
  Callouts: boolean;
  NoneMessage?: string;
  ButtonsAndFunctions?: {
    Name: string;
    ColumnKey: string;
    Function: (id: any) => void;
  }[];

  ViewAsAll?: boolean;
  eid: string;
  URLParams: string;
  RouteParam1?: string;
  RouteParam2?: string;
  contentTypes?: string[];
  blackList?: boolean;
  Elevation?: string;
  sproc?: string;
  params?: string[];
  output?: string;
  class?: string;
  sort?: string;
  sortdesc?: string;
  group?: string;
  groupdesc?: string;
  groupcond?: string;
  filter?: string;
  columnclass?: string;
  columns?: string;
  selectcolumns?: string;
  title?: string;
  subtitle?: string;
  rename?: string;
  reorder?: string;
  widths?: string;
  format?: string;
  tooltips?: string;
  toprowcount?: string;
  bottomrowcount?: string;
  heading?: string;
  target?: string;
  options?: string;
  start?: number;
  limit?: number;
  ucasno?: string;
  pgno?: string;
  prid?: string;

  // for edocs_ext only.
  SPVals?: string[];
  HomeOfficeAudit?: boolean;
}

export const GridWrapper = (props: IProps) => {
  const location = useLocation();
  let userData = useUser();
  let api = useApi();
  let functions = useFunction();

  /* The Debug Of Yikes

  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("ForceProxy...");
      console.log(props.ForceProxy);
    }
  }, [props.ForceProxy]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("legacyURLs...");
      console.log(props.legacyURLs);
    }
  }, [props.legacyURLs]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("Callouts...");
      console.log(props.Callouts);
    }
  }, [props.Callouts]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("Logger...");
      console.log(props.Logger);
    }
  }, [props.Logger]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("da...");
      console.log(props.da);
    }
  }, [props.da]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("PCA...");
      console.log(props.PCA);
    }
  }, [props.PCA]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("Refresher...");
      console.log(props.Refresher);
    }
  }, [props.Refresher]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("GetLink...");
      console.log(props.GetLink);
    }
  }, [props.GetLink]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("NoneMessage...");
      console.log(props.NoneMessage);
    }
  }, [props.NoneMessage]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("ButtonsAndFunctions...");
      console.log(props.ButtonsAndFunctions);
    }
  }, [props.ButtonsAndFunctions]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("RegisterError...");
      console.log(props.RegisterError);
    }
  }, [props.RegisterError]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("DeregisterError...");
      console.log(props.DeregisterError);
    }
  }, [props.DeregisterError]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("RegisterStatus...");
      console.log(props.RegisterStatus);
    }
  }, [props.RegisterStatus]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("DeregisterStatus...");
      console.log(props.DeregisterStatus);
    }
  }, [props.DeregisterStatus]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("Alias...");
      console.log(props.Alias);
    }
  }, [props.Alias]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("AliasChecked...");
      console.log(props.AliasChecked);
    }
  }, [props.AliasChecked]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("ViewAsAll...");
      console.log(props.ViewAsAll);
    }
  }, [props.ViewAsAll]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("eid...");
      console.log(props.eid);
    }
  }, [props.eid]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("URLRoot...");
      console.log(props.URLRoot);
    }
  }, [props.URLRoot]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("URLParams...");
      console.log(props.URLParams);
    }
  }, [props.URLParams]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("RouteParam1...");
      console.log(props.RouteParam1);
    }
  }, [props.RouteParam1]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("RouteParam2...");
      console.log(props.RouteParam2);
    }
  }, [props.RouteParam2]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("contentTypes...");
      console.log(props.contentTypes);
    }
  }, [props.contentTypes]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("blackList...");
      console.log(props.blackList);
    }
  }, [props.blackList]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("Elevation...");
      console.log(props.Elevation);
    }
  }, [props.Elevation]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("sproc...");
      console.log(props.sproc);
    }
  }, [props.sproc]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("params...");
      console.log(props.params);
    }
  }, [props.params]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("output...");
      console.log(props.output);
    }
  }, [props.output]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("class...");
      console.log(props.class);
    }
  }, [props.class]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("sort...");
      console.log(props.sort);
    }
  }, [props.sort]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("sortdesc...");
      console.log(props.sortdesc);
    }
  }, [props.sortdesc]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("group...");
      console.log(props.group);
    }
  }, [props.group]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("groupdesc...");
      console.log(props.groupdesc);
    }
  }, [props.groupdesc]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("groupcond...");
      console.log(props.groupcond);
    }
  }, [props.groupcond]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("filter...");
      console.log(props.filter);
    }
  }, [props.filter]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("columnclass...");
      console.log(props.columnclass);
    }
  }, [props.columnclass]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("columns...");
      console.log(props.columns);
    }
  }, [props.columns]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("selectcolumns...");
      console.log(props.selectcolumns);
    }
  }, [props.selectcolumns]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("title...");
      console.log(props.title);
    }
  }, [props.title]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("subtitle...");
      console.log(props.subtitle);
    }
  }, [props.subtitle]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("rename...");
      console.log(props.rename);
    }
  }, [props.rename]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("reorder...");
      console.log(props.reorder);
    }
  }, [props.reorder]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("widths...");
      console.log(props.widths);
    }
  }, [props.widths]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("format...");
      console.log(props.format);
    }
  }, [props.format]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("tooltips...");
      console.log(props.tooltips);
    }
  }, [props.tooltips]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("toprowcount...");
      console.log(props.toprowcount);
    }
  }, [props.toprowcount]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("bottomrowcount...");
      console.log(props.bottomrowcount);
    }
  }, [props.bottomrowcount]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("heading...");
      console.log(props.heading);
    }
  }, [props.heading]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("target...");
      console.log(props.target);
    }
  }, [props.target]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("options...");
      console.log(props.options);
    }
  }, [props.options]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("start...");
      console.log(props.start);
    }
  }, [props.start]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("limit...");
      console.log(props.limit);
    }
  }, [props.limit]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("ucasno...");
      console.log(props.ucasno);
    }
  }, [props.ucasno]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("pgno...");
      console.log(props.pgno);
    }
  }, [props.pgno]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("prid...");
      console.log(props.prid);
    }
  }, [props.prid]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("SPVals...");
      console.log(props.SPVals);
    }
  }, [props.SPVals]);
  useEffect(() => {
    if (props.eid.startsWith("ApplicantDocuments_")) {
      console.log("HomeOfficeAudit...");
      console.log(props.HomeOfficeAudit);
    }
  }, [props.HomeOfficeAudit]);

  */

  return userData.AliasChecked ? (
    <Grid
      {...props}
      {...functions}
      da={api.General}
      Alias={userData.Alias}
      AliasChecked={userData.AliasChecked}
      Location={location}
      eid={props.eid + functions.Refresher}
    />
  ) : (
    <></>
  );
};
