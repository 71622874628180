import { ChangeEvent } from "react";
import React from "react";
import { DropZone } from "./DropZone";
import { DataAccess, User } from "../../data/DataAccess";
import { FileUploadQueue } from "./FileUploadQueue";
import "../../assets/css/UploadManager.css";
import { getIconClassName } from "@fluentui/react";
import { IItem } from "../../models/IItem";
import { SPOFileState, SPOUploadFile } from "./SPInterfacing/SPOUploadFile";
import { Logging } from "../../Logging";
import { SPOSiteRef } from "./SPInterfacing/SPOSiteRef";
import { SPOContentType } from "./SPInterfacing/SPOContentType";
import SPOUploadLocation from "../../models/SPOUploadLocation";
import { SPOMValState, SPOMetadata } from "./SPInterfacing/SPOMetadata";

interface IProps {
  AuthedUser: User;
  Logger: Logging;
  da: DataAccess;
  GraphAccessToken: string;
  UploadConveyance: NonNullable<string>;
  Autofill?: { Type: string; Value: string };
  SPUL: SPOUploadLocation;
  DragState: boolean;
  Files: SPOUploadFile[];
  SPOR: SPOSiteRef;
  ContentTypes: SPOContentType[];

  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;

  ReferentiallyExamineFileName: (FileName: string) => string;

  StatefullySetDragState: (newstate: boolean) => void;
  StatefullySetFiles: (newfilearray: SPOUploadFile[]) => void;
  StatefullySetFile: (file: SPOUploadFile) => void;
  StatefullyClearFileMetadata: (file: SPOUploadFile) => void;
  StatefullyUpdateFileMetadata: (
    file: SPOUploadFile,
    name: string,
    value: string,
  ) => void;
  StatefullyUpdateFileName: (
    file: SPOUploadFile,
    name: string,
    extension: string,
  ) => void;
  StatefullyUpdateFileContentType: (file: SPOUploadFile, ctid: string) => void;
  StatefullyRemoveFile: (file: SPOUploadFile) => void;
  StatefullyUploadFile: (file: SPOUploadFile) => void;
  StatefullyUploadValidFiles: () => void;
}

class InternalUploadManager extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    if (this.props.SPUL === undefined) {
      window.document.title = "Uploading";
    } else {
      switch (String(this.props.UploadConveyance)) {
        case "ESF Registration Direct Upload --> SPO":
          window.document.title = "Uploading to Registration";
          break;
        case "Clearing Direct Upload --> SPO":
          window.document.title = "Uploading to Clearing";
          break;
        default:
          window.document.title = "Uploading to " + this.props.SPUL.Site;
      }
    }

    return (
      <div className="UploadManager">
        <div className="Segmentary">
          <div className="Segments">
            <div className="Segment">
              <div className="TitleBlock">
                {this.props.Autofill === undefined ? (
                  this.props.SPUL !== undefined ? (
                    <h1>{window.document.title}</h1>
                  ) : (
                    ""
                  )
                ) : (
                  <div className="AdvisoryBar">
                    <strong>Autopopulating:</strong> {this.props.Autofill.Type}{" "}
                    will be automatically set to {this.props.Autofill.Value}.
                  </div>
                )}
              </div>
            </div>
            <div className="Segment">
              <div className="DragAndDropBlock">
                <div
                  className="_eventWrapper"
                  onDragEnter={this.DragOver}
                  onDragOver={this.DragOver}
                >
                  <DropZone
                    SetDrag={this.props.StatefullySetDragState}
                    AddFiles={this.AddFiles}
                    DragState={this.props.DragState}
                  />
                </div>
              </div>
              <div className="Manual">
                <label htmlFor="UploadSingleFile" className="ManualUploadLabel">
                  <i
                    className={getIconClassName("ComplianceAudit")}
                    aria-hidden="true"
                  ></i>{" "}
                  Browse for file(s)
                </label>
                <input
                  id="UploadSingleFile"
                  type="file"
                  multiple
                  onChange={(event) => {
                    this.ReadFile(event);
                  }}
                ></input>
              </div>
            </div>
          </div>
        </div>
        <FileUploadQueue
          {...this.props}
          ReferentiallyExamineFileName={this.props.ReferentiallyExamineFileName}
        />
      </div>
    );
  }

  CheckNames(stateFiles: SPOUploadFile[]) {
    const PROHIBIT__SysFiles: string[] = [
      ".lock",
      "CON",
      "PRN",
      "AUX",
      "NUL",
      "COM0",
      "COM1",
      "COM2",
      "COM3",
      "COM4",
      "COM5",
      "COM6",
      "COM7",
      "COM8",
      "COM9",
      "LPT0",
      "LPT1",
      "LPT2",
      "LPT3",
      "LPT4",
      "LPT5",
      "LPT6",
      "LPT7",
      "LPT8",
      "LPT9",
      "_vti_",
      "desktop.ini",
      "thumbs.db",
    ];

    const WHITELIST_EXTENSIONS: string[] = [
      // Images
      ".jpeg",
      ".jpg",
      ".png",
      ".gif",
      ".svg",
      ".webp",
      ".avif",
      ".bmp",
      ".tif",
      ".tiff",
      // Emails
      ".eml",
      ".msg",
      // MS Office / Office-like type files
      ".doc",
      ".docx",
      ".ppt",
      ".pptx",
      ".ppsx",
      ".xls",
      ".xlsx",
      ".csv",
      ".rtf",
      ".odt",
      ".wps",
      ".xps",
      ".ods",
      ".odp",
      ".gdoc",
      ".odoc",
      ".osheet",
      ".sdw",
      ".wpd",
      ".wps",
      ".wrd",
      ".wrf",
      ".wri",
      ".pez",
      ".shf",
      ".gsheet",
      // Apple/Mac Device Files
      ".heic",
      ".heif",
      ".pages",
      ".key",
      ".keynote",
      ".numbers",
      // Other
      ".txt",
      ".pdf",
      ".epub",
    ];

    var returner: SPOUploadFile[] = [];

    stateFiles.forEach((uf) => {
      uf.Errors = uf.Errors.filter((th) => th.ErrorName !== "FileName");

      if (uf.File_Name !== undefined) {
        if (uf.File_Name === "") {
          uf.Errors.push({
            ErrorName: "FileName",
            ErrorValue: "This is a mandatory field",
          });
        } else if (uf.File_Ext === "") {
          uf.Errors.push({
            ErrorName: "FileName",
            ErrorValue: "This file has no extension",
          });
        } else if (
          WHITELIST_EXTENSIONS.indexOf(uf.File_Ext.toLowerCase()) === -1
        ) {
          uf.Errors.push({
            ErrorName: "FileName",
            ErrorValue: "This file extension is prohibited",
          });
        } else if (
          PROHIBIT__SysFiles.some(
            (th) =>
              th.toLowerCase() === uf.File_Name.toLowerCase() ||
              th.toLowerCase() ===
                uf.File_Name.toLowerCase() + uf.File_Ext.toLowerCase(),
          )
        ) {
          uf.Errors.push({
            ErrorName: "FileName",
            ErrorValue: "System file names are prohibited",
          });
        } else {
          const vspl = uf.File_Name.split(".");
          if (uf.File_Ext === undefined || uf.File_Ext === "") {
            uf.Errors.push({
              ErrorName: "FileName",
              ErrorValue: "File names must have a file extension",
            });
          } else if (vspl.length > 1) {
            uf.Errors.push({
              ErrorName: "FileName",
              ErrorValue: "File names may only have one '.'",
            });
          } else if (vspl[0] === "") {
            uf.Errors.push({
              ErrorName: "FileName",
              ErrorValue: "File names may not start with '.'",
            });
          } else {
            if (uf.File_Name.length > 40) {
              uf.Errors.push({
                ErrorName: "FileName",
                ErrorValue: "File name must be less than 40 characters.",
              });
            }
            // further validation rules now possible...
            let filenameRegex = /[^a-zA-Z0-9_\- ]/;
            if (filenameRegex.test(uf.File_Name)) {
              uf.Errors.push({
                ErrorName: "FileName",
                ErrorValue:
                  "Permitted characters in file names are 'a-zA-Z0-9_- '",
              });
            }
          }
        }
      }

      uf.Suggested_Name = uf.File_Name;
      uf.Suggested_Ext = uf.File_Ext;

      if (
        !PROHIBIT__SysFiles.some(
          (th) =>
            th.toLowerCase() === uf.Suggested_Name.toLowerCase() &&
            uf.File_Ext !== undefined &&
            uf.File_Ext !== "",
        )
      ) {
        if (uf.Suggested_Name.includes(".")) {
          uf.Suggested_Name = uf.Suggested_Name.replaceAll(".", "_");
        }

        let filenameRegex = /[^a-zA-Z0-9_\-]/;

        if (filenameRegex.test(uf.Suggested_Name)) {
          var sug = "";
          for (var i = 0; i < uf.Suggested_Name.length; i++) {
            if (!filenameRegex.test(uf.Suggested_Name[i])) {
              sug += uf.Suggested_Name[i];
            } else {
              sug += "_";
            }
          }

          sug = sug.replace("__", "_").replace("__", "_").replace("__", "_");

          uf.Suggested_Name = sug;
          //  console.log(uf.Suggested_Name);
        }

        if (uf.Suggested_Name.length > 40) {
          uf.Suggested_Name = uf.Suggested_Name.substring(0, 40);
        }
      }

      if (
        uf.Suggested_Name === uf.File_Name &&
        (uf.Overridden_Name === undefined || uf.Overridden_Name === "")
      ) {
        uf.Overridden_Name = uf.File_Name;
        uf.Overridden_Ext = uf.File_Ext;
      } else {
        uf.Overridden_Name = uf.Suggested_Name;
        uf.Overridden_Ext = uf.Suggested_Ext;
      }

      /*No longer than 40 characters total file name length (as we have to add prefixes to that which make it too long overall)
Ideally replace any spaces with hyphens too

Watch out for the request to shorten to max 40 characters length; You need to cope with this scenario for bulk uploads:

thisisalongfilenamethisisalongfilenamethisisalongfilenameFILE1.pdf
thisisalongfilenamethisisalongfilenamethisisalongfilenameFILE2.pdf
thisisalongfilenamethisisalongfilenamethisisalongfilenameFILE3.pdf
thisisalongfilenamethisisalongfilenamethisisalongfilenameFILE4.pdf

If you just stop at the 40th characters for all of the above, the filenames will end up identical!  So you need to have a clever filename-trimmer that either:
Takes the unique bit, and keeps it.
or
Replaces NN number of characters with a randomised string to make it unique.
 */

      returner.push(uf);
    });

    var samename: SPOUploadFile[] = returner.filter((e, i, a) =>
      a.some((th, j) => th.Overridden_Name === e.Overridden_Name && i !== j),
    );

    samename.forEach((element) => {
      returner
        .filter((th) => th.Overridden_Name === element.Overridden_Name)
        .forEach((ith) => {
          if (
            !ith.Errors.some(
              (iith) => iith.ErrorValue === "Multiple files have this name",
            )
          ) {
            ith.Errors.push({
              ErrorName: "FileName",
              ErrorValue: "Multiple files have this name",
            });
          }
        });
    });

    return returner;
  }

  //  SetSearchTerm = (newsearch: string) => {
  //    this.setState({ SearchTerm: newsearch });
  //  };

  //  AutoSearch = (SearchTerm: string) => {
  //    this.setState({
  //      SearchTerm: SearchTerm,
  //    });
  //  };

  DragOver = (event: any) => {
    this.props.StatefullySetDragState(true);
    event.stopPropagation();
    event.preventDefault();
  };

  ReadFile = (event: ChangeEvent<HTMLInputElement>) => {
    let newFiles = event.target.files;

    if (newFiles !== null) {
      this.AddFiles(newFiles);
    }

    event.target.files = null;
    event.target.value = "";
  };

  AddFiles = (newFiles: FileList) => {
    let stateFiles = [...this.props.Files];
    var autoSearchTerms: string = "";

    stateFiles = stateFiles.filter((th) => th.State !== SPOFileState.Completed);

    for (let i = 0; i < newFiles.length; i++) {
      const file = newFiles[i];

      let fileExtension = "";
      let fileName = "";

      if (file.name.includes(".")) {
        fileExtension = file.name.substring(file.name.lastIndexOf("."));
        fileName = file.name.substring(0, file.name.lastIndexOf("."));
      } else {
        fileExtension = "";
        fileName = file.name;
      }

      let PRIDRegex = /[A-Z]{5}[0-9]{5}/g;
      let DANoRegex = /DA[0-9]{8}/g;
      let UCASNoRegex = /[0-9]{10}/g;
      let RegNoRegex = /[0-9]{8}/g;
      let PGNoRegex = /[0-9]{6}/g;

      if ((fileName.match(PRIDRegex)?.length || 0) > 0) {
        fileName.match(PRIDRegex)?.forEach((RXM) => {
          autoSearchTerms = autoSearchTerms + "," + RXM;
        });
      } else {
        if ((fileName.match(UCASNoRegex)?.length || 0) > 0) {
          fileName.match(UCASNoRegex)?.forEach((RXM) => {
            autoSearchTerms = autoSearchTerms + "," + RXM;
          });
        } else {
          if ((fileName.match(DANoRegex)?.length || 0) > 0) {
            fileName.match(DANoRegex)?.forEach((RXM) => {
              autoSearchTerms = autoSearchTerms + "," + RXM;
            });
          } else {
            if ((fileName.match(PGNoRegex)?.length || 0) > 0) {
              fileName.match(PGNoRegex)?.forEach((RXM) => {
                autoSearchTerms = autoSearchTerms + "," + RXM;
              });
            } else {
              if ((fileName.match(RegNoRegex)?.length || 0) > 0) {
                fileName.match(RegNoRegex)?.forEach((RXM) => {
                  autoSearchTerms = autoSearchTerms + "," + RXM;
                });
              }
            }
          }
        }
      }

      //  console.log(this.props.AuthedUser);

      var _md: SPOMetadata[] = [
        {
          DataName: "UploadedBy",
          DataValue: this.props.AuthedUser.userPrincipalName.replace(
            "@essex.ac.uk",
            "",
          ),
          Validation: SPOMValState.Complete,
        },
      ];

      var nf: SPOUploadFile = {
        File: file,
        File_Name: fileName,
        File_Ext: fileExtension,
        Suggested_Name: undefined,
        Suggested_Ext: undefined,
        Overridden_Name: undefined,
        Overridden_Ext: undefined,
        UploadConveyance: this.props.UploadConveyance,
        Destination: this.props.SPUL.List,
        MetaData: _md,
        Errors: [],
        State: SPOFileState.InPreparation,
        InvarWorkOrderID: null,
        ReuploadCheckedIdent: "",
        AlreadyUploadedChecked: true,
        AlreadyUploadedStruct: undefined,
      };

      stateFiles = stateFiles.concat(nf);
    }

    //  if (autoSearchTerms !== undefined && autoSearchTerms !== "") {
    //    this.AutoSearch(autoSearchTerms);
    //  }

    this.CheckNames(stateFiles);

    this.props.StatefullySetFiles(stateFiles);
  };

  catchError = (error: IItem[][]) => {
    this.setState({
      IsSearching: false,
      SearchTerm: "",
    });

    this.ProcessError(error);
  };

  ProcessError = (error: IItem[][]) => {
    if (error !== undefined) {
      if (error.toString().indexOf("[object Object]") === -1) {
        this.setState({ Error: error.toString() });
      } else {
        this.setState({ Error: "" });

        error.forEach((th) => {
          th.forEach((ith) => {
            Object.keys(ith).forEach((iith) => {
              if (iith !== "" && ith[iith] !== "") {
                if (iith === "Error") {
                  this.setState({ Error: ith[iith] });
                }
              }
            });
          });
        });
      }
    }
  };
}

export default InternalUploadManager;
