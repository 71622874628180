import { PropsWithChildren, createContext, useContext } from "react";
import { User } from "../data/DataAccess";

interface UserContextData {
  AuthedUser: User;
  ViewAsUser: User;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
}

interface UserProviderProps {
  UserData: UserContextData;
}

const UserContext = createContext<UserContextData>(undefined);

export const useUser = () => useContext(UserContext);

export const UserProvider = (props: PropsWithChildren<UserProviderProps>) => {
  return (
    <UserContext.Provider value={props.UserData}>
      {props.children}
    </UserContext.Provider>
  );
};
