import * as React from "react";

import "../assets/css/Article.css";
import { ScrollRestoration } from "react-router-dom";

interface IProps {
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
}

class HelpArticle extends React.Component<IProps> {
  render() {
    window.document.title = "University of Essex - EDRM Help Pages";
    return (
      <div className="Article Help">
        <ScrollRestoration />
        <div className="WelcomeBar">
          <h1>Welcome to the EDRM Frequently Asked Questions Help page</h1>
          <div className="Subtitle">Article last updated 13th June 2024.</div>
          <div className="DescriptionBlock">
            This page has been set up as resource to answer some of the most
            common issues arising for ESF/EAF/ECF users. We hope you find it
            useful. If you have suggestions for additional items to include,
            please send your suggestion to{" "}
            <a href="mailto:sphelp@essex.ac.uk">
              <strong>sphelp@essex.ac.uk</strong>
            </a>
            .
          </div>
        </div>
        <div className="Segmentary">
          <div className="Segments">
            <div className="Segment">
              <h2>Frequently Asked Questions</h2>
              <br />
              <hr></hr>
              <h3>My screen does not show the same options as person 'X'</h3>
              <p>
                The Permissions you have been allocated will allow you the
                access to specific menu options, on-screen panels, restricted
                data, and any task screens allocated. If you feel you have not
                got the full array of information for your role displaying to
                you, please contact the
                <a href="mailto:it.helpdesk@essex.ac.uk">
                  {" "}
                  <strong>IT Helpdesk</strong>
                </a>{" "}
                in the first instance.
              </p>
              <br />
              <hr></hr>
              <h3>Documents are missing from page</h3>
              <p>
                First check the main Upload Files page for your service
                (ESF/PGEAF/UGEAF/ECF) and check that the file is showing as
                "uploaded" under the section "Your recently uploaded files".
                Files uploaded in the last working day will appear here. After
                one working day, the file lists are cleared, so you cannot check
                a file from yesterday or last week, for instance. If your file
                is showing as having errored, please contact sphelp@essex.ac.uk.
                If your file shows as successfully uploaded, it should appear on
                the applicant/student details page in the eDocs section
                (remember to press CTRL+F5 to refresh the page).
              </p>
              <br />
              <p>
                The following prefixed to the files listed on eDoc panels:
                <ul>
                  <li>
                    <strong>Pending.</strong> Files are awaiting upload.
                  </li>
                  <li>
                    <strong>Indexing.</strong> This can take up to 90 mins to
                    complete this process, if CTRL + F5 has been pressed, and is
                    not showing as new at this point contact{" "}
                    <a href="mailto:sphelp@essex.ac.uk">
                      {" "}
                      <strong>sphelp@essex.ac.uk</strong>
                    </a>{" "}
                    with all the details. Please note: the file has not been
                    lost.
                  </li>
                  <li>
                    <strong>New!.</strong> This will display for one working day
                    (up to 24 hours), it will then disappear
                  </li>
                  <li>
                    <strong>Error.</strong> This will show if there is a problem
                    with the upload.
                  </li>
                </ul>
                Contact{" "}
                <a href="mailto:sphelp@essex.ac.uk">
                  {" "}
                  <strong>sphelp@essex.ac.uk</strong>
                </a>{" "}
                ensuring all information is added to the email. If possible, add
                screenshots showing all details to assist in diagnosing the
                issue.
              </p>
              <p>
                If you believe documents (eDocs) are missing from the your view
                of the applicant/student details page, this may mean you do not
                have the correct permissions in place. To query this, please
                contact{" "}
                <a href="mailto:sphelp@essex.ac.uk">
                  <strong>sphelp@essex.ac.uk</strong>
                </a>{" "}
                ensuring all information is included in the ticket - the URL of
                the page, the student/applicant it is for, and which documents
                (file names) you believe are not present and should be.
              </p>
              <br />
              <hr></hr>
              <h3>
                My web browser seems to have remembered an incorrect / old
                password for my Essex account
              </h3>
              <p>
                The solution to this all depends on which web browser you are
                using. Follow the appropriate steps below to clear your bad/old
                password:
              </p>
              <ul>
                <li>
                  <strong>Edge</strong>
                  <li>
                    Go to Settings and more {">"} Settings {">"} Profiles {">"}{" "}
                    Passwords{" "}
                  </li>
                  <li>
                    Next to the password you want to change, select More actions
                    , and then select Edit.
                  </li>
                  <li>
                    When prompted, authenticate yourself to the operating system
                    to get access to the password information.
                  </li>
                  <li>
                    After you successfully authenticate yourself, in the Edit
                    password dialog, update your password, and then select Done.
                  </li>
                  <li>
                    <a
                      href="https://support.microsoft.com/en-us/topic/edit-your-passwords-in-microsoft-edge-38ef988f-
5a65-4c6a-9db8-937995d3ae31"
                    >
                      <strong>Link to Edge Help page</strong>
                    </a>
                  </li>
                </li>
                <li>
                  <strong>Chrome</strong>
                  <li>On your computer, open Chrome.</li>
                  <li>
                    At the top right, select Profile then Passwords.
                    <li>
                      If you can’t find the Passwords icon, at the top right,
                      select More {">"} Passwords and autofill {">"} Google
                      Password Manager.
                    </li>
                  </li>
                  <li>
                    Show, edit, delete, or export a password:
                    <li>
                      <strong>Show:</strong> To the right of your password,
                      select the Arrow {">"} Show password
                    </li>
                    <li>
                      <strong>Edit:</strong> To the right of the password,
                      select the Arrow {">"} <strong>Edit</strong>
                    </li>
                    <li>
                      <strong>Delete:</strong> To the right of the website,
                      select the Arrow {">"} <strong>Delete</strong>
                    </li>
                    <li>
                      <strong>Export:</strong> On the left, select{" "}
                      <strong>Settings</strong>
                      <li>
                        Under "Export Passwords," select{" "}
                        <strong>Download file</strong>
                      </li>
                    </li>
                    <li>
                      To clear all your saved passwords,{" "}
                      <a href="https://support.google.com/chrome/answer/2392709">
                        <strong>clear browsing data</strong>
                      </a>{" "}
                      and select <strong>Passwords</strong>
                    </li>
                  </li>
                </li>
                <li>
                  <strong>Firefox</strong>
                  <li>Navigate to the Menu button.</li>
                  <li>Select Passwords</li>
                  <li>Enter website in the search bar</li>
                  <li>
                    Either select edit button and change password in situ or
                    select Remove to delete the website
                  </li>
                  <li>
                    If you want to remove all passwords, select the icon at the
                    top right of the screen “…” select Remove all passwords
                    option
                  </li>
                  <li>
                    <a href="https://support.mozilla.org/en-US/kb/password-manager-remember-delete-edit-logins">
                      Direct link to Firefox help page
                    </a>
                  </li>
                </li>
                <li>
                  <strong>Safari</strong>
                  <li>Open the Safari menu</li>
                  <li>Select Preferences</li>
                  <li>Switch to the Autofill tab</li>
                  <li>Click the Edit button for Usernames and Passwords</li>
                  <li>
                    Delete the entry that corresponds with whichever website you
                    need to remove the password for.
                  </li>
                  <li>
                    <a href="https://support.apple.com/en-gb/105115">
                      Direct link to Safari help page
                    </a>
                  </li>
                </li>
              </ul>
              <br />
              <hr></hr>
              <h3>I can't process a form which has been assigned to me</h3>
              <p>
                ESF Forms: Please ensure you have the correct access rights
                using this web page:
                <a href="https://esf.essex.ac.uk/Utilities/ReviewTaskAssignees">
                  <strong>
                    https://esf.essex.ac.uk/Utilities/ReviewTaskAssignees
                  </strong>
                </a>
                . If you require access to be added, please ask your Line
                Manager to request it using the form at
                <a href="https://servicedesk.essex.ac.uk/servicedesk/customer/portal/2/create/103">
                  <strong>
                    https://servicedesk.essex.ac.uk/servicedesk/customer/portal/2/create/103
                  </strong>
                </a>
                PG and UG Decision Review Forms: please contact Admissions
                Systems Team directly (
                <a href="mailto:admsystems@essex.ac.uk">
                  <strong>admsystems@essex.ac.uk</strong>
                </a>
                )
              </p>
              <br />
              <hr></hr>
              <h3>My file upload status is not changing </h3>
              <p>
                Try using the CTRL and F5 keys to refresh the screen. If no
                changes are noticed after a few minutes, please try this again.
                If no changes have happened to the upload status after 90 mins,
                please contact the{" "}
                <a href="mailto:sphelp@essex.ac.uk">
                  <strong>sphelp@essex.ac.uk</strong>
                </a>{" "}
                ensuring all file details, any error messages, and appropriate
                screen-shots are included within the email.
              </p>
              <br />
              <hr></hr>
              <h3>
                Student Visa Audit (previously known as "Tier 4") changes to the
                "Include for Tier 4 Audit" check box are not saving to the file.
              </h3>
              <p>
                NOTE: There can be a delay of up to 90 mins for the index
                systems of SharePoint to acknowledge the “include for tier 4
                audit checkbox" being modified. Please do not report this as a
                fault until at least 90 minutes have passed. Also please do not
                attempt to re-change the "include for tier4 audit" checkbox
                again until the first change has been accepted by SharePoint. If
                you have identified a file affected by this issue, use this
                workaround (PDF file) to resolve it. Note: You will need write
                permissions to the file to use this workaround, and access to
                this feature is restricted.
              </p>
              <br />
              <hr></hr>
              <h3>
                The date/time format is not displaying correctly/not showing in
                the UK format
              </h3>
              <p>
                This means the locale settings for your browser are not set to
                'English (United Kingdom)' The process for resolving this is
                based upon the browser you are using. Please choose the correct
                steps from the below options.
              </p>
              <p>
                <strong>If you are using Google Chrome:</strong>
              </p>
              <li>
                Go to Chrome Options Open the Chrome Menu (click on the 3 lines
                to the right of the address bar) Select Settings and a Settings
                page is shown
              </li>
              <li>
                Scroll to the bottom of the page and click the 'Show Advanced
                Settings' link
              </li>
              <li>
                Scroll down further to the Languages section click the 'Language
                and input settings…' button.
              </li>
              <li>
                Click the 'Add' button and select 'English (United Kingdom)'
                from the drop-down. Click OK
              </li>
              <li>
                Click and hold 'English (United Kingdom)' and drag it to the top
                of the existing list.
              </li>
              <li>
                In the right-hand panel Click 'Display Google Chrome in this
                language' (Optional) You can also click the 'Use this language
                for spell checking' button for UK Spell Checking.
              </li>
              <li>Click Finished</li>
              <li>Close your Google Chrome browser.</li>
              <br />
              <hr></hr>
              <h3>Why is my web page showing out-of-date information?</h3>
              <p>
                If you believe you are viewing stale data, Please press CTRL +
                F5 together, this resets all browser data for the page and
                should refresh any cached information to show the latest
                information.
              </p>
              <br />
              <hr></hr>
              <h3>How do I open a Shared Mailbox on Web Mail?</h3>
              <p>
                <a href="/OutlookHelp/adding_a_shared_mailbox_on_outlook_webmail.pdf">
                  <strong>Outlook Adding a Shared Mailbox</strong>
                </a>
              </p>
              <br />
              <hr></hr>
              <h3>
                Button to Install "Upload to SharePoint" Outlook Add-In does not
                display for user.
              </h3>
              <p>
                Only members of the staff-all Outlook Group will be able to see
                the button. Only staff of the University should install the
                Add-In.
              </p>
              <p>
                Staff are advised, if possible, to use the Outlook installation
                guide (PDF).
              </p>
              <p>
                However, occasionally "affiliated" or "external" or "temp" staff
                need to install this software, but the button won't show for
                them. Below is the workaround:
              </p>
              <li>
                In a web browser go to{" "}
                <a href="https://email.essex.ac.uk">
                  <strong>https://email.essex.ac.uk</strong>
                </a>
              </li>
              <li>
                Login with your University credentials (to your PERSONAL email
                account, not a shared/role email):
                <li>username@essex.ac.uk</li>
                <li>Your University password</li>
              </li>
              <li>Your Outlook365 inbox should load up in your web browser.</li>
              <li>
                Select an email and double-click to open it up (you will need an
                email open to access the toolbar for the next step)
              </li>
              <li>
                Click on the “...” icon (three dots/ellipses - More actions) on
                the right-hand side of the Email dialog box’s internal toolbar.
              </li>
              <li>
                Click on the Get Add-ins option (usually near the bottom of the
                More Actions menu).
              </li>
              <li>Click on the My add-ins group</li>
              <li>Scroll down in the dialog box to Custom Add-ins.</li>
              <li>Click on the + Add a custom add-in link.</li>
              <li>
                Click Add from URL..
                <li>
                  In the Add private add-in from a URL dialog box type in:
                </li>
                <li>placeholder</li>
                <li>Press OK</li>
              </li>
              <li>Warning box: Press Install.</li>
              <br />
              <hr></hr>
              <h3>I have noticed an error in the data</h3>
              <p>
                Please contact the{" "}
                <a href="mailto:sphelp@essex.ac.uk">
                  <strong>sphelp@essex.ac.uk</strong>
                </a>{" "}
                ensuring all required information is included in the ticket.
                What is displaying incorrectly, what web page (URL) you are
                looking at, any error message displayed, and what you believe
                should be displayed.
              </p>
              <br />
              <hr></hr>
              <h3>My Refile Button is missing</h3>
              <p>
                The REFILE button will be missing until the file has finished
                being indexed by SharePoint Online. It will also only appear to
                users who have permission to use it. If this is not appearing,
                you do not have the required permissions.
              </p>
              <br />
              <hr></hr>
              <h3>Clearing is OFFLINE</h3>
              <p>
                This is deliberate, It is only available during certain parts of
                the year.
              </p>
              <br />
              <hr></hr>
              <h3>
                Upload has failed, message is displaying API throttled followed
                by error attempting to restart
              </h3>
              <img
                src={"/helpimages/Outlookthrottle.png"}
                alt={"Error image from outlook addin"}
                // width={"569"}
                // height={"333"}
              />{" "}
              <p>
                The "Throttle limit" error is only likely to occur on the
                outlook DESKTOP version.
              </p>
              <p>
                Workaround: use the uploader on the website based outlook here{" "}
                <a href="https://email.essex.ac.uk">
                  <strong>https://email.essex.ac.uk</strong>
                </a>
              </p>
              <br />
              <hr></hr>
              <h3>I think screen 'X' would look better with 'Y'</h3>
              <p>
                The SharePoint Team welcomes feedback, and we are receptive to
                fresh ideas. Please request any modifications through the head
                of your section/team/department who should then contact the
                SharePoint Team. All requests will be triaged in accordance with
                resource availability, appropriateness for business continuity,
                compatibility with data/systems overall, and reviewed by the
                Business Owners of the Service (ESF/EAF/ECF) for prioritisation.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HelpArticle;
