import React, { PropsWithChildren, ReactNode } from "react";
import { SURFACE, useSurface } from "../Providers/SurfaceProvider";

interface IPropsShow {
  ShowOn: SURFACE[];
  HideOn?: undefined;
}

interface IPropsHide {
  ShowOn?: undefined;
  HideOn: SURFACE[];
}

export const IfSurface = (
  props: PropsWithChildren<IPropsShow | IPropsHide>,
) => {
  const Surface = useSurface();

  var displayThis = false;

  if (props.ShowOn === undefined && props.HideOn === undefined) {
    displayThis = true;
  } else if (props.ShowOn === undefined && props.HideOn !== undefined) {
    displayThis = true;
    props.HideOn.forEach((th) => {
      if (th === Surface.SURFACE) {
        if (displayThis === true) {
          displayThis = false;
        }
      }
    });
  } else if (props.ShowOn !== undefined && props.HideOn === undefined) {
    displayThis = false;
    props.ShowOn.forEach((th) => {
      if (th === Surface.SURFACE) {
        if (displayThis === false) {
          displayThis = true;
        }
      }
    });
  }

  if (displayThis) {
    return <>{props.children}</>;
  } else {
    return <></>;
  }
};
