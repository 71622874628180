import { HideabilityColumnOptions } from "../Structs/Interfaces/HideabilityColumnOptions";
import { MondrianColumn } from "../Structs/Types/MondrianStructuredData";

interface MondrianHider {
  Hiders: HideabilityColumnOptions[];
  SetHiders: (HO: HideabilityColumnOptions[]) => void;
  Hideables: string[];
  DataColumns: MondrianColumn[];
}

export const MondrianHider = (props: MondrianHider) => {
  return <></>;
  //  return (
  //    <div className="Mondrian__Grid_Visibility">
  //      <div className="Mondrian__Grid_Visibility_Header">Sort</div>
  //      <Table className="Mondrian__Grid_Visibility_Table">
  //        {props.Visibilities.map((th, i) => {
  //          const ind = props.Visibilities.findIndex(
  //            (ith) => ith.Column === th.Column,
  //          );
  //          return (
  //            <tr key={"MGV_" + i.toString()}>
  //              /* column name */
  //              <td>
  //                <div className="Mondrian__Grid_Visibility_ColumnName">
  //                  {th.Column}
  //                </div>
  //              </td>
  //              /* hide this column */
  //              <td>
  //                <button
  //                  className="Mondrian__Grid_Visibility_Hide"
  //                  hidden={th.Hidden}
  //                  onClick={() => {
  //                    var newvisis = [...props.Visibilities];
  //                    newvisis[ind].Hidden = true;
  //                    props.SetVisibilities(newvisis);
  //                  }}
  //                ></button>
  //              </td>
  //              /* show this column */
  //              <td>
  //                <button
  //                  className="Mondrian__Grid_Visibility_Show"
  //                  hidden={!th.Hidden}
  //                  onClick={() => {
  //                    var newvisis = [...props.Visibilities];
  //                    newvisis[ind].Hidden = false;
  //                    props.SetVisibilities(newvisis);
  //                  }}
  //                ></button>
  //              </td>
  //            </tr>
  //          );
  //        })}
  //      </Table>
  //    </div>
  //  );
};
