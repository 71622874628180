import { PropsWithChildren, createContext, useContext } from "react";
import { Logging } from "../Logging";

interface FunctionContextData {
  Logger: NonNullable<Logging>;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  Refresher: string;
}

interface FunctionProviderProps {
  FunctionData: FunctionContextData;
}

const FunctionContext = createContext<FunctionContextData>(undefined);

export const useFunction = () => useContext(FunctionContext);

export const FunctionProvider = (
  props: PropsWithChildren<FunctionProviderProps>,
) => {
  return (
    <FunctionContext.Provider value={props.FunctionData}>
      {props.children}
    </FunctionContext.Provider>
  );
};
