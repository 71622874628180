import { useEffect, useRef, useState } from "react";
import { useUser } from "../../Providers/UserProvider";
import "../../assets/css/InvarAuditor.css";
import { useApi } from "../../Providers/ApiProvider";
import { Logging } from "../../Logging";
import { PublicClientApplication } from "@azure/msal-browser";
import { Mondrian } from "../../integrations/Mondrian/Mondrian";
import { MondrianDisplayType } from "../../integrations/Mondrian/Structs/Enums/MondrianDisplayType";
import { ModulePosition } from "../../integrations/Mondrian/Structs/Enums/ModulePosition";
import { GroupingStructure } from "../../integrations/Mondrian/Structs/Enums/GroupingStructure";
import { SortOrder } from "../../integrations/Mondrian/Structs/Enums/SortOrder";
import { MondrianOptions } from "../../integrations/Mondrian/Structs/Interfaces/MondrianOptions";
import { MondrianDataProvider } from "../../integrations/Mondrian/MondrianDataProvider";
import { GetWorkOrderResponse } from "../../data/InvarDataModels/GetWorkOrderResponse";
import { MondrianColumn } from "../../integrations/Mondrian/Structs/Types/MondrianStructuredData";
import dayjs from "dayjs";

interface IProps {
  Logger: Logging;
  PCA: PublicClientApplication;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  URLRoot: string;
  URLParams: string;
  Refresher: string;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

export function InvarAuditor(props: IProps) {
  const _options: MondrianOptions = {
    Display: MondrianDisplayType.Grid,
    DisplayOptions: { Alternator: true },
    FilterOptions: {
      Filterable: true,
      Position: ModulePosition.Top,
      FilterableColumns: [],
      Defaults: [],
    },
    SoupOptions: {
      Sortable: true,
      Groupable: true,
      Position: ModulePosition.Top,
      GroupingStructure: GroupingStructure.Accordioned,
      ShowSubcount: true,
      SortableColumns: ["Started", "Completed"],
      GroupableColumns: ["Status"],
      Defaults: [
        {
          Column: "Completed",
          Sortable: true,
          Groupable: false,
          Sort: SortOrder.Descending,
          Group: false,
        },
      ],
    },
  };

  const columns: MondrianColumn[] = [
    { HumanName: "Mechanism", MachineNames: ["ConveyanceName"] },
    { HumanName: "Identifier", MachineNames: ["InitialValue"] },
    { HumanName: "Uploaded By", MachineNames: ["RequestedBy"] },
    { HumanName: "Uploaded Date", MachineNames: ["RequestedDate"] },
    { HumanName: "Completed Date", MachineNames: ["FulfilledDate"] },
    { HumanName: "Status", MachineNames: ["Status"] },
    { HumanName: "Action", MachineNames: ["Action"] },
    { HumanName: "_ROW_PROCESSING", MachineNames: ["_ROW_PROCESSING"] },
  ];

  const _reqmeta: string[] = ["PDFs.Concat.LCL>LCL.Output"];

  const _gworplace: GetWorkOrderResponse[] = [];

  const API = useApi();
  const userauth = useUser();
  const [username, setUsername] = useState("");
  const [env, setEnv] = useState(props.Environment);
  const [olderThan, setOlderThan] = useState(undefined);
  const [newerThan, setNewerThan] = useState(undefined);
  const [WORs, setWORs] = useState(_gworplace);
  const [mutatedWORs, setMutatedWORs] = useState([]);

  const [loadingResults, setLoadingResults] = useState(true);

  const refTimeoutProcessing = useRef(null);
  const refTimeoutStale = useRef(null);
  const refTimeoutAll = useRef(null);

  const StatefullyMergeWorkOrderResponse = (GWOR: GetWorkOrderResponse) => {
    setWORs(
      [...WORs]
        .filter((th) => th.WorkOrderID !== GWOR.WorkOrderID)
        .concat(GWOR),
    );
  };

  const StatefullyMergeWorkOrderResponses = (GWORs: GetWorkOrderResponse[]) => {
    setWORs(
      [...WORs]
        .filter(
          (th) => !GWORs.some((ith) => ith.WorkOrderID === th.WorkOrderID),
        )
        .concat(GWORs),
    );
  };

  useEffect(() => {
    setUsername(
      userauth?.ViewAsUser?.userPrincipalName?.replace("@essex.ac.uk", "") ||
        userauth?.AuthedUser?.userPrincipalName?.replace("@essex.ac.uk", ""),
    );
  }, [userauth]);

  const LoadWORs = () => {
    //  console.log("LoadWORs() => ...");
    //  console.log(username);
    //  console.log(env);
    //  console.log(_reqmeta);
    //  console.log(olderThan);
    //  console.log(newerThan);

    API?.Invar?.GetAllWorkOrdersForUser(
      username,
      env,
      _reqmeta,
      undefined,
      olderThan,
      newerThan,
    ).then((data) => {
      //  console.log(data);
      setWORs(data);
    });
  };

  useEffect(() => {
    let isMounted = true;
    if (username !== undefined && username !== "") {
      LoadWORs();
    }
    return () => {
      isMounted = false;
    };
  }, [username, env]);

  useEffect(() => {
    let isMounted = true;
    if (WORs !== undefined) {
      setMutatedWORs(MutateWORs(WORs));
      setLoadingResults(false);
      RegenerateTimers();
    }
    return () => {
      isMounted = false;
    };
  }, [WORs]);

  const PollWorkOrders = async (
    woids: GetWorkOrderResponse[],
    relay: boolean,
  ) => {
    const _wors: GetWorkOrderResponse[] = [];
    var tasks = [];
    woids.forEach((th) => {
      tasks.push(
        API.Invar.GetWorkOrder(th.WorkOrderID, th.ConveyanceID, _reqmeta).then(
          (data) => {
            _wors.push(data);
          },
        ),
      );
    });
    await Promise.all(tasks);
    StatefullyMergeWorkOrderResponses(_wors);
    if (relay) {
      RegenerateTimers();
    }
  };

  const RegenerateTimers = () => {
    //  console.log("RegenerateTimers");
    //  console.log(refTimeoutProcessing);
    //  console.log(refTimeoutStale);
    //  console.log(refTimeoutAll);

    if (refTimeoutProcessing !== undefined) {
      if (refTimeoutProcessing.current !== undefined) {
        clearTimeout(refTimeoutProcessing.current);
        refTimeoutProcessing.current = undefined;
      }
    }

    if (refTimeoutStale !== undefined) {
      if (refTimeoutStale.current !== undefined) {
        clearTimeout(refTimeoutStale.current);
        refTimeoutStale.current = undefined;
      }
    }

    if (refTimeoutAll !== undefined) {
      if (refTimeoutAll.current !== undefined) {
        clearTimeout(refTimeoutAll.current);
        refTimeoutAll.current = undefined;
      }
    }

    var Processings: GetWorkOrderResponse[] = [];
    var Stales: GetWorkOrderResponse[] = [];

    // for each work order marked as Processing, we register a new Interval with JS to refresh that particular Work Order after 15s.
    //  console.log(audits);

    WORs.forEach((th) => {
      if (th.Status === "Processing") {
        //  console.log("RegenerateTimers - proc +1");
        Processings.push(th);
      } else if (th.Status === "Stale") {
        //  console.log("RegenerateTimers - stal +1");
        Stales.push(th);
      }
    });

    //  console.log(Processings);
    if (Processings.length > 0) {
      refTimeoutProcessing.current = setTimeout(async () => {
        PollWorkOrders(Processings, true);
      }, 5000);
    }

    //  console.log(Stales);
    if (Stales.length > 0) {
      refTimeoutStale.current = setTimeout(async () => {
        PollWorkOrders(Stales, false);
      }, 60000);
    }

    if (Processings.length === 0 && Stales.length === 0) {
      refTimeoutAll.current = setTimeout(async () => {
        LoadWORs();
        RegenerateTimers();
      }, 15000);
    }

    //  console.log(refTimeoutProcessing);
    //  console.log(refTimeoutStale);
    //  console.log(refTimeoutAll);
  };

  const Retry = async (CID: number, WOID: number) => {
    StatefullyMergeWorkOrderResponse(
      await API.Invar.RefireWorkOrder(WOID, CID, _reqmeta),
    );
  };

  const Cancel = async (CID: number, WOID: number) => {
    StatefullyMergeWorkOrderResponse(
      await API.Invar.CancelWorkOrder(WOID, CID, _reqmeta),
    );
  };

  const MutateWORs = (WORs: GetWorkOrderResponse[]): any[] => {
    return WORs.sort((lf, rg) => {
      return lf.RequestedDate < rg.RequestedDate
        ? 1
        : lf.RequestedDate === rg.RequestedDate
          ? 0
          : -1;
    }).map((th) => {
      return {
        ConveyanceName: th.ConveyanceName,
        RequestedBy: th.RequestedBy,
        InitialValue: th.InitialValue,
        RequestedDate:
          th.RequestedDate === null || th.RequestedDate === undefined
            ? ""
            : dayjs(th.RequestedDate + "0Z").format("HH:mm:ss DD-MM-YYYY"),
        FulfilledDate:
          th.FulfilledDate === null || th.FulfilledDate === undefined
            ? ""
            : dayjs(th.FulfilledDate + "0Z").format("HH:mm:ss DD-MM-YYYY"),
        Status: (
          <span
            className={
              th.Status === "Completed"
                ? "Mondrian_Status Completed"
                : th.Status === "Cancelled"
                  ? "Mondrian_Status Cancelled"
                  : th.Status === "Processing"
                    ? "Mondrian_Status Processing"
                    : th.Status === "Stale"
                      ? "Mondrian_Status Stale"
                      : th.Status === "Failed"
                        ? "Mondrian_Status Failed"
                        : th.Status === "Permanently Failed"
                          ? "Mondrian_Status Failed"
                          : ""
            }
          >
            <span className="Label">
              {th.Status === "Completed"
                ? "Completed"
                : th.Status === "Cancelled"
                  ? "Cancelled"
                  : th.Status === "Processing"
                    ? "Processing"
                    : th.Status === "Stale"
                      ? "Stale"
                      : th.Status === "Failed"
                        ? "Failed"
                        : th.Status === "Permanently Failed"
                          ? "Failed"
                          : "-"}
            </span>
            {
              ""
              /*
                th.Status !== "Completed" &&th.Status !== "Failed" &&th.Status !== "Cancelled" ? (
              <span className="Mondrian_Status_Detail">
                {th.SegmentaryStatus.map((seg, k) => {
                  return (
                    <span
                      key={"sv_" + k.toString()}
                      className={
                        "Segment" +
                        (seg.StepAttempts.some(
                          (iseg) => iseg.Completed !== null,
                        )
                          ? " Completed"
                          : seg.StepAttempts.some(
                                (iseg) => iseg.Failed !== null,
                              )
                            ? " Failed"
                            : seg.StepAttempts.length === 0 && seg.Ordinal === 1
                              ? " Originator"
                              : seg.StepAttempts.length === 0 &&
                                  seg.Ordinal !== 1
                                ? " NotYetReached"
                                : " InFlight")
                      }
                    >
                      <span className="Ordinal">
                        &nbsp;
                        {
                          //seg.Ordinal.toString()
                        }
                      </span>
                      {
                        //     <div className="Detail">
                        //       <div className="SegmentName">{seg.SegmentName}</div>
                        //       <div className="ActionName">{seg.ActionName}</div>
                        //       <div className="StepAttempts">
                        //         {seg.StepAttempts.map((satt) => {
                        //           return (
                        //             <div className="StepAttempt">{satt.Created}</div>
                        //           );
                        //         })}
                        //       </div>
                        //     </div>
                      }
                    </span>
                  );
                })}
              </span>
            ) : (
              ""
            )*/
            }
          </span>
        ),
        Action:
          th.Status === "Processing" || th.Status === "Stale" ? (
            <input
              className="Mondrian_Button"
              type="button"
              value="Cancel"
              onClick={() => {
                Cancel(th.ConveyanceID, th.WorkOrderID);
              }}
            ></input>
          ) : th.Status === "Failed" ? (
            <input
              className="Mondrian_Button"
              type="button"
              value="Retry"
              onClick={() => {
                Retry(th.ConveyanceID, th.WorkOrderID);
              }}
            ></input>
          ) : th.Status === "Completed" ? (
            <span>
              {th.RelevantMetadata.find(
                (ith) => ith.DatumName === "PDFs.Concat.LCL>LCL.Confirmed",
              )?.DatumValue === "false"
                ? "No documents found."
                : th.RelevantMetadata.find(
                    (ith) => ith.DatumName === "PDFs.Concat.LCL>LCL.Output",
                  )?.DatumValue}
              {
                //  <a
                //    className="Mondrian_Button"
                //    type="button"
                //    rel="external"
                //    href={
                //      "file:" +
                //      th.RelevantMetadata.find(
                //        (ith) => ith.DatumName === "PDFs.Concat.LCL>LCL.Output",
                //      )?.DatumValue.replaceAll("/", "\\")
                //    }
                //    target="_blank"
                //  >
                //    Download
                //  </a>
              }
            </span>
          ) : (
            ""
          ),
      };
    });
  };

  return (
    <div className="InvarAuditor">
      <div
        className="ControlPanel"
        hidden={
          !userauth?.AuthedUser?.groups.some(
            (th) => th.onPremSamName === "spdev-g",
          )
        }
      >
        <div className="Title">
          <h3>Control Panel</h3>
          <em>spdev only</em>
        </div>
        <div className="Environment">
          <h4>Environment</h4>
          <button onClick={() => setEnv("LIVE")} disabled={env === "LIVE"}>
            LIVE
          </button>
          <button onClick={() => setEnv("TEST")} disabled={env === "TEST"}>
            TEST
          </button>
          <button onClick={() => setEnv("DEV")} disabled={env === "DEV"}>
            DEV
          </button>
          <button onClick={() => setEnv("LOCAL")} disabled={env === "LOCAL"}>
            LOCAL
          </button>
        </div>
      </div>
      <div className="Summary">
        <h2>Automation History</h2>
        <div>
          This page shows your historic automated jobs. Document uploads, report
          requests and other things.
        </div>
      </div>
      <br />
      <MondrianDataProvider
        ObjectArray={mutatedWORs}
        Columns={columns}
        LoadingFlag={loadingResults}
      >
        <Mondrian Options={_options}></Mondrian>
      </MondrianDataProvider>
    </div>
  );
}
