import { PropsWithChildren, useContext } from "react";
import { createContext } from "react";
import { IItem } from "../../models/IItem";
import {
  MondrianColumn,
  MondrianStructuredData,
} from "./Structs/Types/MondrianStructuredData";
import { GridRowData } from "./Structs/MondrianStructs";

interface IPropsDataSet {
  ObjectArray?: undefined;
  DataSet: IItem[][];
  LoadingFlag: boolean;
  Columns?: MondrianColumn[];
}

interface IPropsObjectArray {
  ObjectArray: any[];
  DataSet?: undefined;
  LoadingFlag: boolean;
  Columns?: MondrianColumn[];
}

const MondrianDataContext = createContext(MondrianStructuredData.prototype);

export const useMondrianData = () => {
  //  console.log("useMondrianData called...");
  return useContext(MondrianDataContext);
};

export const MondrianDataProvider = (
  props: PropsWithChildren<IPropsDataSet | IPropsObjectArray>,
) => {
  //  console.log("MondrianDataProvider parsing...");

  var MSD: MondrianStructuredData = new MondrianStructuredData();

  MSD.Loading = props.LoadingFlag;

  if (props.Columns !== undefined) {
    MSD.Columns = props.Columns.filter(
      (th) =>
        !(
          th.MachineNames.length === 1 &&
          th.MachineNames[0] === "_ROW_PROCESSING"
        ),
    );
  } else if (props.ObjectArray !== undefined && props.ObjectArray.length > 0) {
    MSD.Columns = Object.getOwnPropertyNames(props.ObjectArray[0]).map((th) => {
      return { MachineNames: [th], HumanName: th };
    });
  } else {
    MSD.Columns = [];
  }

  if (props.DataSet !== undefined) {
    console.log("IItem[][] import not yet built.");
  } else if (props.ObjectArray !== undefined) {
    if (props.ObjectArray.length > 0) {
      if (props.Columns !== undefined) {
        // compare columns
        const compcols: string[] = Object.getOwnPropertyNames(
          props.ObjectArray[0],
        );
        const specifiedcolumns = JSON.stringify(props.Columns.toSorted());
        const extrapolatedcolumns = JSON.stringify(compcols.toSorted());
        if (specifiedcolumns !== extrapolatedcolumns) {
          //  console.log("Column spec doesn't match object:");
          //  console.log(specifiedcolumns);
          //  console.log("vs");
          //  console.log(extrapolatedcolumns);
        }
      }
      MSD.Rows = [];
      props.ObjectArray.forEach((th) => {
        MSD.Rows.push(new GridRowData(th._ROW_PROCESSING, th, MSD.Columns));
      });
    }
  }

  //  console.log("MondrianDataProvider parsed:");
  //  console.log(MSD);
  //  console.log("MondrianDataProvider rendering...");

  return (
    <MondrianDataContext.Provider value={MSD}>
      {props.children}
    </MondrianDataContext.Provider>
  );
};
