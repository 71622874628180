import { SoupColumnOptions } from "../Structs/Interfaces/SoupColumnOptions";
import { SoupOptions } from "../Structs/Interfaces/SoupOptions";
import { MondrianColumn } from "../Structs/Types/MondrianStructuredData";

interface MondrianSouperProps {
  Options: SoupOptions;
  Soups: SoupColumnOptions[];
  SetSoups: (SO: SoupColumnOptions[]) => void;
  DataColumns: MondrianColumn[];
}

export const MondrianSouper = (props: MondrianSouperProps) => {
  return <></>;
  //  return (
  //    <div className="Mondrian__Grid_Sorter">
  //      <div className="Mondrian__Grid_Sorter_Header">Sort</div>
  //      <Table className="Mondrian__Grid_Sorter_Table">
  //        {props.SortBy.map((th, i) => {
  //          const ind = props.SortBy.findIndex((ith) => ith.Column === th.Column);
  //          return (
  //            <tr key={"MGS_" + i.toString()}>
  //              /* switch ascending */
  //              <td>
  //                <button
  //                  className="Mondrian__Grid_Sorter_SortAscending"
  //                  hidden={th.SortOrder === SortOrder.Ascending}
  //                  onClick={() => {
  //                    var newsort = [...props.SortBy];
  //                    newsort[ind].SortOrder = SortOrder.Ascending;
  //                    props.SetSortBy(newsort);
  //                  }}
  //                ></button>
  //              </td>
  //              /* switch descending */
  //              <td>
  //                <button
  //                  className="Mondrian__Grid_Sorter_SortDescending"
  //                  hidden={th.SortOrder === SortOrder.Descending}
  //                  onClick={() => {
  //                    var newsort = [...props.SortBy];
  //                    newsort[ind].SortOrder = SortOrder.Descending;
  //                    props.SetSortBy(newsort);
  //                  }}
  //                ></button>
  //              </td>
  //              /* column name */
  //              <td>
  //                <div className="Mondrian__Grid_Sorter_ColumnName">
  //                  {th.Column}
  //                </div>
  //              </td>
  //              /* promote sort */
  //              <td>
  //                <button
  //                  className="Mondrian__Grid_Sorter_PromoteSort"
  //                  hidden={th.SortOrder === SortOrder.Ascending}
  //                  onClick={() => {
  //                    var newsort = [...props.SortBy];
  //                    newsort[ind] = props.SortBy[ind - 1];
  //                    newsort[ind - 1] = props.SortBy[ind];
  //                    props.SetSortBy(newsort);
  //                  }}
  //                >
  //                  ^
  //                </button>
  //              </td>
  //              /* demote sort */
  //              <td>
  //                <button
  //                  className="Mondrian__Grid_Sorter_DemoteSort"
  //                  hidden={th.SortOrder === SortOrder.Ascending}
  //                  onClick={() => {
  //                    var newsort = [...props.SortBy];
  //                    newsort[ind] = props.SortBy[ind + 1];
  //                    newsort[ind + 1] = props.SortBy[ind];
  //                    props.SetSortBy(newsort);
  //                  }}
  //                >
  //                  v
  //                </button>
  //              </td>
  //              /* remove sort */
  //              <td>
  //                <button
  //                  className="Mondrian__Grid_Sorter_RemoveSort"
  //                  onClick={() => {
  //                    var newsort = [...props.SortBy];
  //                    newsort = newsort.filter((ith, ii) => ii !== ind);
  //                    props.SetSortBy(newsort);
  //                  }}
  //                >
  //                  X
  //                </button>
  //              </td>
  //            </tr>
  //          );
  //        })}
  //      </Table>
  //    </div>
  //  );
};
