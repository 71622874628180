import { GridWrapper } from "../integrations/UoE-Grid/GridWrapper";
import { PublicClientApplication } from "@azure/msal-browser";
import ModeStruct from "../models/ModeStruct";
import SPOUploadLocation from "../models/SPOUploadLocation";
import React from "react";
import { Logging } from "../Logging";
import { DataAccess, User } from "../data/DataAccess";
import { getIconClassName } from "@fluentui/react";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  GraphAccessToken: string;
  URLRoot: string;
  URLParams: string;
  Refresher: string;
  UCASNo: string;
  PRID: string;
  PGNo: string;
  AuthedUser: User;
  ViewAsUser: User;
  wfid: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  GetSPUL: (shortname: string) => SPOUploadLocation;
  SetSearch: (SearchString: string) => void;
  SetTaskList: (TLRef: string) => void;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

interface IState {
  Tab: string;
  ShowUploader: boolean;
}

class ESFAllEAFDocs extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      Tab: "Show_All_EAF_eDocs",
      ShowUploader: false,
    };
  }

  private _edcCTs: string[] = ["uaducasform"];

  private _appformdocCTs: string[] = ["Application Summary"];

  private _casdocCTs: string[] = ["CAS Statement"];

  private _offdocCTs: string[] = [
    "Offer Document",
    "padedocacceptance",
    "pad001am",
    "pad001bm",
    "pad001bm2",
    "pad001cm",
    "pad001dm2",
    "pad009m",
    "pad013am",
    "pad013cm",
    "pad013dm",
    "pad013m",
    "pad014m",
    "pad015m",
    "pad017am",
    "pad017bm",
    "pad017m",
    "pad021am",
    "pad012bm",
    "pad021m",
    "pad023m",
    "pad046am",
    "pad046bm",
    "pad046cm",
    "pad046dm",
    "pad047m",
    "pad048m",
  ];

  private _appdocCTs: string[] = [
    "Application Summary",
    "Offer Document",
    "padedocacceptance",
    "pad001am",
    "pad001bm",
    "pad001bm2",
    "pad001cm",
    "pad001dm2",
    "pad009m",
    "pad013am",
    "pad013cm",
    "pad013dm",
    "pad013m",
    "pad014m",
    "pad015m",
    "pad017am",
    "pad017bm",
    "pad017m",
    "pad021am",
    "pad012bm",
    "pad021m",
    "pad023m",
    "pad046am",
    "pad046bm",
    "pad046cm",
    "pad046dm",
    "pad047m",
    "pad048m",
    "CAS Statement",
  ];
  render() {
    const urlParams = new URLSearchParams(window.location.search);
    const pgno = urlParams.get("pgno");
    const ucasno = urlParams.get("ucasno");

    var PassToAllGrids = {
      Callouts: true,
      Logger: this.props.Logger,
      da: this.props.da,
      prid: this.props.PRID.toUpperCase(),
      wfid: this.props.wfid,
      PCA: this.props.PCA,
      GetLink: this.props.GetLink,
      URLRoot: this.props.URLRoot,
      URLParams: this.props.URLParams,
      pgno: pgno.toUpperCase(),
      ucasno: ucasno.toUpperCase(),
      class: "UoE-Grid ESF",
      Refresher: this.props.Refresher,
      Alias: this.props.Alias,
      AliasChecked: this.props.AliasChecked,
      ViewAsAll: this.props.ViewAsAll,
      RegisterError: this.props.RegisterError,
      DeregisterError: this.props.DeregisterError,
      RegisterStatus: this.props.RegisterStatus,
      DeregisterStatus: this.props.DeregisterStatus,
    };

    return (
      <div className="ESFScreen">
        <h1>EAF Documentation</h1>
        <div className="PGEAFeDocs">
          <div className="SectionRow">
            <h2>
              <i
                className={getIconClassName("Documentation")}
                aria-hidden="true"
              ></i>{" "}
              PGEAF Offer Documents
            </h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"OfferDocuments_" + this.props.Refresher}
              ForceProxy={false}
              output="edocs"
              sort="Created Date"
              sortdesc="true"
              target="_blank"
              contentTypes={this._offdocCTs}
            ></GridWrapper>
          </div>
          <div className="SectionRow">
            <h2>
              <i
                className={getIconClassName("Documentation")}
                aria-hidden="true"
              ></i>{" "}
              PGEAF CAS Documents
            </h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"CASDocuments_" + this.props.Refresher}
              ForceProxy={false}
              output="edocs"
              sort="Created Date"
              sortdesc="true"
              target="_blank"
              contentTypes={this._casdocCTs}
            ></GridWrapper>
          </div>
          <div className="SectionRow">
            <h2>
              <i
                className={getIconClassName("Documentation")}
                aria-hidden="true"
              ></i>{" "}
              PGEAF Applicant Documents
            </h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"ApplicantDocuments_" + this.props.Refresher}
              ForceProxy={false}
              output="edocs"
              sort="Created Date"
              sortdesc="true"
              target="_blank"
              contentTypes={this._appdocCTs}
              blackList={true}
            ></GridWrapper>
          </div>
          <div className="SectionRow">
            <h2>
              <i
                className={getIconClassName("Documentation")}
                aria-hidden="true"
              ></i>{" "}
              PGEAF Application Forms
            </h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"ApplicationFormsDocs_" + this.props.Refresher}
              ForceProxy={false}
              output="edocs"
              sort="Created Date"
              sortdesc="true"
              target="_blank"
              contentTypes={this._appformdocCTs}
            ></GridWrapper>
          </div>
        </div>
        <div className="UGEAFeDocs">
          <div className="SectionRow">
            <h2>
              <i
                className={getIconClassName("Documentation")}
                aria-hidden="true"
              ></i>{" "}
              UGEAF eDocs
            </h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"eDocs_" + this.props.Refresher}
              ForceProxy={false}
              output="edocs"
              group="Document Type"
              sort="Created Date"
              sortdesc="true"
              target="_blank"
              contentTypes={this._edcCTs}
              blackList={true}
            ></GridWrapper>
          </div>
        </div>
      </div>
    );
  }
}
export default ESFAllEAFDocs;
