import { Button } from "@fluentui/react-components";
import { SearchRegular, ArrowUpRightFilled } from "@fluentui/react-icons";
import { User } from "../../data/DataAccess";
import IfPermitted from "../IfPermitted";
import { NavigateFunction } from "react-router-dom";
import dayjs from "dayjs";

export type QuickSearchCharmProps = {
  Permission: (surface: string, scope: string, impish: boolean) => boolean;
  AuthedUser: User;
  ViewAsUser: User;
  MenuVisibilities: Map<string, boolean>;
  SetMenuVisibility: (name: string | undefined, newval: boolean) => void;
  _miniSearchTerm: string;
  _setMiniSearchTerm: (dave: string) => void;
  Navigation: NavigateFunction;

  Title: string;
  OuterWhitelist: string[];
  InnerWhitelist: string[];
  TextSearchURL: string;
  DateSearchURL?: string;
};

export function QuickSearchCharm(props: QuickSearchCharmProps) {
  return (
    <IfPermitted Whitelist={props.OuterWhitelist}>
      <div
        className="MiniSearchMenu"
        onMouseEnter={() => {
          props.SetMenuVisibility("MiniSearch", true);
        }}
      >
        <div tabIndex={0} className="MiniSearchMenuCharm">
          <SearchRegular></SearchRegular>
        </div>
        <div
          className={
            "MiniSearchMenuReveal" +
            (props.MenuVisibilities.get("MiniSearch") ? " Visible" : "")
          }
          aria-expanded={props.MenuVisibilities.get("MiniSearch")}
        >
          <div className="Container">
            <div className="MenuTitle">{props.Title}</div>
            <div className="MenuItem Inert MiniSearchInputRack">
              <input
                type="textbox"
                autoComplete="off"
                id="MiniSearchInput"
                value={props._miniSearchTerm}
                onChange={(val) => {
                  props._setMiniSearchTerm(val.target.value);
                }}
                onPaste={(ev) => {
                  const sterm = ev.clipboardData
                    .getData("text")
                    .trim()
                    .replaceAll(" ", "_");

                  if (
                    (sterm.includes("/") &&
                      dayjs(sterm, "DD/MMM/YYYY", true).isValid()) ||
                    (sterm.includes("-") &&
                      dayjs(sterm, "DD-MM-YYYY", true).isValid())
                  ) {
                    // Only accept values in format 01/JAN/2021
                    props.Navigation(
                      props.DateSearchURL + dayjs(sterm).format("DD-MM-YYYY"),
                    );
                  }
                }}
                onKeyUp={(ev) => {
                  if (ev.key === "Enter") {
                    const sterm = props._miniSearchTerm
                      .trim()
                      .replaceAll(" ", "_");
                    props._setMiniSearchTerm("");
                    props.SetMenuVisibility("MiniSearch", false);
                    //  console.log(props.DateSearchURL);
                    //  console.log(dayjs(sterm));
                    //  console.log(dayjs(sterm).isValid());
                    if (
                      props.DateSearchURL !== undefined &&
                      ((sterm.includes("/") &&
                        dayjs(sterm, "DD/MMM/YYYY", true).isValid()) ||
                        (sterm.includes("-") &&
                          dayjs(sterm, "DD-MM-YYYY", true).isValid()))
                    ) {
                      props.Navigation(
                        props.DateSearchURL + dayjs(sterm).format("DD-MM-YYYY"),
                      );
                    } else {
                      props.Navigation(props.TextSearchURL + sterm);
                    }
                  }
                }}
              ></input>
            </div>
            <div className="MenuItem Inert MiniSearchInputRack">
              <Button
                onClick={() => {
                  const sterm = props._miniSearchTerm
                    .trim()
                    .replaceAll(" ", "_");
                  props._setMiniSearchTerm("");
                  props.SetMenuVisibility("MiniSearch", false);
                  if (
                    props.DateSearchURL !== undefined &&
                    ((sterm.includes("/") &&
                      dayjs(sterm, "DD/MMM/YYYY", true).isValid()) ||
                      (sterm.includes("-") &&
                        dayjs(sterm, "DD-MM-YYYY", true).isValid()))
                  ) {
                    props.Navigation(
                      props.DateSearchURL + dayjs(sterm).format("DD-MM-YYYY"),
                    );
                  } else {
                    props.Navigation(props.TextSearchURL + sterm);
                  }
                }}
              >
                <label htmlFor="MiniSearchInput">Search</label>
              </Button>
              <Button
                onClick={() => {
                  props._setMiniSearchTerm("");
                }}
              >
                Clear
              </Button>
            </div>
            <IfPermitted Whitelist={props.InnerWhitelist}>
              <div className="MenuItem ExternalLink">
                <a
                  href="https://essexuniversity.sharepoint.com/sites/EDRM/SitePages/Search.aspx"
                  target="_blank"
                >
                  Go to EDRM Hub Search{" "}
                  <ArrowUpRightFilled></ArrowUpRightFilled>
                </a>
              </div>
            </IfPermitted>
          </div>
        </div>
      </div>
    </IfPermitted>
  );
}
