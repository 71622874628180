import { getIconClassName } from "@fluentui/react";
import * as React from "react";

import "../../assets/css/UGEAF.css";
import { GridWrapper } from "../../integrations/UoE-Grid/GridWrapper";
import ModeStruct from "../../models/ModeStruct";
import SPOUploadLocation from "../../models/SPOUploadLocation";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess, User } from "../../data/DataAccess";
import { Logging } from "../../Logging";
import { UploadManager } from "../../integrations/SPUploadManager/UploadManager";
import IfPermitted from "../IfPermitted";
import { IMsalContext } from "@azure/msal-react";
import { InvarDataAccess } from "../../data/InvarDataAccess";

interface IProps {
  MSAL: IMsalContext;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  Logger: Logging;
  da: DataAccess;
  IDA: InvarDataAccess;
  PCA: PublicClientApplication;
  GraphAccessToken: string;
  URLRoot: string;
  URLParams: string;
  UCASNo: string;
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  AuthedUser: User;
  ViewAsUser: User;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  GetSPUL: (shortname: string) => SPOUploadLocation;
  SetSearch: (SearchString: string) => void;
  SetTaskList: (TLRef: string) => void;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

interface IState {
  Tab: string;
  ShowUploader: boolean;
}

class UGEAF extends React.Component<IProps, IState> {
  private _edcCTs: string[] = ["uaducasform"];

  constructor(props) {
    super(props);

    this.state = {
      Tab: "FeeStatus",
      ShowUploader: false,
    };
  }

  render() {
    var PassToAllGrids = {
      Callouts: true,
      Logger: this.props.Logger,
      da: this.props.da,
      PCA: this.props.PCA,
      GetLink: this.props.GetLink,
      URLRoot: this.props.URLRoot,
      URLParams: this.props.URLParams,
      ucasno: this.props.UCASNo.toUpperCase(),
      class: "UoE-Grid EAF",
      Alias: this.props.Alias,
      AliasChecked: this.props.AliasChecked,
      ViewAsAll: this.props.ViewAsAll,
      Refresher: this.props.Refresher,
      RegisterError: this.props.RegisterError,
      DeregisterError: this.props.DeregisterError,
      RegisterStatus: this.props.RegisterStatus,
      DeregisterStatus: this.props.DeregisterStatus,
    };

    return (
      <div className="UGEAF">
        <div className="SectionRow">
          <span id="HeaderTop">
            <GridWrapper
              {...PassToAllGrids}
              eid={"HeaderTop_" + this.props.Refresher}
              sproc="sputilities.webui.UGEAF_SUMMARY_applicant_header_top"
              params={["@ucasno", this.props.UCASNo.toUpperCase()]}
              output="data"
            ></GridWrapper>
          </span>
          <span id="ApplicantWarning">
            <GridWrapper
              {...PassToAllGrids}
              eid={"ApplicantWarning_" + this.props.Refresher}
              sproc="sputilities.webui.UGEAF_SUMMARY_applicant_warning"
              params={[
                "@ucasno",
                this.props.UCASNo.toUpperCase(),
                "@username",
                "{%iu}",
              ]}
              output="data"
            ></GridWrapper>
          </span>
        </div>

        <div className="SectionRow">
          <div className="Segmentary">
            <div className="Segments">
              <h2 className="Segment NoBreak">
                <i
                  className={getIconClassName("GroupedList")}
                  aria-hidden="true"
                ></i>{" "}
                Course Applications
              </h2>
              <div className="Segment Righter">
                <span className="Example">Entry Year Example: </span>
                <span className="CurrentExample PASExample">
                  Current Priority Agent Application
                </span>
                <span className="CurrentExample">Current</span>
                <span className="DeferredExample PASExample">
                  Deferred Priority Agent Application
                </span>
                <span className="DeferredExample">Deferred</span>
                <span className="PastExample PASExample">
                  Past Priority Agent Application
                </span>
                <span className="PastExample">Past</span>
              </div>
            </div>
          </div>
          <span id="ChoicesOffers">
            <GridWrapper
              {...PassToAllGrids}
              eid={"ChoicesOffers_" + this.props.Refresher}
              sproc="sputilities.webui.UGEAF_SUMMARY_Choices_And_Offers_api"
              params={[
                "@ucasno",
                this.props.UCASNo.toUpperCase(),
                "@username",
                "{%iu}",
              ]}
              columnclass="DRF,DRF"
            ></GridWrapper>
          </span>
        </div>

        <div className="ColumnLeft">
          <div className="SectionRow">
            <div className="VerticalTabZone">
              <div
                className={
                  "Tab" + (this.state.Tab === "FeeStatus" ? " Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "FeeStatus" });
                }}
              >
                <i
                  className={getIconClassName("AllCurrency")}
                  aria-hidden="true"
                ></i>{" "}
                Fee Status
              </div>
              <div
                className={
                  "Tab" + (this.state.Tab === "StandardChecks" ? " Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "StandardChecks" });
                }}
              >
                <i
                  className={getIconClassName("ReceiptCheck")}
                  aria-hidden="true"
                ></i>{" "}
                Standard Checks
              </div>
              <div
                className={
                  "Tab" + (this.state.Tab === "ContactDetails" ? " Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "ContactDetails" });
                }}
              >
                <i
                  className={getIconClassName("ContactCard")}
                  aria-hidden="true"
                ></i>{" "}
                Contact Details
              </div>
              <div
                className={
                  "Tab" + (this.state.Tab === "Qualifications" ? " Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "Qualifications" });
                }}
              >
                <i
                  className={getIconClassName("Education")}
                  aria-hidden="true"
                ></i>{" "}
                Qualifications
              </div>
              <div
                className={
                  "Tab" + (this.state.Tab === "References" ? " Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "References" });
                }}
              >
                <i className={getIconClassName("Flow")} aria-hidden="true"></i>{" "}
                References
              </div>
              <div
                className={
                  "Tab" +
                  (this.state.Tab === "PersonalStatement" ? " Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "PersonalStatement" });
                }}
              >
                <i
                  className={getIconClassName("TextDocumentShared")}
                  aria-hidden="true"
                ></i>{" "}
                Personal Statement
              </div>
              <div
                className={
                  "Tab" + (this.state.Tab === "Education" ? " Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "Education" });
                }}
              >
                <i
                  className={getIconClassName("WorkforceManagement")}
                  aria-hidden="true"
                ></i>{" "}
                Education
              </div>
              <div
                className={
                  "Tab" + (this.state.Tab === "Employment" ? " Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "Employment" });
                }}
              >
                <i className={getIconClassName("Work")} aria-hidden="true"></i>{" "}
                Employment
              </div>
              <div
                className={
                  "Tab" +
                  (this.state.Tab === "EnglishLanguage" ? " Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "EnglishLanguage" });
                }}
              >
                <i
                  className={getIconClassName("ActivityFeed")}
                  aria-hidden="true"
                ></i>{" "}
                English Language
              </div>
              <div
                className={
                  "Tab" +
                  (this.state.Tab === "OtherInformation" ? " Active" : "")
                }
                onClick={() => {
                  this.setState({ Tab: "OtherInformation" });
                }}
              >
                <i className={getIconClassName("Info")} aria-hidden="true"></i>{" "}
                Other Information
              </div>
            </div>
            <div className="TabbedControls">
              <div
                id="FeeStatus"
                hidden={this.state.Tab !== "FeeStatus"}
                className="TabContent"
              >
                <GridWrapper
                  {...PassToAllGrids}
                  eid={"FeeStatus_" + this.props.Refresher}
                  sproc="sputilities.webui.UGEAF_SUMMARY_applicant_feestatus_api"
                  params={["@ucasno", this.props.UCASNo.toUpperCase()]}
                  output="data"
                ></GridWrapper>
              </div>
              <div
                id="StandardChecks"
                hidden={this.state.Tab !== "StandardChecks"}
                className="TabContent"
              >
                <GridWrapper
                  {...PassToAllGrids}
                  eid={"StandardChecks_" + this.props.Refresher}
                  sproc="sputilities.webui.UGEAF_SUMMARY_applicant_checks"
                  params={[
                    "@ucasno",
                    this.props.UCASNo.toUpperCase(),
                    "@username",
                    "{%iu}",
                  ]}
                  output="data"
                ></GridWrapper>
              </div>
              <div
                id="ContactDetails"
                hidden={this.state.Tab !== "ContactDetails"}
                className="TabContent"
              >
                <GridWrapper
                  {...PassToAllGrids}
                  eid={"ContactDetails_" + this.props.Refresher}
                  sproc="sputilities.webui.UGEAF_SUMMARY_applicant_contacts"
                  params={["@ucasno", this.props.UCASNo.toUpperCase()]}
                  output="data"
                  rename="E-mail,Email"
                ></GridWrapper>
              </div>
              <div
                id="Qualifications"
                hidden={this.state.Tab !== "Qualifications"}
                className="TabContent"
              >
                <GridWrapper
                  {...PassToAllGrids}
                  eid={"Qualifications_" + this.props.Refresher}
                  sproc="sputilities.webui.UGEAF_SUMMARY_applicant_quals"
                  params={["@ucasno", this.props.UCASNo.toUpperCase()]}
                  widths="entry_date,45"
                  columnclass="subject_or_course,pre padded;level_text,pre padded"
                  rename="QualsHeader,SUBHEADER;QualsLegendText,SUBHEADER"
                ></GridWrapper>
              </div>
              <div
                id="References"
                hidden={this.state.Tab !== "References"}
                className="TabContent"
              >
                <GridWrapper
                  {...PassToAllGrids}
                  eid={"References_" + this.props.Refresher}
                  sproc="sputilities.webui.UGEAF_SUMMARY_applicant_refs"
                  params={["@ucasno", this.props.UCASNo.toUpperCase()]}
                  output="data"
                ></GridWrapper>
              </div>
              <div
                id="PersonalStatement"
                hidden={this.state.Tab !== "PersonalStatement"}
                className="TabContent"
              >
                <GridWrapper
                  {...PassToAllGrids}
                  eid={"PersonalStatement_" + this.props.Refresher}
                  sproc="sputilities.webui.UGEAF_SUMMARY_applicant_ps"
                  params={["@ucasno", this.props.UCASNo.toUpperCase()]}
                  output="data"
                ></GridWrapper>
              </div>
              <div
                id="Education"
                hidden={this.state.Tab !== "Education"}
                className="TabContent"
              >
                <GridWrapper
                  {...PassToAllGrids}
                  eid={"Education_" + this.props.Refresher}
                  sproc="sputilities.webui.UGEAF_SUMMARY_applicant_education"
                  params={["@ucasno", this.props.UCASNo.toUpperCase()]}
                  rename="EducationHeader,SUBHEADER"
                ></GridWrapper>
              </div>
              <div
                id="Employment"
                hidden={this.state.Tab !== "Employment"}
                className="TabContent"
              >
                <GridWrapper
                  {...PassToAllGrids}
                  eid={"Employment_" + this.props.Refresher}
                  sproc="sputilities.webui.UGEAF_SUMMARY_applicant_employment"
                  params={["@ucasno", this.props.UCASNo.toUpperCase()]}
                  rename="EmploymentHeader,SUBHEADER;date_from_hidden,_DF"
                ></GridWrapper>
              </div>
              <div
                id="EnglishLanguage"
                hidden={this.state.Tab !== "EnglishLanguage"}
                className="TabContent"
              >
                <GridWrapper
                  {...PassToAllGrids}
                  eid={"EnglishLanguage_" + this.props.Refresher}
                  sproc="sputilities.webui.UGEAF_SUMMARY_applicant_englang"
                  params={["@ucasno", this.props.UCASNo.toUpperCase()]}
                  output="data"
                ></GridWrapper>
              </div>
              <div
                id="OtherInformation"
                hidden={this.state.Tab !== "OtherInformation"}
                className="TabContent"
              >
                <GridWrapper
                  {...PassToAllGrids}
                  eid={"OtherInformation_" + this.props.Refresher}
                  sproc="sputilities.webui.UGEAF_SUMMARY_applicant_otherinfo"
                  params={["@ucasno", this.props.UCASNo.toUpperCase()]}
                  output="data"
                ></GridWrapper>
              </div>
            </div>
          </div>

          <div className="SectionRow">
            <h2>
              <i
                className={getIconClassName("Documentation")}
                aria-hidden="true"
              ></i>{" "}
              eDocs
            </h2>
            <IfPermitted Whitelist={["UGEAF.Uploader"]}>
              <div
                className="UploadButton"
                onClick={() => {
                  this.setState({ ShowUploader: !this.state.ShowUploader });
                }}
              >
                <i className={getIconClassName("Add")} aria-hidden="true"></i>{" "}
                Upload New Document(s)
              </div>
            </IfPermitted>
            <div className="JustASpacer"></div>
            {this.state.ShowUploader === false ? (
              <GridWrapper
                {...PassToAllGrids}
                eid={"eDocs_" + this.props.Refresher}
                ForceProxy={false}
                output="edocs"
                group="Document Type"
                sort="Created Date"
                sortdesc="true"
                target="_blank"
                contentTypes={this._edcCTs}
                blackList={true}
              ></GridWrapper>
            ) : (
              <UploadManager
                UploadConveyance={"UGEAF Direct Upload --> SPO"}
                Autofill={{
                  Type: "UCAS / DA Number",
                  Value: PassToAllGrids.ucasno,
                }}
                SPUL={this.props.GetSPUL("UGEAF")}
                SwitchBack={() => {
                  this.setState({ ShowUploader: false });
                }}
                IncludeRUG={false}
                {...PassToAllGrids}
                IDA={this.props.IDA}
                Environment={this.props.Environment}
                GraphAccessToken={this.props.GraphAccessToken}
                Refresh={this.props.Refresh}
              />
            )}
          </div>
        </div>

        <div className="ColumnRight">
          <div className="SectionRow">
            <h2>
              <i className={getIconClassName("Contact")} aria-hidden="true"></i>{" "}
              Applicant Information
            </h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"ApplicantInformationData_" + this.props.Refresher}
              sproc="sputilities.webui.UGEAF_SUMMARY_applicant_header"
              params={["@ucasno", this.props.UCASNo.toUpperCase()]}
              output="data"
              rename="Nameheader,_N;app_origin,_AO"
            ></GridWrapper>
            <GridWrapper
              {...PassToAllGrids}
              eid={"ApplicantInformationDocs_" + this.props.Refresher}
              output="special-ucasapplicationform"
            ></GridWrapper>
          </div>
          <div className="SectionRow">
            <h2>
              <i
                className={getIconClassName("EngineeringGroup")}
                aria-hidden="true"
              ></i>{" "}
              Workflows in Progress
            </h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"WorkflowsInProgress_" + this.props.Refresher}
              sproc="sputilities.webui.UGEAF_SUMMARY_Task_Pane_In_Progress"
              params={["@EK1", this.props.UCASNo.toUpperCase()]}
            ></GridWrapper>
          </div>
          <div className="SectionRow">
            <h2>
              <i
                className={getIconClassName("Completed")}
                aria-hidden="true"
              ></i>{" "}
              Workflows Completed
            </h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"WorkflowsCompleted_" + this.props.Refresher}
              sproc="sputilities.webui.UGEAF_SUMMARY_Task_Pane_Complete"
              params={[
                "@EK1",
                this.props.UCASNo.toUpperCase(),
                "@user",
                "{%iu}",
              ]}
              columnclass="Workflow,WorkflowCompleted"
            ></GridWrapper>
          </div>
          <div className="SectionRow">
            <h2>
              <i className={getIconClassName("Mail")} aria-hidden="true"></i>{" "}
              Automated Email Correspondence
            </h2>
            <GridWrapper
              {...PassToAllGrids}
              eid={"AutomatedEmailCorrespondence_" + this.props.Refresher}
              sproc="sputilities.webui.ug_eaf_get_applicant_emails"
              // sproc="sputilities.webui.UGEAF_SUMMARY_get_applicant_emails"
              // needs to be changed once new sproc name added to edocs config??
              params={["@ucasno", this.props.UCASNo.toUpperCase()]}
              sort="Email_Sent"
              sortdesc="true"
              rename="subject,Subject;status,Status;detail_link,Subject_URL"
              reorder="Subject"
            ></GridWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default UGEAF;
