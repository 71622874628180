import { GridWrapper } from "../integrations/UoE-Grid/GridWrapper";
import { PublicClientApplication } from "@azure/msal-browser";
import ModeStruct from "../models/ModeStruct";
import SPOUploadLocation from "../models/SPOUploadLocation";
import React from "react";
import { Logging } from "../Logging";
import { DataAccess, User } from "../data/DataAccess";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  URLRoot: string;
  URLParams: string;
  Refresher: string;
  PRID: string;
  wfid: string;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

interface IState {
  Tab: string;
  ShowUploader: boolean;
}

// export function WorkflowInfoPath(props: IProps) {
//   let { wfid } = useParams();
//   useEffect(() => {
//     (async () => {
//   props.da.get(
//     "/sproc",
//     JSON.stringify({
//       sproc: "sputilities.webui.ESF_SUMMARY_Workflow_Infopath",
//       params: ["@WfGUID", wfid],
//     }),
//       );
//     })();
//   }, []);

export class WorkflowViewInfoPath extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      Tab: "Infopath_Workflow",
      ShowUploader: false,
    };
  }

  render() {
    var PassToAllGrids = {
      Callouts: true,
      Logger: this.props.Logger,
      da: this.props.da,
      prid: this.props.PRID.toUpperCase(),
      wfid: this.props.wfid,
      PCA: this.props.PCA,
      GetLink: this.props.GetLink,
      URLRoot: this.props.URLRoot,
      URLParams: this.props.URLParams,
      class: "UoE-Grid ESF",
      Refresher: this.props.Refresher,
      Alias: this.props.Alias,
      AliasChecked: this.props.AliasChecked,
      ViewAsAll: this.props.ViewAsAll,
      RegisterError: this.props.RegisterError,
      DeregisterError: this.props.DeregisterError,
      RegisterStatus: this.props.RegisterStatus,
      DeregisterStatus: this.props.DeregisterStatus,
    };

    return (
      // Header Section
      // ======================
      //
      // Workflow_Name_and_GUID
      // Workflow_Initiation_Date
      // Advisory_Note
      // ESF_Student_Details
      // Faculty_and_Dept

      // Main Body
      // ======================
      //
      // Task Name
      // Assigned_To
      // Input_or_Result
      // DateTime_Recorded
      // Completed_by_username

      <div className="ESFScreen">
        <div className="SectionRow">
          <span id="WFBasicInfoTop">
            <GridWrapper
              {...PassToAllGrids}
              eid={"InfopathWFTop_" + this.props.Refresher}
              sproc="sputilities.webui.ESF_SUMMARY_Workflow_Infopath_Top"
              params={["@WfGUID", this.props.wfid]}
              output="data"
            ></GridWrapper>
          </span>
        </div>
        <div className="SectionRow">
          <span id="WFData">
            <GridWrapper
              {...PassToAllGrids}
              eid={"InfopathWF_" + this.props.Refresher}
              sproc="sputilities.webui.ESF_SUMMARY_Workflow_Infopath"
              params={["@WfGUID", this.props.wfid]}
              //   output="data"
            ></GridWrapper>
          </span>
        </div>
      </div>
    );
  }
}
