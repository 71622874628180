import * as React from "react";
import { IItem } from "../../models/IItem";
import { toTitleCase, sortItems } from "./Functions";

interface IProps {
  eid: string;
  items: IItem[];
  sort?: string;
  sortdesc?: string;
  target?: string;
  ButtonsAndFunctions?: {
    Name: string;
    ColumnKey: string;
    Function: (id: any) => void;
  }[];
}

interface IState {
  items: IItem[];
}

class ListContent extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      items: sortItems(this.props.items, this.props.sort, this.props.sortdesc),
    };
  }

  public render() {
    return (
      <div className={"UoE-DataList"}>
        {this.state.items.map((item, ii) => (
          <ul key={ii} className="DataList">
            {Object.keys(item)
              .filter(
                (x) =>
                  x.charAt(0) !== "_" &&
                  !x.endsWith("_URL") &&
                  !x.endsWith("_LINK") &&
                  !x.endsWith("_CSS") &&
                  !x.endsWith("_CLASS") &&
                  !x.endsWith("_FUNC"),
              )
              .map((key, i) => (
                <li
                  key={i}
                  className={"DataRow ".concat(
                    key.trim(),
                    item[key + "_CSS"] !== undefined &&
                      item[key + "_CSS"].trim() !== ""
                      ? " " + item[key + "_CSS"]
                      : "",
                    item[key + "_CLASS"] !== undefined &&
                      item[key + "_CLASS"].trim() !== ""
                      ? " " + item[key + "_CLASS"]
                      : "",
                    " ",
                    item[key].trim() === "" || item[key].trim() === "-"
                      ? "BLANK"
                      : item[key].trim(),
                  )}
                >
                  <em
                    className={"column-name ".concat(key.trim())}
                    dangerouslySetInnerHTML={{
                      __html: toTitleCase(key.replace(/_/g, " ").trim()) + ":",
                    }}
                  />
                  {item[key + "_FUNC"] !== undefined &&
                  item[key].trim() === key ? (
                    <button
                      className="InducedFunction"
                      onClick={() => {
                        this.props.ButtonsAndFunctions.find(
                          (th) => th.Name === item[key].trim(),
                        ).Function(
                          item[
                            this.props.ButtonsAndFunctions.find(
                              (th) => th.Name === item[key].trim(),
                            ).ColumnKey
                          ],
                        );
                      }}
                      dangerouslySetInnerHTML={{ __html: item[key] }}
                    />
                  ) : (item[key + "_LINK"] !== undefined &&
                      item[key + "_LINK"].trim() !== "") ||
                    (item[key + "_URL"] !== undefined &&
                      item[key + "_URL"].trim() !== "") ? (
                    <span className="column-value">
                      <a
                        href={
                          item[key + "_LINK"] !== undefined
                            ? item[key + "_LINK"]
                            : item[key + "_URL"] !== undefined
                              ? item[key + "_URL"]
                              : ""
                        }
                        target={
                          item[key + "_TARGET"]
                            ? item[key + "_TARGET"]
                            : this.props.target
                              ? this.props.target
                              : ""
                        }
                        dangerouslySetInnerHTML={{ __html: item[key] }}
                      />
                    </span>
                  ) : item[key].match(/^\d{4}-\d{2}-\d{2}/) &&
                    !Number.isNaN(Date.parse(item[key])) ? (
                    <span className="column-value">
                      {new Date(item[key]).getHours() === 0 &&
                      new Date(item[key]).getMinutes() === 0 &&
                      new Date(item[key]).getSeconds() === 0
                        ? new Date(item[key]).toLocaleDateString()
                        : new Date(item[key]).toLocaleString()}
                    </span>
                  ) : (
                    <span
                      className="column-value"
                      dangerouslySetInnerHTML={{ __html: item[key] }}
                    />
                  )}
                </li>
              ))}
          </ul>
        ))}
      </div>
    );
  }
}

export default ListContent;
