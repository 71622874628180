import Notice from "../../../models/Notice";
import { NoticeBlock } from "./NoticeBlock";

interface IProps {
  Notice: Notice;
  DeleteNotice: (_noticeID: string) => void;
  EditNotice: (_noticeID: string) => void;
}

export const NoticeRow = (props: IProps) => {
  return <NoticeBlock {...props}></NoticeBlock>;
};

export default NoticeRow;
