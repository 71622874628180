import * as React from "react";
import Timestamp from "./Timestamp";
import { Toggles } from "./Toggles";

interface IProps {
  Toggles: Toggles;
  Object: any;
}

class TLEntryAccessHistory extends React.Component<IProps> {
  public render() {
    return (
      <div className="AccessEntry">
        <div className="Icon">&#128065;</div>
        <div className="Label">Lock</div>
        <Timestamp
          Toggles={this.props.Toggles}
          Timestamp={this.props.Object["Timestamp"]}
        />
        {this.props.Object["Subtype"] === "Locked" ? "Locked" : "Unlocked"} by{" "}
        <b title={this.props.Object["RelevantUser"]}>
          {this.props.Object["RelevantUserLabel"]}
        </b>
      </div>
    );
  }
}

export default TLEntryAccessHistory;
