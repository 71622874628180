import { PublicClientApplication } from "@azure/msal-browser";
import { Logging } from "../Logging";
import { User, DataAccess } from "../data/DataAccess";
import ModeStruct from "../models/ModeStruct";
import SPOUploadLocation from "../models/SPOUploadLocation";
import TaskListGroup from "../models/TaskListGroup";
import TaskLists from "./TaskLists";
import { useLoaderData, useLocation } from "react-router-dom";

interface IProps {
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  AuthedUser: User;
  ViewAsUser: User;
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  URLRoot: string;
  URLParams: string;
  TaskListGroups: TaskListGroup[] | undefined;
  Refresher: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

export const TaskListWrapper = (props: IProps) => {
  const loader: any = useLoaderData();

  return (
    <TaskLists
      {...props}
      TaskList={loader.Tasklist || ""}
      Parameter1={loader.Parameter1}
      Parameter2={loader.Parameter2}
    />
  );
};
