import { useContext, useEffect, useState } from "react";
import { InvarDataAccess } from "../../data/InvarDataAccess";
import { MondrianDataProvider } from "../Mondrian/MondrianDataProvider";
import { Mondrian } from "../Mondrian/Mondrian";
import { MondrianDisplayType } from "../Mondrian/Structs/Enums/MondrianDisplayType";
import { MondrianColumn } from "../Mondrian/Structs/Types/MondrianStructuredData";
import { BrokerageResponse } from "../../data/InvarDataModels/BrokerageResponse";
import { ConveyanceResponse } from "../../data/InvarDataModels/ConveyanceResponse";

interface IProps {
  IDA: InvarDataAccess;
}

type StructuredConveyance = {
  ConveyanceID: number;
  Name: string;
  View: JSX.Element;
  ActiveAction: JSX.Element;
  Machines: JSX.Element;
};

export const InvarTopology = (props: IProps) => {
  const IDA = props.IDA;

  const [TheseBrokerages, setTheseBrokerages] = useState(
    Array<BrokerageResponse>,
  );
  const [BrokeragesLoaded, setBrokeragesLoaded] = useState(false);

  const [TheseConveyances, setTheseConveyances] = useState(
    Array<ConveyanceResponse>,
  );
  const [ConveyancesLoaded, setConveyancesLoaded] = useState(false);

  const [StructuredConveyances, setStructuredConveyances] = useState(
    Array<StructuredConveyance>,
  );

  useEffect(() => {
    if (IDA !== undefined) {
      //  console.log(TheseConveyances);
      if (!ConveyancesLoaded) {
        setConveyancesLoaded(true);
        IDA.GetConveyances().then((data) => {
          setTheseConveyances(data);
        });
      }
      if (!BrokeragesLoaded) {
        setBrokeragesLoaded(true);
        IDA.GetBrokerages().then((data) => {
          setTheseBrokerages(data);
        });
      }
    }
  }, [IDA]);

  //  console.log(ConveyancesLoaded);
  //  console.log(BrokeragesLoaded);

  const MondrianColumns: MondrianColumn[] = [
    { HumanName: "ID", MachineNames: ["ConveyanceID"] },
    { HumanName: "Name", MachineNames: ["Name"] },
    { HumanName: "View", MachineNames: ["View"] },
    { HumanName: "Status", MachineNames: ["ActiveAction"] },
    { HumanName: "Machines", MachineNames: ["Machines"] },
  ];

  const [_CreateConveyanceName, setCreateConveyanceName] = useState("");

  useEffect(() => {
    const StructuredConveyances: StructuredConveyance[] = [];

    TheseConveyances.sort((l, r) => {
      return l.ConveyanceID - r.ConveyanceID;
    }).forEach((conveyance, i) => {
      const Machines = (
        <div className="MultiMachine">
          {TheseBrokerages.filter(
            (brokerage) => brokerage.ConveyanceID === conveyance.ConveyanceID,
          )
            .sort((l, r) => {
              return l.MachineName.localeCompare(r.MachineName);
            })
            .map((brokerage, j) => {
              return (
                <div
                  key={"Brokerage_" + j.toString()}
                  className="SingleMachine"
                >
                  <span className="Environment">{brokerage.Environment}</span>
                  <span className="MachName">{brokerage.MachineName}</span>
                  {brokerage.Active ? (
                    <span
                      className="Mondrian_Button Split"
                      onClick={() => {
                        IDA.DisableBrokerage(brokerage.BrokerageID).then(
                          (res) => {
                            setTheseBrokerages(
                              [...TheseBrokerages]
                                .filter(
                                  (th) => th.BrokerageID !== res.BrokerageID,
                                )
                                .concat(res),
                            );
                          },
                        );
                      }}
                    >
                      <span className="Mondrian__Check_True">✔</span>
                      <span>Disable</span>
                    </span>
                  ) : (
                    <span
                      className="Mondrian_Button Split"
                      onClick={() => {
                        IDA.EnableBrokerage(brokerage.BrokerageID).then(
                          (res) => {
                            setTheseBrokerages(
                              [...TheseBrokerages]
                                .filter(
                                  (th) => th.BrokerageID !== res.BrokerageID,
                                )
                                .concat(res),
                            );
                          },
                        );
                      }}
                    >
                      <span className="Mondrian__Check_False Bump">✘</span>
                      <span>Enable</span>
                    </span>
                  )}
                </div>
              );
            })}
        </div>
      );

      StructuredConveyances.push({
        ConveyanceID: conveyance.ConveyanceID,
        View: (
          <a
            className="Mondrian_Button Split"
            href={
              "/AdminPortal/Invar/Conveyance/" +
              conveyance.ConveyanceID.toString()
            }
            target="_blank"
          >
            <span className="Mondrian__Icon_Neutral Bump">⇗</span>
            <span>View Structure</span>
          </a>
        ),
        Name: conveyance.Name,
        ActiveAction: conveyance.Active ? (
          <span
            className="Mondrian_Button Split"
            onClick={() => {
              IDA.DisableConveyance(conveyance.ConveyanceID).then((res) => {
                setTheseConveyances(
                  [...TheseConveyances]
                    .filter((th) => th.ConveyanceID !== res.ConveyanceID)
                    .concat(res),
                );
              });
            }}
          >
            <span className="Mondrian__Check_True">✔</span>
            <span>Disable</span>
          </span>
        ) : (
          <span
            className="Mondrian_Button Split"
            onClick={() => {
              IDA.EnableConveyance(conveyance.ConveyanceID).then((res) => {
                setTheseConveyances(
                  [...TheseConveyances]
                    .filter((th) => th.ConveyanceID !== res.ConveyanceID)
                    .concat(res),
                );
              });
            }}
          >
            <span className="Mondrian__Check_False Bump">✘</span>
            <span>Enable</span>
          </span>
        ),
        Machines: Machines,
      });
    });

    setStructuredConveyances(StructuredConveyances);
  }, [TheseConveyances, TheseBrokerages]);

  return (
    <div className="InvarTopologySurface">
      <h2>Invar Topology</h2>
      <div className="Description">
        <p>Current Topology of all Invar Conveyances.</p>
      </div>
      <br />
      <MondrianDataProvider
        ObjectArray={StructuredConveyances}
        Columns={MondrianColumns}
        LoadingFlag={!(ConveyancesLoaded && BrokeragesLoaded)}
      >
        <Mondrian
          Options={{
            Display: MondrianDisplayType.Grid,
            DisplayOptions: { Alternator: true },
          }}
        ></Mondrian>
      </MondrianDataProvider>
    </div>
  );
};
