import { SignOutFilled } from "@fluentui/react-icons";
import { useMsal } from "@azure/msal-react";
import { EndSessionRequest } from "@azure/msal-browser";

export type SignOutCharmProps = {
  TailoredLogoutRequest: EndSessionRequest;
  SetImpersonation: (alias?: string) => void;
};

export function SignOutCharm(props: SignOutCharmProps) {
  const MSAL = useMsal();

  return (
    <div className="UserMenu SignOut">
      <a
        href="#"
        tabIndex={0}
        className="UserCharm SignOut"
        title="Sign Out"
        onClick={() => {
          props.SetImpersonation(undefined);
          MSAL.instance.logoutRedirect(props.TailoredLogoutRequest);
        }}
      >
        <SignOutFilled></SignOutFilled>
      </a>
    </div>
  );
}
