import * as React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess } from "../../data/DataAccess";
import { Logging } from "../../Logging";
import "../../assets/css/ADMIN_LF/Admin_LF.css";
import { Link } from "react-router-dom";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  Environment: NonNullable<string>;
  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Refresher: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;

  Embargo: (scope: string) => boolean;
  HasNotices: (AgentIsPAS: boolean, scopes: string[]) => boolean;
  Notices: (AgentIsPAS: boolean, scopes: string[]) => React.ReactFragment;

  Navigation: (url: string) => void;
}

interface IState {
  // functional state parts
  Errors: string[];
  _figuid: string;
}

class Admin_LF extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      Errors: [],
      _figuid: "",
    };
  }

  public async componentDidMount() {
    window.document.title = "University of Essex - Admin Portal";
  }

  render() {
    return (
      <React.Fragment>
        <div className="StructPanels">
          <div className="StructPanelCluster Row Halved">
            <a
              className="StructPanel"
              href={this.props.GetLink(
                [
                  { Mode: "AdminPortal", Index: 0 },
                  { Mode: "WAGE", Index: 1 },
                ],
                [],
              )}
            >
              <div className="PanelBar Title">
                <div className="Left">Workflow Assignment Group Editor</div>
                <div className="Breaker"></div>
                <div className="Right">Collagen</div>
              </div>
              <div className="PanelBody">
                Use this tool to edit Collagen Workflow Groups and Memberships.
              </div>
            </a>

            <a
              className="StructPanel"
              href={this.props.GetLink(
                [
                  { Mode: "AdminPortal", Index: 0 },
                  { Mode: "Permissions", Index: 1 },
                ],
                [],
              )}
            >
              <div className="PanelBar Title">
                <div className="Left">User Access Checker</div>
                <div className="Breaker"></div>
                <div className="Right">All Frontends</div>
              </div>
              <div className="PanelBody">
                Use this tool to examine a user's permissions and roles.
              </div>
            </a>
            <a
              className="StructPanel"
              href={this.props.GetLink(
                [
                  { Mode: "AdminPortal", Index: 0 },
                  { Mode: "Invar", Index: 1 },
                  { Mode: "Topology", Index: 2 },
                ],
                [],
              )}
            >
              <div className="PanelBar Title">
                <div className="Left">Topology</div>
                <div className="Breaker"></div>
                <div className="Right">Invar</div>
              </div>
              <div className="PanelBody">
                Examine and manage Invar Conveyances and the machines they run
                on.
              </div>
            </a>
            <a
              className="StructPanel"
              href={this.props.GetLink(
                [
                  { Mode: "AdminPortal", Index: 0 },
                  { Mode: "Invar", Index: 1 },
                  { Mode: "Monitor", Index: 2 },
                ],
                [],
              )}
            >
              <div className="PanelBar Title">
                <div className="Left">Monitor Work Orders</div>
                <div className="Breaker"></div>
                <div className="Right">Invar</div>
              </div>
              <div className="PanelBody">
                Monitor Invar work orders, filter, diagnose, archive, and
                re-run.
              </div>
            </a>
            <div className="StructPanel Cassette">
              <div className="PanelBar Title">
                <div className="Left">Workflow Summary</div>
                <div className="Breaker"></div>
                <div className="Right">Collagen</div>
              </div>
              <div className="PanelBody">
                <div id="DeclareUCASApplicants">
                  <p className="InputRow Wide">
                    <label htmlFor="Searcher">
                      Workflow diagnostic page: Enter Form Instance GUID
                      (?figuid= from form url).
                    </label>
                  </p>
                  <p className="InputRow Wide">
                    <input
                      type="text"
                      value={this.state._figuid}
                      id="Searcher"
                      name="Searcher"
                      onChange={(event) => {
                        this.setState({
                          _figuid: event.target.value,
                        });
                      }}
                      onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                          this.props.Navigation(
                            "/AdminPortal/WorkflowSummary/" +
                              this.state._figuid.trim(),
                          );
                        }
                      }}
                    />
                  </p>
                  <p className="SubmitRow Left">
                    {this.state._figuid === "" ? (
                      <a>Open Workflow</a>
                    ) : (
                      <Link
                        to={this.props.GetLink(
                          [
                            { Mode: "AdminPortal", Index: 0 },
                            {
                              Mode: "WorkflowSummary",
                              Index: 1,
                            },
                            {
                              Mode: this.state._figuid.trim(),
                              Index: 2,
                            },
                          ],
                          [],
                        )}
                      >
                        Open Workflow
                      </Link>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Admin_LF;
