import { useContext, useEffect, useState } from "react";
import { DataAccess, IParams, User } from "../../data/DataAccess";
import "../../assets/css/UploadManager.css";
import { IItem } from "../../models/IItem";
import { SPOFileState, SPOUploadFile } from "./SPInterfacing/SPOUploadFile";
import { Logging } from "../../Logging";
import { SPOContentType } from "./SPInterfacing/SPOContentType";
import SPOUploadLocation from "../../models/SPOUploadLocation";
import InternalUploadManager from "./InternalUploadManager";
import { SPOMValState, SPOMetadata } from "./SPInterfacing/SPOMetadata";
import { InvarDataAccess } from "../../data/InvarDataAccess";
import { GridWrapper } from "../UoE-Grid/GridWrapper";
import { PublicClientApplication } from "@azure/msal-browser";
import { useLocation } from "react-router-dom";
import { SURFACE, useSurface } from "../../Providers/SurfaceProvider";
import { useUser } from "../../Providers/UserProvider";
import { SPOMetadataField } from "./SPInterfacing/SPOMetadataField";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  IDA: InvarDataAccess;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  PCA?: PublicClientApplication;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  GraphAccessToken: string;
  URLRoot: string;
  URLParams: string;
  UploadConveyance: NonNullable<string>;
  Autofill?: { Type: string; Value: string };

  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  SPUL: SPOUploadLocation;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
  IncludeRUG: boolean;
  SwitchBack?: () => void;
  Refresher: string;
  Refresh: () => void;
}

export const UploadManager = (props: IProps) => {
  /* root statework */
  const userData = useUser();
  const [dragState, StatefullySetDragState] = useState(false);
  const _files: SPOUploadFile[] = [];
  const [files, StatefullySetFiles] = useState(_files);
  const _contenttypes: SPOContentType[] = [];
  const [contentTypes, StatefullySetContentTypes] = useState(_contenttypes);
  const [validatedIdents, setValidatedIdents] = useState<Map<string, any>>(
    new Map<string, any>(),
  );
  const Surface = useSurface();
  const StatefullySetFile = (file: SPOUploadFile) => {
    let fs = [...files];
    let fsin = fs.findIndex((th) => th.File === file.File);
    fs[fsin] = file;
    StatefullySetFiles(fs);
  };

  useEffect(() => {
    //  console.log("[files] useEffect()...");
    //  console.log(files);

    if (contentTypes !== undefined) {
      if (contentTypes.length === 1) {
        files.forEach((th) => {
          if (!th.MetaData.some((ith) => ith.DataName === "ContentTypeId")) {
            StatefullyUpdateFileContentType(th, contentTypes[0].contentTypeId);
          }
        });
      }
    }

    var files_pendval = files.filter((th) =>
      th.MetaData.some((ith) => ith.Validation === SPOMValState.Pending),
    );
    var files_already = files.filter(
      (th) => th.AlreadyUploadedChecked === false,
    );
    //  console.log(files_pendval);
    if (files_pendval.length > 0) {
      var firstfile_pendval = files_pendval[0];
      //  console.log(firstfile_pendval);
      var fields_pendval = firstfile_pendval.MetaData.filter(
        (th) => th.Validation === SPOMValState.Pending,
      );
      //  console.log(fields_pendval);
      if (fields_pendval.length > 0) {
        var firstfield_pendval = fields_pendval[0];
        //  console.log(firstfield_pendval);
        StatefullyValidateField(
          firstfile_pendval,
          firstfield_pendval.DataName,
          firstfield_pendval.DataValue,
        );
      }
    } else if (files_already.length > 0) {
      var firstfile_already = files_already[0];
      //  console.log(firstfield_pendval);
      StatefullyCheckIfAlreadyUploaded(firstfile_already);
    } else if (
      files.length > 0 &&
      files.every((th) => th.State === SPOFileState.Completed)
    ) {
      // if any files and all are succeeded,
      if (props.SwitchBack !== undefined) {
        props.SwitchBack();
      } else {
        props.Refresh();
      }
    }
  }, [files]);

  const [spor, StatefullySetSPOR] = useState({
    SPSiteRefID: 0,
    SPSiteName: "",
    LastVerified: new Date(),
  });

  /* useEffect ... [] calls when the props array is instantiated - it's basically an onLoad. */
  useEffect(() => {
    if (props.SPUL !== undefined) {
      const call = JSON.stringify({
        sproc: "sputilities.webui.getspositeref",
        params: [
          "@SPSiteName",
          "FY" + new Date().getFullYear().toString() + "-" + props.SPUL.Site,
        ],
      });
      props.da.get("/sproc", call).then((data) => {
        if (data !== undefined && data[0] !== undefined) {
          var th = data[0][0];
          StatefullySetSPOR({
            SPSiteRefID: th.SPSiteRefID,
            SPSiteName: th.SPSiteName,
            LastVerified: th.LastVerified,
          });
        }
      });
    }
  }, [props.SPUL]);
  /* useEffect ... [spor] calls only when spor is updated, which _should_ only be once (see above) */
  useEffect(() => {
    if (spor && spor.SPSiteName && props && props.SPUL && props.SPUL.List) {
      props.da
        .getContentTypes(
          "https://essexuniversity.sharepoint.com/sites/" + spor.SPSiteName,
          props.SPUL.List,
          props.UploadConveyance,
        )
        .then((data) => {
          if (data !== undefined && data[0] !== undefined) {
            var scts: SPOContentType[] = [];

            data.forEach((th: SPOContentType) => {
              scts.push(th);
            });

            scts.sort((a, b) => {
              return a.contentType.localeCompare(b.contentType);
            });

            StatefullySetContentTypes(scts);
          }
        });
    }
  }, [spor]);

  const StatefullyUpdateFileContentType = (
    _uf: SPOUploadFile,
    ctId: string,
  ) => {
    var stateparcel = { ..._uf };

    if (props !== undefined) {
      if (contentTypes !== undefined) {
        var ct = contentTypes.find((th) => th.contentTypeId === ctId);

        stateparcel = ReferentiallyTransposeFileMetadata(stateparcel, ct);

        stateparcel = ReferentiallyUpdateFileMetadata(
          stateparcel,
          "ContentTypeId",
          ct?.contentTypeId,
        );

        stateparcel = ReferentiallyUpdateFileMetadata(
          stateparcel,
          "ContentType",
          ct?.contentType,
        );

        if (props.Autofill !== undefined) {
          stateparcel = ReferentiallyAutofill(stateparcel, props.Autofill);
        }
      }
    }

    stateparcel = ReferentiallyValidateFields(stateparcel);

    StatefullySetFile(stateparcel);
  };

  const ReferentiallyTransposeFileMetadata = (
    _uf: SPOUploadFile,
    ct?: SPOContentType,
  ) => {
    var returner = { ..._uf };

    if (ct !== undefined) {
      returner.MetaDataFields = ct.metadataFields;
    } else {
      returner.MetaDataFields = [];
    }

    var clearKeys: string[] = [];

    returner.Errors?.forEach((th) => {
      if (th.ErrorName !== "UploadedBy") {
        if (
          returner.MetaDataFields?.every(
            (ith) => ith.internalName !== th.ErrorName,
          )
        ) {
          if (!clearKeys.some((ith) => ith === th.ErrorName)) {
            clearKeys.push(th.ErrorName);
          }
        }
      }
    });

    returner.MetaData?.forEach((th) => {
      if (th.DataName !== "UploadedBy") {
        if (
          returner.MetaDataFields?.every(
            (ith) => ith.internalName !== th.DataName,
          )
        ) {
          if (!clearKeys.some((ith) => ith === th.DataName)) {
            clearKeys.push(th.DataName);
          }
        }
      }
    });

    clearKeys.forEach((clearKey) => {
      returner.MetaData = returner.MetaData.filter(
        (th) => th.DataName !== clearKey,
      );
      returner.Errors = returner.Errors.filter(
        (th) => th.ErrorName !== clearKey,
      );
    });

    returner.MetaDataFields.forEach((th) => {
      if (th.type === "Boolean") {
        if (
          !returner.MetaData.some((ith) => ith.DataName === th.internalName)
        ) {
          returner.MetaData = returner.MetaData.concat({
            DataName: th.internalName,
            DataValue: false,
            Validation: SPOMValState.None,
          });
        }
      }
    });

    return returner;
  };

  const ReferentiallyUpdateFileMetadata = (
    _uf: SPOUploadFile,
    name: string,
    value: string,
  ): SPOUploadFile => {
    var returner = { ..._uf };
    var _val: SPOMValState = SPOMValState.None;

    var _file = files.find((th) => th.File === returner.File);
    var _oldmd = _file.MetaData.find((th) => th.DataName === name);

    const names = [
      "esf_prid",
      "edocs_PGNO",
      "edocs_UCASNo",
      "edocs_ClearingRefNo",
      "edocs_RegNo",
    ];

    if (
      names.includes(name) &&
      value !== undefined &&
      value !== "" &&
      (_oldmd === undefined ||
        (_oldmd && _oldmd.DataValue && value !== _oldmd.DataValue))
    ) {
      _val = SPOMValState.Pending;
    }

    returner.MetaData = [...returner.MetaData]
      .filter((th) => th.DataName !== name)
      .concat({ DataName: name, DataValue: value, Validation: _val });

    return returner;
  };

  const ReferentiallyUpdateFileErrors = (
    _uf: SPOUploadFile,
    name: string,
    value: string,
  ): SPOUploadFile => {
    var returner = { ..._uf };

    returner.Errors = [...returner.Errors]
      .filter((th) => th.ErrorName !== name)
      .concat({ ErrorName: name, ErrorValue: value });

    return returner;
  };

  const ReferentiallyRemoveFileMetadata = (
    _uf: SPOUploadFile,
    name: string,
  ): SPOUploadFile => {
    var returner = { ..._uf };

    returner.MetaData = [...returner.MetaData].filter(
      (th) => th.DataName !== name,
    );

    return returner;
  };

  const ReferentiallyRemoveFileErrors = (
    _uf: SPOUploadFile,
    name: string,
  ): SPOUploadFile => {
    var returner = { ..._uf };

    returner.Errors = [...returner.Errors].filter(
      (th) => th.ErrorName !== name,
    );

    return returner;
  };

  const ReferentiallyMarkMetadataValidated = (
    _uf: SPOUploadFile,
    name: string,
    valstatus: SPOMValState,
  ): SPOUploadFile => {
    var returner = { ..._uf };

    var md = { ...[...returner.MetaData].find((th) => th.DataName === name) };
    md.Validation = valstatus;

    returner.MetaData = [...returner.MetaData]
      .filter((th) => th.DataName !== name)
      .concat(md);

    return returner;
  };

  const StatefullyUpdateFileMetadata = (
    file: SPOUploadFile,
    name: string,
    __value: string | boolean | Date,
  ) => {
    //  console.log("StatefullyUpdateFileMetadata");
    //  console.log(file);
    //  console.log(name);
    //  console.log(__value);

    var _val: SPOMValState = SPOMValState.None;

    var _file = files.find((th) => th.File === file.File);
    var _oldmd = _file.MetaData.find((th) => th.DataName === name);
    var _oldvalue: string | boolean | Date = "";
    if (_oldmd !== undefined) {
      if (_oldmd.DataValue !== undefined) {
        _oldvalue = _oldmd.DataValue;
      }
    }

    //  console.log(_oldmd);
    //  console.log(_oldvalue);

    let ct = contentTypes.find(
      (ct) =>
        ct.contentTypeId ==
        file.MetaData.find((md) => md.DataName == "ContentTypeId").DataValue,
    );
    let names = ct.preferredIdent;

    let value = __value;

    //  console.log(names);
    //  console.log(names.includes(name));

    let fs = [...files];

    //  console.log(fs);

    let fsin = fs.findIndex((th) => th.File === file.File);
    let thisfile = fs[fsin];

    if (names.includes(name)) {
      //we're working with a string for sure.
      value = value.toString().trim();
      if (value === "") {
        // if empty string, don't queue validation, and wipe all errors.
        _val = SPOMValState.None;
        thisfile = ReferentiallyRemoveFileErrors(thisfile, name);
      } else {
        if (value === _oldvalue) {
          // value hasn't changed, don't queue another validation, but DON'T wipe all errors.
          _val = SPOMValState.None;
        } else {
          _val = SPOMValState.Pending;
        }
      }
    }

    //  console.log("StatefullyUpdateFileMetadata cont 1");

    //  console.log(thisfile);
    //  console.log(thisfile.MetaData);

    thisfile.MetaData = [...thisfile.MetaData]
      .filter((th) => th.DataName !== name)
      .concat({
        DataName: name,
        DataValue: value,
        Validation: _val,
      });

    //  console.log(thisfile);
    //  console.log(thisfile.MetaData);
    thisfile = ReferentiallyErrorCheckFile(thisfile);

    fs[fsin] = thisfile;

    //  console.log("StatefullyUpdateFileMetadata cont 2");
    //  console.log(fs);
    //  console.log(fs[fsin]);
    //  console.log(fs[fsin].MetaData);

    StatefullySetFiles(fs);
  };

  const StatefullyClearFileMetadata = (file: SPOUploadFile) => {
    var _file = files.find((th) => th.File === file.File);

    //  console.log(_file);

    _file.MetaData = _file.MetaData.filter(
      (th) => th.DataName === "ContentTypeId",
    );

    //  console.log(_file);
    _file = ReferentiallyErrorCheckFile(_file);

    let fs = [...files];

    let fsin = fs.findIndex((th) => th.File === file.File);
    fs[fsin] = _file;

    StatefullySetFiles(fs);
  };

  const StatefullyRemoveFile = (file: SPOUploadFile) => {
    StatefullySetFiles(files.filter((th) => th.File !== file.File));
  };

  const StatefullyUploadFile = async (file: SPOUploadFile) => {
    file.State = SPOFileState.Processing;
    StatefullySetFile(file);
    var pend: Number | String = await props.da.UploadFile(
      file.Overridden_Name + file.Overridden_Ext,
      file.File,
      props.UploadConveyance,
      file.MetaData,
    );

    if (pend === "Failed to communicate with API") {
      file.State = SPOFileState.Errored;
    } else {
      file.State = SPOFileState.Completed;
    }

    StatefullySetFile(file);
  };

  const StatefullyUploadValidFiles = () => {
    files
      .filter((th) => th.State === SPOFileState.InPreparation)
      .forEach((file) => {
        if (
          (file.Errors === undefined ||
            file.Errors.filter((ith) => ith.ErrorName !== "FileName").length ===
              0) &&
          file.MetaData.some((ith) => ith.DataName === "ContentTypeId") &&
          file.MetaDataFields.filter((mdf) => mdf.required).every((mdf) =>
            file.MetaData.some(
              (th) =>
                th.DataName === mdf.internalName &&
                th.DataValue !== undefined &&
                th.DataValue !== "",
            ),
          )
        ) {
          StatefullyUploadFile(file);
        }
      });
  };

  const ReferentiallyErrorCheckFile = (_uf: SPOUploadFile) => {
    var returner = { ..._uf };

    //  console.log("ReferentiallyErrorCheckFile");
    //  console.log(returner.MetaData);

    returner = ReferentiallyValidateFields(returner);

    //  console.log(returner.Errors);

    return returner;
  };

  const StatefullyUpdateFileName = (
    _uf: SPOUploadFile,
    name: string,
    extension: string,
  ) => {
    var stateparcel = { ..._uf };

    //  console.log("StatefullyUpdateFileName");
    //  console.log(name);
    //  console.log(extension);
    //  console.log(stateparcel);

    stateparcel.Overridden_Name = name;
    stateparcel.Overridden_Ext = extension;

    var err = ReferentiallyExamineFileName(name + extension);

    //  console.log(err);
    //  console.log(stateparcel);

    if (err !== undefined) {
      stateparcel = ReferentiallyUpdateFileErrors(
        stateparcel,
        "OverrideFileName",
        err,
      );
    } else {
      stateparcel = ReferentiallyRemoveFileErrors(
        stateparcel,
        "OverrideFileName",
      );
    }

    //  console.log(stateparcel);

    StatefullySetFile(stateparcel);
  };

  const ReferentiallyValidateFields = (_uf: SPOUploadFile): SPOUploadFile => {
    //  console.log("ValidateFields()");
    //  console.log(uf);
    // get metadatafields on internalname
    // then make sure that metadata contains
    //   at least one non-empty non-null entry for one of the relevant fields

    var returner = { ..._uf };

    //  console.log("ReferentiallyValidateFields");
    //  console.log(returner);
    //  console.log(returner.MetaData);

    if (props !== undefined) {
      if (!returner.MetaData.some((th) => th.DataName === "ContentTypeId")) {
        if (contentTypes !== undefined) {
          if (contentTypes.length === 1) {
            const ct = contentTypes[0];
            returner = ReferentiallyUpdateFileMetadata(
              returner,
              "ContentTypeId",
              ct.contentTypeId,
            );
          }
        }
      }
    }

    if (returner.MetaDataFields !== undefined) {
      const _mdt_prid: SPOMetadata | undefined = returner.MetaData.find(
        (th) => th.DataName.toLowerCase() === "esf_prid",
      );
      const _mdt_pgno: SPOMetadata | undefined = returner.MetaData.find(
        (th) => th.DataName.toLowerCase() === "edocs_pgno",
      );
      const _mdt_ucas: SPOMetadata | undefined = returner.MetaData.find(
        (th) => th.DataName.toLowerCase() === "edocs_ucasno",
      );
      const _mdt_clea: SPOMetadata | undefined = returner.MetaData.find(
        (th) => th.DataName.toLowerCase() === "edocs_clearingrefno",
      );
      const _mdt_regn: SPOMetadata | undefined = returner.MetaData.find(
        (th) => th.DataName.toLowerCase() === "edocs_regno",
      );
      const selCT: SPOContentType = contentTypes.find(
        (th) =>
          th.contentTypeId ===
          (returner.MetaData.find((th) => th.DataName === "ContentTypeId")
            .DataValue as string),
      );

      // Remove all errors from preferred idents
      returner.Errors = returner.Errors.filter(
        (ith) => !selCT?.preferredIdent?.includes(ith.ErrorName),
      );

      //  console.log("compuls field checks");
      //  console.log(returner.MetaData);
      //  console.log(selCT);

      selCT?.preferredIdent?.forEach((th) => {
        if (
          (th === "esf_prid" &&
            (_mdt_prid === undefined || _mdt_prid.DataValue === "")) ||
          (th === "edocs_PGNO" &&
            (_mdt_pgno === undefined || _mdt_pgno.DataValue === "")) ||
          (th === "edocs_UCASNo" &&
            (_mdt_ucas === undefined || _mdt_ucas.DataValue === "")) ||
          (th === "edocs_ClearingRefNo" &&
            (_mdt_clea === undefined || _mdt_clea.DataValue === "")) ||
          (th === "edocs_RegNo" &&
            (_mdt_regn === undefined || _mdt_regn.DataValue === ""))
        ) {
          returner.Errors = [...returner.Errors].concat({
            ErrorName: th,
            ErrorValue: "This identifier is preferred.",
          });
        }
        if (
          th === "esf_prid" ||
          th === "edocs_PGNO" ||
          th === "edocs_UCASNo" ||
          th === "edocs_ClearingRefNo" ||
          th === "edocs_RegNo"
        ) {
          const mdm = returner.MetaData.find((ith) => ith.DataName === th);
          if (mdm !== undefined) {
            mdm.Validation = SPOMValState.Pending;
            returner.MetaData = [...returner.MetaData]
              .filter((ith) => ith.DataName !== th)
              .concat(mdm);
          }
        }
      });

      returner.MetaDataFields.forEach((mdf) => {
        if (mdf.required) {
          if (
            !returner.MetaData.filter(
              (md) => md.DataName == mdf.internalName,
            ).some((mdf) => mdf.DataValue != "")
          ) {
            if (
              !returner.Errors.some((err) => err.ErrorName == mdf.internalName)
            ) {
              returner.Errors = [...returner.Errors].concat({
                ErrorName: mdf.internalName,
                ErrorValue: mdf.displayName + " is required",
              });
            }
          } else {
            if (
              returner.Errors.some(
                (err) =>
                  err.ErrorName == mdf.internalName &&
                  err.ErrorValue == mdf.displayName + " is required",
              )
            ) {
              returner.Errors = returner.Errors.filter(
                (err) =>
                  !(
                    err.ErrorName == mdf.internalName &&
                    err.ErrorValue == mdf.displayName + " is required"
                  ),
              );
            }
          }
        }
      });

      return returner;
    }
  };

  const StatefullyCheckIfAlreadyUploaded = (_uf: SPOUploadFile) => {
    //  console.log("StatefullyCheckIfAlreadyUploaded");
    //  console.log(_uf);

    var _meta: string[keyof string];

    var _esf_prid: string = "";
    var _edocs_PGNO: string = "";
    var _edocs_UCASNo: string = "";
    var _edocs_ClearingRefNo: string = "";
    var _edocs_RegNo: string = "";

    _uf.MetaData.forEach((th) => {
      if (th.DataName === "esf_prid") {
        _esf_prid = th.DataValue.toString();
      }
      if (th.DataName === "edocs_PGNO") {
        _edocs_PGNO = th.DataValue.toString();
      }
      if (th.DataName === "edocs_UCASNo") {
        _edocs_UCASNo = th.DataValue.toString();
      }
      if (th.DataName === "edocs_ClearingRefNo") {
        _edocs_ClearingRefNo = th.DataValue.toString();
      }
      if (th.DataName === "edocs_RegNo") {
        _edocs_RegNo = th.DataValue.toString();
      }
    });

    if (
      _esf_prid === "" &&
      _edocs_PGNO === "" &&
      _edocs_UCASNo === "" &&
      _edocs_ClearingRefNo === "" &&
      _edocs_RegNo === ""
    ) {
      var stateparcel = _uf;
      stateparcel.AlreadyUploadedChecked = true;
      StatefullySetFile(stateparcel);
    } else {
      props.IDA.CheckForReupload(
        props.UploadConveyance,
        _uf.Overridden_Name,
        _uf.Overridden_Ext,
        userData.AuthedUser.userPrincipalName.split("@")[0],
        _esf_prid,
        _edocs_PGNO,
        _edocs_UCASNo,
        _edocs_ClearingRefNo,
        _edocs_RegNo,
      ).then(
        (data) => {
          //  console.log(data);
          var stateparcel = _uf;
          stateparcel.AlreadyUploadedChecked = true;
          stateparcel.AlreadyUploadedStruct = data;
          //  console.log(stateparcel);
          StatefullySetFile(stateparcel);
        },
        (reason) => {
          props.RegisterError(
            "SPUploadManager.StatefullyCheckIfAlreadyUploaded()",
            reason,
          );
        },
      );
    }
  };

  const StatefullyValidateField = (
    _uf: SPOUploadFile,
    field: string,
    value: string | boolean | Date,
  ) => {
    //  console.log("StatefullyValidateField");
    //  console.log(_uf);
    //  console.log(field);
    //  console.log(value);

    const params: IParams =
      Surface.SURFACE === SURFACE.ECF
        ? {
            sproc: "sputilities.spuploader.validateecffield",
            params: ["@Field", field, "@Value", value.toString()],
          }
        : {
            sproc: "sputilities.spuploader.validatefield",
            params: ["@Field", field, "@Value", value.toString()],
          };

    let ct = contentTypes.find(
      (ct) =>
        ct.contentTypeId ==
        _uf.MetaData.find((md) => md.DataName == "ContentTypeId").DataValue,
    );
    let fn = ct.metadataFields.find((mdf) => mdf.internalName == field);
    let re = new RegExp(ct.validationRegex, "i");

    //It's not a required field, no meaningful validation can happen
    if (
      !_uf.MetaDataFields.find((f) => f.internalName == field).required &&
      !ct.preferredIdent.includes(field)
    ) {
      var stateparcel = ReferentiallyProcessErrors(_uf, field, undefined);
      //  console.log(stateparcel);
      stateparcel = ReferentiallyMarkMetadataValidated(
        stateparcel,
        field,
        SPOMValState.Complete,
      );

      StatefullySetFile(stateparcel);
      return;
    }

    // It is requierd but is not an identifier
    if (
      _uf.MetaDataFields.find((f) => f.internalName == field).required &&
      !ct.preferredIdent.includes(field)
    ) {
      var stateparcel = ReferentiallyProcessErrors(_uf, field, undefined);

      if (value == undefined || value == "") {
        stateparcel = ReferentiallyProcessErrors(_uf, field, [
          [{ Error: fn.displayName + " is required" }],
        ]);
      }

      //  console.log(stateparcel);
      stateparcel = ReferentiallyMarkMetadataValidated(
        stateparcel,
        field,
        SPOMValState.Complete,
      );

      StatefullySetFile(stateparcel);
      return;
    }

    // It is an identifier and does not match the identifier regex
    if (
      ct.preferredIdent.includes(field) &&
      value.toString().match(re) == null &&
      value.toString() != "" &&
      value != undefined
    ) {
      var stateparcel = ReferentiallyProcessErrors(_uf, field, [
        [{ Error: fn.displayName + " is invalid" }],
      ]);
      //  console.log(stateparcel);
      stateparcel = ReferentiallyMarkMetadataValidated(
        stateparcel,
        field,
        SPOMValState.Complete,
      );

      StatefullySetFile(stateparcel);
      return;
    }

    // It is an identifier and does match the identifier regex
    if (
      ct.preferredIdent.includes(field) &&
      value.toString().match(re) != null
    ) {
      if (validatedIdents.has(field + ":" + value)) {
        let data = validatedIdents.get(field + ":" + value);

        var stateparcel = ReferentiallyProcessErrors(_uf, field, data);
        //  console.log(stateparcel);
        stateparcel = ReferentiallyMarkMetadataValidated(
          stateparcel,
          field,
          SPOMValState.Complete,
        );
        if (
          stateparcel.MetaData.every(
            (th) =>
              th.Validation === SPOMValState.Complete ||
              th.Validation === SPOMValState.None,
          ) &&
          stateparcel.ReuploadCheckedIdent != value.toString()
        ) {
          stateparcel.ReuploadCheckedIdent = value.toString();
          stateparcel.AlreadyUploadedChecked = false;
        }
        //  console.log(stateparcel);
        StatefullySetFile(stateparcel);
      } else {
        //  console.log("/sproc...");
        props.da.get("/sproc", JSON.stringify(params)).then(
          (data) => {
            console.log(data);
            var stateparcel = ReferentiallyProcessErrors(_uf, field, data);
            //  console.log(stateparcel);
            stateparcel = ReferentiallyMarkMetadataValidated(
              stateparcel,
              field,
              SPOMValState.Complete,
            );
            if (
              stateparcel.MetaData.every(
                (th) =>
                  th.Validation === SPOMValState.Complete ||
                  th.Validation === SPOMValState.None,
              )
            ) {
              stateparcel.AlreadyUploadedChecked = false;
            }
            //  console.log(stateparcel);
            setValidatedIdents((old) => {
              old.set(field + ":" + value, data);
              return new Map(old);
            });
            StatefullySetFile(stateparcel);
          },
          (reason) => {
            //  console.log(reason);
            var stateparcel = ReferentiallyProcessErrors(_uf, field, reason);
            //  console.log(stateparcel);
            stateparcel = ReferentiallyMarkMetadataValidated(
              stateparcel,
              field,
              SPOMValState.Complete,
            );
            if (
              stateparcel.MetaData.every(
                (th) =>
                  th.Validation === SPOMValState.Complete ||
                  th.Validation === SPOMValState.None,
              )
            ) {
              stateparcel.AlreadyUploadedChecked = false;
            }
            //  console.log(stateparcel);
            StatefullySetFile(stateparcel);
          },
        );
      }
    }
  };

  const ReferentiallyProcessErrors = (
    _uf: SPOUploadFile,
    field: string,
    value: IItem[][],
  ): SPOUploadFile => {
    //  console.log("ReferentiallyProcessErrors");
    //  console.log(_uf);
    //  console.log(field);
    //  console.log(value);

    var returner = { ..._uf };
    var reterrs = [...returner.Errors];

    if (value !== undefined) {
      if (value.toString().indexOf("[object Object]") === -1) {
        if (
          !reterrs.some(
            (iith) =>
              iith.ErrorName === "" && iith.ErrorValue === value.toString(),
          )
        ) {
          reterrs = reterrs.concat({
            ErrorName: "",
            ErrorValue: value.toString(),
          });
        }
      } else {
        reterrs = reterrs.filter((th) => th.ErrorName !== "");
        reterrs = reterrs.filter((th) => th.ErrorName !== field);
        if (value.length > 0) {
          const _poss_errs = value[0];
          _poss_errs.forEach((ith) => {
            Object.keys(ith).forEach((iith) => {
              if (iith !== "" && ith[iith] !== "") {
                if (iith === "Error") {
                  if (
                    !reterrs.some(
                      (iiith) =>
                        iiith.ErrorName === field &&
                        iiith.ErrorValue === ith[iith],
                    )
                  ) {
                    reterrs = reterrs.concat({
                      ErrorName: field,
                      ErrorValue: ith[iith],
                    });
                  }
                } else if (iith === "Success") {
                  reterrs = reterrs.filter((th) => th.ErrorName !== field);
                }
              }
            });
          });
        }
        if (value.length > 1) {
          const _poss_other_vals = value[1];
          //  console.log(_poss_other_vals);
          if (_poss_other_vals !== undefined && _poss_other_vals.length > 0) {
            if (_poss_other_vals[0].edocs_RegNo !== undefined) {
              const _meta_rgn = returner.MetaData.find(
                (th) => th.DataName.toLowerCase() === "edocs_regno",
              );
              if (_meta_rgn !== undefined && _meta_rgn.DataValue !== "") {
                // check value against all returned values.
                if (
                  !_poss_other_vals.some(
                    (th) =>
                      th.edocs_RegNo.toLowerCase() ===
                      _meta_rgn.DataValue.toString().toLowerCase(),
                  )
                ) {
                  reterrs = reterrs.concat({
                    ErrorName: "esf_prid",
                    ErrorValue: "edocs_RegNo and esf_prid mismatch",
                  });
                } else {
                  reterrs = reterrs.filter(
                    (th) =>
                      th.ErrorName !== "edocs_RegNo" &&
                      th.ErrorName !== "esf_prid",
                  );
                }
              } else {
                // set value to match.
                reterrs = reterrs.filter(
                  (th) =>
                    th.ErrorName !== "edocs_RegNo" &&
                    th.ErrorName !== "esf_prid",
                );
                returner = ReferentiallyUpdateFileMetadata(
                  returner,
                  "edocs_RegNo",
                  _poss_other_vals[0].edocs_RegNo,
                );
              }
            }
            if (_poss_other_vals[0].esf_prid !== undefined) {
              const _meta_prid = returner.MetaData.find(
                (th) => th.DataName.toLowerCase() === "esf_prid",
              );
              if (_meta_prid !== undefined && _meta_prid.DataValue !== "") {
                // check value against all returned values.
                if (
                  !_poss_other_vals.some(
                    (th) =>
                      th.esf_prid.toLowerCase() ===
                      _meta_prid.DataValue.toString().toLowerCase(),
                  )
                ) {
                  reterrs = reterrs.concat({
                    ErrorName: "edocs_RegNo",
                    ErrorValue: "edocs_RegNo and esf_prid mismatch",
                  });
                } else {
                  reterrs = reterrs.filter(
                    (th) =>
                      th.ErrorName !== "edocs_RegNo" &&
                      th.ErrorName !== "esf_prid",
                  );
                }
              } else {
                // set value to match.
                reterrs = reterrs.filter(
                  (th) =>
                    th.ErrorName !== "edocs_RegNo" &&
                    th.ErrorName !== "esf_prid",
                );
                returner = ReferentiallyUpdateFileMetadata(
                  returner,
                  "esf_prid",
                  _poss_other_vals[0].esf_prid,
                );
              }
            }
          }
        }
      }
    }

    returner.Errors = reterrs;

    return returner;
  };

  const ReferentiallyAutofill = (
    _uf: SPOUploadFile,
    Autofill: { Type: string; Value: string },
  ): SPOUploadFile => {
    var returner = { ..._uf };

    if (Autofill.Type === "PRID") {
      returner = ReferentiallyUpdateFileMetadata(
        returner,
        "esf_prid",
        Autofill.Value,
      );
      returner = ReferentiallyErrorCheckFile(returner);
    } else if (Autofill.Type === "PGNo") {
      returner = ReferentiallyUpdateFileMetadata(
        returner,
        "edocs_PGNO",
        Autofill.Value,
      );
      returner = ReferentiallyErrorCheckFile(returner);
    } else if (Autofill.Type === "UCAS / DA Number") {
      returner = ReferentiallyUpdateFileMetadata(
        returner,
        "edocs_UCASNo",
        Autofill.Value,
      );
      returner = ReferentiallyErrorCheckFile(returner);
    } else if (Autofill.Type === "ClearingRefNo") {
      returner = ReferentiallyUpdateFileMetadata(
        returner,
        "edocs_ClearingRefNo",
        Autofill.Value,
      );
      returner = ReferentiallyErrorCheckFile(returner);
    } else if (Autofill.Type === "RegNo") {
      returner = ReferentiallyUpdateFileMetadata(
        returner,
        "edocs_RegNo",
        Autofill.Value,
      );
      returner = ReferentiallyErrorCheckFile(returner);
    }

    return returner;
  };

  const ReferentiallyExamineFileName = (value: string): string => {
    const PROHIBIT__SysFiles: string[] = [
      ".lock",
      "CON",
      "PRN",
      "AUX",
      "NUL",
      "COM0",
      "COM1",
      "COM2",
      "COM3",
      "COM4",
      "COM5",
      "COM6",
      "COM7",
      "COM8",
      "COM9",
      "LPT0",
      "LPT1",
      "LPT2",
      "LPT3",
      "LPT4",
      "LPT5",
      "LPT6",
      "LPT7",
      "LPT8",
      "LPT9",
      "_vti_",
      "desktop.ini",
      "thumbs.db",
    ];
    if (value !== undefined) {
      if (value === "") {
        return "This is a mandatory field";
      } else if (
        PROHIBIT__SysFiles.some(
          (th) => th.toLowerCase() === value.toLowerCase(),
        )
      ) {
        return "System file names are prohibited";
      } else {
        const vspl = value.split(".");
        if (vspl.length > 2) {
          return "File names may only have one '.'";
        } else if (vspl[0] === "") {
          return "File names may not start with '.'";
        } else {
          if (value.length > 40) {
            return "File name must be less than 40 characters.";
          }
          // further validation rules now possible...
          let filenameRegex = /[^a-zA-Z0-9_\- ]/;
          if (filenameRegex.test(vspl[0])) {
            return "Permitted characters in file names are 'a-zA-Z0-9_- '";
          }
        }
      }
    }
    return undefined;
  };

  var PassToAllGrids = {
    Callouts: false,
    Logger: props.Logger,
    da: props.da,
    PCA: props.PCA,
    GetLink: props.GetLink,
    URLRoot: props.URLRoot,
    URLParams: props.URLParams,
    class: "UoE-Grid",
    Refresher: props.Refresher,
    Alias: props.Alias,
    AliasChecked: props.AliasChecked,
    ViewAsAll: props.ViewAsAll,
    RegisterError: props.RegisterError,
    DeregisterError: props.DeregisterError,
    RegisterStatus: props.RegisterStatus,
    DeregisterStatus: props.DeregisterStatus,
  };

  return (
    <>
      <InternalUploadManager
        {...props}
        DragState={dragState}
        Files={files}
        AuthedUser={userData.AuthedUser}
        ReferentiallyExamineFileName={ReferentiallyExamineFileName}
        StatefullySetDragState={StatefullySetDragState}
        StatefullySetFile={StatefullySetFile}
        StatefullySetFiles={StatefullySetFiles}
        StatefullyRemoveFile={StatefullyRemoveFile}
        StatefullyClearFileMetadata={StatefullyClearFileMetadata}
        StatefullyUpdateFileMetadata={StatefullyUpdateFileMetadata}
        StatefullyUpdateFileContentType={StatefullyUpdateFileContentType}
        StatefullyUploadFile={StatefullyUploadFile}
        StatefullyUpdateFileName={StatefullyUpdateFileName}
        StatefullyUploadValidFiles={StatefullyUploadValidFiles}
        SPOR={spor}
        ContentTypes={contentTypes}
      />
      {props.IncludeRUG ? (
        <div className="TaskLists">
          <div className="TaskListPanel">
            <h2>Your Recently Uploaded Files</h2>
            <GridWrapper
              {...PassToAllGrids}
              key={"SPOWUI_UM_" + props.Refresher}
              eid="SPOWUI_UM"
              sproc="sputilities.invar.WEBUI_GetRecentUploads"
              params={["@Surface", Surface.CSSClass, "@User", "{%iu}"]}
              class="UoE-Grid"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
