import * as React from "react";

import "../assets/css/Footer.css";
import "../assets/css/Header.css";
import "../assets/css/FullPageLoader.css";
import "../assets/css/Logo.css";
import AccessibilityIcon from "../assets/icons/AccessibilityIcon";
import ErrorMessage from "../models/ErrorMessage";
import StatusMessage from "../models/StatusMessage";
import { SURFACE, SurfaceAttributes } from "../Providers/SurfaceProvider";

interface IProps {
  ErrorMessages: ErrorMessage[];
  StatusMessages: StatusMessage[];
  HiVis: boolean;
  SetHiVis: (val: boolean) => void;
  Surface: SurfaceAttributes;
  ThemeName: string;
  SetThemeName: (input: string) => void;
}

class FullPageLoader extends React.PureComponent<IProps> {
  render() {
    return (
      <div className="FullPageLoader">
        <div className="Header">
          <div className="Operations">
            <div className="Logo">
              <div className="LogoBlocks">
                <div className="LogoBlockRow">
                  <div className="LogoBlockCell OFF"></div>
                  <div className="LogoBlockCell OFF"></div>
                  <div className="LogoBlockCell ON"></div>
                </div>
                <div className="LogoBlockRow">
                  <div className="LogoBlockCell OFF"></div>
                  <div className="LogoBlockCell ON"></div>
                  <div className="LogoBlockCell OFF"></div>
                </div>
                <div className="LogoBlockRow">
                  <div className="LogoBlockCell OFF"></div>
                  <div className="LogoBlockCell OFF"></div>
                  <div className="LogoBlockCell ON"></div>
                </div>
                <div className="LogoBlockRow">
                  <div className="LogoBlockCell OFF"></div>
                  <div className="LogoBlockCell ON"></div>
                  <div className="LogoBlockCell OFF"></div>
                </div>
                <div className="LogoBlockRow">
                  <div className="LogoBlockCell OFF"></div>
                  <div className="LogoBlockCell OFF"></div>
                  <div className="LogoBlockCell ON"></div>
                </div>
              </div>
              <div className="LogoText">University of Essex</div>
            </div>
          </div>
          <div className="Breadcrumbs">
            <div className="Crumbs">
              <div className="Crumb">
                {this.props.Surface.RootSurface === SURFACE.AOS
                  ? "Agent Online Services Home"
                  : this.props.Surface.RootSurface === SURFACE.EAF
                    ? "EAF Home"
                    : this.props.Surface.RootSurface === SURFACE.ECF
                      ? "ECF Home"
                      : this.props.Surface.RootSurface === SURFACE.ESF
                        ? "ESF Home"
                        : ""}
              </div>
              <div className="Twixt">&gt;</div>
            </div>
          </div>
        </div>
        <div className="LoadingLabel">Loading...</div>
        <div className="mosaic-loader">
          <div className="cell cell1"></div>
          <div className="cell cell2"></div>
          <div className="cell cell3"></div>
          <div className="cell cell4"></div>
          <div className="cell cell5"></div>
          <div className="cell cell6"></div>
          <div className="cell cell7"></div>
          <div className="cell cell8"></div>
          <div className="cell cell9"></div>
          <div className="cell cell10"></div>
          <div className="cell cell11"></div>
          <div className="cell cell12"></div>
          <div className="cell cell13"></div>
          <div className="cell cell14"></div>
          <div className="cell cell15"></div>
          <div className="cell cell16"></div>
        </div>
        <div className="LoadingErrorMessages">
          {this.props.ErrorMessages !== undefined ? (
            <React.Fragment>
              {this.props.ErrorMessages.map((th, i) => {
                return (
                  <div
                    className="LoadingErrorMessage"
                    key={"Error_" + i.toString()}
                  >
                    <div className="ErrorReference">{th.Reference}</div>
                    <div className="ErrorMessage">{th.Message}</div>
                  </div>
                );
              })}
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
        <div className="LoadingStatusMessages">
          {this.props.StatusMessages !== undefined ? (
            <React.Fragment>
              {this.props.StatusMessages.map((th, i) => {
                return (
                  <div
                    className="LoadingStatusMessage"
                    key={"Error_" + i.toString()}
                  >
                    <div className="StatusReference">{th.Reference}</div>
                    <div className="StatusMessage">{th.Message}</div>
                  </div>
                );
              })}
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
        <div className="Footer">
          <div className="AccessibilityMenu">
            <div className="AccessibilityCharm">
              <AccessibilityIcon></AccessibilityIcon>
            </div>
          </div>
          <div className="HelpMenu">
            <div className="HelpCharm">?</div>
          </div>
        </div>
      </div>
    );
  }
}

export default FullPageLoader;
