import { GroupList24Filled, LockClosed24Filled } from "@fluentui/react-icons";
import { ReactElement } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { GridWrapper } from "../../../integrations/UoE-Grid/GridWrapper";
import { Logging } from "../../../Logging";
import { DataAccess } from "../../../data/DataAccess";
import { PublicClientApplication } from "@azure/msal-browser";

interface UgApplicantChoicesAndOffersProps {
  UcasNo: string;
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;

  URLRoot: string;
  URLParams: string;
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;

  Embargo: (scope: string) => boolean;
  Notices: (AgentIsPAS: boolean, scopes: string[]) => React.ReactFragment;
}

export function UgApplicantChoicesAndOffers(
  props: UgApplicantChoicesAndOffersProps,
): ReactElement {
  let agentDetails: any = useRouteLoaderData("AgentRoot");

  var PassToAllGrids = {
    Callouts: false,
    Logger: props.Logger,
    da: props.da,
    PCA: props.PCA,
    GetLink: props.GetLink,
    URLRoot: props.URLRoot,
    URLParams: props.URLParams,
    ucasno: props.UcasNo,
    class: "UoE-Grid AOSScreen",
    Refresher: props.Refresher,
    Alias: props.Alias,
    AliasChecked: props.AliasChecked,
    ViewAsAll: props.ViewAsAll,
    RegisterError: props.RegisterError,
    DeregisterError: props.DeregisterError,
    RegisterStatus: props.RegisterStatus,
    DeregisterStatus: props.DeregisterStatus,
  };

  return (
    <>
      {props.Embargo("UCAS") ? (
        <div className="ApplicantGrid" id="ChoicesOffers">
          <h2>
            <span className="LeftIcon24">
              <LockClosed24Filled></LockClosed24Filled>
            </span>{" "}
            Choices and Offers
          </h2>
          {props.Notices(agentDetails.AgentIsPAS, ["Applicant"])}
        </div>
      ) : (
        <div className="ApplicantGrid" id="ChoicesOffers">
          <h2>
            <span className="LeftIcon24">
              <GroupList24Filled></GroupList24Filled>
            </span>{" "}
            Choices and Offers <span className="PastExample">Past Choice</span>
            <span className="CurrentExample">Current Choice</span>
          </h2>
          <GridWrapper
            eid={"AOS_UG_ChoicesAndOffers_" + props.Refresher}
            {...PassToAllGrids}
            sproc="sputilities.webui.AOSv2_GetUGApplicantChoicesAndOffers"
            params={[
              "@ucasno",
              props.UcasNo.toUpperCase(),
              "@username",
              // "{%u}",
              // "@agent",
              "{%iu}",
            ]}
            output="accordion"
            heading="Choice_Summary"
            sort="Choice_Summary"
          ></GridWrapper>
        </div>
      )}
    </>
  );
}
