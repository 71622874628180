import React, { useEffect, useRef, useState } from "react";
import { DataAccess } from "../../../data/DataAccess";
import "../../../assets/css/HomeOfficeAuditSurface.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { Logging } from "../../../Logging";
import { useApi } from "../../../Providers/ApiProvider";
import { GetWorkOrderResponse } from "../../../data/InvarDataModels/GetWorkOrderResponse";
import { useUser } from "../../../Providers/UserProvider";
import { Mondrian } from "../../../integrations/Mondrian/Mondrian";
import { MondrianDataProvider } from "../../../integrations/Mondrian/MondrianDataProvider";
import { ConveyanceResponse } from "../../../data/InvarDataModels/ConveyanceResponse";
import { MondrianDisplayType } from "../../../integrations/Mondrian/Structs/Enums/MondrianDisplayType";
import { MondrianColumn } from "../../../integrations/Mondrian/Structs/Types/MondrianStructuredData";
import { EnqueueMetadataRequest } from "../../../data/InvarDataModels/EnqueueRequest";
import dayjs from "dayjs";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  URLRoot: string;
  URLParams: string;
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

export function HomeOfficeAuditSurface(props: IProps) {
  const ConveyanceName: string = "Fulfil Home Office Audit Request";
  const MultiConveyanceName: string =
    "Fulfil Multiple Home Office Audit Requests";

  const [ThisConveyance, setConveyance] = useState(
    ConveyanceResponse.prototype,
  );
  const [ThisMultiConveyance, setMultiConveyance] = useState(
    ConveyanceResponse.prototype,
  );

  const _reqmeta: string[] = [
    "PDFs.Concat.LCL>LCL.Output",
    "PDFs.Concat.LCL>LCL.Confirmed",
    "Domain.PGEAF",
    "Domain.UGEAF",
    "Domain.ECF",
    "Domain.ESF",
  ];
  const API = useApi();
  const identity = useUser();
  const [idents, setIdents] = useState("");
  const [invalidIdents, setInvalidIdents]: [
    string[],
    React.Dispatch<React.SetStateAction<string[]>>,
  ] = useState([]);
  const [collate, setCollate] = useState(false);
  const [pgeaf, setPGEAF] = useState(false);
  const [ugeaf, setUGEAF] = useState(false);
  const [ecf, setECF] = useState(false);
  const [esf, setESF] = useState(false);
  const [audits, setAudits] = useState(Array<GetWorkOrderResponse>);
  const [mutatedAudits, setMutatedAudits] = useState([]);

  const [loadingResults, setLoadingResults] = useState(true);

  const refTimeoutProcessing = useRef(null);
  const refTimeoutStale = useRef(null);
  const refTimeoutAll = useRef(null);

  const StatefullyMergeWorkOrderResponse = (GWOR: GetWorkOrderResponse) => {
    setAudits(
      [...audits]
        .filter((th) => th.WorkOrderID !== GWOR.WorkOrderID)
        .concat(GWOR),
    );
  };

  const StatefullyMergeWorkOrderResponses = (GWORs: GetWorkOrderResponse[]) => {
    setAudits(
      [...audits]
        .filter(
          (th) => !GWORs.some((ith) => ith.WorkOrderID === th.WorkOrderID),
        )
        .concat(GWORs),
    );
  };

  const columns: MondrianColumn[] = [
    { HumanName: "Identifier", MachineNames: ["InitialValue"] },
    { HumanName: "Requested By", MachineNames: ["RequestedBy"] },
    { HumanName: "UGEAF", MachineNames: ["UGEAF"] },
    { HumanName: "PGEAF", MachineNames: ["PGEAF"] },
    { HumanName: "ECF", MachineNames: ["ECF"] },
    { HumanName: "ESF", MachineNames: ["ESF"] },
    { HumanName: "Requested Date", MachineNames: ["RequestedDate"] },
    { HumanName: "Fulfilled Date", MachineNames: ["FulfilledDate"] },
    { HumanName: "Status", MachineNames: ["Status"] },
    { HumanName: "Action", MachineNames: ["Action"] },
    { HumanName: "_ROW_PROCESSING", MachineNames: ["_ROW_PROCESSING"] },
  ];

  useEffect(() => {
    API.Invar.GetConveyanceByName(ConveyanceName).then((_c) => {
      setConveyance(_c);
    });
    API.Invar.GetConveyanceByName(MultiConveyanceName).then((_mc) => {
      setMultiConveyance(_mc);
    });
  }, []);

  const LoadAudits = async () => {
    var _auds: GetWorkOrderResponse[] = [
      /*
       {
         WorkOrderID: 1,
         ConveyanceID: 1,
         RequestedBy: "jmcait",
         InitialValue: "CAITS22608",
         RequestedDate: new Date(),
         FulfilledDate: new Date(),
         Status: "Processing",
         RelevantMetadata: [
           { DatumName: "Domain.PGEAF", DatumValue: "true" },
           { DatumName: "Domain.UGEAF", DatumValue: "false" },
           { DatumName: "Domain.ECF", DatumValue: "false" },
           { DatumName: "Domain.ESF", DatumValue: "false" },
           { DatumName: "PDFs.Concat.LCL>LCL.Output", DatumValue: "file.pdf" },
         ],
       },
       {
         WorkOrderID: 2,
         ConveyanceID: 1,
         RequestedBy: "jmcait",
         InitialValue: "CAITS22608",
         RequestedDate: new Date(),
         FulfilledDate: new Date(),
         Status: "Failed",
         RelevantMetadata: [
           { DatumName: "Domain.PGEAF", DatumValue: "false" },
           { DatumName: "Domain.UGEAF", DatumValue: "true" },
           { DatumName: "Domain.ECF", DatumValue: "false" },
           { DatumName: "Domain.ESF", DatumValue: "false" },
           { DatumName: "PDFs.Concat.LCL>LCL.Output", DatumValue: "file.pdf" },
         ],
       },
       {
         WorkOrderID: 3,
         ConveyanceID: 1,
         RequestedBy: "jmcait",
         InitialValue: "CAITS22608",
         RequestedDate: new Date(),
         FulfilledDate: new Date(),
         Status: "Permanently Failed",
         RelevantMetadata: [
           { DatumName: "Domain.PGEAF", DatumValue: "true" },
           { DatumName: "Domain.UGEAF", DatumValue: "true" },
           { DatumName: "Domain.ECF", DatumValue: "true" },
           { DatumName: "Domain.ESF", DatumValue: "true" },
           { DatumName: "PDFs.Concat.LCL>LCL.Output", DatumValue: "file.pdf" },
         ],
       },
       {
         WorkOrderID: 4,
         ConveyanceID: 1,
         RequestedBy: "jmcait",
         InitialValue: "CAITS22608",
         RequestedDate: new Date(),
         FulfilledDate: new Date(),
         Status: "Cancelled",
         RelevantMetadata: [
           { DatumName: "Domain.PGEAF", DatumValue: "false" },
           { DatumName: "Domain.UGEAF", DatumValue: "false" },
           { DatumName: "Domain.ECF", DatumValue: "true" },
           { DatumName: "Domain.ESF", DatumValue: "false" },
           { DatumName: "PDFs.Concat.LCL>LCL.Output", DatumValue: "file.pdf" },
         ],
       },
       {
         WorkOrderID: 5,
         ConveyanceID: 1,
         RequestedBy: "jmcait",
         InitialValue: "CAITS22608",
         RequestedDate: new Date(),
         FulfilledDate: new Date(),
         Status: "Completed",
         RelevantMetadata: [
           { DatumName: "Domain.PGEAF", DatumValue: "false" },
           { DatumName: "Domain.UGEAF", DatumValue: "false" },
           { DatumName: "Domain.ECF", DatumValue: "false" },
           { DatumName: "Domain.ESF", DatumValue: "true" },
           { DatumName: "PDFs.Concat.LCL>LCL.Output", DatumValue: "file.pdf" },
         ],
       },
      */
    ];

    var _underway = [];

    var _auds1: GetWorkOrderResponse[] = [];
    var _auds2: GetWorkOrderResponse[] = [];
    var _auds3: GetWorkOrderResponse[] = [];
    var _auds4: GetWorkOrderResponse[] = [];
    var _auds5: GetWorkOrderResponse[] = [];
    var _auds6: GetWorkOrderResponse[] = [];
    var _auds7: GetWorkOrderResponse[] = [];
    var _auds8: GetWorkOrderResponse[] = [];

    if (ThisConveyance !== undefined) {
      if (identity.ViewAsUser !== undefined) {
        _underway.push(
          API.Invar.GetAllWorkOrdersForUser(
            identity.ViewAsUser.userPrincipalName.replace("@essex.ac.uk", ""),
            props.Environment,
            _reqmeta,
            ThisConveyance.ConveyanceID,
            undefined,
          ).then((data) => {
            _auds1 = data;
          }),
        );
      }

      _underway.push(
        API.Invar.GetAllWorkOrdersForUser(
          identity.AuthedUser.userPrincipalName.replace("@essex.ac.uk", ""),
          props.Environment,
          _reqmeta,
          ThisConveyance.ConveyanceID,
          undefined,
        ).then((data) => {
          _auds2 = data;
        }),
      );
    }

    if (ThisMultiConveyance !== undefined) {
      if (identity.ViewAsUser !== undefined) {
        _underway.push(
          API.Invar.GetAllWorkOrdersForUser(
            identity.ViewAsUser.userPrincipalName.replace("@essex.ac.uk", ""),
            props.Environment,
            _reqmeta,
            ThisConveyance.ConveyanceID,
            undefined,
          ).then((data) => {
            _auds3 = data;
          }),
        );
      }

      _underway.push(
        API.Invar.GetAllWorkOrdersForUser(
          identity.AuthedUser.userPrincipalName.replace("@essex.ac.uk", ""),
          props.Environment,
          _reqmeta,
          ThisMultiConveyance.ConveyanceID,
          undefined,
        ).then((data) => {
          _auds4 = data;
        }),
      );
    }

    if (props.Environment === "DEV") {
      if (ThisConveyance !== undefined) {
        if (identity.ViewAsUser !== undefined) {
          _underway.push(
            API.Invar.GetAllWorkOrdersForUser(
              identity.ViewAsUser.userPrincipalName.replace("@essex.ac.uk", ""),
              "TEST",
              _reqmeta,
              ThisConveyance.ConveyanceID,
              undefined,
            ).then((data) => {
              _auds5 = data;
            }),
          );
        }

        _underway.push(
          API.Invar.GetAllWorkOrdersForUser(
            identity.AuthedUser.userPrincipalName.replace("@essex.ac.uk", ""),
            "TEST",
            _reqmeta,
            ThisConveyance.ConveyanceID,
            undefined,
          ).then((data) => {
            _auds6 = data;
          }),
        );
      }

      if (ThisMultiConveyance !== undefined) {
        if (identity.ViewAsUser !== undefined) {
          _underway.push(
            API.Invar.GetAllWorkOrdersForUser(
              identity.ViewAsUser.userPrincipalName.replace("@essex.ac.uk", ""),
              "TEST",
              _reqmeta,
              ThisConveyance.ConveyanceID,
              undefined,
            ).then((data) => {
              _auds7 = data;
            }),
          );
        }

        _underway.push(
          API.Invar.GetAllWorkOrdersForUser(
            identity.AuthedUser.userPrincipalName.replace("@essex.ac.uk", ""),
            "TEST",
            _reqmeta,
            ThisMultiConveyance.ConveyanceID,
            undefined,
          ).then((data) => {
            _auds8 = data;
          }),
        );
      }
    }

    await Promise.all(_underway);

    _auds = _auds
      .concat(_auds1)
      .concat(_auds2)
      .concat(_auds3)
      .concat(_auds4)
      .concat(_auds5)
      .concat(_auds6)
      .concat(_auds7)
      .concat(_auds8);

    setAudits(_auds);
  };

  useEffect(() => {
    if (
      ThisConveyance &&
      ThisConveyance.ConveyanceID !== 0 &&
      ThisConveyance.ConveyanceID !== null &&
      ThisConveyance.ConveyanceID !== undefined &&
      ThisMultiConveyance &&
      ThisMultiConveyance.ConveyanceID !== 0 &&
      ThisMultiConveyance.ConveyanceID !== null &&
      ThisMultiConveyance.ConveyanceID !== undefined
    ) {
      LoadAudits();
      RegenerateTimers();
    }
  }, [ThisConveyance, ThisMultiConveyance]);

  useEffect(() => {
    let isMounted = true;
    if (audits !== undefined) {
      setMutatedAudits(MutateAudits(audits));
      setLoadingResults(false);
    }
    return () => {
      isMounted = false;
    };
  }, [audits]);

  const PollWorkOrders = async (
    woids: GetWorkOrderResponse[],
    relay: boolean,
  ) => {
    const _wors: GetWorkOrderResponse[] = [];
    var tasks = [];
    woids.forEach((th) => {
      tasks.push(
        API.Invar.GetWorkOrder(th.WorkOrderID, th.ConveyanceID, _reqmeta).then(
          (data) => {
            _wors.push(data);
          },
        ),
      );
    });
    await Promise.all(tasks);
    StatefullyMergeWorkOrderResponses(_wors);
    if (relay) {
      RegenerateTimers();
    }
  };

  const RegenerateTimers = () => {
    //  console.log("RegenerateTimers");
    //  console.log(refTimeoutProcessing);
    //  console.log(refTimeoutStale);
    //  console.log(refTimeoutAll);

    if (refTimeoutProcessing !== undefined) {
      if (refTimeoutProcessing.current !== undefined) {
        clearTimeout(refTimeoutProcessing.current);
        refTimeoutProcessing.current = undefined;
      }
    }

    if (refTimeoutStale !== undefined) {
      if (refTimeoutStale.current !== undefined) {
        clearTimeout(refTimeoutStale.current);
        refTimeoutStale.current = undefined;
      }
    }

    if (refTimeoutAll !== undefined) {
      if (refTimeoutAll.current !== undefined) {
        clearTimeout(refTimeoutAll.current);
        refTimeoutAll.current = undefined;
      }
    }

    var Processings: GetWorkOrderResponse[] = [];
    var Stales: GetWorkOrderResponse[] = [];

    // for each work order marked as Processing, we register a new Interval with JS to refresh that particular Work Order after 15s.
    //  console.log(audits);

    audits.forEach((th) => {
      if (th.Status === "Processing") {
        //  console.log("RegenerateTimers - proc +1");
        Processings.push(th);
      } else if (th.Status === "Stale") {
        //  console.log("RegenerateTimers - stal +1");
        Stales.push(th);
      }
    });

    //  console.log(Processings);
    if (Processings.length > 0) {
      refTimeoutProcessing.current = setTimeout(async () => {
        PollWorkOrders(Processings, true);
      }, 5000);
    }

    //  console.log(Stales);
    if (Stales.length > 0) {
      refTimeoutStale.current = setTimeout(async () => {
        PollWorkOrders(Stales, false);
      }, 60000);
    }

    if (Processings.length === 0 && Stales.length === 0) {
      refTimeoutAll.current = setTimeout(async () => {
        LoadAudits();
        RegenerateTimers();
      }, 15000);
    }

    //  console.log(refTimeoutProcessing);
    //  console.log(refTimeoutStale);
    //  console.log(refTimeoutAll);
  };

  const Retry = async (CID: number, WOID: number) => {
    StatefullyMergeWorkOrderResponse(
      await API.Invar.RefireWorkOrder(WOID, CID, _reqmeta),
    );
  };

  const Cancel = async (CID: number, WOID: number) => {
    StatefullyMergeWorkOrderResponse(
      await API.Invar.CancelWorkOrder(WOID, CID, _reqmeta),
    );
  };

  const MutateAudits = (audits: GetWorkOrderResponse[]): any[] => {
    return audits
      .sort((lf, rg) => {
        return lf.RequestedDate < rg.RequestedDate
          ? 1
          : lf.RequestedDate === rg.RequestedDate
            ? 0
            : -1;
      })
      .map((th) => {
        //  console.log(th.RequestedDate);
        //  console.log(th.RequestedDate + "0Z");
        //  console.log(dayjs(th.RequestedDate + "0Z"));
        //  console.log(dayjs(th.RequestedDate + "0Z").toString());
        //  console.log(
        //    dayjs(th.RequestedDate + "0Z").format("HH:mm:ss DD-MM-YYYY"),
        //  );
        return {
          InitialValue: th.InitialValue,
          RequestedBy: th.RequestedBy,
          UGEAF: th.RelevantMetadata.some(
            (ith) =>
              ith.DatumName === "Domain.UGEAF" && ith.DatumValue === "true",
          ),
          PGEAF: th.RelevantMetadata.some(
            (ith) =>
              ith.DatumName === "Domain.PGEAF" && ith.DatumValue === "true",
          ),
          ECF: th.RelevantMetadata.some(
            (ith) =>
              ith.DatumName === "Domain.ECF" && ith.DatumValue === "true",
          ),
          ESF: th.RelevantMetadata.some(
            (ith) =>
              ith.DatumName === "Domain.ESF" && ith.DatumValue === "true",
          ),
          RequestedDate:
            th.RequestedDate === null || th.RequestedDate === undefined
              ? ""
              : dayjs(th.RequestedDate + "0Z").format("HH:mm:ss DD-MM-YYYY"),
          FulfilledDate:
            th.FulfilledDate === null || th.FulfilledDate === undefined
              ? ""
              : dayjs(th.FulfilledDate + "0Z").format("HH:mm:ss DD-MM-YYYY"),
          Status: (
            <span
              className={
                th.Status === "Completed"
                  ? "Mondrian_Status Completed"
                  : th.Status === "Cancelled"
                    ? "Mondrian_Status Cancelled"
                    : th.Status === "Processing"
                      ? "Mondrian_Status Processing"
                      : th.Status === "Stale"
                        ? "Mondrian_Status Stale"
                        : th.Status === "Failed"
                          ? "Mondrian_Status Failed"
                          : th.Status === "Permanently Failed"
                            ? "Mondrian_Status Failed"
                            : ""
              }
            >
              <span className="Label">
                {th.Status === "Completed"
                  ? "Completed"
                  : th.Status === "Cancelled"
                    ? "Cancelled"
                    : th.Status === "Processing"
                      ? "Processing"
                      : th.Status === "Stale"
                        ? "Stale"
                        : th.Status === "Failed"
                          ? "Failed"
                          : th.Status === "Permanently Failed"
                            ? "Failed"
                            : "-"}
              </span>
              {
                ""
                /*
                th.Status !== "Completed" &&th.Status !== "Failed" &&th.Status !== "Cancelled" ? (
                <span className="Mondrian_Status_Detail">
                  {th.SegmentaryStatus.map((seg, k) => {
                    return (
                      <span
                        key={"sv_" + k.toString()}
                        className={
                          "Segment" +
                          (seg.StepAttempts.some(
                            (iseg) => iseg.Completed !== null,
                          )
                            ? " Completed"
                            : seg.StepAttempts.some(
                                  (iseg) => iseg.Failed !== null,
                                )
                              ? " Failed"
                              : seg.StepAttempts.length === 0 &&
                                  seg.Ordinal === 1
                                ? " Originator"
                                : seg.StepAttempts.length === 0 &&
                                    seg.Ordinal !== 1
                                  ? " NotYetReached"
                                  : " InFlight")
                        }
                      >
                        <span className="Ordinal">
                          &nbsp;
                          {
                            //seg.Ordinal.toString()
                          }
                        </span>
                        {
                          //     <div className="Detail">
                          //       <div className="SegmentName">{seg.SegmentName}</div>
                          //       <div className="ActionName">{seg.ActionName}</div>
                          //       <div className="StepAttempts">
                          //         {seg.StepAttempts.map((satt) => {
                          //           return (
                          //             <div className="StepAttempt">{satt.Created}</div>
                          //           );
                          //         })}
                          //       </div>
                          //     </div>
                        }
                      </span>
                    );
                  })}
                </span>
              ) : (
                ""
              )
                */
              }
            </span>
          ),
          Action:
            th.Status === "Processing" || th.Status === "Stale" ? (
              <input
                className="Mondrian_Button"
                type="button"
                value="Cancel"
                onClick={() => {
                  Cancel(th.ConveyanceID, th.WorkOrderID);
                }}
              ></input>
            ) : th.Status === "Failed" ? (
              <input
                className="Mondrian_Button"
                type="button"
                value="Retry"
                onClick={() => {
                  Retry(th.ConveyanceID, th.WorkOrderID);
                }}
              ></input>
            ) : th.Status === "Completed" ? (
              <span>
                {th.RelevantMetadata.find(
                  (ith) => ith.DatumName === "PDFs.Concat.LCL>LCL.Confirmed",
                )?.DatumValue === "false"
                  ? "No documents found."
                  : th.RelevantMetadata.find(
                      (ith) => ith.DatumName === "PDFs.Concat.LCL>LCL.Output",
                    )?.DatumValue}
                {
                  //  <a
                  //    className="Mondrian_Button"
                  //    type="button"
                  //    rel="external"
                  //    href={
                  //      "file:" +
                  //      th.RelevantMetadata.find(
                  //        (ith) => ith.DatumName === "PDFs.Concat.LCL>LCL.Output",
                  //      )?.DatumValue.replaceAll("/", "\\")
                  //    }
                  //    target="_blank"
                  //  >
                  //    Download
                  //  </a>
                }
              </span>
            ) : (
              ""
            ),
        };
      });
  };

  return (
    <div className="HomeOfficeAuditSurface">
      <h2>Home Office Audit</h2>
      <div className="Description">
        To request a report:
        <ul>
          <li>
            Into the Identifiers field, put a comma-separated list of the
            student IDs on which you wish to report. Most identifiers are
            supported:
            <ul className="ViableIdents">
              <li>PRID</li>
              <li>ASR Key</li>
              <li>UCAS No.</li>
              <li>DA No.</li>
              <li>PG No.</li>
              <li>OAD No.</li>
              <li>HESA No.</li>
              <li>Clearing Ref.</li>
              <li>CAS No.</li>
            </ul>
          </li>
          <li>
            In the Report Domain box, select the domains you want to report on
            and confirm.
          </li>
          <li>
            To combine all requested reports into one, check the Collate box.
            Otherwise, one report will be generated per identifier.
          </li>
          <li>Click Submit.</li>
        </ul>
      </div>
      <label htmlFor="IdentsBox">Identifiers</label>
      <br />
      {/* Turns out the <input> tag isn't ever meant to be used for multiline! who knew?!? */}
      <textarea
        rows={5}
        cols={40}
        id="IdentsBox"
        value={idents}
        onChange={(e) => {
          setIdents(e.target.value);
          setInvalidIdents([]);
        }}
      ></textarea>
      {invalidIdents.length > 0 ? (
        <div className="InvalidIdents">
          The following identifiers are invalid. Please alter and try again.
          <br />
          {invalidIdents.map((th) => {
            return <span className="InvalidIdent">{th}</span>;
          })}
        </div>
      ) : (
        <></>
      )}
      <div className="ReportDomains">
        <div>Report Domain(s):</div>
        <label htmlFor="CB_UGEAF">UGEAF</label>
        <input
          id="CB_UGEAF"
          type="checkbox"
          checked={ugeaf}
          onChange={(e) => {
            setUGEAF(e.target.checked);
          }}
        ></input>
        <label htmlFor="CB_PGEAF">PGEAF</label>
        <input
          id="CB_PGEAF"
          type="checkbox"
          checked={pgeaf}
          onChange={(e) => {
            setPGEAF(e.target.checked);
          }}
        ></input>
        <label htmlFor="CB_ECF">ECF</label>
        <input
          id="CB_ECF"
          type="checkbox"
          checked={ecf}
          onChange={(e) => {
            setECF(e.target.checked);
          }}
        ></input>
        <label htmlFor="CB_ESF">ESF</label>
        <input
          id="CB_ESF"
          type="checkbox"
          checked={esf}
          onChange={(e) => {
            setESF(e.target.checked);
          }}
        ></input>
      </div>
      <div className="Collation">
        <label htmlFor="CB_Collate">Collate?</label>
        <input
          id="CB_Collate"
          type="checkbox"
          checked={collate}
          onChange={(e) => {
            setCollate(e.target.checked);
          }}
        ></input>
      </div>

      <button
        className="Submit"
        disabled={
          (!pgeaf && !ugeaf && !ecf && !esf) ||
          idents === "" ||
          idents.length < 4 ||
          invalidIdents.length > 0
        }
        onClick={async (e) => {
          var identArray: string[] = [];

          idents.split("\r").forEach((th1) => {
            th1.split("\n").forEach((th2) => {
              th2.split("\t").forEach((th3) => {
                th3.split(";").forEach((th4) => {
                  th4.split(",").forEach((th5) => {
                    if (th5.trim() !== "") {
                      identArray.push(th5.trim());
                    }
                  });
                });
              });
            });
          });

          var initialMetadata: EnqueueMetadataRequest[] = [
            {
              Name: "Domain.PGEAF",
              Value: pgeaf ? "true" : "false",
            },
            {
              Name: "Domain.UGEAF",
              Value: ugeaf ? "true" : "false",
            },
            {
              Name: "Domain.ECF",
              Value: ecf ? "true" : "false",
            },
            {
              Name: "Domain.ESF",
              Value: esf ? "true" : "false",
            },
          ];

          var _invi: string[] = [];

          var _validationsUnderway: Array<Promise<void>> = [];

          identArray.forEach((th) => {
            _validationsUnderway.push(
              API.Invar.ValidateAnyIdent(
                th,
                "prid,asr_key,ucas_no,pg_no,oad_no,hesa_no,clearing_no,cas_no",
              ).then((data) => {
                if (data === false) {
                  _invi.push(th);
                }
              }),
            );
          });

          await Promise.all(_validationsUnderway);

          //  console.log("val all idents");
          //  console.log(identArray);
          //  console.log(_invi);

          if (_invi.length === 0) {
            if (identArray.length > 1 && collate) {
              const sanitisedIdents = identArray.join(",");

              StatefullyMergeWorkOrderResponse(
                await API.Invar.Enqueue(
                  identity.AuthedUser.userPrincipalName.replace(
                    "@essex.ac.uk",
                    "",
                  ),
                  ThisMultiConveyance.ConveyanceID,
                  props.Environment,
                  sanitisedIdents,
                  initialMetadata,
                ),
              );
            } else if (identArray.length > 1 && !collate) {
              var LPA: Array<Promise<GetWorkOrderResponse>> = [];
              identArray.forEach((th) => {
                LPA.push(
                  API.Invar.Enqueue(
                    identity.AuthedUser.userPrincipalName.replace(
                      "@essex.ac.uk",
                      "",
                    ),
                    ThisConveyance.ConveyanceID,
                    props.Environment,
                    th,
                    initialMetadata,
                  ),
                );
              });
              await Promise.all(LPA);
              LoadAudits();
            } else {
              const sanitisedIdents = identArray.join(",");

              StatefullyMergeWorkOrderResponse(
                await API.Invar.Enqueue(
                  identity.AuthedUser.userPrincipalName.replace(
                    "@essex.ac.uk",
                    "",
                  ),
                  ThisConveyance.ConveyanceID,
                  props.Environment,
                  sanitisedIdents,
                  initialMetadata,
                ),
              );
            }

            setCollate(false);
            setUGEAF(false);
            setPGEAF(false);
            setECF(false);
            setESF(false);

            setIdents("");
          } else {
            setInvalidIdents(_invi);
          }
        }}
      >
        Submit
      </button>
      <MondrianDataProvider
        ObjectArray={mutatedAudits}
        Columns={columns}
        LoadingFlag={loadingResults}
      >
        <Mondrian
          Options={{
            Display: MondrianDisplayType.Grid,
            DisplayOptions: { Alternator: true },
          }}
        ></Mondrian>
      </MondrianDataProvider>
    </div>
  );
}
