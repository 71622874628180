import dayjs from "dayjs";
import Embargo from "../../../models/Embargo";
import Notice from "../../../models/Notice";
import EmbargoRow from "./EmbargoRow";

interface IProps {
  NewEmbargo: () => void;
  NewEmbargoAllowed: boolean;
  Embargoes: Embargo[];
  DeleteEmbargo: (EID: string) => void;
  EditEmbargo: (EID: string) => void;

  Notices: Notice[];
  DeleteNotice: (_noticeID: string) => void;
  EditNotice: (_noticeID: string) => void;
}

export const EmbargoGrid = (props: IProps) => {
  return (
    <div className="EmbargoGrid">
      <div className="HeaderBar">
        Embargoes
        {props.NewEmbargoAllowed ? (
          <div
            onClick={() => {
              props.NewEmbargo();
            }}
            className="NewEmbargoButton"
          >
            + Create New
          </div>
        ) : (
          ""
        )}
      </div>
      {props.Embargoes === undefined || props.Embargoes.length === 0 ? (
        <div className="Empty">No embargoes found</div>
      ) : (
        props.Embargoes.map((th, i) => {
          var conc_nots = props.Notices.filter((ith) => {
            return ith.EmbargoID === th.EmbargoID;
          });

          var cn_cap = conc_nots.filter((th) => {
            return th._scopes.some((ith) => ith === "CurrentApps");
          });

          var cn_ban = conc_nots.filter((th) => {
            return th._scopes.some((ith) => ith === "Banner");
          });

          var cn_app = conc_nots.filter((th) => {
            return th._scopes.some((ith) => ith === "Applicant");
          });

          return (
            <EmbargoRow
              key={"Notice_" + i.toString()}
              Embargo={th}
              EditEmbargo={props.EditEmbargo}
              DeleteEmbargo={props.DeleteEmbargo}
              EditNotice={props.EditNotice}
              DeleteNotice={props.DeleteNotice}
              ConcurrentNotices_ApplicantPage={cn_app}
              ConcurrentNotices_CurrentApplicants={cn_cap}
              ConcurrentNotices_TopBanner={cn_ban}
            ></EmbargoRow>
          );
        })
      )}
    </div>
  );
};

export default EmbargoGrid;
