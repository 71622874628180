import * as React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess, User } from "../../../data/DataAccess";
import { Logging } from "../../../Logging";
import "../../../assets/css/Compliance_LF/Compliance_LF.css";
import IfPermitted from "../../IfPermitted";
import { NavigateFunction } from "react-router-dom";
import { LinkPanel } from "../Components/LinkPanel";
// import { LinksToFlagging } from "../Components/LinksToFlagging";
// import { AuditLinks } from "../Components/AuditLinks";

interface ComplianceHomepageProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  Environment: NonNullable<string>;
  AuthedUser: User;
  ViewAsUser: User;

  Surface: string;
  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Refresher: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;

  Embargo: (scope: string) => boolean;
  HasNotices: (AgentIsPAS: boolean, scopes: string[]) => boolean;
  Notices: (AgentIsPAS: boolean, scopes: string[]) => React.ReactFragment;
  Navigation: NavigateFunction;
}

function ComplianceHomepage(
  props: ComplianceHomepageProps,
): React.ReactElement {
  window.document.title = "University of Essex - Compliance ePortal";

  return (
    <div className="Flexor">
      <IfPermitted Whitelist={["Compliance.View"]}>
        <LinkPanel
          url="/compliance/search"
          title="Search and View"
          subtitle="Auditors"
        >
          Search and view records for Audit purposes
        </LinkPanel>
      </IfPermitted>
      <IfPermitted Whitelist={["Compliance.AuditReportAuthorised"]}>
        <LinkPanel
          url="/compliance/audit"
          title="Home Office Audit Reports"
          subtitle="Reporting"
        >
          Generate Home Office Audit Reports
        </LinkPanel>
      </IfPermitted>
      <IfPermitted Whitelist={["Compliance.GDPRReportAuthorised"]}>
        <LinkPanel
          url="/compliance/sar"
          title="GDPR/SAR Reports"
          subtitle="Reporting"
        >
          Generate GDPR/SAR Reports
        </LinkPanel>
      </IfPermitted>
      <IfPermitted Whitelist={["Compliance.StudentStayEdit"]}>
        <LinkPanel
          url="/compliance/search/student"
          title="Student Files for Audit"
          subtitle="Students"
        >
          Set Student Files (ESF & ECF) for Audit Inclusion
        </LinkPanel>
      </IfPermitted>
      <IfPermitted Whitelist={["Compliance.AdmissionsEdit"]}>
        <LinkPanel
          url="/compliance/search/ug"
          title="UG Applicant Files for Audit"
          subtitle="Admissions"
        >
          Set UG Applicant Files for Audit Inclusion
        </LinkPanel>
      </IfPermitted>
      <IfPermitted Whitelist={["Compliance.AdmissionsEdit"]}>
        <LinkPanel
          url="/compliance/search/pg"
          title="PG Applicant Files for Audit"
          subtitle="Admissions"
        >
          Set PG Applicant Files for Audit Inclusion
        </LinkPanel>
      </IfPermitted>

      <IfPermitted Whitelist={["Compliance.GDPRReportAuthorised"]}>
        <LinkPanel
          url="https://essexuniversity.sharepoint.com/sites/edrm"
          title="EDRM Search Hub"
          target="_blank"
        >
          The EDRM Document Hub (Opens in new tab)
        </LinkPanel>
      </IfPermitted>

      <div className="StructPanel" style={{ border: 0 }} hidden={true}></div>
      <div className="StructPanel" style={{ border: 0 }} hidden={true}></div>
    </div>
  );
}

export default ComplianceHomepage;
