import { MondrianDisplayType } from "../Structs/Enums/MondrianDisplayType";
import { FilterColumnOptions } from "../Structs/Interfaces/FilterColumnOptions";
import { HideabilityColumnOptions } from "../Structs/Interfaces/HideabilityColumnOptions";
import { KeyItem } from "../Structs/Interfaces/KeyItem";
import { MondrianDisplayOptions } from "../Structs/Interfaces/MondrianDisplayOptions";
import { SoupColumnOptions } from "../Structs/Interfaces/SoupColumnOptions";
import { MondrianStructuredData } from "../Structs/Types/MondrianStructuredData";
import "./Grid.css";

interface IProps {
  Display: MondrianDisplayType;
  DisplayOptions: MondrianDisplayOptions;
  FilterOptions: FilterColumnOptions[];
  SoupOptions: SoupColumnOptions[];
  HideabilityOptions: HideabilityColumnOptions[];
  KeyOptions: KeyItem[];
  Data: MondrianStructuredData;
}

export const MondrianGrid = (props: IProps) => {
  //  console.log("Rendering MondrianGrid...");
  //  console.log(props);

  return props.Data.Loading ? (
    <div className="Mondrian__NoResults">
      <span className="infoicon">&#8505;</span> Loading...
    </div>
  ) : props.Data.Rows === undefined || props.Data.Rows.length === 0 ? (
    <div className="Mondrian__NoResults">
      <span className="infoicon">&#8505;</span> No results were returned.
    </div>
  ) : (
    <div
      className={
        "Mondrian__Grid" +
        (props.DisplayOptions.Alternator ? " Alternator" : "")
      }
    >
      <div className="Mondrian__Grid_Header">
        {props.Data.Columns?.map((th, i) => {
          if (
            props.HideabilityOptions?.some(
              (ith) =>
                th.MachineNames.some((iith) => iith === ith.Column) &&
                ith.Hidden === true,
            )
          ) {
            return <></>;
          } else {
            return (
              <div
                key={"MGHC_" + i.toString()}
                className="Mondrian__Grid_Header_Cell"
              >
                {th.HumanName}
              </div>
            );
          }
        })}
      </div>
      <div className="Mondrian__Grid_Body">
        {props.Data.Rows?.map((th, i) => {
          //  console.log("Mondrian__Grid_Body");
          //  console.log(th);
          return (
            <div
              className={
                th._PROCESSING
                  ? "Mondrian__Grid_Row_Processing"
                  : "Mondrian__Grid_Row"
              }
              key={"row" + i.toString()}
            >
              {th.Cells.map((ith, j) => {
                return (
                  <div
                    className="Mondrian__Grid_Cell"
                    key={"row" + i.toString() + "cell" + j.toString()}
                  >
                    {ith.CellValues.map((iith, k) => {
                      return (
                        <div
                          key={
                            "row" +
                            i.toString() +
                            "cell" +
                            j.toString() +
                            "frag" +
                            k.toString()
                          }
                        >
                          {iith.Value === true ? (
                            <span className="Mondrian__Check_True">✔</span>
                          ) : iith.Value === false ? (
                            <span className="Mondrian__Check_False">✘</span>
                          ) : (
                            iith.Value
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
