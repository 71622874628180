import * as React from "react";

import "../assets/css/Article.css";
import { ScrollRestoration } from "react-router-dom";

interface IProps {
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
}

class KnownIssues extends React.Component<IProps> {
  render() {
    window.document.title = "University of Essex - EDRM Help Pages";
    return (
      <div className="Article Help" id="pagetop">
        <ScrollRestoration />
        {/* When making changes in this file, ensure you change the dates at 
        the top of this file, and change the date in the footer as well */}
        <div className="WelcomeBar">
          <h1>Welcome to the EDRM Known Issues page</h1>
          <div className="Subtitle">Article last updated 28th June 2024.</div>
          <div className="DescriptionBlock">
            This page has been set up as resource to highlight known issues for
            ESF/EAF/ECF users. We hope you find it useful. If you have
            suggestions for additional items to include, please send your
            suggestion to{" "}
            <a href="mailto:sphelp@essex.ac.uk">
              <strong>sphelp@essex.ac.uk</strong>
            </a>
            .
          </div>
        </div>
        <ul>
          <li>
            <a href="#resolved">Jump To Resolved Issues</a>
          </li>
        </ul>
        <div className="Segmentary">
          <div className="Segments">
            <div className="Segment">
              <h2>Known Issues</h2>
              <p>
                <ul>
                  <li>
                    <strong>
                      The 'ESF/PGEAF MyTasks Completed' page still times-out.
                    </strong>{" "}
                    <p>
                      This still happens for some users. This is due to the
                      volume of data it needs to refer to. The SharePoint Team
                      are still investigating a long-term solution to this
                      problem.
                    </p>
                  </li>
                  <li>
                    <strong>
                      My Outlook App is still showing the old version?
                    </strong>{" "}
                    <p>
                      It can take Microsoft many hours (sometimes more than a
                      day) to apply new AddIns to your email account. However,
                      Outlook also has a nasty habit of remembering (caching)
                      the old version. So before contacting the SharePoint Team,
                      try clicking in the "Upload to SharePoint" panel of the
                      Outlook AddIn and pressing CTRL+F5 to refresh the panel.
                    </p>
                  </li>
                  <li>
                    <strong>My Outlook App Icon looks wrong?</strong>{" "}
                    <p>
                      The icon for the Upload to SharePoint Outlook App ('Cloud
                      with an arrow') sometimes reverts back to the default 'two
                      hexagons' App icon. We are looking into why this happens
                      (most frequently on Outlook Desktop). The App will still
                      work successfully despite this. This is a cosmetic issue
                      only.
                    </p>
                  </li>
                  <li>
                    <strong>
                      Not all users are seeing the new Cloud/Arrow icon on the
                      Outlook App.
                    </strong>{" "}
                    <p>
                      Currently, Outlook seems to be having an occasional issue
                      with the Uploader App icon not being displayed correctly.
                      <p>
                        <img
                          src={"/helpimages/OutlookAppIcon.png"}
                          alt={"Outlook App Icon"}
                          // width={"569"}
                          // height={"333"}
                        />
                        <img
                          src={"/helpimages/OutlookAppHexIcon.png"}
                          alt={"Outlook App Hex Icon"}
                          // width={"569"}
                          // height={"333"}
                        />
                      </p>
                      <br />
                      The primary concern is when you click on the icon that is
                      present, do you get a circular loading bar, or a
                      horizontal one. As long as the circular one is present
                      this is good.
                      <br />
                      An alternate way to check is to follow the steps listed
                      here, this will take you to the webmail version of
                      outlook:
                      <ul>
                        <li>
                          Go to{" "}
                          <a href="https://email.essex.ac.uk" target="_blank">
                            email.essex.ac.uk
                          </a>{" "}
                          in a web browser, login as prompted.
                        </li>
                        <li>
                          On the left column of icons “more apps” (icon that
                          looks like a cooker hob with red highlight).{" "}
                          <p>
                            <img
                              src={"/helpimages/OutlookLHtab.jpg"}
                              alt={"Icon Tab LH Side"}
                              // width={"569"}
                              // height={"333"}
                            />
                          </p>
                        </li>
                        <li>
                          Select <strong>Add Apps</strong> button as shown
                          above.
                        </li>
                        <li>
                          On the next screen click on the{" "}
                          <strong>Upload to SharePoint</strong> section (under
                          the <strong>Built for your organisation</strong>{" "}
                          header).
                          <p>
                            <img
                              src={"/helpimages/UploadtoSPAppSelect.png"}
                              alt={"Upload to SharePoint App Selection"}
                              // width={"569"}
                              // height={"333"}
                            />
                          </p>
                        </li>
                        <li>
                          Then check the version number, it should display
                          version 3.0.0.0 (not 2.0.0.0).
                          <p>
                            <img
                              src={"/helpimages/OutlookAppVersion.png"}
                              alt={"Outlook App version"}
                              // width={"569"}
                              // height={"333"}
                            />
                          </p>
                        </li>
                      </ul>
                      Even if you usually use the App via Outlook Desktop, the
                      version number shown in webmail (
                      <a href="https://email.essex.ac.uk" target="_blank">
                        email.essex.ac.uk
                      </a>
                      ) will be the same one you see in your Outlook Desktop
                      program.
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div>
            <br />
            <hr></hr>
            <br />
          </div>
          <div className="Segments">
            <h2 id="resolved">
              Resolved Issues{" "}
              <small>
                <a href="#pagetop">Return to Top</a>
              </small>
            </h2>
            <p>
              <ul>
                <li>
                  <strong>
                    Problems opening the DRF From Workflow completed Panel on
                    EAF pages. (Resolved - 26/06/2024)
                  </strong>{" "}
                  <p>
                    Currently, the hyperlinks to completed Admissions PG and UG
                    Decision Review Forms in the 'Workflows Completed' panel
                    (right-hand side) will not work for users outside of
                    Admissions. A fix for this issue has been completed.
                  </p>
                </li>
                <li>
                  <strong>
                    Selectors unable to find link to MyTasks in PGEAF service.
                    (Resolved - 25/06/2024)
                  </strong>{" "}
                  <p>
                    Currently, Selectors in the PGEAF service are unable to find
                    the My Tasks Screen. A workaround had been in place to go to
                    the ESF service and access from that location. A fix for
                    this issue has been completed, by adding a 'My Tasks' button
                    on the front page and a link to My tasks in the quick launch
                    charm at the top right of the screen.
                  </p>
                </li>
                <li>
                  <strong>
                    Some files (mostly images) will be listed TWICE in the eDocs
                    panel. (Resolved - 28/06/2024)
                  </strong>{" "}
                  <p>
                    This shows for applicants/students whilst files are being
                    indexed. This is a known issue, and currently unresolved due
                    to how Microsoft stores images on SharePoint Online. After
                    24 hours, the duplicate record should automatically
                    disappear. The SharePoint Team are still investigating a
                    long-term solution to this problem.
                  </p>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default KnownIssues;
