import { useState } from "react";
import { InvarDataAccess } from "../../../data/InvarDataAccess";
import { ConveyanceSegmentConfigResponse } from "../../../data/InvarDataModels/ConveyanceSegmentConfigResponse";

interface IProps {
  IDA: InvarDataAccess;
  ConveyanceSegmentConfig: ConveyanceSegmentConfigResponse;
  EditConveyanceSegmentConfig: (
    CSCID: number,
    CSID: number,
    newName: string,
    newValue: string,
  ) => void;
}

export const InvarAdminConveyanceSegmentConfig = (props: IProps) => {
  const [editMe, setEditMe] = useState(false);
  const [editName, setEditName] = useState("");
  const [editValue, setEditValue] = useState("");

  return (
    <div className="ConvSegConf">
      {editMe ? (
        <>
          <input
            type="text"
            value={editName}
            onChange={(e) => {
              setEditName(e.target.value);
            }}
          ></input>
          <br />
          <textarea
            rows={5}
            cols={30}
            value={editValue}
            onChange={(e) => {
              setEditValue(e.target.value);
            }}
          ></textarea>
        </>
      ) : (
        <>
          <div className="ConvSegConfName">
            {props.ConveyanceSegmentConfig.ConfigName}
          </div>
          <div className="ConvSegConfValue">
            {props.ConveyanceSegmentConfig.ConfigValue}
          </div>
        </>
      )}
      {editMe ? (
        <>
          <div
            className="ConvSegConfEdit"
            onClick={() => {
              setEditMe(false);
              setEditName("");
              setEditValue("");
            }}
          >
            ✘
          </div>
          <div
            className="ConvSegConfEdit"
            onClick={() => {
              props.EditConveyanceSegmentConfig(
                props.ConveyanceSegmentConfig.ConveyanceSegmentConfigID,
                props.ConveyanceSegmentConfig.ConveyanceSegmentID,
                editName,
                editValue,
              );
              setEditMe(false);
              setEditName("");
              setEditValue("");
            }}
          >
            ✔
          </div>
        </>
      ) : (
        <div
          className="ConvSegConfEdit"
          onClick={() => {
            setEditMe(true);
            setEditName(props.ConveyanceSegmentConfig.ConfigName);
            setEditValue(props.ConveyanceSegmentConfig.ConfigValue);
          }}
        >
          ✎
        </div>
      )}
      <div
        className="ConvSegConfDelete"
        onClick={async () => {
          props.IDA.DeleteConveyanceSegmentConfig(
            props.ConveyanceSegmentConfig.ConveyanceSegmentConfigID,
          );
        }}
      >
        🗑
      </div>
    </div>
  );
};
