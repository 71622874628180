import { useState } from "react";
import { InvarDataAccess } from "../../../data/InvarDataAccess";
import { ConveyanceResponse } from "../../../data/InvarDataModels/ConveyanceResponse";
import { BrokerageResponse } from "../../../data/InvarDataModels/BrokerageResponse";

interface IProps {
  IDA: InvarDataAccess;
  Conveyance: ConveyanceResponse;
  theseBrokerages: Array<BrokerageResponse>;
  setTheseBrokerages: (inbound: Array<BrokerageResponse>) => void;
}

export const InvarAdminConveyanceBrokerages = (props: IProps) => {
  const [showAddBrokerage, setShowAddBrokerage] = useState(false);
  const [_nbenv, setNBEnv] = useState("");
  const [_nbname, setNBName] = useState("");

  return (
    <div className="ConvBrokerages">
      <div className="ConvBrokeragesTitleRow">
        <div className="ConvBrokeragesTitle">Brokerages:</div>
        <div
          className="ConvBroksAddButton"
          onClick={() => {
            setShowAddBrokerage(!showAddBrokerage);
          }}
        >
          {!showAddBrokerage ? "[+]" : "[>]"}
        </div>
        {showAddBrokerage ? (
          <>
            <label>Environment:</label>
            <input
              required={true}
              type="text"
              value={_nbenv}
              onChange={(ev) => {
                setNBEnv(ev.target.value);
              }}
              className="ConvBroksAddMachine"
            ></input>
            <label>NAME:</label>
            <input
              onChange={(ev) => {
                setNBName(ev.target.value);
              }}
              value={_nbname}
              className="ConvBroksAddMachine"
              type="text"
            ></input>
            <div
              className="ConvBroksAdd"
              onClick={async () => {
                if (
                  _nbenv === "" ||
                  _nbenv === "LIVE" ||
                  _nbenv === "TEST" ||
                  _nbenv === "DEV" ||
                  _nbenv === "LOCAL"
                ) {
                  props.setTheseBrokerages(
                    [...props.theseBrokerages]
                      .concat(
                        await props.IDA.CreateBrokerage(
                          _nbenv,
                          _nbname,
                          props.Conveyance.ConveyanceID,
                        ),
                      )
                      .sort((l, r) => {
                        if (
                          l.MachineName.toLowerCase() <
                          r.MachineName.toLowerCase()
                        )
                          return -1;
                        if (
                          l.MachineName.toLowerCase() >
                          r.MachineName.toLowerCase()
                        )
                          return 1;
                        return 0;
                      }),
                  );
                  setNBEnv("");
                  setNBName("");
                }
              }}
            >
              Add New
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="ConvBrokeragesBody">
        {props.theseBrokerages.map((th, i) => {
          return (
            <div
              className={
                "ConvBrokerage" + (!th.Active ? " Inactive" : " Active")
              }
              key={"brokerage_" + i.toString()}
            >
              <div className={"ConvBrokerageEnvironment ENV" + th.Environment}>
                {th.Environment}
              </div>
              <div className={"ConvBrokerageMachineName ENV" + th.Environment}>
                {th.MachineName}
              </div>
              <div
                className={
                  "ConvBrokerageStatus" +
                  (!th.Active ? " Disabled" : " Enabled")
                }
              >
                {!th.Active ? "Disabled" : "Enabled"}
              </div>
              {th.Active ? (
                <div
                  className="InvarButton ConvBrokerageDeactivate"
                  onClick={async () => {
                    props.setTheseBrokerages(
                      [...props.theseBrokerages]
                        ?.filter((ith) => ith.BrokerageID !== th.BrokerageID)
                        ?.concat(
                          await props.IDA.DisableBrokerage(th.BrokerageID),
                        )
                        .sort((l, r) => {
                          if (
                            l.MachineName.toLowerCase() <
                            r.MachineName.toLowerCase()
                          )
                            return -1;
                          if (
                            l.MachineName.toLowerCase() >
                            r.MachineName.toLowerCase()
                          )
                            return 1;
                          return 0;
                        }),
                    );
                  }}
                >
                  Disable
                </div>
              ) : (
                <div
                  className="InvarButton ConvBrokerageActivate"
                  onClick={async () => {
                    props.setTheseBrokerages(
                      [...props.theseBrokerages]
                        ?.filter((ith) => ith.BrokerageID !== th.BrokerageID)
                        ?.concat(
                          await props.IDA.EnableBrokerage(th.BrokerageID),
                        )
                        .sort((l, r) => {
                          if (
                            l.MachineName.toLowerCase() <
                            r.MachineName.toLowerCase()
                          )
                            return -1;
                          if (
                            l.MachineName.toLowerCase() >
                            r.MachineName.toLowerCase()
                          )
                            return 1;
                          return 0;
                        }),
                    );
                  }}
                >
                  Enable
                </div>
              )}
              <div
                className="InvarButton ConvBrokerageDelete"
                onClick={async () => {
                  if (await props.IDA.DeleteBrokerage(th.BrokerageID)) {
                    props.setTheseBrokerages(
                      [...props.theseBrokerages]
                        ?.filter((ith) => ith.BrokerageID !== th.BrokerageID)
                        .sort((l, r) => {
                          if (
                            l.MachineName.toLowerCase() <
                            r.MachineName.toLowerCase()
                          )
                            return -1;
                          if (
                            l.MachineName.toLowerCase() >
                            r.MachineName.toLowerCase()
                          )
                            return 1;
                          return 0;
                        }),
                    );
                  }
                }}
              >
                Delete
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
