import { useEffect, useState } from "react";
import { useUser } from "../../Providers/UserProvider";
import { InvarDataAccess } from "../../data/InvarDataAccess";
import { GetMicroWorkOrdersResponse } from "../../data/InvarDataModels/GetMicroWorkOrdersResponse";
import { Link } from "react-router-dom";
import {
  AirplaneFilled,
  CheckmarkFilled,
  ChevronRightRegular,
  ErrorCircleRegular,
  SettingsFilled,
} from "@fluentui/react-icons";
import { SURFACE, useSurface } from "../../Providers/SurfaceProvider";

export type InvarMicroStatusProps = {
  IDA: InvarDataAccess;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
};

export function InvarMicroStatus(props: InvarMicroStatusProps) {
  const userauth = useUser();
  const surfactant = useSurface();
  const [username, setUsername] = useState("");
  const [MWOR, setMWOR] = useState(GetMicroWorkOrdersResponse.prototype);

  // Router's _meant_ to find the closest route match to current, but... isn't, for this one? Wonder what I'm missing.
  const _url =
    surfactant.SURFACE === SURFACE.ESF_COMPLIANCE
      ? "compliance/utilities/invar"
      : surfactant.SURFACE === SURFACE.EAF_PGEAF
        ? "pgeaf/utilities/invar"
        : surfactant.SURFACE === SURFACE.EAF_UGEAF
          ? "ugeaf/utilities/invar"
          : surfactant.SURFACE === SURFACE.EAF_CLEARING
            ? "clearing/utilities/invar"
            : "utilities/invar";

  useEffect(() => {
    //  console.log(userauth);
    //  console.log(userauth?.ViewAsUser?.userPrincipalName);
    //  console.log(userauth?.AuthedUser?.userPrincipalName);
    setUsername(
      userauth?.ViewAsUser?.userPrincipalName?.replace("@essex.ac.uk", "") ||
        userauth?.AuthedUser?.userPrincipalName?.replace("@essex.ac.uk", ""),
    );
  }, [userauth]);

  useEffect(() => {
    //  console.log("username change detected");
    //  console.log(username);
    let isMounted = true;
    if (username !== undefined && username !== "") {
      props.IDA.GetMicroWorkOrdersForUser(username, props.Environment).then(
        (data) => {
          //  console.log();
          setMWOR(data);
        },
      );
    }
    return () => {
      isMounted = false;
    };
  }, [username]);

  //  console.log("InvarMicroStatus");
  //  console.log(userauth);
  //  console.log(MWOR);

  if (
    MWOR === undefined ||
    MWOR.FailCount === undefined ||
    MWOR.InFlightCount === undefined ||
    MWOR.SuccessCount === undefined
  ) {
    return (
      <Link to={_url}>
        <div className="InvarMicroStatus">
          <div className="Loading">-</div>
        </div>
      </Link>
    );
  } else if (
    MWOR.FailCount === 0 &&
    MWOR.InFlightCount === 0 &&
    MWOR.SuccessCount === 0
  ) {
    return (
      <Link to={_url}>
        <div className="InvarMicroStatus">
          You have not yet started any automations.
        </div>
      </Link>
    );
  } else {
    return (
      <Link to={_url} className="JustPointer">
        <div className="InvarMicroStatusTitle">Your Automations</div>
        <div className="InvarMicroStatus">
          <SettingsFilled></SettingsFilled>
          <ChevronRightRegular></ChevronRightRegular>
          <AirplaneFilled className="InFlight"></AirplaneFilled>
          <div className="InFlight">{MWOR.InFlightCount.toString()}</div>
          <CheckmarkFilled className="Completed"></CheckmarkFilled>
          <div className="Completed">{MWOR.SuccessCount.toString()}</div>
          <ErrorCircleRegular className="Failed"></ErrorCircleRegular>
          <div className="Failed"> {MWOR.FailCount.toString()}</div>
        </div>
      </Link>
    );
  }
}
