import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import "../../assets/css/ADMIN_LF/Invar.css";
import { useApi } from "../../Providers/ApiProvider";
import { MonitoredWorkOrderResponse } from "../../data/InvarDataModels/MonitoredWorkOrderResponse";
import { ConveyanceResponse } from "../../data/InvarDataModels/ConveyanceResponse";
import { Mondrian } from "../Mondrian/Mondrian";
import { MondrianDataProvider } from "../Mondrian/MondrianDataProvider";
import { MondrianDisplayType } from "../Mondrian/Structs/Enums/MondrianDisplayType";
import { MondrianColumn } from "../Mondrian/Structs/Types/MondrianStructuredData";

export const InvarMonitorAll = () => {
  const [MonitorConveyanceIDs, setMonitorConveyanceIDs] = useState(
    Array<number>,
  );
  const [MonitorOrderBySlowest, setMonitorOrderBySlowest] = useState(false);
  const [MonitorOrderByRecent, setMonitorOrderByRecent] = useState(true);
  const [MonitorLimit, setMonitorLimit] = useState(100);
  const [MonitorFrom, setMonitorFrom] = useState(undefined);
  const [MonitorUntil, setMonitorUntil] = useState(undefined);
  const [MonitorSearchTerm, setMonitorSearchTerm] = useState("");

  const [SearchStale, setSearchStale] = useState(false);

  const refTimeoutProcessing = useRef(null);
  const refTimeoutStale = useRef(null);
  const refTimeoutAll = useRef(null);

  const [theseWorkOrders, setTheseWorkOrders] = useState(
    Array<MonitoredWorkOrderResponse>,
  );
  const [theseConveyances, setTheseConveyances] = useState(
    Array<ConveyanceResponse>,
  );

  const [mutatedWorkOrders, setMutatedWorkOrders] = useState(Array<any>);

  const [loadingWorkOrders, setLoadingWorkOrders] = useState(true);

  const columns: MondrianColumn[] = [
    { HumanName: "Work Order", MachineNames: ["WorkOrder"] },
    { HumanName: "_ROW_PROCESSING", MachineNames: ["_ROW_PROCESSING"] },
  ];

  const DA = useApi();
  const IDA = DA.Invar;

  useEffect(() => {
    if (IDA !== undefined) {
      if (theseConveyances === undefined || theseConveyances.length === 0) {
        LoadConveyances();
      }
      Load();
    }
  }, [IDA]);

  const StatefullyMergeWorkOrderResponse = (
    MWOR: MonitoredWorkOrderResponse,
  ) => {
    setTheseWorkOrders(
      [...theseWorkOrders]
        .filter((th) => th.WorkOrderID !== MWOR.WorkOrderID)
        .concat(MWOR),
    );
  };

  const StatefullyMergeWorkOrderResponses = (
    MWORs: MonitoredWorkOrderResponse[],
  ) => {
    setTheseWorkOrders(
      [...theseWorkOrders]
        .filter(
          (th) => !MWORs.some((ith) => ith.WorkOrderID === th.WorkOrderID),
        )
        .concat(MWORs),
    );
  };

  const Retry = async (WOID: number) => {
    StatefullyMergeWorkOrderResponse(await IDA.RefireMonitoredWorkOrder(WOID));
  };

  const Cancel = async (WOID: number) => {
    StatefullyMergeWorkOrderResponse(await IDA.CancelMonitoredWorkOrder(WOID));
  };

  const Archive = async (WOID: number) => {
    await IDA.ArchiveMonitoredWorkOrder(WOID);

    setTheseWorkOrders(
      [...theseWorkOrders].filter((th) => th.WorkOrderID !== WOID),
    );
  };

  const Clone = async (WOID: number) => {
    IDA.CloneMonitoredWorkOrder(WOID).then((data) => {
      setTheseWorkOrders([...theseWorkOrders].concat(data));
    });
  };

  const Activate = async (WOID: number) => {
    StatefullyMergeWorkOrderResponse(
      await IDA.ActivateMonitoredWorkOrder(WOID),
    );
  };

  const PollWorkOrders = async (
    woids: MonitoredWorkOrderResponse[],
    relay: boolean,
  ) => {
    const _wors: MonitoredWorkOrderResponse[] = [];
    var tasks = [];
    woids.forEach((th) => {
      tasks.push(
        IDA.MonitorThisWorkOrder(th.WorkOrderID).then((data) => {
          _wors.push(data);
        }),
      );
    });
    await Promise.all(tasks);
    StatefullyMergeWorkOrderResponses(_wors);
    if (relay) {
      RegenerateTimers();
    }
  };

  const RegenerateTimers = () => {
    //  console.log("RegenerateTimers");
    //  console.log(refTimeoutProcessing);
    //  console.log(refTimeoutStale);
    //  console.log(refTimeoutAll);

    if (refTimeoutProcessing !== undefined) {
      if (refTimeoutProcessing.current !== undefined) {
        clearTimeout(refTimeoutProcessing.current);
        refTimeoutProcessing.current = undefined;
      }
    }

    if (refTimeoutStale !== undefined) {
      if (refTimeoutStale.current !== undefined) {
        clearTimeout(refTimeoutStale.current);
        refTimeoutStale.current = undefined;
      }
    }

    if (refTimeoutAll !== undefined) {
      if (refTimeoutAll.current !== undefined) {
        clearTimeout(refTimeoutAll.current);
        refTimeoutAll.current = undefined;
      }
    }

    var Processings: MonitoredWorkOrderResponse[] = [];

    // for each work order marked as Processing, we register a new Interval with JS to refresh that particular Work Order after 15s.
    //  console.log(audits);

    theseWorkOrders.forEach((th) => {
      if (th.Active) {
        //  console.log("RegenerateTimers - proc +1");
        Processings.push(th);
      }
    });

    //  console.log(Processings);
    if (Processings.length > 0) {
      refTimeoutProcessing.current = setTimeout(async () => {
        PollWorkOrders(Processings, true);
      }, 5000);
    }

    if (Processings.length === 0) {
      refTimeoutAll.current = setTimeout(async () => {
        Load();
        RegenerateTimers();
      }, 15000);
    }

    //  console.log(refTimeoutProcessing);
    //  console.log(refTimeoutStale);
    //  console.log(refTimeoutAll);
  };

  useEffect(() => {
    setMutatedWorkOrders(MutateWorkOrders(theseWorkOrders));
    setSearchStale(false);
    setLoadingWorkOrders(false);
  }, [theseWorkOrders]);

  const MutateWorkOrders = (
    workOrders: MonitoredWorkOrderResponse[],
  ): any[] => {
    console.log(theseConveyances);

    return (
      workOrders
        //  .sort((lf, rg) => {
        //    return lf.Created < rg.Created ? 1 : lf.Created === rg.Created ? 0 : -1;
        //  })
        .map((th) => {
          return {
            WorkOrder: (
              <div className={"WOCard" + (th.Poison ? " Poisoned" : "")}>
                <div className="WOCardTop">
                  <div className="WOCardTop_WOID">
                    <b>WOID</b>
                    <span>{th.WorkOrderID.toString()}</span>
                  </div>
                  <div className="WOCardTop_ConveyanceName">
                    <b>Conveyance</b>
                    <span>
                      {
                        theseConveyances?.find(
                          (ith) => ith.ConveyanceID === th.ConveyanceID,
                        )?.Name
                      }
                    </span>
                  </div>
                  <div className="WOCardTop_Status">
                    <b>Status</b>
                    <span>
                      {th.Poison
                        ? "Poisoned"
                        : th.Cancelled
                          ? "Cancelled"
                          : th.Active
                            ? "In-Flight"
                            : th.Completed !== undefined
                              ? "Completed"
                              : "Pending"}
                    </span>
                  </div>
                </div>
                <div className="WOCardBody">
                  <span className="WOCardBody_InitialValue">
                    {th.InitialValue}
                  </span>
                </div>
                <div className="WOCardBody">
                  <span className="WOCardBody_ViewButton">
                    <a
                      className="Mondrian_Button Split"
                      type="button"
                      href={
                        "/AdminPortal/Invar/Monitor/" +
                        th.WorkOrderID.toString()
                      }
                      target="_blank"
                    >
                      <span className="Mondrian__Icon_Neutral Bump">⇗</span>
                      <span>View</span>
                    </a>
                    <div
                      className="Mondrian_Button Split"
                      onClick={() => {
                        Archive(th.WorkOrderID);
                      }}
                    >
                      <span className="Mondrian__Icon_Neutral">🗑</span>
                      <span>Archive</span>
                    </div>
                    <div
                      className="Mondrian_Button Split"
                      onClick={() => {
                        Clone(th.WorkOrderID);
                      }}
                    >
                      <span className="Mondrian__Icon_Neutral Bump">🐑🐑</span>
                      <span>Clone</span>
                    </div>
                    {!th.Active &&
                    th.Completed === undefined &&
                    !th.Cancelled &&
                    !th.Poison ? (
                      <div
                        className="Action"
                        onClick={() => {
                          Activate(th.WorkOrderID);
                        }}
                      >
                        Activate
                      </div>
                    ) : (
                      <></>
                    )}
                    <a
                      type="button"
                      className="Mondrian_Button Split"
                      href={
                        "/AdminPortal/Invar/Conveyance/" +
                        th.ConveyanceID.toString()
                      }
                      target="_blank"
                    >
                      <span className="Mondrian__Icon_Neutral">⇗</span>
                      <span>View Conveyance Structure</span>
                    </a>
                  </span>
                </div>
                <div className="WOCardTime">
                  <div className="WOCardTime_CreatedBy">
                    <b>Created By</b>
                    <span>{th.CreatedBy}</span>
                  </div>
                  <div className="WOCardTime_Enqueued">
                    <b>Enqueued</b>
                    <span>{th.Created}</span>
                  </div>
                  <div className="WOCardTime_FromTo">
                    <b>&gt;&gt;&gt;</b>
                    <span>&gt;&gt;&gt;</span>
                  </div>
                  <div className="WOCardTime_Finished">
                    <b>Finished</b>
                    <span>{th.Completed}</span>
                  </div>
                  <div className="WOCardTime_Duration">
                    <b>Duration</b>
                    <span>
                      {dayjs(0)
                        .utc()
                        .add(
                          dayjs(th.Completed)
                            .utc()
                            .diff(dayjs(th.Created).utc()),
                          "millisecond",
                        )
                        .format("HH.mm.ss.SSS")}
                    </span>
                  </div>
                </div>
              </div>
            ),
          };
        })
    );
  };

  async function LoadConveyances() {
    setTheseConveyances(await IDA.GetConveyances());
  }

  async function Load() {
    setLoadingWorkOrders(true);
    setTheseWorkOrders(
      await IDA.MonitorWorkOrders(
        MonitorOrderBySlowest,
        MonitorOrderByRecent,
        MonitorLimit,
        MonitorConveyanceIDs,
        MonitorFrom?.toDate(),
        MonitorUntil?.toDate(),
        MonitorSearchTerm,
      ),
    );
  }

  return (
    <div className="InvarMonitorAll">
      <div className="ParamBar">
        <div className="ParamFlex">
          <h2>Monitor All</h2>
          <hr />
          <h4>Order By</h4>
          <div className="MultiToggle">
            <div
              className={"Toggle" + (MonitorOrderByRecent ? " Active" : "")}
              onClick={() => {
                var val = MonitorOrderByRecent;
                setSearchStale(true);
                setMonitorOrderByRecent(!val);
                setMonitorOrderBySlowest(val);
              }}
            >
              Recent
            </div>
            <div
              className={"Toggle" + (MonitorOrderBySlowest ? " Active" : "")}
              onClick={() => {
                var val = MonitorOrderBySlowest;
                setSearchStale(true);
                setMonitorOrderByRecent(val);
                setMonitorOrderBySlowest(!val);
              }}
            >
              Slowest
            </div>
          </div>
          <hr />
          <h4>Conveyances</h4>
          <select
            className="ConveyancesSelector"
            multiple={true}
            onChange={(e) => {
              const newCIDS: number[] = [];
              // console.log(e.target.selectedOptions);
              for (var v = 0; v < e.target.selectedOptions.length; v++) {
                // console.log(e.target.selectedOptions[v]);
                console.log(e.target.selectedOptions[v].value);
                newCIDS.push(
                  Number.parseInt(e.target.selectedOptions[v].value),
                );
              }
              setMonitorConveyanceIDs(newCIDS);
              setSearchStale(true);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                Load();
              }
            }}
          >
            {theseConveyances.map((th, i) => {
              return (
                <option key={"conv_" + i.toString()} value={th.ConveyanceID}>
                  {th.Name}
                </option>
              );
            })}
          </select>
          <hr />
          <div className="Group">
            <label htmlFor="searchinput" className="Plain">
              Search Term:
            </label>
            <input
              type="text"
              id="searchinput"
              className="SearchText"
              placeholder="Search Term"
              value={MonitorSearchTerm}
              onChange={(e) => {
                setSearchStale(true);
                setMonitorSearchTerm(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  Load();
                }
              }}
            ></input>
          </div>
          <div className="Group">
            <label htmlFor="limitinput" className="Plain">
              Limit:
            </label>
            <input
              id="limitinput"
              type="numeric"
              className="LimitText"
              value={MonitorLimit}
              onChange={(e) => {
                setSearchStale(true);
                var tex = e.target.value;
                var num = 0;
                if (tex === "") {
                  num = 100;
                } else {
                  num = Number.parseInt(tex);
                }
                setMonitorLimit(num);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  Load();
                }
              }}
            ></input>
          </div>
          <hr />
          <h4>Time Window</h4>
          <div className="MultiToggle">
            <div className="Plain">Start</div>
            <div
              className={
                "Toggle" + (MonitorFrom === undefined ? " Active" : "")
              }
              onClick={() => {
                if (MonitorFrom !== undefined) {
                  setSearchStale(true);
                  setMonitorFrom(undefined);
                }
              }}
            >
              Null
            </div>
            <div
              className="Action"
              onClick={() => {
                setSearchStale(true);
                setMonitorFrom(dayjs().subtract(1, "hour"));
              }}
            >
              Now - 1h
            </div>
          </div>
          <input
            className="dateinput"
            id="VisibleFrom"
            type="datetime-local"
            value={MonitorFrom?.local().format("YYYY-MM-DDTHH:mm") ?? ""}
            onChange={(e) => {
              setSearchStale(true);
              setMonitorFrom(dayjs(e.target.value));
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                Load();
              }
            }}
          />
          <div className="MultiToggle">
            <div className="Plain">Finish</div>
            <div
              className={
                "Toggle" + (MonitorUntil === undefined ? " Active" : "")
              }
              onClick={() => {
                if (MonitorUntil !== undefined) {
                  setSearchStale(true);
                  setMonitorUntil(undefined);
                }
              }}
            >
              Null
            </div>
            <div
              className="Action"
              onClick={() => {
                setSearchStale(true);
                setMonitorUntil(dayjs());
              }}
            >
              Now
            </div>
          </div>
          <input
            className="dateinput"
            id="VisibleFrom"
            type="datetime-local"
            value={MonitorUntil?.local().format("YYYY-MM-DDTHH:mm") ?? ""}
            onChange={(e) => {
              setSearchStale(true);
              setMonitorUntil(dayjs(e.target.value));
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                Load();
              }
            }}
          />
          <hr />
          <div
            className={"Button" + (SearchStale ? " Highlight" : "")}
            onClick={() => {
              Load();
            }}
          >
            Refresh
          </div>
        </div>
      </div>
      <div className="Results">
        <MondrianDataProvider
          ObjectArray={mutatedWorkOrders}
          Columns={columns}
          LoadingFlag={loadingWorkOrders}
        >
          <Mondrian
            Options={{
              Display: MondrianDisplayType.Grid,
              DisplayOptions: { Alternator: false },
            }}
          ></Mondrian>
        </MondrianDataProvider>
      </div>
    </div>
  );
};
