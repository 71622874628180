import * as React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess, User } from "../../data/DataAccess";
import { Logging } from "../../Logging";
import "../../assets/css/ECF_LF/ECF_LF.css";
import { Link } from "react-router-dom";
import IfPermitted from "../IfPermitted";
import { SURFACE } from "../../Providers/SurfaceProvider";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  Environment: NonNullable<string>;
  AuthedUser: User;
  ViewAsUser: User;

  Surface: SURFACE;
  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Refresher: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;

  Embargo: (scope: string) => boolean;
  HasNotices: (AgentIsPAS: boolean, scopes: string[]) => boolean;
  Notices: (AgentIsPAS: boolean, scopes: string[]) => React.ReactFragment;
  Navigation: (url: string) => void;
}

interface IState {
  // functional state parts
  Errors: string[];
  _SearchFor: string;
}

class ECF_LF extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      _SearchFor: "",
      Errors: [],
    };
  }

  public async componentDidMount() {
    window.document.title = "University of Essex - Electronic Case Files";
  }

  render() {
    return (
      <React.Fragment>
        <div className="StructPanels">
          <div className="StructPanelCluster Row Halved">
            <IfPermitted Whitelist={["ECF.BasicAccess"]}>
              <div className="StructPanel Cassette">
                <div className="PanelBar Title">
                  <div className="Left">Search Current/Archived Students</div>
                  <div className="Breaker"></div>
                  <div className="Right">ECF</div>
                </div>
                {/* <div className='PanelBar Action Search'>Search Current or Archived Students</div> */}
                <div className="PanelBody">
                  <div id="Search">
                    <p className="InputRow Wide">
                      <label htmlFor="Searcher">
                        Enter Surname, PRID, login, or reg no.
                      </label>
                    </p>
                    <p className="InputRow Wide">
                      <input
                        type="text"
                        value={this.state._SearchFor}
                        id="Searcher"
                        name="Searcher"
                        onKeyPress={(ev) => {
                          if (ev.key === "Enter") {
                            this.props.Navigation(
                              "/Search/CurrentStudentsECF/" +
                                this.state._SearchFor
                                  .trim()
                                  .replaceAll(" ", "_"),
                            );
                          }
                        }}
                        onChange={(event) => {
                          const pattern = /^[0-9A-Za-z '\-]*$/g;
                          this.setState({
                            _SearchFor:
                              (event.target.value.match(pattern) || []).pop() ||
                              "",
                          });
                        }}
                      />
                    </p>
                    <p className="SubmitRow Left">
                      <Link
                        to={this.props.GetLink(
                          [
                            { Mode: "Search", Index: 0 },
                            { Mode: "CurrentStudentsECF", Index: 1 },
                            {
                              Mode: this.state._SearchFor
                                .trim()
                                .replaceAll(" ", "_"),
                              Index: 2,
                            },
                          ],
                          [],
                        )}
                      >
                        Search Current Students
                      </Link>
                      <Link
                        className="ArchiveSearch"
                        to={this.props.GetLink(
                          [
                            { Mode: "Search", Index: 0 },
                            { Mode: "ArchivedStudentsECF", Index: 1 },
                            { Mode: this.state._SearchFor.trim(), Index: 2 },
                          ],
                          [],
                        )}
                      >
                        Search Archived Students
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </IfPermitted>
            <IfPermitted Whitelist={["ECF.Uploader"]}>
              <Link
                className="StructPanel"
                to={this.props.GetLink([{ Mode: "Upload", Index: 0 }], [])}
              >
                <div className="PanelBar Title">
                  <div className="Left">Upload document</div>
                  <div className="Breaker"></div>
                  <div className="Right">ECF</div>
                </div>
                {/* <div className='PanelBarSimple'>
              <div className='Left'>Upload a document to ECF</div>
            </div> */}
                <div className="PanelBody">General ECF uploader tool.</div>
              </Link>
            </IfPermitted>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ECF_LF;
