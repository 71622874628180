import Notice from "../../../models/Notice";
import { EmbargoBlock } from "./EmbargoBlock";
import Embargo from "../../../models/Embargo";
import { NoticeBlock } from "./NoticeBlock";

interface IProps {
  Embargo: Embargo;
  DeleteEmbargo: (EID: string) => void;
  EditEmbargo: (EID: string) => void;

  ConcurrentNotices_ApplicantPage: Notice[];
  ConcurrentNotices_CurrentApplicants: Notice[];
  ConcurrentNotices_TopBanner: Notice[];

  DeleteNotice: (_noticeID: string) => void;
  EditNotice: (_noticeID: string) => void;
}

export const EmbargoRow = (props: IProps) => {
  return (
    <div className="EmbargoRow">
      <EmbargoBlock {...props}></EmbargoBlock>

      <div className="ConcurrentNotices">
        <b>Concurrent Notices:</b>
        <em className="Description">
          To set a Concurrent Notice, edit a Notice and set the Embargo
          dropdown.
        </em>
      </div>

      <div className="ConcurrentNotices">
        <b>Current Applicants:</b>
        <em className="Description">
          This notice will show on the Agent Portal, in place of the
          embargo-blocked Current Applicants link panel.
        </em>
      </div>

      {props.ConcurrentNotices_CurrentApplicants.length > 0 ? (
        props.ConcurrentNotices_CurrentApplicants.map((th, i) => {
          return (
            <div className="ConcurrentNotices">
              <NoticeBlock
                key={"ConcurrentNotice_" + i.toString()}
                Notice={th}
                EditNotice={props.EditNotice}
                DeleteNotice={props.DeleteNotice}
              ></NoticeBlock>
            </div>
          );
        })
      ) : (
        <div className="ConcurrentNotices Empty">Notice not set.</div>
      )}

      <div className="ConcurrentNotices">
        <b>Applicant Summary:</b>
        <em className="Description">
          This notice will show on the Applicant Summary page, in place of any
          Embargo-blocked components.
        </em>
      </div>

      {props.ConcurrentNotices_ApplicantPage.length > 0 ? (
        props.ConcurrentNotices_ApplicantPage.map((th, i) => {
          return (
            <div className="ConcurrentNotices">
              <NoticeBlock
                key={"ConcurrentNotice_" + i.toString()}
                Notice={th}
                EditNotice={props.EditNotice}
                DeleteNotice={props.DeleteNotice}
              ></NoticeBlock>
            </div>
          );
        })
      ) : (
        <div className="ConcurrentNotices Empty">Notice not set.</div>
      )}

      <div className="ConcurrentNotices">
        <b>Top Banner:</b>
        <em className="Description">
          This notice will show on the Notices banner at the top of the Agent
          Portal.
        </em>
      </div>

      {props.ConcurrentNotices_TopBanner.length > 0 ? (
        props.ConcurrentNotices_TopBanner.map((th, i) => {
          return (
            <div className="ConcurrentNotices">
              <NoticeBlock
                key={"ConcurrentNotice_" + i.toString()}
                Notice={th}
                EditNotice={props.EditNotice}
                DeleteNotice={props.DeleteNotice}
              ></NoticeBlock>
            </div>
          );
        })
      ) : (
        <div className="ConcurrentNotices Empty">Notice not set.</div>
      )}
    </div>
  );
};

export default EmbargoRow;
