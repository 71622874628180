import { useLoaderData } from "react-router-dom";
import { Logging } from "../../Logging";
import CollagenWorkflowSummary from "./CollagenWorkflowSummary";
import { useApi } from "../../Providers/ApiProvider";
import "../../assets/css/Integrations/WorkflowSummary.css";

interface IProps {
  MODE_LOCAL: boolean;
  MODE_DEVELOPMENT: boolean;
  MODE_STAGING: boolean;
  MODE_LIVE: boolean;
  Logger: Logging;
}

export const WorkflowSummary = (props: IProps) => {
  const blermpo = useLoaderData();
  const da = useApi();

  return (
    <div id="CollagenWorkflowSummary" className="CWS">
      <CollagenWorkflowSummary
        {...props}
        da={da.General}
        figuid={blermpo?.toString() ?? ""}
      ></CollagenWorkflowSummary>
    </div>
  );
};
