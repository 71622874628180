import * as React from "react";
import TaskListGroup from "../../models/TaskListGroup";
import ModeStruct from "../../models/ModeStruct";
import SPOUploadLocation from "../../models/SPOUploadLocation";
import { EndSessionRequest } from "@azure/msal-browser";
import { Link, NavigateFunction } from "react-router-dom";
import { DB_UserResponse } from "../../models/DB_UserResponse";
import { QuickNav } from "../QuickNav";
import IfPermitted from "../IfPermitted";
import { User } from "../../data/DataAccess";
import { QuickSearchCharm } from "./QuickSearchCharm";
import uni from "../../assets/icons/uni.svg";
import { IfSurface } from "../IfSurface";
import { SURFACE, SurfaceAttributes } from "../../Providers/SurfaceProvider";
import { ReportingMenu } from "./ReportingMenu";
import { SignOutCharm } from "./SignOutCharm";
import { TaskListCharm } from "./TaskListCharm";

import "../../assets/css/Header.css";
import "../../assets/css/Logo.css";
import { UserCharm } from "./UserCharm";
import { InvarDataAccess } from "../../data/InvarDataAccess";

interface IHeaderProps {
  IDA: InvarDataAccess;

  TailoredLogoutRequest: EndSessionRequest;
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  Surface: SurfaceAttributes;
  Location: any;

  Header_AuthedCharmData?: DB_UserResponse;
  Header_ViewAsCharmData?: DB_UserResponse;
  AuthedUser: User;
  ViewAsUser: User;
  ViewAsNotFound: boolean;
  ViewAsAll: boolean;

  Mode: string[];
  Modes: ModeStruct[];
  TaskListGroups: TaskListGroup[] | undefined;

  Refresher: string;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  GetSPUL: (shortname: string) => SPOUploadLocation;
  SetTaskList: (TLRef: string) => void;
  Permission: (surface: string, scope: string, impish: boolean) => boolean;
  SetImpersonation: (alias?: string) => void;

  HiVis: boolean;
  SetHiVis: (val: boolean) => void;

  _alias: string;
  _setAlias: (dave: string) => void;

  MenuVisibilities: Map<string, boolean>;
  SetMenuVisibility: (name: string | undefined, newval: boolean) => void;

  _miniSearchTerm: string;
  _setMiniSearchTerm: (dave: string) => void;

  Navigation: NavigateFunction;
  SetThemeName: (input: string) => void;
}

class InnerHeader extends React.Component<IHeaderProps> {
  render() {
    const routebits = this.props.Location.pathname
      .split("/")
      .filter((th) => th != "");
    //  console.log(routebits);

    var crumbos: { URL: string; Name: string }[] = [];
    var writeHead: string = "";

    for (var i = 0; i < routebits.length; i++) {
      crumbos[i] = { URL: writeHead + "/" + routebits[i], Name: routebits[i] };

      if (i + 1 < routebits.length) {
        // Exclude the ending pdf/jpg on the document viewer
        if (routebits[i + 1] == "pdf" || routebits[i + 1] == "jpg") {
          crumbos[i] = {
            URL: writeHead + "/" + routebits[i] + "/" + routebits[i + 1],
            Name: routebits[i],
          };
          break;
        }
      }

      writeHead += "/" + routebits[i];
    }

    return (
      <div
        className={
          "Header " +
          ((this.props.HiVis ? "HiVis " : "") + this.props.Surface.CSSClass)
        }
      >
        <div className="Operations">
          <a className="Logo" href={this.props.GetLink([], [])}>
            <div className="LogoSVG">
              <img src={uni} alt="University of Essex Icon" height={60} />
            </div>
          </a>

          <div
            className={
              "SubTitle Inline For" + this.props.Surface.CSSClass ||
              "" + (this.props.HiVis ? " HiVis" : "")
            }
          >
            <div className="BreakerLeft"></div>
            <div className="PageTitle">{this.props.Surface.Title}</div>
            <div className="BreakerRight"></div>
          </div>

          <div className="SubTitle Spacer"></div>

          <span
            id="InvisibleMultiCharmWrapper"
            onMouseLeave={() => {
              this.props.SetMenuVisibility(undefined, false);
            }}
          >
            <IfSurface ShowOn={[SURFACE.EAF_UGEAF]}>
              <QuickSearchCharm
                Title="Undergraduate Applicant Search"
                OuterWhitelist={["UGEAF.Admissions", "UGEAF.Reader"]}
                InnerWhitelist={["UGEAF.Admissions"]}
                TextSearchURL="/UGEAF/Search/CurrentUGApplicants/"
                DateSearchURL="/UGEAF/Search/CurrentUGApplicantsbyDOB/"
                {...this.props}
              ></QuickSearchCharm>
            </IfSurface>
            <IfSurface ShowOn={[SURFACE.EAF_PGEAF]}>
              <QuickSearchCharm
                Title="Postgraduate Applicant Search"
                OuterWhitelist={["PGEAF.Admissions", "PGEAF.Reader"]}
                InnerWhitelist={["PGEAF.Admissions"]}
                TextSearchURL="/PGEAF/Search/CurrentPGApplicants/"
                DateSearchURL="/PGEAF/Search/CurrentPGApplicantsbyDOB/"
                {...this.props}
              ></QuickSearchCharm>
            </IfSurface>
            <IfSurface ShowOn={[SURFACE.ESF]}>
              <QuickSearchCharm
                Title="Student Search"
                OuterWhitelist={["ESF.Teams", "ESF.Reader"]}
                InnerWhitelist={["ESF.Teams"]}
                TextSearchURL="/Search/CurrentStudentsESF/"
                DateSearchURL="/Search/CurrentStudentsESFbyDOB/"
                {...this.props}
              ></QuickSearchCharm>
            </IfSurface>
            {/* Doesnt work 100% yet */}
            <IfSurface ShowOn={[SURFACE.AOS]}>
              <QuickSearchCharm
                Title="Applicant Search"
                OuterWhitelist={["AOS.Admin", "AOS.Access"]}
                InnerWhitelist={["AOS.Admin"]}
                TextSearchURL="/Search/"
                {...this.props}
              ></QuickSearchCharm>
            </IfSurface>
            <IfPermitted
              Whitelist={
                this.props.Surface.SURFACE === SURFACE.ESF
                  ? ["ESF.Teams", "ESF.CourseRecordsTeam", "ESF.Reader"]
                  : this.props.Surface.SURFACE === SURFACE.EAF_PGEAF
                    ? ["PGEAF.Admissions", "PGEAF.Reader"]
                    : this.props.Surface.SURFACE === SURFACE.EAF_UGEAF
                      ? ["UGEAF.Admissions", "UGEAF.Reader"]
                      : []
              }
            >
              <TaskListCharm
                Permission={this.props.Permission}
                AuthedUser={this.props.AuthedUser}
                ViewAsUser={this.props.ViewAsUser}
                MenuVisibilities={this.props.MenuVisibilities}
                SetMenuVisibility={this.props.SetMenuVisibility}
                TaskListGroups={this.props.TaskListGroups}
                GetLink={this.props.GetLink}
              ></TaskListCharm>
            </IfPermitted>
            <IfPermitted
              Whitelist={
                this.props.Surface.SURFACE === SURFACE.ESF_COMPLIANCE
                  ? [
                      "Compliance.GDPRReportAuthorised",
                      "Compliance.AuditReportAuthorised",
                    ]
                  : this.props.Surface.SURFACE === SURFACE.ESF
                    ? ["Collagen.Admins", "Admin.All"]
                    : this.props.Surface.SURFACE === SURFACE.EAF_UGEAF
                      ? ["UGEAF.Admissions"]
                      : this.props.Surface.SURFACE === SURFACE.AOS
                        ? [
                            "Collagen.Admins",
                            "AOS.Admin",
                            "Admin.All",
                            "Admin.AOS",
                          ]
                        : []
              }
            >
              <ReportingMenu
                ViewAsUser={this.props.ViewAsUser}
                AuthedUser={this.props.AuthedUser}
                Permission={this.props.Permission}
                MenuVisibilities={this.props.MenuVisibilities}
                SetMenuVisibility={this.props.SetMenuVisibility}
                GetLink={this.props.GetLink}
              ></ReportingMenu>
            </IfPermitted>
            <UserCharm
              IDA={this.props.IDA}
              Environment={this.props.Environment}
              Permission={this.props.Permission}
              Header_AuthedCharmData={this.props.Header_AuthedCharmData}
              Header_ViewAsCharmData={this.props.Header_ViewAsCharmData}
              ViewAsNotFound={this.props.ViewAsNotFound}
              ViewAsAll={this.props.ViewAsAll}
              MenuVisibilities={this.props.MenuVisibilities}
              SetMenuVisibility={this.props.SetMenuVisibility}
              SetImpersonation={this.props.SetImpersonation}
              _alias={this.props._alias}
              _setAlias={this.props._setAlias}
              AuthedUser={this.props.AuthedUser}
              ViewAsUser={this.props.ViewAsUser}
              SetThemeName={this.props.SetThemeName}
            ></UserCharm>
            <SignOutCharm
              SetImpersonation={this.props.SetImpersonation}
              TailoredLogoutRequest={this.props.TailoredLogoutRequest}
            ></SignOutCharm>
          </span>
        </div>

        <div
          className={
            "SubTitle Beneath For" + this.props.Surface.CSSClass ||
            "" + (this.props.HiVis ? " HiVis" : "")
          }
        >
          <div className="BreakerLeft"></div>
          <div className="PageTitle">{this.props.Surface.Title}</div>
          <div className="BreakerRight"></div>
        </div>

        <div
          className={
            "SubTitle Block For" + this.props.Surface.CSSClass ||
            "" + (this.props.HiVis ? " HiVis" : "")
          }
        >
          <div className="PageTitle">{this.props.Surface.Title}</div>
        </div>

        <div className="Breadcrumbs">
          <QuickNav {...this.props} />
          {/* step 1 breadcrumb */}
          <div className="Crumbs">
            <a className="Crumb" href={this.props.GetLink([], [])}>
              <IfSurface ShowOn={[SURFACE.AOS]}>
                Agent Online Services Home
              </IfSurface>
              <IfSurface
                ShowOn={[
                  SURFACE.EAF,
                  SURFACE.EAF_CLEARING,
                  SURFACE.EAF_PGEAF,
                  SURFACE.EAF_UGEAF,
                ]}
              >
                EAF Home
              </IfSurface>
              <IfSurface ShowOn={[SURFACE.ECF]}>ECF Home</IfSurface>
              <IfSurface
                ShowOn={[
                  SURFACE.ESF,
                  SURFACE.ESF_ADMIN,
                  SURFACE.ESF_COMPLIANCE,
                ]}
              >
                ESF Home
              </IfSurface>
            </a>

            <div className="Twixt">&gt;</div>
            {/* step 2 breadcrumb */}
            {this.props.Mode !== undefined ? (
              this.props.Surface.SURFACE === SURFACE.NONE ? (
                this.props.Mode[0] === "pg" &&
                this.props.Mode[1] === "applicant" ? (
                  <React.Fragment>
                    <Link
                      className="Crumb"
                      to={this.props.GetLink(
                        [
                          { Mode: "pg", Index: 0 },
                          { Mode: "current", Index: 1 },
                        ],
                        [],
                      )}
                    >
                      Postgraduate Applicants
                    </Link>
                    <div className="Twixt">&gt;</div>
                    <div className="Crumb Inert">{this.props.Mode[2]}</div>
                  </React.Fragment>
                ) : this.props.Mode[0] === "ug" &&
                  this.props.Mode[1] === "applicant" ? (
                  <React.Fragment>
                    <Link
                      className="Crumb"
                      to={this.props.GetLink(
                        [
                          { Mode: "ug", Index: 0 },
                          { Mode: "current", Index: 1 },
                        ],
                        [],
                      )}
                    >
                      Undergraduate Applicants
                    </Link>
                    <div className="Twixt">&gt;</div>
                    <div className="Crumb Inert">{this.props.Mode[2]}</div>
                  </React.Fragment>
                ) : this.props.Mode[0] === "search" &&
                  this.props.Mode[1] === "agentapplications" ? (
                  <React.Fragment>
                    <div className="Crumb Inert">Search All Applicants</div>
                    <div className="Twixt">&gt;</div>
                    <div className="Crumb Inert">{this.props.Mode[2]}</div>
                  </React.Fragment>
                ) : //  this.props.Mode[0] === 'Utilities' && this.props.Mode[1] === 'EmbargoManager'
                //      ? <React.Fragment><div className='Crumb Inert'>Utilities</div><div className='Twixt'>&gt;</div><div className='Crumb Inert'>Embargo Manager</div></React.Fragment> :
                this.props.Mode[0] === "Utilities" &&
                  this.props.Mode[1] === "NoticeEmbargoManager" ? (
                  <React.Fragment>
                    <div className="Crumb Inert">Utilities</div>
                    <div className="Twixt">&gt;</div>
                    <div className="Crumb Inert">
                      Manage Notices and Embargoes
                    </div>
                  </React.Fragment>
                ) : this.props.Mode[0] === "Utilities" &&
                  this.props.Mode[1] === "LinkedWithdrawals" ? (
                  <React.Fragment>
                    <div className="Crumb Inert">Utilities</div>
                    <div className="Twixt">&gt;</div>
                    <div className="Crumb">
                      Linked Withdrawal Requests Report
                    </div>
                  </React.Fragment>
                ) : // ) : this.props.Mode[0] === "Utilities" &&
                //   this.props.Mode[1] === "LecturerModuleTimetables" ? (
                //   <React.Fragment>
                //     <div className="Crumb Inert">Utilities</div>
                //     <div className="Twixt">&gt;</div>
                //     <div className="Crumb Inert">
                //       Lecturer Module Timetables
                //     </div>
                //   </React.Fragment>
                this.props.Mode[0] === "pg" &&
                  this.props.Mode[1] === "unsubmitted" ? (
                  <React.Fragment>
                    <div className="Crumb Inert">Postgraduate</div>
                    <div className="Twixt">&gt;</div>
                    <div className="Crumb Inert">Unsubmitted Applications</div>
                  </React.Fragment>
                ) : this.props.Mode[0] === "ug" &&
                  this.props.Mode[1] === "unsubmitted" ? (
                  <React.Fragment>
                    <div className="Crumb Inert">Undergraduate</div>
                    <div className="Twixt">&gt;</div>
                    <div className="Crumb Inert">Unsubmitted Applications</div>
                  </React.Fragment>
                ) : this.props.Mode[0] === "pg" &&
                  this.props.Mode[1] === "current" ? (
                  <React.Fragment>
                    <div className="Crumb Inert">Postgraduate</div>
                    <div className="Twixt">&gt;</div>
                    <div className="Crumb Inert">Current Applications</div>
                  </React.Fragment>
                ) : this.props.Mode[0] === "ug" &&
                  this.props.Mode[1] === "current" ? (
                  <React.Fragment>
                    <div className="Crumb Inert">Undergraduate</div>
                    <div className="Twixt">&gt;</div>
                    <div className="Crumb Inert">Current Applications</div>
                  </React.Fragment>
                ) : this.props.Mode[0] === "pg" &&
                  this.props.Mode[1] === "archive" ? (
                  <React.Fragment>
                    <div className="Crumb Inert">Postgraduate</div>
                    <div className="Twixt">&gt;</div>
                    <div className="Crumb Inert">Archive Applications</div>
                  </React.Fragment>
                ) : this.props.Mode[0] === "ug" &&
                  this.props.Mode[1] === "archive" ? (
                  <React.Fragment>
                    <div className="Crumb Inert">Undergraduate</div>
                    <div className="Twixt">&gt;</div>
                    <div className="Crumb Inert">Archive Applications</div>
                  </React.Fragment>
                ) : (
                  ""
                )
              ) : (
                crumbos.map((th, i) => {
                  return (
                    <React.Fragment key={"bc_c_" + i.toString()}>
                      {th.Name.toLowerCase() === "applicant" ? (
                        <></>
                      ) : th.Name.toLowerCase() === "search" ||
                        th.Name.toLowerCase() === "tasklists" ||
                        th.Name.toLowerCase() === "esf" ||
                        th.Name.toLowerCase() === "student" ||
                        th.Name.toLowerCase() === "utilities" ||
                        th.Name.toLowerCase() === "ecf" ||
                        th.Name.toLowerCase() === "metadata" ||
                        th.Name.toLowerCase() === "view" ||
                        th.Name.toLowerCase() === "review" ||
                        th.Name.toLowerCase() === "pg" ||
                        th.Name.toLowerCase() === "ug" ||
                        th.Name.toLowerCase() === "audit" ||
                        th.Name.toLowerCase() === "flagging" ||
                        th.Name.toLowerCase() === "attendance" ||
                        th.Name.toLowerCase() === "workflow" ||
                        th.Name.toLowerCase() === "document" ||
                        th.Name.toLowerCase() === "sar" ||
                        th.Name.toLowerCase() === "invar" ||
                        th.Name.toLowerCase() === "conveyance" ? (
                        <div className="Crumb Inert">
                          {this.props.GetMode(th.Name).Human}
                        </div>
                      ) : th.Name.toUpperCase() ===
                        "WEBUI_T_PGDRF_FIRMACCEPT" ? (
                        <>
                          <Link
                            className="Crumb"
                            to={th.URL.replace(
                              "WEBUI_T_PGDRF_FIRMACCEPT",
                              "WEBUI_T_PGDRF_DEPTLINKS",
                            )}
                          >
                            Stage 9: Outcomes by Department: Reject, Offer, FA
                          </Link>
                          <div className="Twixt">&gt;</div>
                          <div className="Crumb Inert">
                            {this.props.GetMode(th.Name).Human}
                          </div>
                        </>
                      ) : th.Name.toUpperCase() === "WEBUI_T_PGDRF_REJECTED" ? (
                        <>
                          <Link
                            className="Crumb"
                            to={th.URL.replace(
                              "WEBUI_T_PGDRF_REJECTED",
                              "WEBUI_T_PGDRF_DEPTLINKS",
                            )}
                          >
                            Stage 9: Outcomes by Department: Reject, Offer, FA
                          </Link>
                          <div className="Twixt">&gt;</div>
                          <div className="Crumb Inert">
                            {this.props.GetMode(th.Name).Human}
                          </div>
                        </>
                      ) : th.Name.toUpperCase() ===
                        "WEBUI_T_PGDRF_WITHOFFER" ? (
                        <>
                          <Link
                            className="Crumb"
                            to={th.URL.replace(
                              "WEBUI_T_PGDRF_WITHOFFER",
                              "WEBUI_T_PGDRF_DEPTLINKS",
                            )}
                          >
                            Stage 9: Outcomes by Department: Reject, Offer, FA
                          </Link>
                          <div className="Twixt">&gt;</div>
                          <div className="Crumb Inert">
                            {this.props.GetMode(th.Name).Human}
                          </div>
                        </>
                      ) : (
                        <Link className="Crumb" to={th.URL}>
                          {this.props.GetMode(th.Name).Human}
                        </Link>
                      )}

                      {i < crumbos.length - 1 &&
                      th.Name.toLowerCase() !== "applicant" ? (
                        <div className="Twixt">&gt;</div>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  );
                })
              )
            ) : (
              //  this.props.Mode.map((value: string, index: number) => {
              //      if (index < this.props.Mode.length - 1) {
              //          return <React.Fragment key={'bc_c_' + index.toString()}>
              //              <Link className='Crumb' to={this.props.GetLink([ { Mode: this.props.GetMode(value).Machine, Index: index } ], [])}>{this.props.GetMode(value).Human}</Link>
              //              <div className='Twixt'>&gt;</div>
              //          </React.Fragment>
              //      } else {
              //          return <div key={'bc_c_' + index.toString()} className="Crumb Inert">{this.props.GetMode(value).Human}</div>
              //      }
              //  })
              ""
            )}
          </div>

          <div className="Links"></div>
        </div>
      </div>
    );
  }
}

export default InnerHeader;
