export default function BurgerMenuIcon() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="100"
      height="100"
      viewBox="18 18 70 70"
      enableBackground="new 0 0 100 100"
      // xml:space="preserve"
      // fill="#000000"
      // fill="currentcolor"
      // x="0"
      // y="0"
    >
      <g id="Hamburger">
        <path
          fill="none"
          // fill="currentcolor"
          // stroke="#000000"
          stroke="currentcolor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M33.791,33.604c4.528-4.528,10.462-6.791,16.396-6.791c5.934,
0,11.868,2.264,16.396,6.791c4.527,4.527,6.79,10.459,6.791,16.392 c0.001,5.936-2.263,11.872-6.791,16.4c-9.055,
9.055-23.737,9.055-32.792,0S24.736,42.659,33.791,33.604z"
        />
        <g>
          <line
            // fill="none"
            // fill="currentcolor"
            // stroke="#000000"
            stroke="currentcolor"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="58.187"
            y1="50"
            x2="42.187"
            y2="50"
          />
          <line
            // fill="none"
            // fill="currentcolor"
            // stroke="#000000"
            stroke="currentcolor"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="58.187"
            y1="42"
            x2="42.187"
            y2="42"
          />
          <line
            // fill="none"
            // fill="currentcolor"
            // stroke="#000000"
            stroke="currentcolor"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            x1="58.187"
            y1="58"
            x2="42.187"
            y2="58"
          />
        </g>
      </g>
    </svg>
  );
}
