import * as React from "react";
import Timestamp from "./Timestamp";
import { Toggles } from "./Toggles";

interface IProps {
  Toggles: Toggles;
  Object: any;
  Data: [];
  Squished?: boolean;
  SquishToggle: any;
}

interface IState {
  Toggles: Toggles;
  Object: any;
  Data: [];
  Squished?: boolean;
  SquishToggle: any;
}

class TLEntryDatumHistory extends React.Component<IState, IProps> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      Toggles: this.props.Toggles,
      Object: this.props.Object,
      Data: this.props.Data,
      Squished: this.props.Squished,
      SquishToggle: this.props.SquishToggle,
    };
  }

  public render() {
    return (
      <div className="DatumEntry">
        <div className="Icon">&#128218;</div>
        <div className="Label">Data Entry</div>
        <Timestamp
          Timestamp={this.props.Object["Timestamp"]}
          Toggles={this.props.Toggles}
        />
        {this.props.Squished === undefined || this.props.Squished === true ? (
          <React.Fragment>
            <b title={this.props.Object["RelevantUser"]}>
              {this.props.Object["RelevantUserLabel"]}
            </b>{" "}
            set this data:
            <button
              className={"SquishButton Squished"}
              onClick={() => this.props.SquishToggle()}
            >
              <React.Fragment>&#43;</React.Fragment>
            </button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <b title={this.props.Object["RelevantUser"]}>
              {this.props.Object["RelevantUserLabel"]}
            </b>{" "}
            set this data:
            <button
              className={"SquishButton"}
              onClick={() => this.props.SquishToggle()}
            >
              <React.Fragment>&#8722;</React.Fragment>
            </button>
            <br />
            <div className="FieldWrap">
              <div className="Fields">
                {this.props.Data !== undefined
                  ? this.props.Data.map(
                      (
                        dt: {
                          FieldName:
                            | string
                            | number
                            | boolean
                            | React.ReactElement<
                                any,
                                string | React.JSXElementConstructor<any>
                              >
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined;
                          FieldValue:
                            | string
                            | number
                            | boolean
                            | React.ReactElement<
                                any,
                                string | React.JSXElementConstructor<any>
                              >
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined;
                        },
                        j: Number,
                      ) => (
                        <div
                          className="FieldRow"
                          key={"subdatum_" + j.toString()}
                        >
                          <div className="FieldName">{dt.FieldName}</div>
                          <div className="FieldValue">{dt.FieldValue}</div>
                        </div>
                      ),
                    )
                  : ""}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default TLEntryDatumHistory;
