import * as React from "react";
import { IItem } from "../../models/IItem";
import TLEntryMailRequest from "./TLEntryMailRequest";
import { Toggles } from "./Toggles";
import TLEntryDesignationQueue from "./TLEntryDesignationQueue";
import TLEntryAccessHistory from "./TLEntryAccessHistory";
import TLEntryAssignmentHistory from "./TLEntryAssignmentHistory";
import TLEntryEventQueue from "./TLEntryEventQueue";
import TLEntryDatumHistory from "./TLEntryDatumHistory";
import TLEntryOtherHistory from "./TLEntryOtherHistory";
import { DataAccess } from "../../data/DataAccess";

interface IProps {
  FIGUID: string;
  Interleaved: Interleaf[];
  EventTable: IItem[];
  DesignationTable: IItem[];
  CommitTable: IItem[];
  AccessTable: IItem[];
  CommitDatumsTable: IItem[];
  AssignmentHistoryTable: IItem[];
  OtherTable: IItem[];
  MailTable: IItem[];
  SquishMap: boolean[];
  DA: DataAccess;
  Toggles: Toggles;
  SquishToggle: (i: number) => void;
  PassthroughRequeue: (EID: Number, FIGUID: string) => void;
}

interface IState {
  FIGUID: string;
  Interleaved: Interleaf[];
  EventTable: IItem[];
  DesignationTable: IItem[];
  CommitTable: IItem[];
  AccessTable: IItem[];
  CommitDatumsTable: IItem[];
  AssignmentHistoryTable: IItem[];
  OtherTable: IItem[];
  MailTable: IItem[];
  SquishMap: boolean[];
  DA: DataAccess;
  Toggles: Toggles;
  SquishToggle: (i: number) => void;
  PassthroughRequeue: (EID: Number, FIGUID: string) => void;
}

class Interleaf {
  ID: number;
  Type: string;
  Object: any;
  Data: [];

  constructor() {
    this.ID = 0;
    this.Type = "";
    this.Object = {};
    this.Data = [];
  }
}

class Timeline extends React.Component<IState, IProps> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      Interleaved: this.props.Interleaved,
      EventTable: this.props.EventTable,
      DesignationTable: this.props.DesignationTable,
      CommitTable: this.props.CommitTable,
      AccessTable: this.props.AccessTable,
      CommitDatumsTable: this.props.CommitDatumsTable,
      AssignmentHistoryTable: this.props.AssignmentHistoryTable,
      OtherTable: this.props.OtherTable,
      MailTable: this.props.MailTable,
      SquishMap: this.props.SquishMap,
      DA: this.props.DA,
      FIGUID: this.props.FIGUID,
      Toggles: this.props.Toggles,
      SquishToggle: this.props.SquishToggle,
      PassthroughRequeue: this.props.PassthroughRequeue,
    };
  }

  static getDerivedStateFromProps(props: any, current_state: any) {
    if (
      current_state.EventTable !== props.EventTable ||
      current_state.DesignationTable !== props.DesignationTable ||
      current_state.CommitTable !== props.CommitTable ||
      current_state.CommitDatumsTable !== props.CommitDatumsTable ||
      current_state.AssignmentHistoryTable !== props.AssignmentHistoryTable ||
      current_state.OtherTable !== props.OtherTable
    ) {
      var proto_Interleaved: Interleaf[] = [];
      // populate Interleaved with Date, Type, Contents for all other tables.
      var i: number = 0;

      props.EventTable.forEach((th: any) => {
        proto_Interleaved[i] = new Interleaf();
        proto_Interleaved[i].Type = "TLEntryEventQueue";
        proto_Interleaved[i].Object = th;
        i++;
      });

      props.DesignationTable.forEach((th: any) => {
        proto_Interleaved[i] = new Interleaf();
        proto_Interleaved[i].Type = "TLEntryDesignationQueue";
        proto_Interleaved[i].Object = th;
        i++;
      });

      props.AccessTable.forEach((th: any) => {
        proto_Interleaved[i] = new Interleaf();
        proto_Interleaved[i].Type = "TLEntryAccessHistory";
        proto_Interleaved[i].Object = th;
        i++;
      });

      props.AssignmentHistoryTable.forEach((th: any) => {
        proto_Interleaved[i] = new Interleaf();
        proto_Interleaved[i].Type = "TLEntryAssignmentHistory";
        proto_Interleaved[i].Object = th;
        i++;
      });

      props.OtherTable.forEach((th: any) => {
        proto_Interleaved[i] = new Interleaf();
        proto_Interleaved[i].Type = "TLEntryOtherHistory";
        proto_Interleaved[i].Object = th;
        i++;
      });
      props.CommitTable.forEach((th: any) => {
        proto_Interleaved[i] = new Interleaf();
        proto_Interleaved[i].Type = "TLEntryDatumHistory";
        proto_Interleaved[i].Object = th;
        proto_Interleaved[i].Data = props.CommitDatumsTable.filter(
          (ith: { CommitID: any }) => ith.CommitID === th.CommitID,
        );
        i++;
      });
      props.MailTable.forEach((th: any) => {
        proto_Interleaved[i] = new Interleaf();
        proto_Interleaved[i].Type = "TLEntryMailRequest";
        proto_Interleaved[i].Object = th;
        i++;
      });

      var j: number = 0;

      proto_Interleaved
        .sort((a: Interleaf, b: Interleaf) => {
          return a.Object["Timestamp"] > b.Object["Timestamp"] ? 1 : -1;
        })
        .forEach((th) => {
          th.ID = j;
          j = j + 1;
        });

      if (
        props.Toggles !== undefined &&
        props.Toggles.SortTimeDescending === true
      ) {
        return {
          Interleaved: proto_Interleaved.sort((a: Interleaf, b: Interleaf) => {
            return a.Object["Timestamp"] > b.Object["Timestamp"] ? -1 : 1;
          }),
        };
      } else {
        return {
          Interleaved: proto_Interleaved.sort((a: Interleaf, b: Interleaf) => {
            return a.Object["Timestamp"] > b.Object["Timestamp"] ? 1 : -1;
          }),
        };
      }
    }
    return null;
  }

  render() {
    //console.log(this.props.SquishMap);
    if (this.state.Interleaved !== undefined) {
      return (
        <div className="Timeline">
          {this.state.Interleaved.map((row: Interleaf, i: number) =>
            row.Type === "TLEntryDesignationQueue" &&
            this.props.Toggles.ShowDesignations ? (
              <React.Fragment key={row.ID}>
                <TLEntryDesignationQueue
                  Object={row.Object}
                  Toggles={this.props.Toggles}
                />
              </React.Fragment>
            ) : row.Type === "TLEntryEventQueue" &&
              this.props.Toggles.ShowEvents ? (
              <React.Fragment key={row.ID}>
                <TLEntryEventQueue
                  FIGUID={this.state.FIGUID}
                  DA={this.state.DA}
                  Object={row.Object}
                  Toggles={this.props.Toggles}
                  PassthroughRequeue={this.props.PassthroughRequeue}
                />
              </React.Fragment>
            ) : row.Type === "TLEntryAssignmentHistory" &&
              this.props.Toggles.ShowAssignments ? (
              <React.Fragment key={row.ID}>
                <TLEntryAssignmentHistory
                  Object={row.Object}
                  Toggles={this.props.Toggles}
                />
              </React.Fragment>
            ) : row.Type === "TLEntryAccessHistory" &&
              this.props.Toggles.ShowAccessLocks ? (
              <React.Fragment key={row.ID}>
                <TLEntryAccessHistory
                  Object={row.Object}
                  Toggles={this.props.Toggles}
                />
              </React.Fragment>
            ) : row.Type === "TLEntryDatumHistory" &&
              this.props.Toggles.ShowData ? (
              <React.Fragment key={row.ID}>
                <TLEntryDatumHistory
                  Object={row.Object}
                  Toggles={this.props.Toggles}
                  Data={row.Data}
                  Squished={this.props.SquishMap[row.ID]}
                  SquishToggle={() => this.props.SquishToggle(row.ID)}
                />
              </React.Fragment>
            ) : row.Type === "TLEntryOtherHistory" &&
              this.props.Toggles.ShowOther ? (
              <React.Fragment key={row.ID}>
                <TLEntryOtherHistory
                  Object={row.Object}
                  Toggles={this.props.Toggles}
                />
              </React.Fragment>
            ) : row.Type === "TLEntryMailRequest" &&
              this.props.Toggles.ShowMail ? (
              <React.Fragment key={row.ID}>
                <TLEntryMailRequest
                  Object={row.Object}
                  Toggles={this.props.Toggles}
                  Squished={this.props.SquishMap[row.ID]}
                  SquishToggle={() => this.props.SquishToggle(row.ID)}
                />
              </React.Fragment>
            ) : (
              <React.Fragment key={row.ID}></React.Fragment>
            ),
          )}
        </div>
      );
    } else {
      return <div>Interleaf failure.</div>;
    }
  }
}

export default Timeline;
