import { useMsal } from "@azure/msal-react";
import AccessibilityIcon from "../assets/icons/AccessibilityIcon";
import "../assets/css/AOS2/AOS2_AgentLoginPage.css";
import React from "react";
import { RedirectRequest } from "@azure/msal-browser";
import uni from "../assets/icons/uni.svg";

interface IProps {
  Request: RedirectRequest;
}

export const AgentLoginSpiel = (props: IProps) => {
  const msalContext = useMsal();

  const handleLogin = () => {
    msalContext.instance.loginRedirect(props.Request).catch((e) => {
      console.log(e);
    });
  };

  return (
    <React.Fragment>
      <div id="SPOWebUIOnPrem">
        <div className={"Header " + "AOS2"}>
          <div className="Operations">
            <a className="Logo" href="/">
              <div className="LogoSVG">
                <img src={uni} alt="University of Essex Icon" height={60} />
              </div>
            </a>

            <div className={"SubTitle Inline For" + "AOS2"}>
              <div className="BreakerLeft"></div>
              <div className="PageTitle">Agent Online Services</div>
              <div className="BreakerRight"></div>
            </div>

            <div className="SubTitle Spacer"></div>
          </div>

          <div className={"SubTitle Beneath For" + "AOS2"}>
            <div className="BreakerLeft"></div>
            <div className="PageTitle">Agent Online Services</div>
            <div className="BreakerRight"></div>
          </div>

          <div className={"SubTitle Block For" + "AOS2"}>
            <div className="PageTitle">Agent Online Services</div>
          </div>

          <div className="Breadcrumbs">
            <div className="Crumbs">
              <div className="Crumb">Agent Online Services Home</div>
              <div className="Twixt">&gt;</div>
            </div>
          </div>
        </div>

        <div className="Body AOS2">
          <div className="AnnouncementPanel">
            <div className="PanelBar Title">
              Welcome to Agent Online Services for the University of Essex
            </div>
            <div className="PanelBar">Login</div>
            <div className="PanelBody">
              <p>Please note: authentication for this service has changed.</p>
              <p>
                When you are prompted for your username, please provide it in
                the following format: <strong>login@essex.ac.uk</strong>
              </p>
              <div className="AOSLoginButton" onClick={() => handleLogin()}>
                Login
              </div>
            </div>
            <div className="PanelBar">Lost or Forgotten Password</div>
            <div className="PanelBody">
              <p>
                If you have lost or forgotten your password, you can use the
                following link to reset it (opens in new window).
              </p>
              <a
                href="https://www1.essex.ac.uk/password/login.aspx"
                target="_blank"
                className="ResetButton"
              >
                Reset Password
              </a>
            </div>
          </div>
        </div>
        <div className="Footer">
          <div className="AccessibilityMenu">
            <div className="AccessibilityCharm">
              <AccessibilityIcon></AccessibilityIcon>
            </div>
          </div>
          <div className="HelpMenu">
            <div className="HelpCharm">?</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AgentLoginSpiel;
