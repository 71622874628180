import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useContext,
} from "react";
import { DataAccess } from "../data/DataAccess";
import { InvarDataAccess } from "../data/InvarDataAccess";

interface ApiContextData {
  General: DataAccess;
  Invar?: InvarDataAccess;
}

interface ApiProviderProps {
  Api: ApiContextData;
}

const ApiContext = createContext<ApiContextData>(undefined);

export function useApi(): ApiContextData {
  return useContext(ApiContext);
}

export function ApiProvider(
  props: PropsWithChildren<ApiProviderProps>,
): ReactElement {
  return (
    <ApiContext.Provider value={props.Api}>
      {props.children}
    </ApiContext.Provider>
  );
}
