import * as React from "react";
import { GridWrapper } from "../integrations/UoE-Grid/GridWrapper";

import "../assets/css/SearchResults.css";
import ModeStruct from "../models/ModeStruct";
import SPOUploadLocation from "../models/SPOUploadLocation";
import { PublicClientApplication } from "@azure/msal-browser";
import { DataAccess } from "../data/DataAccess";
import { Logging } from "../Logging";
import { Link, NavigateFunction } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { ReactElement } from "react";
import { Button } from "@fluentui/react-components";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  URLRoot: string;
  URLParams: string;
  Mode: string[];
  Modes: ModeStruct[];
  SearchType: string;
  SearchTerm: string;
  Location: any;
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  GetSPUL: (shortname: string) => SPOUploadLocation;
  SetSearch: (SearchString: string) => void;
  SetTaskList: (TLRef: string) => void;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
  Navigation: NavigateFunction;
  Error?: string;
}

interface IState {
  _SearchString: string;
  _SearchDate: string;
  refreshed: string;
  _min: Dayjs;
  _max: Dayjs;
}

type SearchScaffold = {
  ID: ModeStruct;
  SearchPlaceholder: string;
  Key: string;
  EID: string;
  Sproc: string;
  Parameters: string[];
  LinkBar: ReactElement;
  SearchRoot: string;
};

class SearchScaffolder {
  TheseScaffolds: SearchScaffold[];

  constructor(Refresher: string, _SearchTerm: string) {
    const SearchTerm = _SearchTerm.replaceAll(" ", "_");

    this.TheseScaffolds = [
      {
        ID: {
          Human: "Current/Pre-Arrival Students (ESF) by DOB",
          Machine: "CurrentStudentsESFbyDOB",
          Style: "ESF",
        },
        SearchPlaceholder: "Date of Birth",
        Key: "SPOWUI_CSESF_" + Refresher,
        EID: "SPOWUI_CSESF",
        Sproc: "sputilities.webui.SEARCH_esf_student_DOB",
        Parameters: ["@SEARCH", SearchTerm],
        LinkBar: (
          <div className="SearchSwitch">
            <Link to="/Search/CurrentStudentsESF/" className="Other">
              Current Students (ESF)
            </Link>
            <Link to="/Search/ArchivedStudentsESF/" className="Other">
              Archived Students (ESF)
            </Link>
            <Link
              to={"/Search/CurrentStudentsESFbyDOB/" + SearchTerm}
              className="Current"
            >
              Current/Pre-Arrival Students (ESF) by DOB
            </Link>
          </div>
        ),
        SearchRoot: "/Search/CurrentStudentsESFbyDOB/",
      },
      {
        ID: {
          Human: "Current PG Applicants by DOB",
          Machine: "CurrentPGApplicantsbyDOB",
          Style: "EAF",
        },
        SearchPlaceholder: "Date of Birth",
        Key: "SPOWUI_CPGA_" + Refresher,
        EID: "SPOWUI_CPGA",
        Sproc: "sputilities.webui.SEARCH_pgeaf_DOB",
        Parameters: ["@SEARCH", SearchTerm],
        LinkBar: (
          <div className="SearchSwitch">
            <Link to="/PGEAF/Search/CurrentPGApplicants/" className="Other">
              Current PG Applicants
            </Link>
            <Link to="/PGEAF/Search/ArchivedPGApplicants/" className="Other">
              Archived PG Applicants
            </Link>
            <Link
              to={"/PGEAF/Search/CurrentPGApplicantsbyDOB/" + SearchTerm}
              className="Current"
            >
              Current PG Applicants by DOB
            </Link>
          </div>
        ),
        SearchRoot: "/PGEAF/Search/CurrentPGApplicantsbyDOB/",
      },
      {
        ID: {
          Human: "Current UG Applicants by DOB",
          Machine: "CurrentUGApplicantsbyDOB",
          Style: "EAF",
        },
        SearchPlaceholder: "Date of Birth",
        Key: "SPOWUI_CUGA_" + Refresher,
        EID: "SPOWUI_CUGA",
        Sproc: "sputilities.webui.SEARCH_ugeaf_DOB",
        Parameters: ["@SEARCH", SearchTerm],
        LinkBar: (
          <div className="SearchSwitch">
            <Link to="/UGEAF/Search/CurrentUGApplicants/" className="Other">
              Current UG Applicants
            </Link>
            <Link to="/UGEAF/Search/ArchivedUGApplicants/" className="Other">
              Archived UG Applicants
            </Link>
            <Link
              to={"/UGEAF/Search/CurrentUGApplicantsbyDOB/" + SearchTerm}
              className="Current"
            >
              Current UG Applicants by DOB
            </Link>
          </div>
        ),
        SearchRoot: "/UGEAF/Search/CurrentUGApplicantsbyDOB/",
      },
      {
        ID: {
          Human: "Current Students (ESF)",
          Machine: "CurrentStudentsESF",
          Style: "ESF",
        },
        SearchPlaceholder: "Surname, PRID, login or reg no",
        Key: "SPOWUI_CSESF_" + Refresher,
        EID: "SPOWUI_CSESF",
        Sproc: "sputilities.webui.SEARCH_esf_student",
        Parameters: ["@SEARCH", SearchTerm, "@CURRENT", "1"],
        LinkBar: (
          <div className="SearchSwitch">
            <Link
              to={"/Search/CurrentStudentsESF/" + SearchTerm}
              className="Current"
            >
              Current Students (ESF)
            </Link>
            <Link
              to={"/Search/ArchivedStudentsESF/" + SearchTerm}
              className="Other"
            >
              Archived Students (ESF)
            </Link>
            <Link to="/Search/CurrentStudentsESFbyDOB/" className="Other">
              Current/Pre-Arrival Students (ESF) by DOB
            </Link>
          </div>
        ),
        SearchRoot: "/Search/CurrentStudentsESF/",
      },
      {
        ID: {
          Human: "Archived Students (ESF)",
          Machine: "ArchivedStudentsESF",
          Style: "ESF",
        },
        SearchPlaceholder: "Surname, PRID, login or reg no",
        Key: "SPOWUI_ASESF_" + Refresher,
        EID: "SPOWUI_ASESF",
        Sproc: "sputilities.webui.SEARCH_esf_student",
        Parameters: ["@SEARCH", SearchTerm, "@CURRENT", "0"],
        LinkBar: (
          <div className="SearchSwitch">
            <Link
              to={"/Search/CurrentStudentsESF/" + SearchTerm}
              className="Other"
            >
              Current Students (ESF)
            </Link>
            <Link
              to={"/Search/ArchivedStudentsESF/" + SearchTerm}
              className="Current"
            >
              Archived Students (ESF)
            </Link>
            <Link to="/Search/CurrentStudentsESFbyDOB/" className="Other">
              Current/Pre-Arrival Students (ESF) by DOB
            </Link>
          </div>
        ),
        SearchRoot: "/Search/ArchivedStudentsESF/",
      },
      {
        ID: {
          Human: "Current Students (ECF)",
          Machine: "CurrentStudentsECF",
          Style: "ECF",
        },
        SearchPlaceholder: "Surname, PRID, login or reg no",
        Key: "SPOWUI_CSECF_" + Refresher,
        EID: "SPOWUI_CSECF",
        Sproc: "sputilities.webui.SEARCH_ecf_student",
        Parameters: ["@SEARCH", SearchTerm, "@CURRENT", "1"],
        LinkBar: (
          <div className="SearchSwitch">
            <Link
              to={"/Search/CurrentStudentsECF/" + SearchTerm}
              className="Current"
            >
              Current Students (ECF)
            </Link>
            <Link
              to={"/Search/ArchivedStudentsECF/" + SearchTerm}
              className="Other"
            >
              Archived Students (ECF)
            </Link>
          </div>
        ),
        SearchRoot: "/Search/CurrentStudentsECF/",
      },
      {
        ID: {
          Human: "Archived Students (ECF)",
          Machine: "ArchivedStudentsECF",
          Style: "ECF",
        },
        SearchPlaceholder: "Surname, PRID, login or reg no",
        Key: "SPOWUI_ASESF_" + Refresher,
        EID: "SPOWUI_ASECF",
        Sproc: "sputilities.webui.SEARCH_ecf_student",
        Parameters: ["@SEARCH", SearchTerm, "@CURRENT", "0"],
        LinkBar: (
          <div className="SearchSwitch">
            <Link
              to={"/Search/CurrentStudentsECF/" + SearchTerm}
              className="Other"
            >
              Current Students (ECF)
            </Link>
            <Link
              to={"/Search/ArchivedStudentsECF/" + SearchTerm}
              className="Current"
            >
              Archived Students (ECF)
            </Link>
          </div>
        ),
        SearchRoot: "/Search/ArchivedStudentsECF/",
      },
      {
        ID: {
          Human: "Current PG Applicants",
          Machine: "CurrentPGApplicants",
          Style: "EAF",
        },
        SearchPlaceholder: "PG No, surname, or PRID",
        Key: "SPOWUI_CPGA_" + Refresher,
        EID: "SPOWUI_CPGA",
        Sproc: "sputilities.webui.SEARCH_pgeaf",
        Parameters: ["@SEARCH", SearchTerm, "@CURRENT", "1"],
        LinkBar: (
          <div className="SearchSwitch">
            <Link
              to={"/PGEAF/Search/CurrentPGApplicants/" + SearchTerm}
              className="Current"
            >
              Current PG Applicants
            </Link>
            <Link
              to={"/PGEAF/Search/ArchivedPGApplicants/" + SearchTerm}
              className="Other"
            >
              Archived PG Applicants
            </Link>
            <Link
              to="/PGEAF/Search/CurrentPGApplicantsbyDOB/"
              className="Other"
            >
              Current PG Applicants by DOB
            </Link>
          </div>
        ),
        SearchRoot: "/PGEAF/Search/CurrentPGApplicants/",
      },
      {
        ID: {
          Human: "Archived PG Applicants",
          Machine: "ArchivedPGApplicants",
          Style: "EAF",
        },
        SearchPlaceholder: "PG No, surname, or PRID",
        Key: "SPOWUI_CPGA_" + Refresher,
        EID: "SPOWUI_CPGA",
        Sproc: "sputilities.webui.SEARCH_pgeaf",
        Parameters: ["@SEARCH", SearchTerm, "@CURRENT", "0"],
        LinkBar: (
          <div className="SearchSwitch">
            <Link
              to={"/PGEAF/Search/CurrentPGApplicants/" + SearchTerm}
              className="Other"
            >
              Current PG Applicants
            </Link>
            <Link
              to={"/PGEAF/Search/ArchivedPGApplicants/" + SearchTerm}
              className="Current"
            >
              Archived PG Applicants
            </Link>
            <Link
              to="/PGEAF/Search/CurrentPGApplicantsbyDOB/"
              className="Other"
            >
              Current PG Applicants by DOB
            </Link>
          </div>
        ),
        SearchRoot: "/PGEAF/Search/ArchivedPGApplicants/",
      },
      {
        ID: {
          Human: "Current UG Applicants",
          Machine: "CurrentUGApplicants",
          Style: "EAF",
        },
        SearchPlaceholder: "UCAS ID, surname, PRID, or DA Number",
        Key: "SPOWUI_CUGA_" + Refresher,
        EID: "SPOWUI_CUGA",
        Sproc: "sputilities.webui.SEARCH_ugeaf",
        Parameters: ["@SEARCH", SearchTerm, "@CURRENT", "1"],
        LinkBar: (
          <div className="SearchSwitch">
            <Link
              to={"/UGEAF/Search/CurrentUGApplicants/" + SearchTerm}
              className="Current"
            >
              Current UG Applicants
            </Link>
            <Link
              to={"/UGEAF/Search/ArchivedUGApplicants/" + SearchTerm}
              className="Other"
            >
              Archived UG Applicants
            </Link>
            <Link
              to="/UGEAF/Search/CurrentUGApplicantsbyDOB/"
              className="Other"
            >
              Current UG Applicants by DOB
            </Link>
          </div>
        ),
        SearchRoot: "/UGEAF/Search/CurrentUGApplicants/",
      },
      {
        ID: {
          Human: "Archived UG Applicants",
          Machine: "ArchivedUGApplicants",
          Style: "EAF",
        },
        SearchPlaceholder: "UCAS ID, surname, PRID, or DA Number",
        Key: "SPOWUI_CUGA_" + Refresher,
        EID: "SPOWUI_CUGA",
        Sproc: "sputilities.webui.SEARCH_ugeaf",
        Parameters: ["@SEARCH", SearchTerm, "@CURRENT", "0"],
        LinkBar: (
          <div className="SearchSwitch">
            <Link
              to={"/UGEAF/Search/CurrentUGApplicants/" + SearchTerm}
              className="Other"
            >
              Current UG Applicants
            </Link>
            <Link
              to={"/UGEAF/Search/ArchivedUGApplicants/" + SearchTerm}
              className="Current"
            >
              Archived UG Applicants
            </Link>
            <Link
              to="/UGEAF/Search/CurrentUGApplicantsbyDOB/"
              className="Other"
            >
              Current UG Applicants by DOB
            </Link>
          </div>
        ),
        SearchRoot: "/UGEAF/Search/ArchivedUGApplicants/",
      },
      {
        ID: {
          Human: "Student Visa Audit Flag Search (Students)",
          Machine: "StudentVisaAuditFlagSearchESF",
          Style: "ESF",
        },
        SearchPlaceholder: "Surname, Reg No., login, CAS No., or PRID",
        Key: "SPOWUI_SVUG_" + Refresher,
        EID: "SPOWUI_SVUG",
        Sproc: "sputilities.webui.SEARCH_esf_tier4_auditflag",
        Parameters: ["@Search", SearchTerm],
        LinkBar: <></>,
        SearchRoot: "/Compliance/Search/student/",
      },
      {
        ID: {
          Human: "Student Visa Audit Flag Search (UG Applicants)",
          Machine: "StudentVisaAuditFlagSearchUG",
          Style: "EAF",
        },
        SearchPlaceholder: "Surname, CAS No., or UCAS No.",
        Key: "SPOWUI_SVUG_" + Refresher,
        EID: "SPOWUI_SVUG",
        Sproc: "sputilities.webui.SEARCH_ugeaf_tier4_auditflag",
        Parameters: ["@Search", SearchTerm],
        LinkBar: <></>,
        SearchRoot: "/Compliance/Search/ug/",
      },
      {
        ID: {
          Human: "Student Visa Audit Flag Search (PG Applicants)",
          Machine: "StudentVisaAuditFlagSearchPG",
          Style: "EAF",
        },
        SearchPlaceholder: "Surname, CAS No., or PG No.",
        Key: "SPOWUI_SVPG_" + Refresher,
        EID: "SPOWUI_SVPG",
        Sproc: "sputilities.webui.SEARCH_pgeaf_tier4_auditflag",
        Parameters: ["@Search", SearchTerm],
        LinkBar: <></>,
        SearchRoot: "/Compliance/Search/pg/",
      },
      {
        ID: { Human: "Compliance", Machine: "Compliance", Style: "ESF" },
        SearchPlaceholder: "Surname, PRID, CAS No., login, or reg no",
        Key: "SPOWUI_COMPL_" + Refresher,
        EID: "SPOWUI_COMPL",
        Sproc: "sputilities.webui.SEARCH_comply",
        Parameters: ["@Search", SearchTerm],
        LinkBar: <></>,
        SearchRoot: "/Compliance/Search/",
      },
      {
        ID: { Human: "Clearing", Machine: "Clearing", Style: "EAF" },
        SearchPlaceholder: "Surname or Clearing reference.",
        Key: "SPOWUI_CLEAR_" + Refresher,
        EID: "SPOWUI_CLEAR",
        Sproc: "sputilities.webui.SEARCH_ugeaf_clearing",
        Parameters: ["@Search", SearchTerm],
        LinkBar: <></>,
        SearchRoot: "/Clearing/Search/",
      },
    ];
  }
}

// ew ew ew
export function IngestDateFromURL(input: string): dayjs.Dayjs | undefined {
  const valar =
    input.indexOf("-") > -1
      ? input.split("-")
      : input.indexOf("/") > -1
        ? input.split("/")
        : [];
  //  console.log(input);
  //  console.log(valar);
  if (valar.length > 0) {
    if (valar[0].length === 4) {
      // YYYY-MM-DD
      const valar_concat = valar[0] + "-" + valar[1] + "-" + valar[2];
      //  console.log(valar_concat);
      //  console.log(dayjs(valar_concat));
      return dayjs(valar_concat);
    } else {
      // DD-MM-YYYY
      const valar_concat = valar[1] + "-" + valar[0] + "-" + valar[2];
      //  console.log(valar_concat);
      //  console.log(dayjs(valar_concat));
      return dayjs(valar_concat);
    }
  }
  return undefined;
}

class SearchResults extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const __max = dayjs().add(-14, "year");
    const __min = dayjs().add(-100, "year");

    //  console.log("SearchResults constructor ...");
    //  console.log(props.SearchTerm);
    //  console.log(dayjs(props.SearchTerm));

    const refr = dayjs().toISOString();

    this.state = {
      _SearchString: props.SearchTerm.replaceAll("_", " "),
      _SearchDate: props.SearchTerm.replaceAll("_", " "),
      refreshed: refr,
      _max: __max,
      _min: __min,
    };

    //  console.log("SearchResults state...");
    //  console.log(this.state);
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    // because SearchResults is class-derived, its props have an update issue when the app
    // simultaneously changes searchtype and sets searchterm to "".
    // so, we manually check and IFF it's been deliberately set to /:SearchType/{""},
    // we wipe state.
    //  if (
    //    prevProps.SearchType !== this.props.SearchType &&
    //    this.props.SearchTerm === ""
    //  ) {
    //    this.setState({ _SearchString: "", _SearchDate: "" });
    //  }
    if (
      this.props.SearchTerm !== prevProps.SearchTerm &&
      (this.props.SearchTerm !== this.state._SearchDate ||
        this.props.SearchTerm !== this.state._SearchString)
    ) {
      const refr = dayjs().toISOString();
      this.setState({
        _SearchDate: this.props.SearchTerm,
        _SearchString: this.props.SearchTerm,
        refreshed: refr,
      });
    }
  }

  ValidDate = () => {
    //  console.log("ValidDate");
    //  console.log("this.state._SearchDate");
    //  console.log(this.state._SearchDate);

    const df = IngestDateFromURL(this.state._SearchDate);

    //  console.log("df");
    //  console.log(df);

    if (df?.isValid()) {
      const now = df;
      return {
        Valid: now > this.state._min && now < this.state._max,
        Value: now,
      };
    } else {
      return { Valid: false, Value: undefined };
    }
  };

  InvalidDateMessage = () => {
    if (this.state._SearchDate === "") {
      return "Invalid date.";
    } else {
      const df = IngestDateFromURL(this.state._SearchDate);

      const now = dayjs(df);

      if (df === undefined || !now.isValid()) {
        return "Invalid date.";
      } else if (now > this.state._min) {
        return "Specified date of birth should be more than 14 years in the past.";
      } else if (now < this.state._max) {
        return "Specified date of birth should be less than 100 years in the past.";
      } else {
        return "";
      }
    }
  };

  WireFormat = (input: Dayjs) => {
    return dayjs(input).format("YYYY-MM-DD");
  };

  URLFormat = (input: Dayjs) => {
    return dayjs(input).format("DD-MM-YYYY");
  };

  render() {
    var PassToAllGrids = {
      Callouts: true,
      Logger: this.props.Logger,
      da: this.props.da,
      PCA: this.props.PCA,
      GetLink: this.props.GetLink,
      URLRoot: this.props.URLRoot,
      URLParams: this.props.URLParams,
      NoneMessage: "No results were returned.",
      Refresher: this.props.Refresher,
      Alias: this.props.Alias,
      AliasChecked: this.props.AliasChecked,
      ViewAsAll: this.props.ViewAsAll,
      RegisterError: this.props.RegisterError,
      DeregisterError: this.props.DeregisterError,
      RegisterStatus: this.props.RegisterStatus,
      DeregisterStatus: this.props.DeregisterStatus,
    };

    const scaff = new SearchScaffolder(
      this.props.Refresher,
      this.props.SearchTerm,
    );

    let ThisScaffold = scaff.TheseScaffolds.find(
      (th) => th.ID.Machine === this.props.SearchType,
    );

    //  console.log(scaff);
    //  console.log(this.props.SearchType);
    //  console.log(ThisScaffold);
    //  console.log("this.props.SearchTerm");
    //  console.log(this.props.SearchTerm);
    //  console.log("this.state._SearchString");
    //  console.log(this.state._SearchString);
    //  console.log("this.state._SearchDate");
    //  console.log(this.state._SearchDate);
    //  console.log("this.state._max");
    //  console.log(this.state._max);
    //  console.log("this.state._min");
    //  console.log(this.state._min);
    //  console.log("dayjs(this.state._newSearchString, 'DD-MM-YYYY')");
    //  console.log(dayjs(this.state._newSearchString, "DD-MM-YYYY"));
    //  console.log("dayjs(this.state._newSearchString, 'DD-MM-YYYY').isValid()");
    //  console.log(dayjs(this.state._newSearchString, "DD-MM-YYYY").isValid());
    //  console.log("dayjs(this.props.SearchTerm, 'DD-MM-YYYY')");
    //  console.log(dayjs(this.props.SearchTerm, "DD-MM-YYYY"));
    //  console.log("dayjs(this.props.SearchTerm, 'DD-MM-YYYY').isValid()");
    //  console.log(dayjs(this.props.SearchTerm, "DD-MM-YYYY").isValid());

    let thisPanel = (
      <GridWrapper
        {...PassToAllGrids}
        key={ThisScaffold.Key}
        eid={ThisScaffold.EID}
        sproc={ThisScaffold.Sproc}
        params={ThisScaffold.Parameters}
        class={
          "UoE-Grid " + ThisScaffold.ID.Style + " " + ThisScaffold.ID.Machine
        }
      />
    );

    if (ThisScaffold === undefined) {
      return <></>;
    } else {
      return (
        <div className="SearchResults">
          <div className="SearchSummary">
            <h2>
              {this.props.GetMode(this.props.SearchType).Human} Search Results
            </h2>
            <div className="SearchAgainBar">
              <label htmlFor="MainSearchField" id="MainSearchLabel">
                {"Search by " + ThisScaffold.SearchPlaceholder}
              </label>
              <br />
              {this.props.GetMode(this.props.SearchType).Machine ===
                "CurrentStudentsESFbyDOB" ||
              this.props.GetMode(this.props.SearchType).Machine ===
                "CurrentPGApplicantsbyDOB" ||
              this.props.GetMode(this.props.SearchType).Machine ===
                "CurrentUGApplicantsbyDOB" ? (
                <>
                  <input
                    key={this.state.refreshed}
                    className={
                      "dateinput" + (this.ValidDate().Valid ? "" : " invalid")
                    }
                    id="VisibleFrom"
                    type="date"
                    defaultValue={
                      dayjs(this.props.SearchTerm).isValid()
                        ? this.WireFormat(
                            IngestDateFromURL(this.props.SearchTerm),
                          )
                        : ""
                    }
                    min={this.WireFormat(this.state._min)}
                    max={this.WireFormat(this.state._max)}
                    onChange={(e) => {
                      this.setState({
                        _SearchDate: e.target.value,
                      });
                    }}
                    onKeyUp={(ev) => {
                      if (ev.key === "Enter") {
                        const vd = this.ValidDate();
                        if (vd.Valid) {
                          this.props.Navigation(
                            ThisScaffold.SearchRoot + this.URLFormat(vd.Value),
                          );
                        }
                      }
                    }}
                    onPaste={(e) => {
                      const val = e.clipboardData.getData("text");
                      const valar =
                        val.indexOf("-") > -1
                          ? val.split("-")
                          : val.indexOf("/") > -1
                            ? val.split("/")
                            : [];

                      //  console.log(val);
                      //  console.log(valar);
                      if (valar.length > 0) {
                        if (valar[1].length === 3) {
                          const pastedESISdate =
                            val.indexOf("-") > -1
                              ? dayjs(val, "DD-MMM-YYYY", true)
                              : dayjs(val, "DD/MMM/YYYY", true);

                          if (pastedESISdate.isValid()) {
                            // I know, I know, BUT, input type="date" has 'wire' and 'presentation'
                            // internal date values that cause _havoc_ with the State paradigm.
                            e.currentTarget.value =
                              this.WireFormat(pastedESISdate);
                            this.setState({
                              _SearchDate: this.URLFormat(pastedESISdate),
                            });
                            this.props.Navigation(
                              ThisScaffold.SearchRoot +
                                this.URLFormat(pastedESISdate),
                            );
                          }
                        } else {
                          const pastedotherdate = IngestDateFromURL(val);
                          if (pastedotherdate.isValid()) {
                            e.currentTarget.value =
                              this.WireFormat(pastedotherdate);
                            this.setState({
                              _SearchDate: this.URLFormat(pastedotherdate),
                            });
                            this.props.Navigation(
                              ThisScaffold.SearchRoot +
                                this.URLFormat(pastedotherdate),
                            );
                          }
                        }
                      }
                    }}
                  />

                  {this.ValidDate().Valid ? (
                    <></>
                  ) : (
                    <div className="invalid">{this.InvalidDateMessage()}</div>
                  )}
                </>
              ) : (
                <input
                  type="text"
                  pattern="^[0-9A-Za-z '-]*$"
                  value={this.state._SearchString}
                  onChange={(e) => {
                    this.setState({ _SearchString: e.target.value });
                  }}
                  placeholder={ThisScaffold.SearchPlaceholder}
                  id="MainSearchField"
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      const newloc = this.state._SearchString
                        .trim()
                        .replaceAll(" ", "_");
                      this.props.Navigation(ThisScaffold.SearchRoot + newloc);
                    }
                  }}
                ></input>
              )}
              {this.props.GetMode(this.props.SearchType).Machine ===
                "CurrentStudentsESFbyDOB" ||
              this.props.GetMode(this.props.SearchType).Machine ===
                "CurrentPGApplicantsbyDOB" ||
              this.props.GetMode(this.props.SearchType).Machine ===
                "CurrentUGApplicantsbyDOB" ? (
                <Button
                  id="MainSearchButton"
                  onClick={() => {
                    const vd = this.ValidDate();
                    if (vd.Valid) {
                      this.props.Navigation(
                        ThisScaffold.SearchRoot + this.URLFormat(vd.Value),
                      );
                    }
                  }}
                  disabled={!this.ValidDate().Valid}
                >
                  Search
                </Button>
              ) : (
                <Button
                  id="MainSearchButton"
                  onClick={() => {
                    const newloc = this.state._SearchString
                      .trim()
                      .replaceAll(" ", "_");
                    this.props.Navigation(ThisScaffold.SearchRoot + newloc);
                  }}
                >
                  Search
                </Button>
              )}
              {ThisScaffold.LinkBar}
            </div>
          </div>
          {this.props.Error !== undefined && this.props.Error !== "" ? (
            <div className="ErrorBar">{this.props.Error}</div>
          ) : (
            <div className="SearchResultsPanel">{thisPanel}</div>
          )}
        </div>
      );
    }
  }
}

export default SearchResults;
