import { PropsWithChildren } from "react";
import { useUser } from "../Providers/UserProvider";

interface IProps {
  Whitelist?: string[];
  Blacklist?: string[];
  ShowDisallowed?: boolean;
}

// Only display the children if the user has the specified permission
function IfPermitted(props: PropsWithChildren<IProps>) {
  let userData = useUser();
  var displayThis = false;

  props.Whitelist?.forEach((wh) => {
    if (displayThis) {
      return;
    }

    if (
      userData.AuthedUser != undefined &&
      userData.AuthedUser.permissions.includes(wh)
    ) {
      if (
        userData.ViewAsUser == undefined ||
        userData.ViewAsUser.permissions.includes(wh)
      ) {
        displayThis = true;
      }
      return;
    }
  });
  if (displayThis) {
    props.Blacklist?.forEach((wh) => {
      if (!displayThis) {
        return;
      }

      if (
        userData.AuthedUser != undefined &&
        userData.AuthedUser.permissions.includes(wh)
      ) {
        if (
          userData.ViewAsUser == undefined ||
          userData.ViewAsUser.permissions.includes(wh)
        ) {
          displayThis = false;
        }
        return;
      }
    });
  }

  if (displayThis) {
    return <>{props.children}</>;
  } else if (props.ShowDisallowed) {
    return (
      <div className="Body">
        <div className="NoPermissions">
          Access to this resource is strictly limited. If you believe you should
          have access, but are seeing this page, please contact{" "}
          <a href="mailto:sphelp@essex.ac.uk">sphelp@essex.ac.uk</a> giving
          details of your job role and what access you require (Read / File
          Upload) and to which systems (ESF/UGEAF/PGEAF/ECF).
          <br />
          Thank you.
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default IfPermitted;
