import { NamedObject } from "./NamedObject";

export class GetMergedWorkOrdersRequest {
  Environment: NonNullable<"LIVE" | "TEST" | "DEV" | "LOCAL" | "">;
  ConveyanceID: number;
  OlderThan?: Date;
  NewerThan?: Date;
  IncludeMetadata: string[];
  SprocName: string;
  SprocParameters: NamedObject[];
  KeyField: string;
}
