import NoticeRow from "./NoticeRow";
import Notice from "../../../models/Notice";
import dayjs from "dayjs";

interface IProps {
  NewNotice: (
    _scopes?: string[],
    _startDate?: dayjs.Dayjs,
    _enddate?: dayjs.Dayjs,
  ) => void;
  NewNoticeAllowed: boolean;
  DeleteNotice: (_noticeID: string) => void;
  EditNotice: (_noticeID: string) => void;
  Notices: Notice[];
}

export const NoticeGrid = (props: IProps) => {
  return (
    <div className="NoticeGrid">
      <div className="HeaderBar">
        Notices
        {props.NewNoticeAllowed ? (
          <div
            onClick={() => {
              props.NewNotice();
            }}
            className="NewNoticeButton"
          >
            + Create New
          </div>
        ) : (
          ""
        )}
      </div>
      {props.Notices === undefined || props.Notices.length === 0 ? (
        <div className="Empty">No messages found</div>
      ) : (
        props.Notices.map((th, i) => {
          return (
            <NoticeRow
              key={"Notice_" + i.toString()}
              Notice={th}
              EditNotice={props.EditNotice}
              DeleteNotice={props.DeleteNotice}
            ></NoticeRow>
          );
        })
      )}
    </div>
  );
};

export default NoticeGrid;
