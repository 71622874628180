import { PublicClientApplication } from "@azure/msal-browser";
import { Logging } from "../Logging";
import { DataAccess } from "../data/DataAccess";
import TimetableSearch from "./TimetableSearch";
import { NavigateFunction, useLocation } from "react-router-dom";
import ModeStruct from "../models/ModeStruct";
import SPOUploadLocation from "../models/SPOUploadLocation";

interface IProps {
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;
  Mode: string[];
  URLRoot: string;
  URLParams: string;
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  Refresh: () => void;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  GetMode: (Either: string) => ModeStruct;
  GetSPUL: (shortname: string) => SPOUploadLocation;
  SetSearch: (SearchString: string) => void;
  SetTaskList: (TLRef: string) => void;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
  Navigation: NavigateFunction;
}

export const TimetableSearchWrapper = (props: IProps) => {
  const location = useLocation();

  return <TimetableSearch {...props} Location={location} />;
};
