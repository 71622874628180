import { useState, useEffect, useRef } from "react";
import { useApi } from "../../Providers/ApiProvider";
import "../../assets/css/ADMIN_LF/Invar.css";
import { MonitoredWorkOrderDatumResponse } from "../../data/InvarDataModels/MonitoredWorkOrderDatumResponse";
import { MonitoredWorkOrderResponse } from "../../data/InvarDataModels/MonitoredWorkOrderResponse";
import { useLoaderData, useNavigate } from "react-router-dom";
import { MonitoredWorkOrderStepResponse } from "../../data/InvarDataModels/MonitoredWorkOrderStepResponse";
import { MonitoredWorkOrderExceptionResponse } from "../../data/InvarDataModels/MonitoredWorkOrderExceptionResponse";
import { MonitoredWorkOrderEmailResponse } from "../../data/InvarDataModels/MonitoredWorkOrderEmailResponse";
import dayjs from "dayjs";
import { Mondrian } from "../Mondrian/Mondrian";
import { MondrianDataProvider } from "../Mondrian/MondrianDataProvider";
import { MondrianDisplayType } from "../Mondrian/Structs/Enums/MondrianDisplayType";
import { MondrianColumn } from "../Mondrian/Structs/Types/MondrianStructuredData";
import { ConveyanceSegmentResponse } from "../../data/InvarDataModels/ConveyanceSegmentResponse";
import { ConveyanceSegmentConfigResponse } from "../../data/InvarDataModels/ConveyanceSegmentConfigResponse";
import { ActionResponse } from "../../data/InvarDataModels/ActionResponse";

interface IProps {}

export const InvarMonitorOne = (props: IProps) => {
  const loader: number = useLoaderData() as number;

  const DA = useApi();
  const IDA = DA.Invar;

  const [thisWorkOrder, setThisWorkOrder] = useState(
    MonitoredWorkOrderResponse.prototype,
  );
  const [thisConveyance, setThisConveyance] = useState(undefined);
  const [theseConveyanceSegments, setTheseConveyanceSegments] = useState(
    Array<ConveyanceSegmentResponse>,
  );
  const [theseActions, setTheseActions] = useState(Array<ActionResponse>);
  const [theseConveyanceSegmentConfigs, setTheseConveyanceSegmentConfigs] =
    useState(Array<ConveyanceSegmentConfigResponse>);
  const [theseWorkOrderData, setTheseWorkOrderData] = useState(
    Array<MonitoredWorkOrderDatumResponse>,
  );
  const [theseWorkOrderSteps, setTheseWorkOrderSteps] = useState(
    Array<MonitoredWorkOrderStepResponse>,
  );
  const [theseWorkOrderExceptions, setTheseWorkOrderExceptions] = useState(
    Array<MonitoredWorkOrderExceptionResponse>,
  );
  const [theseWorkOrderEmails, setTheseWorkOrderEmails] = useState(
    Array<MonitoredWorkOrderEmailResponse>,
  );
  const [mutatedLifeCycle, setMutatedLifeCycle] = useState(
    Array<LocalLifeCycleObject>,
  );

  const [loadingResults, setLoadingResults] = useState(true);

  const refTimeoutProcessing = useRef(null);
  const refTimeoutStale = useRef(null);
  const refTimeoutAll = useRef(null);

  const navigate = useNavigate();

  const columns: MondrianColumn[] = [
    { HumanName: "When", MachineNames: ["When"] },
    { HumanName: "Type", MachineNames: ["Type"] },
    { HumanName: "What Happened", MachineNames: ["WhatHappened"] },
    { HumanName: "Detail", MachineNames: ["Detail"] },
    { HumanName: "_ROW_PROCESSING", MachineNames: ["_ROW_PROCESSING"] },
  ];

  useEffect(() => {
    if (IDA !== undefined && loader !== undefined) {
      Load();
    }
  }, [IDA, loader]);

  useEffect(() => {
    if (thisWorkOrder?.ConveyanceID ?? 0 !== 0) {
      IDA.GetConveyance(thisWorkOrder.ConveyanceID).then((data) => {
        setThisConveyance(data);
      });
      IDA.GetActions().then((data) => {
        setTheseActions(data);
      });
      IDA.GetConveyanceSegmentsForConveyance(thisWorkOrder.ConveyanceID).then(
        (data) => {
          setTheseConveyanceSegments(data);
        },
      );
      IDA.GetConveyanceSegmentConfigsForConveyance(
        thisWorkOrder.ConveyanceID,
      ).then((data) => {
        setTheseConveyanceSegmentConfigs(data);
      });
    }
  }, [thisWorkOrder]);

  const Load = async () => {
    setThisWorkOrder(await IDA.MonitorThisWorkOrder(loader));
    setTheseWorkOrderData(await IDA.MonitorThisWorkOrderData(loader));
    setTheseWorkOrderEmails(await IDA.MonitorThisWorkOrderEmails(loader));
    setTheseWorkOrderExceptions(
      await IDA.MonitorThisWorkOrderExceptions(loader),
    );
    setTheseWorkOrderSteps(await IDA.MonitorThisWorkOrderSteps(loader));
  };

  const Archive = async (WOID: number) => {
    await IDA.ArchiveMonitoredWorkOrder(WOID);
    navigate("..", { relative: "path" });
  };

  const Clone = async (WOID: number) => {
    await IDA.CloneMonitoredWorkOrder(WOID);
    navigate("..", { relative: "path" });
  };

  const Activate = async (WOID: number) => {
    setThisWorkOrder(await IDA.ActivateMonitoredWorkOrder(WOID));
  };

  const Cancel = async (WOID: number) => {
    setThisWorkOrder(await IDA.CancelMonitoredWorkOrder(WOID));
  };

  const PollWorkOrders = async (WOID: number, relay: boolean) => {
    IDA.MonitorThisWorkOrder(WOID).then((data) => {
      setThisWorkOrder(data);
    });

    if (relay) {
      RegenerateTimers();
    }
  };

  const RegenerateTimers = () => {
    //  console.log("RegenerateTimers");
    //  console.log(refTimeoutProcessing);
    //  console.log(refTimeoutStale);
    //  console.log(refTimeoutAll);

    if (refTimeoutProcessing !== undefined) {
      if (refTimeoutProcessing.current !== undefined) {
        clearTimeout(refTimeoutProcessing.current);
        refTimeoutProcessing.current = undefined;
      }
    }

    if (refTimeoutStale !== undefined) {
      if (refTimeoutStale.current !== undefined) {
        clearTimeout(refTimeoutStale.current);
        refTimeoutStale.current = undefined;
      }
    }

    if (refTimeoutAll !== undefined) {
      if (refTimeoutAll.current !== undefined) {
        clearTimeout(refTimeoutAll.current);
        refTimeoutAll.current = undefined;
      }
    }

    var Processings: MonitoredWorkOrderResponse[] = [];

    // for each work order marked as Processing, we register a new Interval with JS to refresh that particular Work Order after 15s.
    //  console.log(audits);

    if (thisWorkOrder.Active) {
      //  console.log("RegenerateTimers - proc +1");
      Processings.push(thisWorkOrder);
    }

    //  console.log(Processings);
    if (Processings.length > 0) {
      refTimeoutProcessing.current = setTimeout(async () => {
        PollWorkOrders(thisWorkOrder.WorkOrderID, true);
      }, 5000);
    }
  };

  type LocalLifeCycleObject = {
    When: string;
    _Type: string;
    Type: JSX.Element;
    WhatHappened: string | JSX.Element;
    Detail: string | JSX.Element;
  };

  useEffect(() => {
    var temp: LocalLifeCycleObject[] = [];
    theseWorkOrderData.forEach((th) => {
      temp.push({
        When: dayjs(th.Created).format("YYYY-MM-DD HH:mm:ss.SSS"),
        _Type: "Data",
        Type: <b className="Data">Data</b>,
        WhatHappened: "The following data was set:",
        Detail: (
          <span className="DataRow">
            <span className="DataName">{th.DataName}</span>
            <span className="DataValue">{th.DataValue}</span>
          </span>
        ),
      });
    });
    theseWorkOrderEmails.forEach((th) => {
      temp.push({
        When: dayjs(th.Datestamp).format("YYYY-MM-DD HH:mm:ss.SSS"),
        _Type: "Email",
        Type: <b className="Email">Email</b>,
        WhatHappened: "An email was sent.",
        Detail: th.Subject,
      });
    });
    theseWorkOrderExceptions.forEach((th) => {
      temp.push({
        When: dayjs(th.Created).format("YYYY-MM-DD HH:mm:ss.SSS"),
        _Type: "Exception",
        Type: <b className="Exception">Exception</b>,
        WhatHappened: "An exception was thrown.",
        Detail: th.ExceptionMessage,
      });
    });
    theseWorkOrderSteps.forEach((th) => {
      temp.push({
        When: dayjs(th.Created).format("YYYY-MM-DD HH:mm:ss.SSS"),
        _Type: "StepBegin",
        Type: <b className="Begin">Step Begins</b>,
        WhatHappened: (
          <div>
            {
              theseConveyanceSegments?.find(
                (ith) => ith.ConveyanceSegmentID === th.ConveyanceSegmentID,
              )?.SegmentName
            }
          </div>
        ),
        Detail: (
          <table>
            <tbody>
              {theseConveyanceSegmentConfigs
                ?.filter(
                  (ith) => ith.ConveyanceSegmentID === th.ConveyanceSegmentID,
                )
                ?.map((ith) => {
                  var repdval = ith.ConfigValue;
                  if (repdval === "##CREATOR##") {
                    repdval = thisWorkOrder.CreatedBy;
                  } else if (repdval === "##ENVIRONMENT##") {
                    repdval = thisWorkOrder.Environment;
                  } else if (repdval.lastIndexOf("#") !== -1) {
                    var datum = theseWorkOrderData.find((iith) => {
                      return ith.ConfigValue.includes(iith.DataName);
                    });
                    if (datum !== undefined) {
                      repdval = datum.DataValue;
                    }
                  }
                  var cnam = ith.ConfigName;
                  var conseg = theseConveyanceSegments.find(
                    (ith) => ith.ConveyanceSegmentID === th.ConveyanceSegmentID,
                  );
                  if (conseg !== undefined) {
                    var act = theseActions.find(
                      (ith) => ith.ActionID === conseg.ActionID,
                    );
                    cnam = cnam.replace(act.Name + ".", "");
                  }
                  return (
                    <tr key={"csc_" + ith.ConveyanceSegmentConfigID}>
                      <td>{cnam}</td>
                      <td>{ith.ConfigValue}</td>
                      <td>{repdval !== ith.ConfigValue ? repdval : ""}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ),
      });
    });
    theseWorkOrderSteps.forEach((th) => {
      //  console.log(th);
      //  console.log(th.Completed);
      //  console.log(th.Failed);
      if (
        (th.Completed !== null && th.Completed !== undefined) ||
        (th.Failed !== null && th.Failed !== undefined)
      ) {
        temp.push({
          When:
            th.Completed !== undefined && th.Completed !== null
              ? dayjs(th.Completed).format("YYYY-MM-DD HH:mm:ss.SSS")
              : th.Failed !== undefined && th.Failed !== null
                ? dayjs(th.Failed).format("YYYY-MM-DD HH:mm:ss.SSS")
                : undefined,
          _Type:
            th.Completed !== undefined && th.Completed !== null
              ? "Success"
              : th.Failed !== undefined && th.Failed !== null
                ? "Failure"
                : "Fizzle",
          Type:
            th.Completed !== undefined && th.Completed !== null ? (
              <b className="Success">Step Succeeds</b>
            ) : th.Failed !== undefined && th.Failed !== null ? (
              <b className="Failure">Step Fails</b>
            ) : (
              <b className="Fizzle">Step Fizzles</b>
            ),
          WhatHappened: theseConveyanceSegments?.find(
            (ith) => ith.ConveyanceSegmentID === th.ConveyanceSegmentID,
          )?.SegmentName,
          Detail:
            th.Completed !== undefined && th.Completed !== null
              ? "Step marked as Succeeded"
              : th.Failed !== undefined && th.Failed !== null
                ? "Step marked as Failed"
                : "Step appears to have never completed",
        });
      }
    });

    temp.sort((l, r) => {
      return dayjs(l.When).diff(dayjs(r.When));
    });

    var temp2: LocalLifeCycleObject[] = [];

    if (
      temp !== undefined &&
      temp[0] !== undefined &&
      temp[0].When !== undefined
    ) {
      temp.forEach((th) => {
        if (temp2.length === 0) {
          temp2.push(th);
        } else if (th._Type !== "Data") {
          temp2.push(th);
        } else if (temp2.lastItem._Type === "Data") {
          // if they're BOTH Data, merge them somehow...?
          temp2.lastItem.Detail = (
            <>
              {temp2.lastItem.Detail}
              {th.Detail}
            </>
          );
        }
      });
    }

    if (
      temp2 !== undefined &&
      temp2[0] !== undefined &&
      temp2[0].When !== undefined
    ) {
      const rootdts = temp2[0].When;

      temp2.forEach((th) => {
        if (th.When !== rootdts) {
          th.When = "+" + dayjs(th.When).diff(rootdts).toString() + "ms";
        }
      });
    }

    setLoadingResults(false);
    setMutatedLifeCycle(temp2);
  }, [
    theseWorkOrderData,
    theseWorkOrderEmails,
    theseWorkOrderExceptions,
    theseWorkOrderSteps,
    theseConveyanceSegments,
  ]);

  return (
    <div className="InvarMonitorOne">
      <h2>Work Order {thisWorkOrder?.WorkOrderID?.toString() ?? ""}</h2>
      {thisWorkOrder !== undefined ? (
        <div className={"WOCard" + (thisWorkOrder.Poison ? " Poisoned" : "")}>
          <div className="WOCardTop">
            <div className="WOCardTop_WOID">
              <b>WOID</b>
              <span>{thisWorkOrder?.WorkOrderID?.toString() ?? ""}</span>
            </div>
            <div className="WOCardTop_ConveyanceName">
              <b>Conveyance</b>
              <span>{thisConveyance?.Name ?? ""}</span>
            </div>
            <div className="WOCardTop_Status">
              <b>Status</b>
              <span>
                {thisWorkOrder.Poison
                  ? "Poisoned"
                  : thisWorkOrder.Cancelled
                    ? "Cancelled"
                    : thisWorkOrder.Active
                      ? "In-Flight"
                      : thisWorkOrder.Completed !== undefined
                        ? "Completed"
                        : "Pending"}
              </span>
            </div>
          </div>
          <div className="WOCardBody">
            <span className="WOCardBody_InitialValue">
              {thisWorkOrder.InitialValue}
            </span>
          </div>
          <div className="WOCardBody">
            <span className="WOCardBody_ViewButton">
              <div
                className="Mondrian_Button Split"
                onClick={() => {
                  Archive(thisWorkOrder.WorkOrderID);
                }}
              >
                <span className="Mondrian__Icon_Neutral">🗑</span>
                <span>Archive</span>
              </div>
              <div
                className="Mondrian_Button Split"
                onClick={() => {
                  Clone(thisWorkOrder.WorkOrderID);
                }}
              >
                <span className="Mondrian__Icon_Neutral Bump">🐑🐑</span>
                <span>Clone</span>
              </div>
              {!thisWorkOrder.Active &&
              thisWorkOrder.Completed === undefined &&
              !thisWorkOrder.Cancelled &&
              !thisWorkOrder.Poison ? (
                <div
                  className="Action"
                  onClick={() => {
                    Activate(thisWorkOrder.WorkOrderID);
                  }}
                >
                  Activate
                </div>
              ) : (
                <></>
              )}
              <a
                type="button"
                className="Mondrian_Button Split"
                href={
                  "/AdminPortal/Invar/Conveyance/" +
                  thisWorkOrder?.ConveyanceID?.toString()
                }
                target="_blank"
              >
                <span className="Mondrian__Icon_Neutral Bump">⇗</span>
                <span>View Conveyance Structure</span>
              </a>
            </span>
          </div>
          <div className="WOCardTime">
            <div className="WOCardTime_CreatedBy">
              <b>Created By</b>
              <span>{thisWorkOrder.CreatedBy}</span>
            </div>
            <div className="WOCardTime_Enqueued">
              <b>Enqueued</b>
              <span>{thisWorkOrder.Created}</span>
            </div>
            <div className="WOCardTime_FromTo">
              <b>&gt;&gt;&gt;</b>
              <span>&gt;&gt;&gt;</span>
            </div>
            <div className="WOCardTime_Finished">
              <b>Finished</b>
              <span>{thisWorkOrder.Completed}</span>
            </div>
            <div className="WOCardTime_Duration">
              <b>Duration</b>
              <span>
                {dayjs(0)
                  .utc()
                  .add(
                    dayjs(thisWorkOrder.Completed)
                      .utc()
                      .diff(dayjs(thisWorkOrder.Created).utc()),
                    "millisecond",
                  )
                  .format("HH.mm.ss.SSS")}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="Results">
        <MondrianDataProvider
          ObjectArray={mutatedLifeCycle}
          Columns={columns}
          LoadingFlag={loadingResults}
        >
          <Mondrian
            Options={{
              Display: MondrianDisplayType.Grid,
              DisplayOptions: { Alternator: false },
            }}
          ></Mondrian>
        </MondrianDataProvider>
      </div>
    </div>
  );
};
