import { useEffect, useState } from "react";
import { useApi } from "../../Providers/ApiProvider";

interface RegistrationPhotoProps {
  ident: string;
}

export function RegistrationPhoto(props: RegistrationPhotoProps) {
  let api = useApi();
  let [photo, setDocument] = useState<any>();

  useEffect(() => {
    (async () => {
      let doc = await api.General.getRegPhoto(props.ident);
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          // Chromium has a 2MB limit on dataURIs, so we have to convert to blobs.
          // BLOBBY BLOBBY BLOBBY!
          let blobby = dataURLtoBlob(reader.result);
          let blobbyUrl = window.URL.createObjectURL(blobby);

          // convert image file to base64 string
          setDocument(blobbyUrl);
        },
        false,
      );

      reader.readAsDataURL(doc);
    })();
  }, []);

  if (photo == undefined) {
    return <></>;
  } else {
    return (
      <div
        style={{
          backgroundImage: "url(" + photo + ")",
          backgroundSize: "cover",
        }}
      />
    );
  }
}

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
