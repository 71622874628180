import { DocumentMultiple24Filled } from "@fluentui/react-icons";
import { ReactElement } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { GridWrapper } from "../../../integrations/UoE-Grid/GridWrapper";
import { Logging } from "../../../Logging";
import { DataAccess } from "../../../data/DataAccess";
import { PublicClientApplication } from "@azure/msal-browser";

const _appsummCTs: string[] = [
  "Application Summary",
  "uaducasform",
  "Application form",
];
const _offdocCTs: string[] = ["Offer Document"];
const _casdocCTs: string[] = ["CAS Statement", "CASStatement"];
const _appdocCTs: string[] = [
  "Offer Document",
  "CAS Statement",
  "CASStatement",
  "Application Summary",
  "uaducasform",
  "Application form",
];

interface PgApplicantDocumentsProps {
  PgNo: string;
  Logger: Logging;
  da: DataAccess;
  PCA: PublicClientApplication;

  URLRoot: string;
  URLParams: string;
  Refresher: string;
  Alias: string;
  AliasChecked: NonNullable<boolean>;
  ViewAsAll: boolean;
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  RegisterError: (Reference: string, Message: string) => void;
  DeregisterError: (Reference: string) => void;
  RegisterStatus: (Reference: string, Message: string) => void;
  DeregisterStatus: (Reference: string) => void;
}

export function PgApplicantDocuments(
  props: PgApplicantDocumentsProps,
): ReactElement {
  let agentDetails: any = useRouteLoaderData("AgentRoot");

  var PassToAllGrids = {
    Callouts: false,
    Logger: props.Logger,
    da: props.da,
    PCA: props.PCA,
    GetLink: props.GetLink,
    URLRoot: props.URLRoot,
    URLParams: props.URLParams,
    pgno: props.PgNo.toUpperCase(),
    class: "UoE-Grid AOSScreen",
    Refresher: props.Refresher,
    Alias: props.Alias,
    AliasChecked: props.AliasChecked,
    ViewAsAll: props.ViewAsAll,
    RegisterError: props.RegisterError,
    DeregisterError: props.DeregisterError,
    RegisterStatus: props.RegisterStatus,
    DeregisterStatus: props.DeregisterStatus,
  };

  return (
    <>
      <div className="ApplicantGrid">
        <h2>
          <span className="LeftIcon24">
            <DocumentMultiple24Filled></DocumentMultiple24Filled>
          </span>{" "}
          <span className="HeaderText">Application Summary</span>
        </h2>
        <GridWrapper
          eid={"AOS_AppSummary_" + props.Refresher}
          {...PassToAllGrids}
          class=" AOS-eDocs"
          ForceProxy={true}
          output="edocs"
          contentTypes={_appsummCTs}
          Callouts={true}
        ></GridWrapper>
      </div>
      <div className="ApplicantGrid">
        <h2>
          <span className="LeftIcon24">
            <DocumentMultiple24Filled></DocumentMultiple24Filled>
          </span>{" "}
          <span className="HeaderText">Offer Documents</span>
        </h2>
        <GridWrapper
          eid={"AOS_OfferDocuments_" + props.Refresher}
          {...PassToAllGrids}
          class=" AOS-eDocs"
          ForceProxy={true}
          output="edocs"
          contentTypes={_offdocCTs}
          Callouts={true}
        ></GridWrapper>
      </div>
      <div className="ApplicantGrid">
        <h2>
          <span className="LeftIcon24">
            <DocumentMultiple24Filled></DocumentMultiple24Filled>
          </span>{" "}
          <span className="HeaderText">CAS Documents</span>
        </h2>
        <GridWrapper
          eid={"AOS_CASDocuments_" + props.Refresher}
          {...PassToAllGrids}
          class=" AOS-eDocs"
          ForceProxy={true}
          output="edocs"
          contentTypes={_casdocCTs}
          Callouts={true}
        ></GridWrapper>
      </div>
      <div className="ApplicantGrid">
        <h2>
          <span className="LeftIcon24">
            <DocumentMultiple24Filled></DocumentMultiple24Filled>
          </span>{" "}
          <span className="HeaderText">Applicant Documents</span>
        </h2>
        <GridWrapper
          eid={"AOS_ApplicantDocuments_" + props.Refresher}
          {...PassToAllGrids}
          class=" AOS-eDocs"
          ForceProxy={true}
          output="edocs"
          contentTypes={_appdocCTs}
          blackList={true}
          Callouts={true}
        ></GridWrapper>
      </div>
    </>
  );
}
