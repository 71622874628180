import Footer from "./Footer";
import ErrorMessage from "../models/ErrorMessage";
import StatusMessage from "../models/StatusMessage";
import { useSurface } from "../Providers/SurfaceProvider";

interface IProps {
  Environment: NonNullable<string>;
  ErrorMessages: ErrorMessage[];
  StatusMessages: StatusMessage[];
  GetLink: (
    NewModes: { Mode: string; Index: number }[],
    NewParams: { Name: string; Value: string }[],
  ) => string;
  HiVis: boolean;
  SetHiVis: (val: boolean) => void;
  MenuVisibilities: Map<string, boolean>;
  SetMenuVisibility: (name: string | undefined, newval: boolean) => void;
}

export const FooterWrapper = (props: IProps) => {
  const Surface = useSurface();

  return <Footer {...props} Surface={Surface} />;
};
