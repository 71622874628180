import { useParams } from "react-router-dom";
import "../assets/css/AutomatedMailViewer.css";
import { DataAccess } from "../data/DataAccess";
import "../assets/css/FullPageLoader.css";
import { useEffect, useState } from "react";
import { SURFACE, useSurface } from "../Providers/SurfaceProvider";

interface IProps {
  da: DataAccess;
}

export function AutomatedMailViewer(props: IProps) {
  const Surface = useSurface();
  let { personIdent, mailId } = useParams();
  let [emailList, setEmailList] = useState<any[]>();
  let [currentEmail, setCurrentEmail] = useState<any>();
  let [currentPerson, setCurrentPerson] = useState("Loading...");

  useEffect(() => {
    (async () => {
      if (
        Surface.SURFACE === SURFACE.ESF ||
        Surface.SURFACE === SURFACE.ESF_COMPLIANCE
      ) {
        props.da
          .get(
            "/sproc",
            JSON.stringify({
              sproc: "studb.dbo.esf_basic_info_top",
              params: ["@PRID", personIdent],
            }),
          )
          .then((resp) => {
            setCurrentPerson(
              resp[0][0].student
                .replace("<strong>", "")
                .replace("</strong>", ""),
            );
          });

        let emails = await props.da.getEsfEmails(personIdent);
        setEmailList(emails);
      } else {
        props.da
          .get(
            "/sproc",
            JSON.stringify({
              sproc: "sputilities.webui.UGEAF_SUMMARY_applicant_header_top",
              params: ["@ucasno", personIdent],
            }),
          )
          .then((resp) => {
            setCurrentPerson(
              resp[0][0].student
                .replace("<strong>", "")
                .replace("</strong>", ""),
            );
          });

        let emails = await props.da.getUgeafEmails(personIdent);
        setEmailList(emails);
      }
    })();
  }, []);

  useEffect(() => {
    if (emailList != undefined) {
      viewEmail(mailId);
    }
  }, [emailList, mailId]);

  function viewEmail(mailId) {
    emailList.map((e) => {
      if (e.mailItemId.toString() == mailId) {
        setCurrentEmail(e);
      }
    });
  }

  if (emailList == undefined) {
    return (
      <div className="FullPageLoader">
        <div className="LoadingLabel">Loading...</div>
        <div className="mosaic-loader">
          <div className="cell cell1"></div>
          <div className="cell cell2"></div>
          <div className="cell cell3"></div>
          <div className="cell cell4"></div>
          <div className="cell cell5"></div>
          <div className="cell cell6"></div>
          <div className="cell cell7"></div>
          <div className="cell cell8"></div>
          <div className="cell cell9"></div>
          <div className="cell cell10"></div>
          <div className="cell cell11"></div>
          <div className="cell cell12"></div>
          <div className="cell cell13"></div>
          <div className="cell cell14"></div>
          <div className="cell cell15"></div>
          <div className="cell cell16"></div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="AMV_Main">
        <div className="AMV_MailList">
          <h2 className="AMV_MailList_PersonName">
            Emails for {currentPerson}
          </h2>
          {/* <GridWrapper { ...PassToAllGrids } eid={ 'BasicInfoTop_' + props.Refresher } sproc="studb.dbo.esf_basic_info_top" params={ [ '@prid', personIdent ] } output="data"></GridWrapper> */}
          <table className="AMV_MailListTable">
            <thead className="AMV_MailListTable_Head">
              <tr className="AMV_MailListTable_Headers">
                <th className="AMV_MailListTable_Headers_Cell AMV_MailListTable_Headers_SentDate">
                  Sent Date
                </th>
                <th className="AMV_MailListTable_Headers_Cell AMV_MailListTable_Headers_Subject">
                  Subject
                </th>
                <th className="AMV_MailListTable_Headers_Cell AMV_MailListTable_Headers_Status">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="AMV_MailListTable_Body">
              {emailList.map((email) => (
                <tr
                  onClick={(_) => {
                    viewEmail(email.mailItemId);
                  }}
                  className="AMV_MailListTable_BodyRow"
                >
                  <td className="AMV_MailListTable_Body_Cell AMV_MailListTable_Body_SentDate">
                    {new Date(Date.parse(email.sentDate)).toLocaleDateString()},{" "}
                    {new Date(Date.parse(email.sentDate)).toLocaleTimeString()}
                  </td>
                  <td className="AMV_MailListTable_Body_Cell AMV_MailListTable_Body_Subject">
                    {email.subject}
                  </td>
                  <td className="AMV_MailListTable_Body_Cell AMV_MailListTable_Body_Status">
                    {email.sentStatus}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="AMV_MailPreview">
          {currentEmail !== undefined ? (
            <>
              <h2 className="AMV_MailPreview_Subject">
                {currentEmail.subject}
              </h2>
              <table className="AMV_MailPreviewTable">
                <tr className="AMV_MailPreviewTable_Row">
                  <th className="AMV_MailPreviewTable_Header">Sent:</th>
                  <td className="AMV_MailPreviewTable_Data">
                    {currentEmail.sentDate}
                  </td>
                  <th className="AMV_MailPreviewTable_Header">Recipients:</th>
                  <td className="AMV_MailPreviewTable_Data">
                    {currentEmail.recipients}
                  </td>
                  <th className="AMV_MailPreviewTable_Header"></th>
                  <td className="AMV_MailPreviewTable_Data"></td>
                </tr>
                <tr className="AMV_MailPreviewTable_Row">
                  <th className="AMV_MailPreviewTable_Header">Importance:</th>
                  <td className="AMV_MailPreviewTable_Data">
                    {currentEmail.importance}
                  </td>
                  <th className="AMV_MailPreviewTable_Header">Status:</th>
                  <td className="AMV_MailPreviewTable_Data">
                    {currentEmail.sentStatus}
                  </td>
                  <th className="AMV_MailPreviewTable_Header">Format:</th>
                  <td className="AMV_MailPreviewTable_Data">
                    {currentEmail.format}
                  </td>
                </tr>
              </table>
              <iframe
                className="AMV_MailItem"
                sandbox=""
                srcDoc={currentEmail.body}
              ></iframe>
            </>
          ) : (
            <h2 className="AMV_MailPreview_Subject">No email selected</h2>
          )}
        </div>
      </div>
    );
  }
}
