import React from "react";
import {
  Checkbox,
  Label,
  TextField,
  TooltipHost,
  Dropdown,
  IDropdownOption,
  DatePicker,
} from "@fluentui/react";
import { SPOFileState, SPOUploadFile } from "./SPInterfacing/SPOUploadFile";
import { SPOMetadataField } from "./SPInterfacing/SPOMetadataField";
import "../SPUploadManager/datepicker-mutations.css";

interface IMetaDataFieldProps {
  file: SPOUploadFile;
  field: SPOMetadataField;
  value?: string | Date | boolean;
  errorText?: string;
  UpdateField: (
    uf: SPOUploadFile,
    metadataField: string,
    value: string | boolean | Date,
  ) => void;
}

export function MetaDataField(props: IMetaDataFieldProps) {
  return (
    <React.Fragment>
      <div className="metadata-field Error">
        <TooltipHost
          content={
            "" // props.field.Description
          }
        >
          <Label className="metadata-field-label">
            {props.field.displayName}
          </Label>
        </TooltipHost>
        {props.field.type === "Text"
          ? renderTextField(props)
          : props.field.type === "Boolean"
            ? renderBoolField(props)
            : props.field.type === "DateTime"
              ? renderDateTimeField(props)
              : props.field.type === "Choice"
                ? renderChoiceField(props)
                : props.field.type === "MultiChoice"
                  ? renderMultiChoiceField(props)
                  : props.field.type === "Note"
                    ? renderTextAreaField(props)
                    : renderDefaultField(props)}
      </div>
    </React.Fragment>
  );
}

function renderDefaultField(props: IMetaDataFieldProps) {
  return (
    <React.Fragment>
      --!!-- UNKNOWN METADATA TYPE: {props.field.type} --!!--
      {props.value as string}
    </React.Fragment>
  );
}

function renderTextField(props: IMetaDataFieldProps) {
  return (
    <TextField
      disabled={props.file.State !== SPOFileState.InPreparation}
      required={props.field.required}
      value={(props.value as string) || ""}
      onChange={(_, val) =>
        props.UpdateField(props.file, props.field.internalName, val)
      }
      errorMessage={props.errorText}
    />
  );
}

function renderTextAreaField(props: IMetaDataFieldProps) {
  return (
    <TextField
      disabled={props.file.State !== SPOFileState.InPreparation}
      required={props.field.required}
      value={(props.value as string) || ""}
      onChange={(_, val) =>
        props.UpdateField(props.file, props.field.internalName, val)
      }
      multiline
      errorMessage={props.errorText}
    />
  );
}

function renderBoolField(props: IMetaDataFieldProps) {
  return (
    <Checkbox
      disabled={props.file.State !== SPOFileState.InPreparation}
      required={props.field.required}
      checked={(props.value as boolean) || false}
      onChange={(_, val) => {
        props.UpdateField(
          props.file,
          props.field.internalName,
          val === undefined ? "" : val,
        );
      }}
    />
  );
}

function renderDateTimeField(props: IMetaDataFieldProps) {
  let propsDate = new Date();

  if (Object.prototype.toString.call(props.value) == "[object Date]") {
    propsDate = props.value as Date;
  }
  if (Object.prototype.toString.call(props.value) == "[object String]") {
    propsDate = new Date(Date.parse(props.value as string));
  }

  return (
    <React.Fragment>
      <DatePicker
        formatDate={(d: Date): string => {
          return d.toLocaleDateString();
        }}
        disabled={props.file.State !== SPOFileState.InPreparation}
        isRequired={props.field.required}
        value={propsDate}
        onSelectDate={(val) => {
          props.UpdateField(props.file, props.field.internalName, val);
        }}
      />
      {props.errorText}
    </React.Fragment>
  );
}

function renderMultiChoiceField(props: IMetaDataFieldProps) {
  let _options: IDropdownOption[] = [{ key: "-", text: "-" }].concat(
    props.field.options?.map((opt) => ({
      key: opt,
      text: opt,
    })),
  );

  if (props.field.internalName === "Confidentiality") {
    _options = _options.filter((th) => th.key !== "Wellbeing Only");
  }

  if (props.field.options.length === 1) {
    props.UpdateField(
      props.file,
      props.field.internalName,
      props.field.options[0],
    );
  }

  return (
    <>
      <Dropdown
        multiSelect={true}
        disabled={props.file.State !== SPOFileState.InPreparation}
        required={props.field.required}
        options={_options}
        selectedKey={(props.value as string) ?? "-"}
        onChange={(_, val) =>
          props.UpdateField(
            props.file,
            props.field.internalName,
            val === undefined ? "" : val.key.toString(),
          )
        }
        errorMessage={props.errorText}
      />
    </>
  );
}

function renderChoiceField(props: IMetaDataFieldProps) {
  let _options: IDropdownOption[] = [{ key: "-", text: "-" }].concat(
    props.field.options?.map((opt) => ({
      key: opt,
      text: opt,
    })),
  );

  if (props.field.internalName === "Confidentiality") {
    _options = _options.filter((th) => th.key !== "Wellbeing Only");
  }

  if (props.field.options.length === 1) {
    props.UpdateField(
      props.file,
      props.field.internalName,
      props.field.options[0],
    );
  }

  return (
    <>
      <Dropdown
        multiSelect={false}
        disabled={props.file.State !== SPOFileState.InPreparation}
        required={props.field.required}
        options={_options}
        selectedKey={(props.value as string) ?? "-"}
        onChange={(_, val) =>
          props.UpdateField(
            props.file,
            props.field.internalName,
            val === undefined ? "" : val.key.toString(),
          )
        }
        errorMessage={props.errorText}
      />
    </>
  );
}
